import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext, useLayoutEffect, useRef, useState } from 'preact/hooks'
import { useQuery, useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { UploadedFile } from '@modbox/s3-uploads-client'

import styles from './styles.scss'
import { GetUserByUsernameResult } from 'store/operations/user'
import {
  GetCategoriesWithTagsResult,
  GET_CATEGORIES_WITH_TAGS,
} from 'store/operations/tagsCategory'
import { useModal, modalContent } from 'services/ModalManager'
import { EditUserModal } from 'ui/organisms/EditUserModal'
import { getTagAndAncestors, getAllTagsFromTagsCategories } from 'utils/tags'
import { stringToHexColor } from 'utils/string-to-color'
import { Badge } from 'ui/atoms/Badge'
import { Button } from 'ui/atoms/Button'
import { TagBadge } from 'ui/atoms/TagBadge'
import { formatDateAsDelay } from 'utils/date'
import { EditableAvatar } from 'ui/atoms/EditableAvatar'
import { User } from 'types/user'
import { handleEnterKey } from 'utils/components'
import { uploadModule } from 'utils/upload'
import { useAlerts } from 'services/AlertManager'
import { Avatar } from 'ui/atoms/Avatar'
import { CurrentUser } from 'store/local/user'
import { resizeImageFile } from 'utils/files'

const shortDateFormatter = Intl.DateTimeFormat('fr-FR', {
  month: 'short',
  year: '2-digit',
})

export const UserCard: FunctionalComponent<{
  user: GetUserByUsernameResult['getUserByUsername']
  update: (
    update: Partial<User>,
    newAvatarFile?: UploadedFile,
  ) => Promise<boolean>
  isEditable: boolean
  loading: boolean
}> = ({ user, isEditable, update, loading }) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()
  const [avatarLoading, setAvatarLoading] = useState(false)
  const authenticatedUser = useReactiveVar(CurrentUser)

  const { data: getTagsData, loading: getTagsLoading } =
    useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)

  const color = stringToHexColor(user.username)
  const lastSeenAt = user.lastSeenAt ? new Date(user.lastSeenAt) : null
  const isOnline = lastSeenAt
    ? Date.now() - lastSeenAt.getTime() < 5 * 60 * 1000
    : false
  const [isEditingBio, setIsEditingBio] = useState(false)
  const bioTextareaRef = useRef<HTMLTextAreaElement>()

  const [editUserModal] = useModal(modalContent(EditUserModal), {
    title: t('core.forum.user.edit_teacher_tags_title'),
    size: 'small',
  })

  const showEditUserModal = (): void =>
    user &&
    getTagsData?.getCategoriesWithTags &&
    editUserModal.show({
      user,
      categoriesWithTags: getTagsData.getCategoriesWithTags,
    })

  const teacherTags =
    getTagsData?.getCategoriesWithTags && user && user.tagNodeId
      ? getTagAndAncestors(
          getAllTagsFromTagsCategories(getTagsData.getCategoriesWithTags),
          user.tagNodeId,
        )
      : []

  useLayoutEffect(() => {
    if (bioTextareaRef.current && isEditable && isEditingBio) {
      bioTextareaRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bioTextareaRef.current, isEditable, isEditingBio])

  const handleError = (): void =>
    void showAlert({
      type: 'error',
      message: t('core.user_profile.avatar.upload_error'),
    })

  const updateAvatar = async (file: File | null): Promise<void> => {
    setAvatarLoading(true)
    const handleFile = async (fileToUpload: File): Promise<UploadedFile> => {
      // Resize avatar to make it fit the limit and reduce storage impacts
      const resizedFile = await resizeImageFile(fileToUpload, {
        maxWidth: 200,
        maxHeight: 200,
        compressType: 'PNG',
      })

      // Upload the resized file
      return await (await uploadModule.Avatar.uploadOne(resizedFile)).promise
    }

    // Upload the file if exist
    const newAvatar = file ? await handleFile(file) : undefined

    // Update profile with new avatar.
    // When avatar is deleted we pass null as avatarUrl
    // To upload a new avatar we pass the new avatar file as avatarFile
    const success = await update(
      newAvatar ? {} : { avatarUrl: null },
      newAvatar,
    )
    success || handleError()
    setAvatarLoading(false)
  }

  return (
    <div
      className={styles['profile-wrapper']}
      style={color ? { backgroundColor: color } : ''}
    >
      <div className={styles['darken-background']}>
        <div className="container">
          <div className={styles.profile}>
            <div className={styles.avatar}>
              {isEditable ? (
                <EditableAvatar
                  size="xl"
                  url={user.avatarUrl || undefined}
                  username={user.username}
                  className={styles['avatar-img']}
                  onUpdate={updateAvatar}
                  onDelete={() => updateAvatar(null)}
                  loading={loading || avatarLoading}
                />
              ) : (
                <Avatar
                  size="xl"
                  url={user.avatarUrl || undefined}
                  username={user.username}
                  className={styles['avatar-img']}
                />
              )}
            </div>
            <div className={styles.content}>
              <div className={styles['username-and-badges']}>
                <span>{user.username}</span>
                {user.isTeacher && (
                  <Badge
                    iconBadge
                    icon={<i className="fas fa-user-graduate" />}
                    tooltip={t('core.user_profile.teacher_badge_tooltip', {
                      username: user.username,
                    })}
                    tooltipPosition="top"
                    tooltipWidth="fit-content"
                    tooltipClassName={styles['teacher-badge-tooltip']}
                  />
                )}
                {isEditable && (
                  <Button
                    iconOnly
                    rounded
                    onClick={showEditUserModal}
                    style="link-darken-hover"
                    size="xs"
                  >
                    <i className="fas fa-pencil-alt" />
                  </Button>
                )}
              </div>
              <div className={styles['teacher-tags']}>
                {user.isTeacher &&
                  teacherTags.map((t) => (
                    <TagBadge key={`user-tag-${t.name}`} tag={t} />
                  ))}
                {!user.isTeacher && isEditable && (
                  <Button
                    style="link"
                    className={styles['add-teacher-tags-link']}
                    size="sm"
                    padding="none"
                    onClick={showEditUserModal}
                  >
                    {t('core.forum.user.add_teacher_tags_link')}
                  </Button>
                )}
              </div>

              <div className={styles['last-seen-and-joined']}>
                {user.preferences.discloseOnline && lastSeenAt && (
                  <div>
                    {isOnline ? (
                      <Fragment>
                        <i
                          className={`fas fa-circle ${styles.online}`}
                          style={{ color: '#7fba00' }}
                        />
                        {t('core.user_profile.online_text')}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <i className="far fa-clock" />
                        {formatDateAsDelay(t, lastSeenAt)}
                      </Fragment>
                    )}
                  </div>
                )}
                <div>
                  {t('core.user_profile.joined_date_text', {
                    monthAndYear: shortDateFormatter.format(
                      new Date(user.joinedAt),
                    ),
                  })}
                </div>
              </div>
              {user.preferences.discloseEmail && (
                <div className={styles['contact-me']}>
                  <Button
                    style="link-darken-hover"
                    padding="xs"
                    size="sm"
                    bold={false}
                    disabled={!authenticatedUser}
                    onClick={() =>
                      user.email &&
                      window.open(`mailto:${user.email}`, '_blank')
                    }
                    tooltip={
                      !authenticatedUser
                        ? t('core.user_profile.login_to_contact_me_text', {
                            username: user.username,
                          })
                        : undefined
                    }
                    tooltipPosition="bottom"
                    className={
                      !authenticatedUser ? styles['disabled-contact-btn'] : ''
                    }
                  >
                    <i className="fas fa-envelope" />
                    {t('core.user_profile.contact_me_text', {
                      username: user.username,
                    })}
                  </Button>
                </div>
              )}

              <div
                className={`${styles.bio} ${
                  isEditable && isEditingBio ? styles.editing : ''
                } ${isEditable ? styles.editable : ''}`}
                onClick={
                  !isEditingBio && isEditable && !loading
                    ? () => setIsEditingBio(true)
                    : undefined
                }
              >
                {isEditingBio ? (
                  <textarea
                    className="FormControl"
                    ref={bioTextareaRef}
                    placeholder={t('core.user_profile.self_bio_placeholder')}
                    rows={3}
                    value={user.bio ?? undefined}
                    disabled={loading}
                    onKeyDown={handleEnterKey(() =>
                      bioTextareaRef.current.blur(),
                    )}
                    onBlur={async () => {
                      setIsEditingBio(false)
                      if (
                        (!!user.bio || !!bioTextareaRef.current.value) &&
                        user.bio !== bioTextareaRef.current.value
                      ) {
                        const success = await update({
                          bio: bioTextareaRef.current.value,
                        })
                        success || handleError()
                      }
                    }}
                  />
                ) : (
                  <p
                    className={user.bio ? '' : styles.placeholder}
                    dangerouslySetInnerHTML={{
                      __html:
                        !user.bio && isEditable
                          ? t('core.user_profile.self_bio_placeholder')
                          : user.bio?.replace('\n', '<br />') ||
                            t('core.user_profile.user_bio_placeholder', {
                              username: user.username,
                            }),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
