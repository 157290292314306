import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import { fromEvent, getGraphQLErrors, handleEnterKey } from 'utils/components'
import { Button } from 'ui/atoms/Button'
import {
  UpdateUserEmailInput,
  UpdateUserEmailResult,
  UPDATE_USER_EMAIL,
} from 'store/operations/user'
import { ModalContent } from 'services/ModalManager'
import { useAlerts } from 'services/AlertManager'

export const ChangeEmailModal: ModalContent<{ email: string }> = ({
  onDismiss,
  email: initialEmail,
  autoFocusRef,
}) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()
  const [email, setEmail] = useState(initialEmail)
  const [password, setPassword] = useState('')

  const [updateEmail, { loading, error, called }] = useMutation<
    UpdateUserEmailResult,
    UpdateUserEmailInput
  >(UPDATE_USER_EMAIL, {
    onError: (e) => {
      showAlert({ type: 'error', message: getGraphQLErrors([e])[0] })
    },
  })
  // Dismiss on success
  const success = called && !loading && !error

  const submit = (): void =>
    void updateEmail({
      variables: { newEmail: email, password },
    })

  return success ? (
    <div className="Modal-body">
      <div className="Form Form--centered">
        <p className="helpText">
          {t('core.user_profile.settings.change_email.confirmation_message', {
            email,
          })}
        </p>
        <div className="Form-group">
          <Button onClick={onDismiss}>
            {t('core.user_profile.settings.change_email.dismiss_button')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="Modal-body">
      {
        <div className="Form Form--centered">
          <div className="Form-group">
            <input
              className="FormControl"
              name="email"
              type="email"
              placeholder={initialEmail}
              disabled={loading}
              ref={autoFocusRef}
              value={email}
              onKeyPress={handleEnterKey(submit)}
              onChange={fromEvent(setEmail)}
            />
          </div>
          <div className="Form-group">
            <input
              className="FormControl"
              name="password"
              type="password"
              placeholder={t(
                'core.user_profile.settings.change_email.confirm_password_placeholder',
              )}
              disabled={loading}
              value={password}
              onKeyPress={handleEnterKey(submit)}
              onChange={fromEvent(setPassword)}
            />
          </div>

          <div className="Form-group">
            <Button loading={loading} onClick={submit}>
              {t('core.user_profile.settings.change_email.submit_button')}
            </Button>
          </div>
        </div>
      }
    </div>
  )
}
