import { gql } from '@apollo/client'
import { UploadedFile } from '@modbox/s3-uploads-client'

import {
  Discussion,
  DiscussionSubscription,
  DiscussionWithLastPostData,
} from 'types/discussion'
import {
  DiscussionFragment,
  DiscussionWithLastPostDataFragment,
} from './fragments/discussion'

export type GetDiscussionByIdInput = {
  id: string
}
export type GetDiscussionByIdResult = {
  getDiscussionById: Discussion
}
export const GET_DISCUSSION_BY_ID = gql`
  query GetDiscussionById($id: ID!) {
    getDiscussionById(id: $id) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type GetUserDiscussionsInput = {
  limit: number
  offset: number
  userId: string
}
export type GetUserDiscussionsResult = {
  getUserDiscussions: {
    discussions: DiscussionWithLastPostData[]
    offset: number
    count: number
    totalCount: number
  }
}
export const GET_USER_DISCUSSIONS = gql`
  query GetUserDiscussions($offset: Int!, $limit: Int!, $userId: ID!) {
    getUserDiscussions(offset: $offset, limit: $limit, userId: $userId) {
      offset
      count
      totalCount
      discussions {
        ...DiscussionWithLastPostDataFragment
      }
    }
  }

  ${DiscussionWithLastPostDataFragment}
`

export type CreateDiscussionInput = {
  title: string
  markdownContent: string
  tagIds: string[]
  files?: UploadedFile[]
}
export type CreateDiscussionResult = {
  createDiscussion: Discussion
}
export const CREATE_DISCUSSION = gql`
  mutation CreateDiscussion(
    $title: String!
    $markdownContent: String!
    $tagIds: [ID!]!
    $files: [UploadedFile!]
  ) {
    createDiscussion(
      title: $title
      markdownContent: $markdownContent
      tagIds: $tagIds
      files: $files
    ) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type UpdateDiscussionTitleInput = {
  id: string
  title: string
}
export type UpdateDiscussionTitleResult = {
  updateDiscussionTitle: Discussion
}
export const UPDATE_DISCUSSION_TITLE = gql`
  mutation UpdateDiscussionTitle($id: ID!, $title: String!) {
    updateDiscussionTitle(id: $id, title: $title) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type UpdateDiscussionTagsInput = {
  discussionId: string
  tagIds: string[]
}
export type UpdateDiscussionTagsResult = {
  updateDiscussionTags: Discussion
}
export const UPDATE_DISCUSSION_TAGS = gql`
  mutation UpdateDiscussionTags($discussionId: ID!, $tagIds: [ID!]!) {
    updateDiscussionTags(discussionId: $discussionId, tagIds: $tagIds) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type LockDiscussionInput = {
  id: string
  lock: boolean
}
export type LockDiscussionResult = {
  lockDiscussion: Discussion
}
export const LOCK_DISCUSSION = gql`
  mutation LockDiscussion($id: ID!, $lock: Boolean!) {
    lockDiscussion(id: $id, lock: $lock) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type StickDiscussionInput = {
  id: string
  stick: boolean
}
export type StickDiscussionResult = {
  stickDiscussion: Discussion
}
export const STICK_DISCUSSION = gql`
  mutation StickDiscussion($id: ID!, $stick: Boolean!) {
    stickDiscussion(id: $id, stick: $stick) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type UpdateDiscussionSubscriptionInput = {
  discussionId: string
  subscription: DiscussionSubscription | null
}
export type UpdateDiscussionSubscriptionResult = {
  updateDiscussionSubscription: Discussion
}
export const UPDATE_DISCUSSION_SUBSCRIPTION = gql`
  mutation UpdateDiscussionSubscription(
    $discussionId: ID!
    $subscription: DiscussionSubscription
  ) {
    updateDiscussionSubscription(
      discussionId: $discussionId
      subscription: $subscription
    ) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`

export type DeleteDiscussionInput = {
  discussionId: string
}
export type DeleteDiscussionResult = {
  deleteDiscussion: Discussion
}
export const DELETE_DISCUSSION = gql`
  mutation DeleteDiscussion($discussionId: ID!) {
    deleteDiscussion(discussionId: $discussionId) {
      ...DiscussionFragment
    }
  }

  ${DiscussionFragment}
`
