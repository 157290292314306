import camelcase from 'camelcase'

export const objectToCamelCase = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.keys(obj).reduce((acc, k) => {
    const v = obj[k]

    const newKey = camelcase(k)
    const newValue =
      v && typeof v === 'object' && !Array.isArray(v)
        ? objectToCamelCase(v as Record<string, unknown>)
        : v

    return {
      ...acc,
      [newKey]: newValue,
    }
  }, {})
}
