/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const PostControls = flarum.core.compat['utils/PostControls']
const Button = flarum.core.compat['components/Button']

import FlagPostModal from './components/FlagPostModal'

export default function() {
  extend(PostControls, 'userControls', function(items, post) {
    if (post.isHidden() || post.contentType() !== 'comment' || !post.canFlag())
      return

    items.add(
      'flag',
      <Button
        icon="fas fa-flag"
        onclick={() => app.modal.show(new FlagPostModal({ post }))}
      >
        {app.translator.trans('flarum-flags.forum.post_controls.flag_button')}
      </Button>,
    )
  })
}
