import { FunctionalComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { fromEvent } from 'utils/components'
import { UpdateResourcePayload } from 'types/resource'

export const ResourceAuthorStep: FunctionalComponent<{
  payload: UpdateResourcePayload
  onChange: (payload: UpdateResourcePayload) => void
}> = ({ payload, onChange }) => {
  const { t } = useContext(TranslateContext)

  const { originalAuthor } = payload

  const partialUpdate = (update: Partial<UpdateResourcePayload>): void =>
    onChange({
      ...payload,
      ...update,
    })

  return (
    <div className={`Form Form--centered ${styles.form}`}>
      <div className="Form-group">
        <input
          className={`FormControl  ${styles.FormControl}`}
          name="originalAuthor"
          type="text"
          placeholder={t(
            'core.resources.update_resource_modal.original_author_placeholder',
          )}
          value={originalAuthor}
          onInput={fromEvent((originalAuthor) =>
            partialUpdate({ originalAuthor }),
          )}
        />
      </div>

      <div className={styles['legal-notice']}>
        {t('core.resources.update_resource_modal.legal_notice', {
          buttonLabel: t('core.resources.update_resource_modal.send_button'),
        })}
      </div>
    </div>
  )
}
