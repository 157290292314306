import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'

export const Stepper: FunctionalComponent<{
  steps: string[]
  currentStep: number
  className?: string
}> = ({ steps, currentStep, className }) => {
  return (
    <div className={`${styles.stepper} ${className || ''}`}>
      <div className={styles['stepper-progress']}>
        {steps.map((step, idx) => {
          const isDone = idx < currentStep
          const isCurrent = idx === currentStep

          return (
            <div
              key={`step-progress-${idx}`}
              className={`${styles.step} ${
                isDone ? styles.done : styles.undone
              } ${isCurrent ? styles.current : ''}`}
            >
              <i className={isDone ? 'fas fa-check' : 'fas fa-circle'} />
            </div>
          )
        })}
      </div>
      <div className={styles['stepper-labels']}>
        {steps.map((step, idx) => {
          return <div key={`step-label-${idx}`}>{step}</div>
        })}
      </div>
    </div>
  )
}
