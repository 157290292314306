import { TranslateContext } from '@denysvuika/preact-translate'
import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'

import { fromEvent, handleEnterKey } from 'utils/components'
import { Button } from 'ui/atoms/Button'
import { ModalContent } from 'services/ModalManager'

export const HyperlinkModal: ModalContent<{
  onConfirm: (text: string, url: string) => void
}> = ({ onDismiss, onConfirm, autoFocusRef }) => {
  const { t } = useContext(TranslateContext)

  const [text, setText] = useState('')
  const [url, setUrl] = useState('')

  const confirm = (): void => {
    if (!text || !url) {
      return
    }

    onConfirm(text, url)
    onDismiss()
    setText('')
    setUrl('')
  }

  return (
    <div className="Modal-body">
      <div className="Form Form--centered">
        <div className="Form-group">
          <input
            className="FormControl"
            name="text"
            type="text"
            placeholder={t('core.composer.link_modal.text_placeholder')}
            value={text}
            onInput={fromEvent(setText)}
            ref={autoFocusRef}
            onKeyPress={handleEnterKey(confirm)}
          />
        </div>
        <div className="Form-group">
          <input
            className="FormControl"
            name="text"
            type="text"
            placeholder={t('core.composer.link_modal.url_placeholder')}
            value={url}
            onInput={fromEvent(setUrl)}
            onKeyPress={handleEnterKey(confirm)}
          />
        </div>
        <div className="Form-group">
          <Button
            style="btn-primary"
            disabled={!text || !url}
            fill
            onClick={confirm}
          >
            {t('core.composer.link_modal.confirm_button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
