import { h, FunctionalComponent } from 'preact'
import styles from './styles.scss'

export const ResourceContentSummary: FunctionalComponent<{
  content: string
}> = ({ content }) => {
  return (
    <div className={`${styles['resource-content-summary']}`}>
      <div className={`container ${styles.summary}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
}
