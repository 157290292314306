/** @jsx m */

/**
 * The `Session` class defines the current user session. It stores a reference
 * to the current authenticated user, and provides methods to log in/out.
 */
export default class Session {
  constructor(user, accessToken) {
    /**
     * The current authenticated user.
     *
     * @type {User|null}
     * @public
     */
    this.user = user || null

    /**
     * The AccessToken.
     *
     * @type {String|null}
     * @public
     */
    this.accessToken = accessToken || null
  }

  // Remove session from localStorage
  static flushSessionMeta() {
    localStorage.removeItem('userId')
    localStorage.removeItem('accessToken')
  }

  // TODO: Should be factorized with the initial boot request in a single fn
  async refreshForumRessource() {
    const res = await app.request({
      method: 'GET',
      url: app.forum.attribute('apiUrl'),
    })

    // HACK: Ensure no attributes survive the update
    const emptyAttributes = Object.keys(app.forum.data.attributes).reduce(
      (attrs, key) => Object.assign(attrs, { [key]: undefined }),
      {},
    )
    res.data.attributes = {
      ...emptyAttributes,
      ...res.data.attributes,
      apiUrl: app.forum.attribute('apiUrl'),
    }

    app.store.pushPayload(res)
    app.forum = app.store.getById('forums', 1)
  }

  async afterLogin() {
    await app.store.find('users', this.user.id())
    await this.refreshForumRessource()
    m.redraw()
  }

  async afterLogout() {
    await this.refreshForumRessource()
    m.route('/')
    m.redraw()
  }
}
