import { gql } from '@apollo/client'

import { FullTagsCategoryWithTagsFragment } from './fragments/tagsCategory'
import { TagsCategoryWithTags } from 'types/tagsCategory'

export type GetCategoriesWithTagsResult = {
  getCategoriesWithTags: TagsCategoryWithTags[]
}
export const GET_CATEGORIES_WITH_TAGS = gql`
  query GetCategoriesWithTags {
    getCategoriesWithTags {
      ...FullTagsCategoryWithTags
    }
  }

  ${FullTagsCategoryWithTagsFragment}
`
