import { gql } from '@apollo/client'
import { FullTagFragment } from './tag'

export const FullTagsCategoryFragment = gql`
  fragment FullTagsCategory on TagsCategory {
    id
    name
    slug
    description
    isForResource
    isForUser
  }
`

export const FullTagsCategoryWithTagsFragment = gql`
  fragment FullTagsCategoryWithTags on TagsCategory {
    ...FullTagsCategory
    tags {
      ...FullTag
    }
  }

  ${FullTagsCategoryFragment}
  ${FullTagFragment}
`
