import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import { Button } from 'ui/atoms/Button'
import {
  UpdateUserInput,
  UpdateUserResult,
  UPDATE_USER,
} from 'store/operations/user'
import {
  toggleEvent,
  getGraphQLErrors,
  fromEvent,
  handleEnterKey,
} from 'utils/components'
import { DisplayUser, User } from 'types/user'
import { getAllTagsFromTagsCategories, getTagParent } from 'utils/tags'
import { TagsCategoryWithTags } from 'types/tagsCategory'
import { TeacherTagsSelect } from 'ui/molecules/TeacherTagsSelect'
import { ModalContent } from 'services/ModalManager'
import { updateCachedUser } from 'store/cache/user'
import { fromMutationResult } from 'store/cache/utils'

export const EditUserModal: ModalContent<{
  user: DisplayUser & Pick<User, 'isTeacher' | 'tagNodeId'>
  categoriesWithTags: TagsCategoryWithTags[]
}> = ({ onDismiss, setAlerts, user, categoriesWithTags, autoFocusRef }) => {
  const { t } = useContext(TranslateContext)

  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName, setLastName] = useState(user.lastName || '')
  const [isTeacher, setIsTeacher] = useState(user.isTeacher)
  const [tagNodeId, setTagNodeId] = useState<string | null>(user.tagNodeId)

  const [update, { error: updateError, loading: updateLoading }] = useMutation<
    UpdateUserResult,
    UpdateUserInput
  >(UPDATE_USER, {
    onCompleted: onDismiss,
    update: fromMutationResult(updateCachedUser, {
      incomingUser: 'updateUser',
    }),
  })

  const isValid = isTeacher
    ? !!tagNodeId &&
      !!getTagParent(
        getAllTagsFromTagsCategories(categoriesWithTags),
        tagNodeId,
      )
    : true

  const updateUser = (): void =>
    void update({
      variables: {
        user: {
          id: user.id,
          firstName: firstName || null,
          lastName: lastName || null,
          isTeacher,
          tagNodeId: isTeacher && tagNodeId ? tagNodeId : null,
        },
      },
    })

  setAlerts(getGraphQLErrors([updateError]))

  return (
    <div className="Modal-body">
      <div className="Form Form--centered">
        <div className="Form-group">
          <input
            className="FormControl"
            name="username"
            type="text"
            placeholder={t('core.user.edit.first_name_placeholder')}
            value={firstName}
            onInput={fromEvent(setFirstName)}
            disabled={updateLoading}
            ref={autoFocusRef}
            onKeyPress={handleEnterKey(updateUser)}
          />
        </div>

        <div className="Form-group">
          <input
            className="FormControl"
            name="username"
            type="text"
            placeholder={t('core.user.edit.last_name_placeholder')}
            value={lastName}
            onInput={fromEvent(setLastName)}
            disabled={updateLoading}
            onKeyPress={handleEnterKey(updateUser)}
          />
        </div>

        <div className="Form-group">
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                disabled={updateLoading}
                checked={isTeacher}
                onInput={toggleEvent(setIsTeacher, isTeacher)}
              />
              {t('core.user.edit.is_teacher_placeholder')}
            </label>
          </div>
        </div>

        {isTeacher && (
          <TeacherTagsSelect
            categoriesWithTags={categoriesWithTags}
            onChange={setTagNodeId}
            tagNodeId={tagNodeId}
          />
        )}

        <div className="Form-group">
          <Button
            disabled={!isValid}
            fill
            loading={updateLoading}
            onClick={updateUser}
          >
            {t('core.user.edit.submit')}
          </Button>
        </div>
      </div>
    </div>
  )
}
