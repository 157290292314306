import { Tag, TagWithCategory } from 'types/tag'
import { TagsCategoryWithTags } from 'types/tagsCategory'

export const getTagFrom = <T extends Tag>(
  tags: T[],
  tagNodeId: T['id'],
): T | undefined => tags.find((t) => t.id === tagNodeId)

export const getTagParent = <T extends Tag>(
  tags: T[],
  tagNodeId: T['id'],
): T | undefined => {
  const me = getTagFrom(tags, tagNodeId)
  return (me && me.parentId && getTagFrom(tags, me.parentId)) || undefined
}

export const getTagAndAncestors = <T extends Tag>(
  tags: T[],
  tagNodeId: T['id'],
): T[] => {
  const me = getTagFrom(tags, tagNodeId)

  if (me) {
    if (me.parentId) {
      return [me, ...getTagAndAncestors(tags, me.parentId)]
    }

    return [me]
  }

  return []
}

export const getAllTagsFromTagsCategories = (
  categories: TagsCategoryWithTags[],
): TagWithCategory[] => {
  return categories.reduce<TagWithCategory[]>(
    (acc, c) => [
      ...acc,
      ...c.tags.map((t) => ({
        ...t,
        category: { ...c, tags: undefined },
      })),
    ],
    [],
  )
}

export const getTagAndAncestorsByCategory = (
  categories: TagsCategoryWithTags[],
  tagNodeId: Tag['id'],
): { [categoryId: string]: TagWithCategory | undefined } => {
  const flattenTags = getAllTagsFromTagsCategories(categories)

  const meAndAncestors = getTagAndAncestors(flattenTags, tagNodeId)

  return meAndAncestors.reduce(
    (acc, a) => ({
      ...acc,
      [a.category.id]: a,
    }),
    {},
  )
}

export const getTagChildren = <T extends Tag>(
  tags: T[],
  tagNodeId: T['id'] | null,
): T[] => {
  return tags.filter((t) => t.parentId === tagNodeId)
}
