/** @jsx m */

import 'bootstrap/js/affix'
import 'bootstrap/js/dropdown'
import 'bootstrap/js/modal'
import 'bootstrap/js/tooltip'
import 'bootstrap/js/transition'
import 'jquery.hotkeys/jquery.hotkeys'

import patchMithril from './utils/patchMithril'

patchMithril(window)

import * as Extend from './extend/index'
import Session from './Session'

export { Extend, Session }
