import { h, FunctionalComponent } from 'preact'
import { route } from 'preact-router'
import { getRouteUrl, PageType } from 'common/routing'
import { stringToHexColor } from 'utils/string-to-color'

import styles from './styles.scss'

export interface AvatarProps {
  url?: string | null
  username?: string
  size: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  goToProfileOnClick?: boolean
}

export const Avatar: FunctionalComponent<AvatarProps> = ({
  url,
  className = '',
  size,
  username,
  goToProfileOnClick = false,
}) => {
  if (!url) {
    return (
      <div
        className={`${styles.avatar} ${styles[size]} ${className} ${
          goToProfileOnClick ? styles.clickable : ''
        }`}
        style={{
          backgroundColor: `#${stringToHexColor(username || '?')}`,
        }}
        onClick={
          goToProfileOnClick && username
            ? () => route(getRouteUrl(PageType.USER_PROFILE, { username }))
            : undefined
        }
      >
        {(username || '?').charAt(0).toUpperCase()}
      </div>
    )
  }

  return (
    <img
      src={url}
      className={`${styles.avatar} ${styles[size]} ${className} ${
        goToProfileOnClick ? styles.clickable : ''
      }`}
      onClick={
        goToProfileOnClick && username
          ? () => route(getRouteUrl(PageType.USER_PROFILE, { username }))
          : undefined
      }
    />
  )
}
