/** @jsx m */

const { extend } = flarum.core.compat['extend']
const SettingsPage = flarum.core.compat['components/SettingsPage']
const FieldSet = flarum.core.compat['components/FieldSet']
const Switch = flarum.core.compat['components/Switch']
const ItemList = flarum.core.compat['utils/ItemList']

export default function() {
  extend(SettingsPage.prototype, 'notificationsItems', function(items) {
    items.add(
      'followAfterReply',
      Switch.component({
        children: app.translator.trans(
          'flarum-subscriptions.forum.settings.follow_after_reply_label',
        ),
        state: this.user.preferences().followAfterReply,
        onchange: this.preferenceSaver('followAfterReply'),
      }),
    )
  })
}
