import { DisplayPost } from './post'
import { DisplayResource } from './resource'
import { DisplayUser } from './user'

export enum ModerationFlagType {
  POST = 'POST',
  RESOURCE = 'RESOURCE',
}

export type ModerationFlag =
  | {
      id: string
      type: ModerationFlagType.POST
      postId: string
      post: DisplayPost
      resourceId: null
      resource: null
      userId: string
      reason: string
      reasonDetail: string
      createdAt: string
      user: DisplayUser
    }
  | {
      id: string
      type: ModerationFlagType.RESOURCE
      postId: null
      post: null
      resourceId: string
      resource: DisplayResource
      userId: string
      reason: string
      reasonDetail: string
      createdAt: string
      user: DisplayUser
    }
