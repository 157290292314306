import { h, FunctionalComponent, RefObject } from 'preact'
import { useEffect, useLayoutEffect, useState } from 'preact/hooks'
import MarkdownArea from 'mdarea'

import styles from './styles.scss'
import mdStyles from 'ui/quarks/Markdown.scss'
import marked from 'marked'

export const TextEditor: FunctionalComponent<{
  value: string
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  placeholder?: string
  disabled?: boolean
  minHeight?: string
  maxHeight?: string
  editorRef: RefObject<HTMLTextAreaElement>
  preview?: boolean
}> = ({
  value,
  onChange,
  onBlur,
  placeholder,
  disabled = false,
  minHeight = '100px',
  maxHeight = '60vh',
  editorRef,
  preview = false,
}) => {
  const [editor, setEditor] = useState<MarkdownArea | null>(null)
  const [editorHeight, setEditorHeight] = useState<number | null>(null)
  const [localValue, setLocalValue] = useState<string>(value)

  useEffect(() => {
    if (!editorRef.current) {
      if (editor) {
        editor.destroy()
        setEditor(null)
      }

      return
    }

    setEditor(new MarkdownArea(editorRef.current, { keyMap: { inline: [] } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current])

  useLayoutEffect(() => {
    if (editorRef.current) {
      setEditorHeight(editorRef.current.scrollHeight)
    }
  }, [editorHeight, editorRef])

  if (preview) {
    return (
      <div
        className={mdStyles.markdown}
        style={editorHeight ? `${editorHeight}px` : 'auto'}
        dangerouslySetInnerHTML={{ __html: marked(value, { breaks: true }) }}
      />
    )
  }

  return (
    <div className={styles['text-editor']}>
      <textarea
        ref={editorRef}
        className="FormControl Composer-flexible"
        onInput={(e) => {
          setEditorHeight(null)
          const updated = (e.target as { value: string } | null)?.value || ''
          onChange ? onChange(updated) : setLocalValue(updated)
        }}
        onBlur={
          onBlur && (() => (onChange ? onBlur(value) : onBlur(localValue)))
        }
        placeholder={placeholder}
        disabled={disabled}
        value={onChange ? value : localValue}
        style={{
          height: editorHeight ? `${editorHeight}px` : 'auto',
          minHeight,
          maxHeight,
        }}
      />
    </div>
  )
}
