import { h, Fragment, Component, JSX, createRef } from 'preact'

import styles from './styles.scss'
import { TranslateContext } from '@denysvuika/preact-translate'
import { ContextValue } from 'utils/types'
import {
  AnyExtendedProps,
  ModalContentFactory,
  ModalProperties,
  ModalSetProps,
} from 'services/ModalManager'
import { Button } from 'ui/atoms/Button'

export class Modal extends Component<
  ModalProperties<AnyExtendedProps> & {
    content: ModalContentFactory<AnyExtendedProps>
    setProps: ModalSetProps
  }
> {
  static contextType = TranslateContext
  context!: ContextValue<typeof TranslateContext>
  autoFocusRef = createRef<HTMLInputElement>()

  previousShow = false
  shouldFocus = false

  componentDidUpdate(): void {
    if (this.shouldFocus) {
      this.autoFocusRef.current?.focus()
    }
  }

  render(): JSX.Element {
    const {
      show,
      onDismiss,
      className = '',
      isDismissible,
      title,
      alerts,
      size,
    } = this.props
    const { setProps, content, ...contentProps } = this.props

    if (this.previousShow !== show && show) {
      this.shouldFocus = true
    } else {
      this.shouldFocus = false
    }

    if (this.previousShow !== show) {
      this.previousShow = show
    }

    return (
      <Fragment>
        <div
          className={`ModalManager modal fade ${
            show ? `in ${styles.show}` : styles.hide
          }`}
          // onClick={isDismissible ? onDismiss : undefined}
        >
          {show ? (
            <div
              class={`${className} Modal modal-dialog ${
                size ? `Modal--${size}` : ''
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div class="Modal-content">
                {isDismissible ? (
                  <div class="Modal-close App-backControl">
                    <Button iconOnly size="sm" style="link" onClick={onDismiss}>
                      <i className="fas fa-times" />
                    </Button>
                  </div>
                ) : null}

                <div class="Modal-header">
                  <h3 class="App-titleControl App-titleControl--text">
                    {title}
                  </h3>
                </div>

                {alerts && alerts.length ? (
                  <div class="Modal-alert">
                    {alerts.map((message, idx) => (
                      <div
                        className="Alert Alert--error"
                        key={`alert-message-${idx}`}
                      >
                        <span className="Alert-body">
                          {this.context.t(message)}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}

                {content(contentProps, setProps, this.autoFocusRef)}
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={`modal-backdrop fade ${
            show ? `in ${styles.show}` : styles.hide
          }`}
          onClick={(e) => e.stopPropagation()}
        />
      </Fragment>
    )
  }
}
