import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'
import {
  AnyExtendedProps,
  ModalManagerContext,
  ModalProperties,
} from 'services/ModalManager'
import { Modal } from 'ui/atoms/Modal'

export const ModalRoot: FunctionalComponent = () => {
  const [modals] = useContext(ModalManagerContext)

  return (
    <div id="modals">
      {modals.map((m) => {
        return (
          <Modal
            key={`modal-${m.id}`}
            {...(m.properties as ModalProperties<AnyExtendedProps>)}
            setProps={m.setProps}
            content={m.content}
          />
        )
      })}
    </div>
  )
}
