import { gql } from '@apollo/client'

import { DisplayUserFragment } from './user'

export const DisplayPostFragment = gql`
  fragment DisplayPostFragment on Post {
    id
    type
    discussionId
    number
    createdAt
    content
    markdownContent
    discussionTitle
  }
`

export const PostFragment = gql`
  fragment PostFragment on Post {
    ...DisplayPostFragment
    editedAt
    editedByUserId
    deletedAt
    deletedByUserId
    deletedByUsername
    uploads {
      url
      bucket
      key
      filename
      mimetype
      size
    }
    author {
      ...DisplayUser
    }
    likedByUsers {
      id
      username
      avatarUrl
    }
    canLike
    canUpdate
    canDelete
    canFlag
  }

  ${DisplayPostFragment}
  ${DisplayUserFragment}
`
