import { h, FunctionalComponent } from 'preact'
import styles from './ResourceTitle.css'

interface ResourceTitlePropsInterface {
  value: string
}

export const ResourceTitle: FunctionalComponent<
  ResourceTitlePropsInterface
> = ({ value }) => {
  return <div className={`${styles['infos-title']}`}>{value}</div>
}
