import { h, FunctionalComponent } from 'preact'
import { useContext, useRef } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { Composer, ComposerProps } from 'ui/molecules/Composer'
import { fromEvent } from 'utils/components'

export const DiscussionComposer: FunctionalComponent<
  ComposerProps & {
    values: { title: string }
    onChange: { title: (title: string) => void }
  }
> = ({
  values: { title, ...composerValues },
  onChange: { title: onTitleChange, ...composerOnChange },
  ...otherComposerProps
}) => {
  const { t } = useContext(TranslateContext)
  const autoFocusRef = useRef<HTMLInputElement>(null)

  return (
    <Composer
      {...otherComposerProps}
      values={composerValues}
      onChange={composerOnChange}
      autoFocusRef={autoFocusRef}
    >
      <div className={styles.header}>
        <input
          ref={autoFocusRef}
          className={styles['title-input']}
          onInput={fromEvent(onTitleChange)}
          value={title}
          placeholder={t(
            'core.community.discussions_page.composer_title_placeholder',
          )}
        />
      </div>
    </Composer>
  )
}
