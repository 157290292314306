/** @jsx m */

const { extend, override } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const Discussion = flarum.core.compat['models/Discussion']
const Post = flarum.core.compat['models/Post']
const Badge = flarum.core.compat['components/Badge']
const DiscussionListItem = flarum.core.compat['components/DiscussionListItem']
const PostComponent = flarum.core.compat['components/Post']
const CommentPost = flarum.core.compat['components/CommentPost']
const Button = flarum.core.compat['components/Button']
const PostControls = flarum.core.compat['utils/PostControls']

app.initializers.add(
  'flarum-approval',
  () => {
    Discussion.prototype.isApproved = Discussion.attribute('isApproved')

    extend(Discussion.prototype, 'badges', function(items) {
      if (!this.isApproved() && !items.has('hidden')) {
        items.add(
          'awaitingApproval',
          <Badge
            type="awaitingApproval"
            icon="fas fa-gavel"
            label={app.translator.trans(
              'flarum-approval.forum.badge.awaiting_approval_tooltip',
            )}
          />,
        )
      }
    })

    Post.prototype.isApproved = Post.attribute('isApproved')
    Post.prototype.canApprove = Post.attribute('canApprove')

    extend(DiscussionListItem.prototype, 'attrs', function(attrs) {
      if (!this.props.discussion.isApproved()) {
        attrs.className += ' DiscussionListItem--unapproved'
      }
    })

    extend(PostComponent.prototype, 'attrs', function(attrs) {
      if (!this.props.post.isApproved()) {
        attrs.className += ' Post--unapproved'
      }
    })

    extend(CommentPost.prototype, 'headerItems', function(items) {
      if (!this.props.post.isApproved() && !this.props.post.isHidden()) {
        items.add(
          'unapproved',
          app.translator.trans(
            'flarum-approval.forum.post.awaiting_approval_text',
          ),
        )
      }
    })

    override(PostComponent.prototype, 'flagReason', function(original, flag) {
      if (flag.type() === 'approval') {
        return app.translator.trans(
          'flarum-approval.forum.post.awaiting_approval_text',
        )
      }

      return original(flag)
    })

    extend(PostControls, 'destructiveControls', function(items, post) {
      if (!post.isApproved() && post.canApprove()) {
        items.add(
          'approve',
          <Button
            icon="fas fa-check"
            onclick={PostControls.approveAction.bind(post)}
          >
            {app.translator.trans(
              'flarum-approval.forum.post_controls.approve_button',
            )}
          </Button>,
          10,
        )
      }
    })

    PostControls.approveAction = function() {
      this.save({ isApproved: true })

      if (this.number() === 1) {
        this.discussion().pushAttributes({ isApproved: true })
      }
    }
  },
  -10,
) // set initializer priority to run after reports
