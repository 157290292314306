import { gql } from '@apollo/client'

export const DisplayUserFragment = gql`
  fragment DisplayUser on DisplayUser {
    id
    username
    email
    firstName
    lastName
    avatarUrl
    lastSeenAt
    joinedAt
    bio
    preferences {
      discloseEmail
      discloseOnline
    }
    isTeacher
    tagNodeId
  }
`

export const FullUserFragment = gql`
  fragment FullUser on User {
    id
    username
    firstName
    lastName
    email
    isEmailConfirmed
    bio
    avatarUrl
    preferences {
      discloseEmail
      discloseOnline
      followAfterReply
      indexProfile
      notifyResourceRecommendedAlert
      notifyDiscussionLockedAlert
      notifyDiscussionRenamedAlert
      notifyNewPostAlert
      notifyNewPostEmail
      notifyPostLikedAlert
      notifyPostMentionedAlert
      notifyPostMentionedEmail
      notifyUserMentionedAlert
      notifyUserMentionedEmail
      notifyUserSuspendedAlert
      notifyUserUnsuspendedAlert
      newsletterSubscription
    }
    joinedAt
    lastSeenAt
    markedAllAsReadAt
    readNotificationAt
    discussionCount
    readFlagAt
    suspendedUntil
    isTeacher
    tagNodeId
  }
`

export const FullAccessTokenFragment = gql`
  fragment FullAccessToken on AccessToken {
    token
    userId
  }
`
