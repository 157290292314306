/** @jsx m */

const { extend } = flarum.core.compat['extend']
const DiscussionControls = flarum.core.compat['utils/DiscussionControls']
const Button = flarum.core.compat['components/Button']

import TagDiscussionModal from './components/TagDiscussionModal'

export default function() {
  // Add a control allowing the discussion to be moved to another category.
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canTag()) {
      items.add(
        'tags',
        Button.component({
          children: app.translator.trans(
            'flarum-tags.forum.discussion_controls.edit_tags_button',
          ),
          icon: 'fas fa-tag',
          onclick: () => app.modal.show(new TagDiscussionModal({ discussion })),
        }),
      )
    }
  })
}
