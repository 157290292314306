import { h, FunctionalComponent } from 'preact'
import { useAlerts } from 'services/AlertManager'
import { Alert } from 'ui/atoms/Alert'

import styles from './styles.scss'

export const AlertGroup: FunctionalComponent = () => {
  const [alerts] = useAlerts()

  return (
    <div className={styles['alert-group']}>
      {alerts.map((a, idx) => (
        <Alert key={`alert-${idx}`} {...a.attributes} onDismiss={a.dismiss} />
      ))}
    </div>
  )
}
