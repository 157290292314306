import { h } from 'preact'
import { useMutation } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useContext } from 'preact/hooks'

import { ModalContent } from 'services/ModalManager'
import { FlagPostForm } from './FlagPostForm'
import { FlagPostSuccess } from './FlagPostSuccess'
import {
  AddModerationFlagInput,
  AddModerationFlagResult,
  ADD_MODERATION_FLAG,
} from 'store/operations/moderationFlags'

export const FlagPostModal: ModalContent<{
  postId: string
}> = ({ postId, onDismiss, setAlerts }) => {
  const { t } = useContext(TranslateContext)

  const [flagPost, { loading, error, called }] = useMutation<
    AddModerationFlagResult,
    AddModerationFlagInput
  >(ADD_MODERATION_FLAG)
  const showForm = !called || loading || error

  setAlerts(error ? [t('core.lib.error.generic_message')] : [])

  return showForm ? (
    <div className="Modal-body">
      <FlagPostForm
        loading={loading}
        onFlag={(reason, reasonDetail) =>
          void flagPost({
            variables: { postId, reason, reasonDetail },
          })
        }
      />
    </div>
  ) : (
    <FlagPostSuccess onDismiss={onDismiss} />
  )
}
