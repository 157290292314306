import { gql } from '@apollo/client'

export const PresignedRequestInfoFragment = gql`
  fragment PresignedRequestInfoFragment on PresignedRequestInfo {
    url
    fields {
      key
      value
    }
    headers {
      key
      value
    }
  }
`

export const InitiatedUploadFragment = gql`
  fragment InitiatedUploadFragment on InitiatedUpload {
    uploadType
    uploadMode
    filename
    mimetype
    bucket
    key
    size
    uploadId
    chunkSize
    partsCount
    presignedRequest {
      ...PresignedRequestInfoFragment
    }
  }

  ${PresignedRequestInfoFragment}
`
