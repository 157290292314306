/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const HeaderSecondary = flarum.core.compat['components/HeaderSecondary']
import FlagsDropdown from './components/FlagsDropdown'

export default function() {
  extend(HeaderSecondary.prototype, 'items', function(items) {
    if (app.forum.attribute('canViewFlags')) {
      items.add('flags', <FlagsDropdown />, 15)
    }
  })
}
