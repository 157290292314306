import { ApolloError } from '@apollo/client'

export const fromEvent = <T extends string = string>(
  fn: (value: T) => void,
) => (e: Event): void => {
  const target = e.target as HTMLInputElement
  fn(target.value as T)
}

export const toggleEvent = (
  fn: (value: boolean) => void,
  value: boolean,
) => (): void => {
  fn(!value)
}

export const getGraphQLErrors = (
  errors: Array<ApolloError | undefined>,
): string[] =>
  errors.reduce<string[]>((all, e) => (!!e && all.concat(e.message)) || all, [])

export const handleEnterKey = (
  cb: (e?: KeyboardEvent) => unknown,
  next?: (e: KeyboardEvent) => unknown,
) => (e: KeyboardEvent): void => {
  if (e.keyCode === 13 && !e.shiftKey) {
    cb(e)
  } else {
    next && next(e)
  }
}
