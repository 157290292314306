import { h, FunctionalComponent, AnyComponent, ComponentChildren } from 'preact'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { Button } from 'ui/atoms/Button'
import { useContext } from 'preact/hooks'

export interface AlertBannerProps {
  className?: string
  type: 'info' | 'error' | 'warning' | 'success' | 'neutral'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: AnyComponent<any, any>[]
  dismissible?: boolean
  onDismiss?: () => void
  // Setting duration to 0 will disable alert auto-dismiss
  duration?: number
  children: ComponentChildren
}

export const AlertBanner: FunctionalComponent<AlertBannerProps> = ({
  className = '',
  type,
  dismissible,
  controls,
  onDismiss,
  children,
}) => {
  const { t } = useContext(TranslateContext)

  return (
    <div
      className={`${styles['alert-banner']} ${styles[type]} ${className || ''}`}
    >
      <div className="container">
        <div>{children}</div>
      </div>
      <div className={styles.controls}>
        {controls}
        {dismissible && (
          <Button onClick={onDismiss} style="link" size="sm" padding="none">
            <i className="fas fa-times" />
          </Button>
        )}
      </div>
    </div>
  )
}
