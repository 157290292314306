import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { DiscussionWithLastPostData } from 'types/discussion'
import { Avatar } from 'ui/atoms/Avatar'
import { TagBadge } from 'ui/atoms/TagBadge'
import { formatDateAsDelay } from 'utils/date'

import styles from './styles.scss'

export const DiscussionItem: FunctionalComponent<{
  discussion: DiscussionWithLastPostData
  onClick: () => void
  className?: string
}> = ({ discussion, className, onClick }) => {
  const { t } = useContext(TranslateContext)

  return (
    <div
      className={`${styles['discussion-item']} ${className || ''}`}
      onClick={onClick}
    >
      <div>
        <Avatar
          size="md"
          url={discussion.author?.avatarUrl}
          username={discussion.author?.username}
        />
      </div>
      <div>
        <div>
          {discussion.tags.map((t) => (
            <TagBadge
              key={`discussion-${discussion.id}-tag-${t.id}`}
              tag={t}
              className={styles.tag}
            />
          ))}
        </div>
        <div className={styles.title}>{discussion.title}</div>
        <div className={styles['last-action']}>
          {discussion.lastPostNumber !== 1 && <i className="fas fa-reply" />}{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                `core.discussion_list.discussion_item.${
                  discussion.lastPostNumber === 1 ? 'started' : 'replied'
                }_text`,
                {
                  username: `<strong>${discussion.lastPostedUser.username}</strong>`,
                  ago: formatDateAsDelay(t, discussion.lastPostedAt),
                },
              ),
            }}
          />
        </div>
      </div>
      <div className={styles['reply-number']}>
        <i className="far fa-comment" /> {discussion.commentCount - 1}
      </div>
    </div>
  )
}
