import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import { ModalContent } from 'services/ModalManager'
import { Button } from 'ui/atoms/Button'
import styles from './styles.scss'

export const ConfirmDiscussionDeletionModal: ModalContent<{
  onConfirm: () => void
}> = ({ onDismiss, onConfirm }) => {
  const { t } = useContext(TranslateContext)

  return (
    <div className="Modal-body">
      <div className={`Form-group ${styles['action-buttons']}`}>
        <Button style="btn-info" onClick={onDismiss}>
          {t('core.community.discussion_page.delete_modal.cancel')}
        </Button>
        <Button
          style="btn-danger"
          onClick={() => {
            onConfirm()
            onDismiss()
          }}
        >
          {t('core.community.discussion_page.delete_modal.confirm')}
        </Button>
      </div>
    </div>
  )
}
