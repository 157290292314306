import { Component } from 'preact'
import { route } from 'preact-router'

export class Redirect extends Component<{ to: string }> {
  componentDidMount(): void {
    route(this.props.to, true)
  }

  render(): null {
    return null
  }
}
