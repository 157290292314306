/** @jsx m */

const Component = flarum.core.compat['Component']
import tagIcon from '../../common/helpers/tagIcon'

export default class TagCategoryLinkButton extends Component {
  view() {
    const { category, expanded, onclick } = this.props
    const description = category && category.description()
    const children = category
      ? category.name()
      : app.translator.trans('flarum-tags.forum.index.untagged_link')

    return (
      <a
        className={`TagCategoryButton hasIcon ${expanded ? 'text-bold' : ''}`}
        onclick={onclick}
        title={description || ''}
      >
        {tagIcon(category, { className: 'Button-icon' }, { useColor: false })}
        {children}
        <i
          class={`expand-icon icon fas fa-${
            expanded ? 'angle-down' : 'angle-right'
          }`}
        />
      </a>
    )
  }
}
