import { h, FunctionalComponent } from 'preact'
import { S3Object, UploadedFile } from '@modbox/s3-uploads-client'

import { ProgressBar } from 'ui/atoms/ProgressBar'
import { FileItem } from '../FileItem'
import styles from './styles.scss'

export enum EditorToolbarUploadState {
  Idle,
  Uploading,
  Uploaded,
  Removed,
}
export type EditorToolbarUpload<
  S extends EditorToolbarUploadState = EditorToolbarUploadState
> = S extends EditorToolbarUploadState.Uploading
  ? {
      state: EditorToolbarUploadState.Uploading
      file: File
    }
  : S extends EditorToolbarUploadState.Uploaded
  ? {
      state: EditorToolbarUploadState.Uploaded
      file: UploadedFile
    }
  : S extends EditorToolbarUploadState.Idle
  ? {
      state: EditorToolbarUploadState.Idle
      file: S3Object
    }
  : {
      state: EditorToolbarUploadState.Removed
      file: S3Object
    }

export const ToolbarUpload: FunctionalComponent<{
  uploads?: EditorToolbarUpload[]
  progressPercentage?: number
  onRemove?: (upload: EditorToolbarUpload) => void
}> = ({ uploads = [], onRemove, progressPercentage }) => {
  if (!uploads.length) {
    return null
  }
  const showProgressBar =
    uploads.some(
      (upload) => upload.state === EditorToolbarUploadState.Uploading,
    ) && progressPercentage !== undefined

  return (
    <div className={styles['toolbar-uploads']}>
      {!!uploads.length &&
        uploads
          .filter(
            (
              u,
            ): u is Exclude<
              EditorToolbarUpload,
              { state: EditorToolbarUploadState.Removed }
            > => u.state !== EditorToolbarUploadState.Removed,
          )
          .map((upload, idx) => (
            <FileItem
              loading={upload.state === EditorToolbarUploadState.Uploading}
              key={`texteditor-toolbar-upload-${
                upload.state === EditorToolbarUploadState.Uploading
                  ? upload.file.name
                  : upload.file.filename
              }-${idx}`}
              file={
                upload.state === EditorToolbarUploadState.Uploading
                  ? { mimetype: upload.file.type, filename: upload.file.name }
                  : {
                      mimetype: upload.file.mimetype,
                      filename: upload.file.filename,
                    }
              }
              className={styles['upload-item']}
              canRemove={!!onRemove}
              onAction={{ remove: onRemove && (() => onRemove(upload)) }}
            />
          ))}
      {showProgressBar && (
        <div className={styles['progress-bar']}>
          <ProgressBar progressPercentage={progressPercentage} />
        </div>
      )}
    </div>
  )
}
