import { FunctionalComponent, h } from 'preact'
import { useContext, useRef, useState } from 'preact/hooks'
import { useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'

import { DropdownItem } from 'ui/atoms/DropdownItem'
import { DropdownButton } from 'ui/molecules/DropdownButton'
import { Button } from 'ui/atoms/Button'
import styles from './styles.scss'
import { DropdownSeparator } from 'ui/atoms/DropdownSeparator'
import { IsAuthenticated, IsUserVerified } from 'store/local/user'

export const DiscussionReplyAndManageButton: FunctionalComponent<{
  sticky: boolean
  locked: boolean
  onPost: () => void
  onRename: () => void
  onStick: () => void
  onLock: () => void
  onTagEdit: () => void
  onDelete: () => void
  permissions: {
    canPost: boolean
    canManage: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}> = ({
  sticky,
  locked,
  onPost,
  onRename,
  onLock,
  onStick,
  onTagEdit,
  onDelete,
  permissions: { canPost, canManage, canDelete, canUpdate },
}) => {
  const { t } = useContext(TranslateContext)

  const expandButtonRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const isAuthenticated = useReactiveVar(IsAuthenticated)
  const isUserVerified = useReactiveVar(IsUserVerified)
  const cantReplyTooltip =
    !canPost && !isAuthenticated
      ? t('core.community.discussion_page.can_t_reply_unauthenticated')
      : !canPost && !isUserVerified
      ? t('core.community.discussion_page.can_t_reply_unverified')
      : !canPost && locked
      ? t('core.community.discussion_page.can_t_reply_locked')
      : undefined

  const ReplyButton = (
    <Button
      fill
      size="sm"
      padding="sm"
      disabled={!canPost}
      tooltip={cantReplyTooltip}
      onClick={onPost}
    >
      {t('core.community.discussion_page.reply_button')}
    </Button>
  )

  if (!canManage && !canDelete && !canUpdate) {
    return ReplyButton
  }

  return (
    <DropdownButton
      align="right"
      for={expandButtonRef}
      onExpandChange={setExpanded}
      buttons={[
        ReplyButton,
        <Button
          ref={expandButtonRef}
          key="manage-dpd-btn-2"
          active={expanded}
          style="btn-primary"
          padding="sm"
          size="sm"
          iconOnly
        >
          <i className={'fas fa-caret-down'} />
        </Button>,
      ]}
    >
      {canUpdate && (
        <DropdownItem hasIcon iconClassName="fas fa-pen" onClick={onRename}>
          {t('core.community.discussion_page.manage_dropdown.rename_button')}
        </DropdownItem>
      )}
      {canManage && [
        <DropdownItem
          key="manage-dpd-btn-lock"
          hasIcon
          iconClassName={`fas fa-${locked ? 'unlock' : 'lock'}`}
          onClick={onLock}
        >
          <span className={styles['dropdown-item']}>
            {t(
              `core.community.discussion_page.manage_dropdown.${
                locked ? 'unlock' : 'lock'
              }_button`,
            )}
          </span>
        </DropdownItem>,
        <DropdownItem
          key="manage-dpd-btn-stick"
          hasIcon
          iconClassName="fas fa-thumbtack"
          onClick={onStick}
        >
          <span className={styles['dropdown-item']}>
            {t(
              `core.community.discussion_page.manage_dropdown.${
                sticky ? 'unsticky' : 'sticky'
              }_button`,
            )}
          </span>
        </DropdownItem>,
      ]}
      {canUpdate && (
        <DropdownItem hasIcon iconClassName="fas fa-tag" onClick={onTagEdit}>
          <span className={styles['dropdown-item']}>
            {t(
              'core.community.discussion_page.manage_dropdown.update_tags_button',
            )}
          </span>
        </DropdownItem>
      )}
      {(canUpdate || canManage) && canDelete && <DropdownSeparator />}
      {canDelete && (
        <DropdownItem
          hasIcon
          iconClassName="far fa-trash-alt"
          onClick={onDelete}
        >
          <span className={styles['dropdown-item']}>
            {t('core.community.discussion_page.manage_dropdown.delete_button')}
          </span>
        </DropdownItem>
      )}
    </DropdownButton>
  )
}
