/** @jsx m */

const app = flarum.core.compat['app']

import downloadButtonInteraction from './downloadButtonInteraction'
import addUploadButton from './addUploadButton'

app.initializers.add('fof-upload', () => {
  addUploadButton()
  downloadButtonInteraction()
})
