import { h, FunctionalComponent } from 'preact'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { ResourceCard } from 'ui/organisms/ResourceCard'
import { Button } from 'ui/atoms/Button'
import { CreateResourceModal } from 'ui/organisms/CreateResourceModal'
import {
  GetResourcesResult,
  GET_RESOURCES,
  GetResourcesInput,
} from 'store/operations/resource'
import { getGraphQLErrors } from 'utils/components'
import { Loader } from 'ui/atoms/Loader'
import {
  GET_CATEGORIES_WITH_TAGS,
  GetCategoriesWithTagsResult,
} from 'store/operations/tagsCategory'
import { Pagination } from 'ui/organisms/Pagination'
import { Link, route } from 'preact-router'
import { CurrentResourcePageNumber } from 'store/local/navigation'
import { ResourceFilters } from 'ui/organisms/ResourceFilters'
import { Tag } from 'types/tag'
import { modalContent, useModal } from 'services/ModalManager'
import { CurrentUser } from 'store/local/user'
import {
  ResourceSearchTerms,
  SearchTagNodeId,
  SearchTagNodeIdSkipped,
  SearchTagsFilter,
} from 'store/local/search'
import { LegacyComponent } from 'legacy/compat'
import WelcomeHero from 'legacy/src/core/js/forum/components/WelcomeHero'
import { getRouteUrl, PageType } from 'common/routing'
import { Banner, BannerType } from 'ui/atoms/Banner'

const ResourcesPage: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)

  const [createResourceModal] = useModal(modalContent(CreateResourceModal), {
    title: t('core.resources.new_resource_modal.title'),
    size: 'large',
  })
  const skipped = useReactiveVar(SearchTagNodeIdSkipped)
  const searchTagsFilter = useReactiveVar(SearchTagsFilter)
  const searchTagNodeId = useReactiveVar(SearchTagNodeId)
  const page = useReactiveVar(CurrentResourcePageNumber)
  const authenticatedUser = useReactiveVar(CurrentUser)
  const searchTerms = useReactiveVar(ResourceSearchTerms)
  const allPublic = searchTagNodeId === null && skipped

  const {
    data: getResourcesData,
    loading: getResourcesLoading,
    error: getResourcesError,
  } = useQuery<GetResourcesResult, GetResourcesInput>(GET_RESOURCES, {
    variables: {
      tagsFilter: searchTagsFilter,
      teacherTagNodeId: searchTagNodeId || undefined,
      allPublic,
      pageIndex: page,
      pageItemCount: 6,
      searchTerms,
    },
  })
  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)

  const errors = getGraphQLErrors([getResourcesError, getTagsError])
  const resources = getResourcesData?.getResources.resources
  const isResultRelevant = getResourcesData?.getResources.isRelevant
  const bannerType: BannerType | null = errors.length
    ? 'error'
    : !resources?.length
    ? 'info'
    : !isResultRelevant
    ? 'info'
    : null
  const bannerContent = errors.length ? (
    <div>{t('core.lib.error.generic_message')}</div>
  ) : !resources?.length ? (
    t('core.resources.index.no_resource_for_current_search')
  ) : !isResultRelevant ? (
    <div>
      {t('core.resources.index.irrelevant_result_text')}
      <br />
      {t('core.resources.index.irrelevant_result_text_2')}{' '}
      <Link href={getRouteUrl(PageType.DISCUSSIONS)}>
        {t('core.resources.index.irrelevant_result_community_link')}
      </Link>
    </div>
  ) : null

  return (
    <section className={styles['main-container']}>
      <LegacyComponent component={WelcomeHero} section="resource" />
      <div className={`container ${styles['page-content-container']}`}>
        <div className={`${styles['page-content']}`}>
          <div className={styles.aside}>
            <Button
              className={styles['new-resource-button']}
              onClick={() => !!authenticatedUser && createResourceModal.show()}
              tooltip={
                authenticatedUser
                  ? undefined
                  : t('core.resources.index.new_resource_forbidden_tooltip')
              }
              tooltipPosition="bottom"
              disabled={!authenticatedUser}
              fill
            >
              {t('core.resources.index.new_resource_button')}
            </Button>
            <div
              className={`${styles['relevance-hint']} ${
                searchTagNodeId !== null || allPublic ? styles.visible : ''
              }`}
            >
              <i className={`fas fa-info ${styles.icon}`} />{' '}
              {t('core.resources.filter_menu.filter_relevance_hint')}
            </div>
            {getTagsData?.getCategoriesWithTags ? (
              <ResourceFilters
                className={styles['filters-menu']}
                teacherCategoriesAndTags={getTagsData.getCategoriesWithTags.filter(
                  (c) => c.isForUser,
                )}
                resourceTags={getTagsData.getCategoriesWithTags
                  .filter((c) => c.isForResource && !c.isForUser)
                  .reduce<Tag[]>((acc, c) => acc.concat(c.tags), [])}
                selectedTagNodeId={searchTagNodeId}
                selectedResourceTags={searchTagsFilter}
                skipped={skipped}
                onChange={({ tagNodeId, resourceTags, skipped }) => {
                  tagNodeId !== undefined && SearchTagNodeId(tagNodeId)
                  resourceTags !== undefined && SearchTagsFilter(resourceTags)
                  skipped !== undefined && SearchTagNodeIdSkipped(skipped)

                  // Reset page index after filter changed
                  CurrentResourcePageNumber(0)
                }}
              />
            ) : null}
          </div>
          <div className={styles['main-content']}>
            <Loader
              loaded={!getResourcesLoading}
              size="md"
              className={styles.loader}
            >
              {bannerType && bannerContent && (
                <Banner
                  type={bannerType}
                  className={styles['resource-banner-message']}
                >
                  {bannerContent}
                </Banner>
              )}
              {!isResultRelevant && !!resources?.length && (
                <div className={styles['might-like']}>
                  {t('core.resources.index.some_resources_you_might_like')}
                </div>
              )}
              {!errors.length &&
                !!resources?.length &&
                resources.map((r, idx) => (
                  <ResourceCard
                    key={`resource-${idx}-${r.id}`}
                    resource={r}
                    onClick={() => route(`/resource/${r.id}`)}
                    showScore={!!searchTagNodeId || allPublic}
                  />
                ))}
            </Loader>
          </div>
        </div>
      </div>

      <div className={`container flex ${styles['pagination-container']}`}>
        <Pagination
          pageCount={getResourcesData?.getResources.pageCount}
          currentPageIndex={page}
          className={styles.pagination}
          onPageChange={CurrentResourcePageNumber}
        />
      </div>
    </section>
  )
}

export default ResourcesPage
