import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'

export const DropdownItem: FunctionalComponent<{
  hasIcon?: boolean
  iconClassName?: string
  onClick?: () => void
  className?: string
}> = ({
  hasIcon = false,
  iconClassName = '',
  children,
  onClick,
  className,
}) => {
  return (
    <li
      className={`${className || ''} ${styles['item-container']} ${
        hasIcon ? styles.hasIcon : ''
      }`}
      onClick={onClick}
    >
      {hasIcon && iconClassName ? (
        <i className={`${styles.icon} ${iconClassName}`} />
      ) : null}
      <div className={styles.content}>{children}</div>
    </li>
  )
}
