import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { route } from 'preact-router'
import { useContext } from 'preact/hooks'
import { getRouteUrl, PageType } from 'common/routing'
import { PartenersLogo } from 'ui/molecules/PartenersLogo'

export const Footer: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)

  return (
    <div className="bg-neutral-800 text-white pt-8 pb-16">
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-y-6">
        <div className="font-roboto-slab text-2xl md:col-span-2">
          {t('core.footer.title')}
        </div>
        <ul className="text-base font-bold list-none leading-8 p-0 inline-block mr-16 align-top">
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href="https://www.helloasso.com/associations/enseignerleclimat/formulaires/1"
              target="_blank"
            >
              {t('core.footer.donate')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href="https://docs.google.com/document/d/e/2PACX-1vQqDNh_SHnKF1E7pA6hw_gT78Z4MQ0uDsHklorQrX3EDZz34Vb8OJYkNNO31VORJo7u2tPLAYVdEEQs/pub"
            >
              {t('core.footer.faq')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href={`mailto:contact@enseignerleclimat.org`}
            >
              {t('core.footer.contact_us')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href="https://docs.google.com/document/d/e/2PACX-1vT4cc_Sus7bCPuaHT2tnP8T18aOoaUs549szPTBgDn5il7ayRzgp9WCPRv2mDzKGwI5GGq6oqV76bDD/pub"
            >
              {t('core.footer.legal_mentions')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href="https://docs.google.com/document/d/e/2PACX-1vRBWIvRx0ewYtsGiwT6sLH9Wko0OdgZ0HzuknEnSIBPPykcLQjNUzERKuq9BHOFSue67RWJ7kGlX2vX/pub"
            >
              {t('core.footer.terms_of_use')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href="https://docs.google.com/document/d/e/2PACX-1vT9t4SI3AR3_FVjXBUi7AblGoj3FNwdsnE8B_xYyok7YFiF3DdBOEKqWwa6bETyCABv8Jr6YkoezujI/pub"
            >
              {t('core.footer.community_charter')}
            </a>
          </li>
        </ul>

        <ul className="text-base font-bold list-none leading-8 p-0 inline-block mr-16 align-top">
          <li onClick={() => route('/resources')}>
            <a
              className="text-white"
              href={getRouteUrl(PageType.RESOURCES)}
              onClick={(e) => {
                e.preventDefault()
                route(getRouteUrl(PageType.RESOURCES))
              }}
            >
              {t('core.footer.resources')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href={getRouteUrl(PageType.DISCUSSIONS)}
              onClick={(e) => {
                e.preventDefault()
                route(getRouteUrl(PageType.DISCUSSIONS))
              }}
            >
              {t('core.footer.community')}
            </a>
          </li>
          <li className="cursor-pointer hover:underline">
            <a
              className="text-white"
              href={getRouteUrl(PageType.ABOUT)}
              onClick={(e) => {
                e.preventDefault()
                route(getRouteUrl(PageType.ABOUT))
              }}
            >
              {t('core.footer.about_us')}
            </a>
          </li>
        </ul>

        <PartenersLogo
          brightLogos
          className="grid-cols-4 gap-x-6 md:col-span-2 max-w-md mt-6"
        />
      </div>
    </div>
  )
}
