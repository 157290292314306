/** @jsx m */

import Component from '../../common/Component'
import humanTime from '../../common/utils/humanTime'
import ItemList from '../../common/utils/ItemList'
import UserControls from '../utils/UserControls'
import avatar from '../../common/helpers/avatar'
import username from '../../common/helpers/username'
import icon from '../../common/helpers/icon'
import Dropdown from '../../common/components/Dropdown'
import AvatarEditor from './AvatarEditor'
import listItems from '../../common/helpers/listItems'

/**
 * The `UserCard` component displays a user's profile card. This is used both on
 * the `UserPage` (in the hero) and in discussions, shown when hovering over a
 * post author.
 *
 * ### Props
 *
 * - `user`
 * - `className`
 * - `editable`
 * - `controlsButtonClassName`
 */
export default class UserCard extends Component {
  view() {
    const user = this.props.user
    const controls = UserControls.controls(user, this).toArray()
    const color = user.color()
    const badges = user.badges().toArray()

    const teacherTags =
      this.props.teacherMetadata &&
      this.props.teacherMetadata.isTeacher &&
      this.props.teacherMetadata.tags
        ? this.props.teacherMetadata.tags.map((t, idx) => (
            <span
              style={`background: #E3EAF1; border-radius: 2px; padding: 4px 8px; display: inline-flex; vertical-align: middle; margin-left: ${
                idx === 0 ? '12' : '6'
              }px;`}
            >
              <span style="align-self: center; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 12px; line-height: 14px; color: #849AA4;">
                {t.name}
              </span>
            </span>
          ))
        : this.props.teacherMetadata && this.props.editable
        ? [
            <button
              className={`Button Button--link ${this.props.addTeacherTagsLinkClassName}`}
              onclick={this.props.showEditUserModal}
            >
              {app.translator.trans('core.forum.user.add_teacher_tags_link')}
            </button>,
          ]
        : null

    if (this.props.editable) {
      teacherTags.push(
        <button
          className="Button Button--icon Button--flat"
          style="margin-left: 6px;"
          onclick={this.props.showEditUserModal}
        >
          <i
            className="icon fas fa-pencil-alt Button-icon"
            style="vertical-align: middle; font-size: 14px;"
          ></i>
        </button>,
      )
    }

    return (
      <div
        className={'UserCard ' + (this.props.className || '')}
        style={color ? { backgroundColor: color } : ''}
      >
        <div className="darkenBackground">
          <div className="container">
            {controls.length
              ? Dropdown.component({
                  children: controls,
                  className: 'UserCard-controls App-primaryControl',
                  menuClassName: 'Dropdown-menu--right',
                  buttonClassName: this.props.controlsButtonClassName,
                  label: app.translator.trans(
                    'core.forum.user_controls.button',
                  ),
                  icon: 'fas fa-ellipsis-v',
                })
              : ''}

            <div className="UserCard-profile">
              <h2 className="UserCard-identity">
                {this.props.editable ? (
                  [
                    AvatarEditor.component({
                      user,
                      className: 'UserCard-avatar',
                      updateAvatar: this.props.updateAvatar,
                      onUpdateAvatarError: this.props.onUpdateAvatarError,
                    }),
                    username(user),
                    ...teacherTags,
                  ]
                ) : (
                  <a
                    href={app.route.user(user)}
                    onclick={() => m.route(app.route.user(user))}
                  >
                    <div className="UserCard-avatar">{avatar(user)}</div>
                    {username(user)}
                    {teacherTags}
                  </a>
                )}
              </h2>

              {badges.length ? (
                <ul className="UserCard-badges badges">{listItems(badges)}</ul>
              ) : (
                ''
              )}

              <ul className="UserCard-info">
                {listItems(this.infoItems().toArray())}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Build an item list of tidbits of info to show on this user's profile.
   *
   * @return {ItemList}
   */
  infoItems() {
    const items = new ItemList()
    const user = this.props.user
    const lastSeenAt = user.lastSeenAt()

    if (lastSeenAt) {
      const online = user.isOnline()

      items.add(
        'lastSeen',
        <span className={'UserCard-lastSeen' + (online ? ' online' : '')}>
          {online
            ? [
                icon('fas fa-circle'),
                ' ',
                app.translator.trans('core.forum.user.online_text'),
              ]
            : [icon('far fa-clock'), ' ', humanTime(lastSeenAt)]}
        </span>,
      )
    }

    items.add(
      'joined',
      app.translator.trans('core.forum.user.joined_date_text', {
        ago: humanTime(user.joinTime()),
      }),
    )

    return items
  }
}
