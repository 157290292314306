import { FunctionalComponent, h } from 'preact'
import { route, useRouter } from 'preact-router'

import { Button } from 'ui/atoms/Button'
import { Portal } from 'ui/atoms/Portal'
import { getRouteUrl, isRouteIn, PageGroup, PageType } from 'common/routing'

const routeToBack = {
  [PageGroup.COMMUNITY]: [PageType.DISCUSSIONS],
  [PageGroup.RESOURCE]: [PageType.RESOURCES],
} as const

export const BackButton: FunctionalComponent = () => {
  const [{ path }] = useRouter()

  if (!path) {
    return null
  }

  const [, params] =
    Object.entries(routeToBack).find(([group]) =>
      isRouteIn((+group as unknown) as PageGroup, path),
    ) || []

  return params ? (
    <Portal into="#header-navigation">
      <Button
        iconOnly
        style="link-primary-faded"
        onClick={() => route(getRouteUrl(params[0]))}
        size="sm"
      >
        <i className="fas fa-chevron-left" />
      </Button>
    </Portal>
  ) : null
}
