import { h, FunctionalComponent, RefObject } from 'preact'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { CancellableUpload, S3Object } from '@modbox/s3-uploads-client'

import styles from './styles.scss'
import { TextEditor } from 'ui/molecules/TextEditor'
import { Button } from 'ui/atoms/Button'
import { TextEditorToolbar } from 'ui/molecules/TextEditorToolbar'
import { uploadModule } from 'utils/upload'
import { useAlerts } from 'services/AlertManager'
import {
  EditorToolbarUpload,
  EditorToolbarUploadState,
} from 'ui/molecules/TextEditorToolbar/ToolbarUpload'

export interface ComposerProps {
  values: { text: string; uploads: EditorToolbarUpload[] }
  onChange: {
    text: (value: string) => void
    uploads: (
      fn: (currentState: EditorToolbarUpload[]) => EditorToolbarUpload[],
    ) => void
  }
  onUploadRemove: (upload: EditorToolbarUpload) => void
  loading?: boolean
  placeholder?: string
  show: boolean
  onDismiss: () => void
  onSend?: () => void
  autoFocusRef?: RefObject<HTMLInputElement> | RefObject<HTMLTextAreaElement>
}

export const Composer: FunctionalComponent<ComposerProps> = ({
  onChange: { text: onTextChange, uploads: onUploadsChange },
  onUploadRemove,
  values: { text, uploads },
  placeholder = '',
  show,
  onDismiss,
  onSend,
  loading = false,
  children,
  autoFocusRef,
}) => {
  const { t } = useContext(TranslateContext)
  const [, showAlerts] = useAlerts()
  const editorRef = useRef<HTMLTextAreaElement | null>(null)

  const [preview, setPreview] = useState(false)
  const [active, setActive] = useState(false)
  const [uploadsCompleted, setUploadsCompleted] = useState(true)
  const [uploadProgress, setUploadProgress] = useState<{
    total: number
    completed: number
  }>({ total: 1, completed: 0 })

  const focusElement = autoFocusRef?.current || editorRef.current
  useEffect(() => {
    if (show && focusElement) {
      focusElement.focus()
    }
  }, [focusElement, show])

  useEffect(() => {
    if (
      uploadsCompleted &&
      uploads.some((u) => u.state === EditorToolbarUploadState.Uploading)
    ) {
      setUploadsCompleted(false)
    }
    if (
      !uploadsCompleted &&
      uploads.every((u) => u.state !== EditorToolbarUploadState.Uploading)
    ) {
      setUploadsCompleted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads])

  const updateUpload = (
    toUpdate: File | S3Object,
    update: Partial<EditorToolbarUpload>,
  ): void =>
    onUploadsChange((uploadsState) => {
      const idx = uploadsState.findIndex(({ file }) => file === toUpdate)
      if (idx === -1) {
        return uploadsState
      }

      const updated = Object.assign(uploadsState[idx], update)
      return [
        ...uploadsState.slice(0, idx),
        updated,
        ...uploadsState.slice(idx + 1),
      ]
    })

  const addUpload = (toAdd: CancellableUpload): void => {
    onUploadsChange((uploadsState) => {
      return uploadsState.concat({
        state: EditorToolbarUploadState.Uploading,
        file: toAdd.file,
      })
    })
  }

  return (
    <div
      onFocusCapture={() => setActive(true)}
      onBlurCapture={() => setActive(false)}
      className={`${styles['composer-wrapper']} ${show ? styles.show : ''}`}
    >
      <div className="container">
        <div className={`${styles.composer} ${active ? styles.active : ''}`}>
          {children}
          <div className={styles.controls}>
            <Button
              iconOnly
              style="link-primary-faded-hover"
              size="sm"
              onClick={() => {
                if (uploads.length || text) {
                  confirm(t('core.composer.abort_message')) && onDismiss()
                  return
                }
                onDismiss()
              }}
            >
              <i className={`fas fa-times ${styles.icon}`} />
            </Button>
          </div>
          <TextEditor
            onChange={onTextChange}
            value={text}
            placeholder={placeholder}
            editorRef={editorRef}
            preview={preview}
            disabled={preview || loading}
          />
          <TextEditorToolbar
            editorRef={editorRef}
            className={styles.toolbar}
            values={{
              preview,
              uploads,
              canSend: uploadsCompleted,
              loading,
              progressPercentage:
                (uploadProgress.completed / uploadProgress.total) * 100,
            }}
            onEvent={{
              send: onSend,
              preview: setPreview,
              uploadRemove: onUploadRemove,
              upload: async (files) => {
                const pendingUploads = await uploadModule.CommunityPost.uploadMany(
                  files,
                  {
                    onProgress: ({
                      completedChunksCount,
                      totalChunksCount,
                    }) => {
                      setUploadProgress({
                        completed: completedChunksCount,
                        total: totalChunksCount,
                      })
                    },
                    onUploadComplete: (uploadCompleted, originalFile) =>
                      updateUpload(originalFile, {
                        state: EditorToolbarUploadState.Uploaded,
                        file: uploadCompleted,
                      }),
                    onError: (uploadError) => {
                      const toolbarUpload = uploads.find(
                        ({ file }) => file === uploadError.erroredUpload.file,
                      )
                      toolbarUpload && onUploadRemove(toolbarUpload)
                      showAlerts({
                        message: uploadError.error.message,
                        type: 'error',
                      })
                    },
                  },
                )
                pendingUploads.uploads.forEach(addUpload)
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}
