/** @jsx m */

const Modal = flarum.core.compat['components/Modal']
const avatar = flarum.core.compat['helpers/avatar']
const username = flarum.core.compat['helpers/username']

export default class PostLikesModal extends Modal {
  className() {
    return 'PostLikesModal Modal--small'
  }

  title() {
    return app.translator.trans('flarum-likes.forum.post_likes.title')
  }

  content() {
    return (
      <div className="Modal-body">
        <ul className="PostLikesModal-list">
          {this.props.post.likes().map(user => (
            <li>
              <a
                href={app.route.user(user)}
                onclick={() => m.route(app.route.user(user))}
              >
                {avatar(user)} {username(user)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
