import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useQuery, useReactiveVar } from '@apollo/client'

import { ModalContent } from 'services/ModalManager'
import { Button } from 'ui/atoms/Button'
import styles from './styles.scss'
import { CurrentUser } from 'store/local/user'
import { getTagAndAncestorsByCategory } from 'utils/tags'
import {
  GetCategoriesWithTagsResult,
  GET_CATEGORIES_WITH_TAGS,
} from 'store/operations/tagsCategory'
import { Loader } from 'ui/atoms/Loader'
import { Tag } from 'types/tag'
import { Badge } from 'ui/atoms/Badge'

export const RecommendationModal: ModalContent<{
  onRecommend: (tagNodeId: string | null) => void
}> = ({ onDismiss, onRecommend }) => {
  const { t } = useContext(TranslateContext)
  const authenticatedUser = useReactiveVar(CurrentUser)
  const userTagNodeId =
    (authenticatedUser?.isTeacher && authenticatedUser.tagNodeId) || null

  const { data: getTagsData } = useQuery<GetCategoriesWithTagsResult>(
    GET_CATEGORIES_WITH_TAGS,
  )

  const [tagNodeId, setTagNodeId] = useState<string | null>(userTagNodeId)

  if (!getTagsData) {
    return (
      <div className="Modal-body">
        <Loader size="md" className={styles.loader} />
      </div>
    )
  }

  const userTagAndAncestors: [id: string | null, name: string][] =
    userTagNodeId !== null
      ? Object.entries(
          getTagAndAncestorsByCategory(
            getTagsData.getCategoriesWithTags,
            userTagNodeId,
          ),
        )
          .sort(([categoryIdA], [categoryIdB]) => +categoryIdB - +categoryIdA)
          .filter(
            (categoryAndTag): categoryAndTag is [string, Required<Tag>] =>
              !!categoryAndTag[1],
          )
          .map(([, tag]) => [tag.id, tag.name])
      : [
          [null, '...'],
          [null, '...'],
        ]

  return (
    <div className="Modal-body">
      <div className={styles['radios-area']}>
        {userTagAndAncestors.map(([id, name], idx) => (
          <div
            key={`recommendation-tag-select-${id || idx}`}
            className={`${styles.radio} ${id === null ? styles.disabled : ''} ${
              !!id && id === tagNodeId ? styles.selected : ''
            }`}
          >
            <input
              id={`recommendation_radio_specific_${id || idx}`}
              type="radio"
              name="recommmended_tag_node_id"
              value={id || undefined}
              disabled={id === null}
              checked={!!id && id === tagNodeId}
              onChange={(e) =>
                setTagNodeId(
                  (e as { target: { value: string } | null }).target
                    ?.value as string,
                )
              }
            />
            <label htmlFor={`recommendation_radio_specific_${id || idx}`}>
              {!!id && t('core.resource.recommendation_modal.specific_label')}{' '}
              <Badge value={name} />
            </label>
          </div>
        ))}
        <div className={styles.radio}>
          <input
            id="recommendation_radio_all_public"
            type="radio"
            name="recommmended_tag_node_id"
            checked={tagNodeId === null}
            onChange={() => setTagNodeId(null)}
          />
          <label htmlFor="recommendation_radio_all_public">
            {t('core.resource.recommendation_modal.all_public_label')}
          </label>
        </div>
      </div>
      {userTagNodeId === null && (
        <div className={styles.hint}>
          <i class={`fas fa-info ${styles['icon']} ${styles['info-icon']}`} />
          <span>
            {t('core.resource.recommendation_modal.not_a_teacher_hint')}
          </span>
        </div>
      )}
      {/* {tagNodeId === null && (
        <div className={styles.hint}>
          <i class={`fas fa-exclamation-triangle ${styles['icon']}`} />
          <span>{t('core.resource.recommendation_modal.all_public_hint')}</span>
        </div>
      )} */}
      <div className={`Form-group ${styles['action-buttons']}`}>
        <Button style="btn-info" onClick={onDismiss}>
          {t('core.resource.recommendation_modal.cancel_button')}
        </Button>
        <Button
          onClick={() => {
            onRecommend(tagNodeId)
            onDismiss()
          }}
        >
          {t('core.resource.recommendation_modal.confirm_button')}
        </Button>
      </div>
    </div>
  )
}
