import {
  h,
  Component,
  createContext,
  ComponentChildren,
  RefObject,
} from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { v4 as uuid } from 'uuid'
import { dequal as deepEqual } from 'dequal'

import { OptionalKeys, RemappedOmit } from 'utils/types'

export type DropdownProperties = {
  show: boolean
  for: RefObject<HTMLElement>
  align: 'right' | 'left'
  className?: string
  children: () => ComponentChildren
  dismissOnClick?: boolean
}
export type DropdownSetProps = (props: Partial<DropdownProperties>) => void

type DropdownPropertiesRequired = 'for' | 'children'
type DropdownPropertiesWithDefault = 'align' | 'show'
export interface Dropdown<
  JITProps extends Partial<DropdownProperties> = Partial<DropdownProperties>,
  ShowParam = Record<string, never> extends JITProps
    ? Record<string, never>
    : OptionalKeys<JITProps, DropdownPropertiesWithDefault> &
        Partial<DropdownProperties>
> {
  id: string
  properties: Partial<DropdownProperties> &
    Pick<
      DropdownProperties,
      DropdownPropertiesWithDefault | DropdownPropertiesRequired
    >
  setProps: DropdownSetProps
  show: (
    ...params: Record<string, never> extends ShowParam
      ? [props?: ShowParam]
      : [props: ShowParam]
  ) => void
  dismiss: () => void
  remove: () => void
}

const hasPropsChanged = (
  nextProps: Record<string, unknown>,
  prevProps: Record<string, unknown>,
): boolean => {
  const nextKeys = Object.keys(nextProps)
  const prevKeys = Object.keys(prevProps).filter((k) => nextKeys.includes(k))
  const next = nextKeys.reduce((acc, k) => ({ ...acc, [k]: nextProps[k] }), {})
  const prev = prevKeys.reduce((acc, k) => ({ ...acc, [k]: prevProps[k] }), {})

  return !deepEqual(next, prev)
}

export const useDropdown = <
  AOTProps extends Partial<DropdownProperties> &
    Pick<DropdownProperties, DropdownPropertiesRequired>,
  JITProps = RemappedOmit<DropdownProperties, keyof AOTProps>
>({
  onShow,
  onHide,
  ...props
}: AOTProps & { onShow?: () => void; onHide?: () => void }): [
  dropdown: Dropdown<JITProps>,
  isShown: boolean,
] => {
  const [, { createDropdown, getDropdownById }] = useContext(
    DropdownManagerContext,
  )
  const [dropdownId, setDropdownId] = useState<string | null>(null)

  const dropdown =
    (dropdownId && getDropdownById<JITProps>(dropdownId)) ||
    createDropdown<AOTProps, JITProps>(props as AOTProps)
  if (!dropdownId) {
    setDropdownId(dropdown.id)
  } else if (hasPropsChanged(props, dropdown.properties)) {
    dropdown.setProps(props)
  }

  useEffect(() => {
    return () => {
      const dropdownToRemove = getDropdownById<JITProps>(dropdown.id)
      dropdownToRemove?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isShown = dropdown.properties.show
  useEffect(() => {
    if (!dropdownId && !isShown) {
      return
    }

    isShown ? onShow && onShow() : onHide && onHide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown])

  return [dropdown, isShown]
}

export type DropdownContext = [
  dropdowns: Dropdown[],
  functions: {
    createDropdown: <
      AOTProps extends Partial<DropdownProperties> &
        Pick<DropdownProperties, DropdownPropertiesRequired>,
      JITProps extends Partial<DropdownProperties>,
      ShowParam = Record<string, never> extends JITProps
        ? Record<string, never>
        : OptionalKeys<JITProps, DropdownPropertiesWithDefault> &
            Partial<DropdownProperties>
    >(
      props: AOTProps,
    ) => Dropdown<JITProps, ShowParam>
    getDropdownById: <JITProps extends Partial<DropdownProperties>>(
      id: string,
    ) => Dropdown<JITProps> | undefined
  },
]
export const DropdownManagerContext = createContext<DropdownContext>([
  [],
  {
    createDropdown: () => {
      throw new Error('no_dropdown_provider_found')
    },
    getDropdownById: () => {
      throw new Error('no_dropdown_provider_found')
    },
  },
])

export class DropdownProvider extends Component<
  { children?: ComponentChildren },
  { dropdowns: Dropdown[] }
> {
  state: { dropdowns: Dropdown[] } = { dropdowns: [] }

  getDropdownById = <
    JITProps extends Partial<DropdownProperties>,
    ShowParam = Record<string, never> extends JITProps
      ? Record<string, never>
      : OptionalKeys<JITProps, DropdownPropertiesWithDefault> &
          Partial<DropdownProperties>
  >(
    id: string,
  ): Dropdown<JITProps, ShowParam> | undefined => {
    return this.state.dropdowns.find((m) => m.id === id) as
      | Dropdown<JITProps, ShowParam>
      | undefined
  }

  updateDropdownProps = (
    id: string,
    propsUpdate: Partial<DropdownProperties>,
  ): void => {
    this.setState((state) => {
      const idx = state.dropdowns.findIndex((m) => m.id === id)
      if (idx === -1) {
        return null
      }

      const { properties, ...dropdown } = state.dropdowns[idx]
      return {
        dropdowns: [
          ...state.dropdowns.slice(0, idx),
          {
            ...dropdown,
            properties: {
              ...properties,
              ...propsUpdate,
            },
          },
          ...state.dropdowns.slice(idx + 1),
        ],
      }
    })
  }

  removeDropdown = (id: string): void => {
    this.setState((state) => {
      const idx = state.dropdowns.findIndex((m) => m.id === id)
      if (idx === -1) {
        return state
      }

      return {
        dropdowns: [
          ...state.dropdowns.slice(0, idx),
          ...state.dropdowns.slice(idx + 1),
        ],
      }
    })
  }

  createDropdown = <
    AOTProps extends Partial<DropdownProperties> &
      Pick<DropdownProperties, DropdownPropertiesRequired>,
    JITProps extends Partial<DropdownProperties>,
    ShowParam = Record<string, never> extends JITProps
      ? Record<string, never>
      : OptionalKeys<JITProps, DropdownPropertiesWithDefault> &
          Partial<DropdownProperties>
  >(
    properties: AOTProps,
  ): Dropdown<JITProps, ShowParam> => {
    const id = uuid()

    const dropdown: Dropdown<JITProps, ShowParam> = {
      id,
      properties: {
        ...properties,
        align: properties.align ?? 'right',
        show: properties.show ?? false,
      },
      show: (props?: Partial<DropdownProperties>) => {
        this.updateDropdownProps(id, {
          ...(props || {}),
          show: true,
        })
      },
      dismiss: () => {
        this.updateDropdownProps(id, { show: false })
      },
      remove: () => this.removeDropdown(id),
      setProps: (props) => this.updateDropdownProps(id, props),
    }

    this.setState((state) => ({
      dropdowns: state.dropdowns.concat(dropdown as Dropdown),
    }))
    return dropdown
  }

  render(): h.JSX.Element {
    return (
      <DropdownManagerContext.Provider
        value={[
          this.state.dropdowns,
          {
            createDropdown: this.createDropdown,
            getDropdownById: this.getDropdownById,
          },
        ]}
      >
        <div
          onClick={({ target }) => {
            const targetDataset = (target as
              | (typeof target & {
                  dataset?: { dropdownId?: string }
                })
              | null)?.dataset

            this.state.dropdowns.forEach(
              (d) => targetDataset?.dropdownId !== d.id && d.dismiss(),
            )
          }}
        >
          {this.props.children}
        </div>
      </DropdownManagerContext.Provider>
    )
  }
}

export const DropdownConsumer = DropdownManagerContext.Consumer
