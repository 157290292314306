const hsvToRgb = (
  h: number,
  s: number,
  v: number,
): { r: number; g: number; b: number } => {
  const i = Math.floor(h * 6)
  const f = h * 6 - i
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      return {
        r: Math.floor(v * 255),
        g: Math.floor(t * 255),
        b: Math.floor(p * 255),
      }
    case 1:
      return {
        r: Math.floor(q * 255),
        g: Math.floor(v * 255),
        b: Math.floor(p * 255),
      }
    case 2:
      return {
        r: Math.floor(p * 255),
        g: Math.floor(v * 255),
        b: Math.floor(t * 255),
      }
    case 3:
      return {
        r: Math.floor(p * 255),
        g: Math.floor(q * 255),
        b: Math.floor(v * 255),
      }
    case 4:
      return {
        r: Math.floor(t * 255),
        g: Math.floor(p * 255),
        b: Math.floor(v * 255),
      }
    case 5:
      return {
        r: Math.floor(v * 255),
        g: Math.floor(p * 255),
        b: Math.floor(q * 255),
      }
    default:
      return {
        r: Math.floor(v * 255),
        g: Math.floor(p * 255),
        b: Math.floor(q * 255),
      }
  }
}

/**
 * Convert the given string to a unique color.
 *
 * @param {String} string
 * @return {String}
 */
export const stringToHexColor = (str: string): string => {
  // Convert the username into a number based on the ASCII value of each
  // character.
  const num = str.split('').reduce<number>((acc, c) => acc + c.charCodeAt(0), 0)

  // Construct a color using the remainder of that number divided by 360, and
  // some predefined saturation and value values.
  const hue = num % 360
  const rgb = hsvToRgb(hue / 360, 0.3, 0.9)

  return `${rgb.r.toString(16)}${rgb.g.toString(16)}${rgb.b.toString(16)}`
}
