import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext } from 'preact/hooks'

import { TagBadge } from 'ui/atoms/TagBadge'
import styles from './styles.scss'
import { FullResource } from 'types/resource'
import { TranslateContext } from '@denysvuika/preact-translate'
import { Avatar } from 'ui/atoms/Avatar'
import { Badge } from 'ui/atoms/Badge'

interface ResourceCardProps {
  className?: string
  resource: FullResource
  onClick?: () => void
  showScore?: boolean
}

const SCORE_LEVELS: Array<{
  minimum: number
  scoreValue: 'high' | 'medium' | 'low'
}> = [
  { minimum: 80, scoreValue: 'high' },
  { minimum: 20, scoreValue: 'medium' },
  { minimum: 0, scoreValue: 'low' },
]

export const ResourceCard: FunctionalComponent<ResourceCardProps> = ({
  className,
  resource,
  onClick,
  showScore = true,
}) => {
  const { t } = useContext(TranslateContext)

  const {
    title,
    tags,
    author,
    metadatas: {
      totalViewCount,
      totalRecommendationCount,
      filteredScore,
      isRecommendedByMe,
      filteredViewCount,
      isReliable,
    },
  } = resource

  const hasReliableScore = !!filteredScore && !!filteredViewCount && isReliable

  return (
    <div className={`${className || ''} ${styles['card-wrapper']}`}>
      <div
        className={`${styles.card} ${onClick ? styles.clickable : ''}`}
        onClick={onClick}
      >
        <div className={styles.taglist}>
          {tags.slice(0, 3).map((t, idx) => (
            <TagBadge
              key={`resource-card-tag-${title}-${t.name}-${idx}`}
              tag={t}
              className={styles.tagitem}
            />
          ))}
          {tags.length > 3 && (
            <Badge
              value={t('core.resources.card_more_tags', {
                nb: tags.length - 3,
              })}
              tooltip={tags.slice(3).map(({ name, id }) => (
                <div
                  className={styles['more-tags-item']}
                  key={`more-tags-${id}`}
                >
                  {name}
                </div>
              ))}
              tooltipPosition="top"
              tooltipWidth="fit-content"
            />
          )}
        </div>
        <h2 title={title}>{title}</h2>
        <div className={styles.author}>
          <Avatar
            className={styles.picture}
            size="sm"
            username={author.username}
            url={author.avatarUrl}
          />
          <span className={styles.name}>{author.username}</span>
        </div>
        <div className={styles.recommendation}>
          {!showScore ? (
            <Fragment>
              <span className={`${styles.label}`}>
                <i className={`fas fa-eye`} /> {totalViewCount}
              </span>
              <span className={`${styles.label}`}>
                <i className={`fas fa-heart`} /> {totalRecommendationCount}
              </span>
            </Fragment>
          ) : hasReliableScore ? (
            <Fragment>
              <span
                className={`${styles.label} ${
                  styles[
                    SCORE_LEVELS.find(
                      ({ minimum }) => minimum <= (filteredScore || 0),
                    )?.scoreValue || 'low'
                  ]
                }`}
              >
                {t('core.resources.card_recommendation_text', {
                  score: filteredScore as number,
                })}
              </span>
              <span>
                {t('core.resources.card_recommendation_text_peer_tip')}
              </span>
            </Fragment>
          ) : isRecommendedByMe ? (
            <span className={`${styles.label} ${styles.high}`}>
              {t('core.resources.card_recommendation_text_by_you')}
            </span>
          ) : (
            <Fragment>
              <div className={styles['help-us-icon']}>
                <i className="far fa-lightbulb" />
              </div>
              <span className={`${styles.label}`}>
                {/* ${styles['help-us-label']} */}
                {t('core.resources.card_recommendation_text_unreliable')}
              </span>
            </Fragment>
          )}

          {isRecommendedByMe && (
            <i className={`fas fa-heart ${styles['recommended-by-me']}`} />
          )}
        </div>
      </div>
    </div>
  )
}
