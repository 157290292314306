import { h, FunctionalComponent, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { route, useRouter } from 'preact-router'
import { useQuery } from '@apollo/client'

import { ResourceHeader } from '../../organisms/ResourceHeader'
import { ResourceSubHeader } from '../../organisms/ResourceSubHeader'
import { ResourceContentSummary } from '../../organisms/ResourceContentSummary'
import styles from './styles.scss'
import {
  GetResourceByIdResult,
  GetResourceByIdInput,
  GET_RESOURCE_BY_ID,
} from 'store/operations/resource'
import { BackButton } from 'ui/molecules/BackButton'
import { ResourceContentThreads } from 'ui/organisms/ResourceContentThreads'
import { Loader } from 'ui/atoms/Loader'
import { ModerationFlag } from 'ui/organisms/ModerationFlag'
import { getRouteUrl, PageType } from 'common/routing'

const ResourcePage: FunctionalComponent = () => {
  const [resourceSummary, setResourceSummary] = useState(true)
  const [{ matches }] = useRouter<{ id: string; moderatedFlagId?: string }>()
  const resourceId = matches.id
  const moderatedFlagId = matches.moderatedFlagId
  const moderationMode = !!moderatedFlagId

  const { data, error, loading } = useQuery<
    GetResourceByIdResult,
    GetResourceByIdInput
  >(GET_RESOURCE_BY_ID, { variables: { id: resourceId } })

  return (
    <div className={`${styles['resource-page']}`}>
      <Loader
        loaded={!!data}
        size="md"
        passingProps={{ resource: data?.getResourceById }}
        className={styles.loader}
      >
        {({ resource }) => (
          <Fragment>
            <ResourceHeader resource={resource} />
            {moderationMode && (
              <div className="container">
                <ModerationFlag
                  className={styles['moderation-flag']}
                  flagId={moderatedFlagId}
                  onFlagReviewed={() =>
                    route(getRouteUrl(PageType.RESOURCE, { id: resourceId }))
                  }
                />
              </div>
            )}
            <ResourceSubHeader
              switchContentView={setResourceSummary}
              isResourceSummary={resourceSummary}
              resource={resource}
              canFlag={data?.getResourceById.canFlag ?? false}
              canUpdate={data?.getResourceById.canUpdate ?? false}
            />
            {resourceSummary && (
              <ResourceContentSummary
                content={data?.getResourceById.description || '...'}
              />
            )}
            {!resourceSummary && <ResourceContentThreads />}
          </Fragment>
        )}
      </Loader>
      <BackButton />
    </div>
  )
}

export default ResourcePage
