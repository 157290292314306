/** @jsx m */

const { extend } = flarum.core.compat['extend']
const DiscussionList = flarum.core.compat['components/DiscussionList']
const DiscussionListItem = flarum.core.compat['components/DiscussionListItem']
const { truncate } = flarum.core.compat['utils/string']

export default function addStickyControl() {
  extend(DiscussionList.prototype, 'requestParams', function(params) {
    params.include.push('firstPost')
  })

  extend(DiscussionListItem.prototype, 'infoItems', function(items) {
    const discussion = this.props.discussion

    if (
      discussion.isSticky() &&
      !this.props.params.q &&
      !discussion.lastReadPostNumber()
    ) {
      const firstPost = discussion.firstPost()

      if (firstPost) {
        const excerpt = truncate(firstPost.contentPlain(), 175)

        items.add('excerpt', excerpt, -100)
      }
    }
  })
}
