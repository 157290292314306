/** @jsx m */

import Component from '../../common/Component'
import Button from '../../common/components/Button'

/**
 * The `WelcomeHero` component displays a hero that welcomes the user to the
 * forum.
 */
export default class WelcomeHero extends Component {
  init() {
    this.tooltipIn = false
    const localStorageKey =
      this.props.section === 'resource'
        ? 'resourceWelcomeHidden'
        : 'communityWelcomeHidden'
    this.hidden = localStorage.getItem(localStorageKey)
  }

  view() {
    if (this.hidden) return <div />

    const slideUp = () => {
      this.$().slideUp(this.hide.bind(this))
    }

    if (this.props.section === 'resource') {
      return (
        <header
          className="Hero WelcomeHero"
          style="box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);"
        >
          <div class="container">
            {Button.component({
              icon: 'fas fa-times',
              onclick: slideUp,
              className: 'Hero-close Button Button--icon Button--link',
            })}

            <div className="containerNarrow">
              <h2 className="Hero-title">
                Le partage des ressources, comment ça marche ?
                {/* {app.forum.attribute('welcomeTitle')} */}
              </h2>
              {/* <div className="Hero-subtitle">
                {m.trust(app.forum.attribute('welcomeMessage'))}
              </div> */}
            </div>
            <div
              className="Hero-subtitle"
              style="display: flex; justify-content: space-between; padding: 0 10%; margin-top: 50px;"
            >
              <div style="width: 28%">
                <i
                  className="fas fa-search"
                  style="font-size: 46px; margin-bottom: 16px;"
                />
                <p>
                  <div
                    style="display: inline-block; white-space: nowrap; cursor: help; position: relative;"
                    onmouseenter={() => (this.tooltipIn = true)}
                    onmouseleave={() => (this.tooltipIn = false)}
                  >
                    <div
                      className={`tooltip bottom ${this.tooltipIn ? 'in' : ''}`}
                      style="left: 50%; transform: translateX(-50%); pointer-events: none; top: 100%; white-space: normal; width: 150%; overflow: hidden; font-size: 13px;"
                    >
                      <div className="tooltip-arrow" />
                      <div className="tooltip-inner" style="max-width: none;">
                        L'orientation de votre recherche se compose du niveau,
                        du domaine et de la discipline qui vous interesse. Vous
                        pouvez la saisir manuellement sur le menu de gauche pour
                        chacune de vos recherches. Si vous êtes enseignant, nous
                        utiliserons les informations de votre profil.
                      </div>
                    </div>
                    <strong>Orientez votre recherche</strong>
                    <i
                      className="fas fa-question-circle"
                      style="margin-left: 4px;"
                    />
                  </div>{' '}
                  et nous organiserons votre affichage par{' '}
                  <strong>pertinence</strong>. Vous pourrez ensuite{' '}
                  <strong>filtrer</strong> les ressources{' '}
                  <strong>par type</strong> ou <strong>par mots-clefs</strong>.
                  {/* Renseignez votre <strong>niveau</strong>,{' '}
                  <strong>domaine</strong> et <strong>discipline</strong>{' '}
                  d'enseignement et nous personaliserons votre affichage par{' '}
                  <strong>pertinences</strong>. Vous pourrez ensuiter filtrer
                  les ressources par type ou par mot clefs via la{' '}
                  <strong>barre de recherche</strong> */}
                  {/* Renseignez votre <strong>niveau</strong>,{' '}
                  <strong>domaine</strong> et <strong>discipline</strong>{' '}
                  d'enseignement et nous organiserons les{' '}
                  <strong>ressources</strong> par <strong>pertinences</strong>.
                  Vous pourrez ensuiter filtrer les ressources par type ou par
                  mot clefs via la <strong>barre de recherche</strong> */}
                  {/* Renseignez votre <strong>niveau</strong>,{' '}
                  <strong>domaine</strong> et <strong>discipline</strong>{' '}
                  d'enseignement et nous trierons les{' '}
                  <strong>ressources</strong> par <strong>pertinences</strong>.
                  Vous pourrez ensuiter filtrer les ressources par type ou par
                  mot clefs via la <strong>barre de recherche</strong> */}
                  {/* Renseignez votre <strong>niveau</strong>,{' '}
                  <strong>domaine</strong> et <strong>discipline</strong>{' '}
                  d'enseignement et nous vous montrerons les{' '}
                  <strong>ressources</strong> les plus{' '}
                  <strong>pertinentes</strong> en premier. Vous pourrez ensuiter
                  filtrer les ressources par type ou par mot clefs via la{' '}
                  <strong>barre de recherche</strong> */}
                </p>
              </div>
              <div style="width: 28%">
                <i
                  className="fas fa-heart"
                  style="font-size: 46px; margin-bottom: 16px;"
                />
                <p>
                  <strong>Vos recommandations changent tout!</strong>{' '}
                  {/* Vous êtes acteur du <strong>référencement</strong> des
                  ressources par vos <strong>recommandations</strong>! */}
                  Inscrivez-vous et{' '}
                  <strong>
                    recommandez les ressources adaptées à votre profil
                  </strong>{' '}
                  afin que nous les proposions à vos pairs qui ont un profil
                  similaire.
                </p>
              </div>
              <div style="width: 28%">
                <i
                  className="fas fa-seedling"
                  style="font-size: 46px; margin-bottom: 16px;"
                />
                <p>
                  Cet espace grandit grâce à vous!{' '}
                  <strong>Partagez vos ressources</strong> avec le reste de la
                  communauté pour avoir un <strong>impact</strong> sur
                  l'enseignement de demain.
                  {/* <span className="TagsLabel">
                    <span className="TagLabel">
                      <span className="TagLabel-text">Meta</span>
                    </span>
                  </span> */}
                  {/* . */}
                </p>
              </div>
            </div>
          </div>
        </header>
      )
    }

    return (
      <header className="Hero WelcomeHero">
        <div class="container">
          {Button.component({
            icon: 'fas fa-times',
            onclick: slideUp,
            className: 'Hero-close Button Button--icon Button--link',
          })}

          <div className="containerNarrow">
            <h2 className="Hero-title">
              À quoi sert la section Communauté ?
              {/* {app.forum.attribute('welcomeTitle')} */}
            </h2>
            {/* <div className="Hero-subtitle">
              {m.trust(app.forum.attribute('welcomeMessage'))}
            </div> */}
          </div>
          <div
            className="Hero-subtitle"
            style="display: flex; justify-content: space-between; padding: 0 10%; margin-top: 50px;"
          >
            <div style="width: 25%">
              <i
                className="fas fa-newspaper"
                style="font-size: 46px; margin-bottom: 16px;"
              />
              <p>
                Interpellez vos pairs autour d'une <strong>actualité</strong>{' '}
                scientifique, de l'enseignement ou encore d'un{' '}
                <strong>évènement</strong> intéressant et réagissez en
                commentaire.
              </p>
            </div>
            <div style="width: 25%">
              <i
                className="fas fa-user-graduate"
                style="font-size: 46px; margin-bottom: 16px;"
              />
              <p>
                Posez vos <strong>questions</strong>, échangez autour des{' '}
                <strong>méthodes pédagogiques</strong> et profitez d'une
                communauté collaborative pour améliorer votre pratique.
              </p>
            </div>
            <div style="width: 25%">
              <i
                className="fas fa-lightbulb"
                style="font-size: 46px; margin-bottom: 16px;"
              />
              <p>
                Construisons ensemble. Faites <strong>évoluer</strong> cette
                plateforme en partageant vos <strong>idées</strong> et en
                réagissant à celles des autres sous le label{' '}
                <span className="TagsLabel">
                  <span className="TagLabel">
                    <span className="TagLabel-text">Meta</span>
                  </span>
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </header>
    )
  }

  /**
   * Hide the welcome hero.
   */
  hide() {
    const localStorageKey =
      this.props.section === 'resource'
        ? 'resourceWelcomeHidden'
        : 'communityWelcomeHidden'

    localStorage.setItem(localStorageKey, 'true')

    this.hidden = true
  }
}
