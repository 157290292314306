/** @jsx m */

const { extend } = flarum.core.compat['extend']
const Discussion = flarum.core.compat['models/Discussion']
const Badge = flarum.core.compat['components/Badge']

export default function addLockBadge() {
  extend(Discussion.prototype, 'badges', function(badges) {
    if (this.isLocked()) {
      badges.add(
        'locked',
        Badge.component({
          type: 'locked',
          label: app.translator.trans('flarum-lock.forum.badge.locked_tooltip'),
          icon: 'fas fa-lock',
        }),
      )
    }
  })
}
