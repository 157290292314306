/** @jsx m */

const { extend } = flarum.core.compat['extend']
const Discussion = flarum.core.compat['models/Discussion']
const Badge = flarum.core.compat['components/Badge']

export default function addStickyBadge() {
  extend(Discussion.prototype, 'badges', function(badges) {
    if (this.isSticky()) {
      badges.add(
        'sticky',
        Badge.component({
          type: 'sticky',
          label: app.translator.trans(
            'flarum-sticky.forum.badge.sticky_tooltip',
          ),
          icon: 'fas fa-thumbtack',
        }),
        10,
      )
    }
  })
}
