import { useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext, useRef, useState } from 'preact/hooks'
import {
  getRoutePath,
  getRouteUrl,
  isRouteIn,
  PageGroup,
  PageType,
} from 'common/routing'

import styles from './styles.scss'
import Search from 'legacy/src/core/js/forum/components/Search'
import {
  NextResourceSearchTerms,
  ResourceSearchTerms,
} from 'store/local/search'
import { fromEvent, handleEnterKey } from 'utils/components'
import { LegacyComponent } from 'legacy/compat'
import {
  CurrentResourcePageNumber,
  CurrentRouterPath,
} from 'store/local/navigation'
import { Button } from 'ui/atoms/Button'
import { route } from 'preact-router'

export const SearchBar: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const inputRef = useRef<HTMLInputElement>(null)

  const searchTerms = useReactiveVar(ResourceSearchTerms)
  const nextSearchTerms = useReactiveVar(NextResourceSearchTerms)
  const path = useReactiveVar(CurrentRouterPath)
  const [hasFocus, setHasFocus] = useState(false)
  const isResourceSearch = !!path && isRouteIn(PageGroup.RESOURCE, path)
  const isCommunitySearch = !!path && isRouteIn(PageGroup.COMMUNITY, path)
  const resourcesPagePath = getRoutePath(PageType.RESOURCES)

  if (!isResourceSearch && !isCommunitySearch) {
    return null
  } else if (isCommunitySearch) {
    return (
      <LegacyComponent
        component={Search}
        placeholder={t('core.navbar.searchbox.community_placeholder')}
      />
    )
  }

  const displayedSearchTerms = hasFocus ? nextSearchTerms : searchTerms
  const validateSearch = (): void => {
    ResourceSearchTerms(nextSearchTerms)
    CurrentResourcePageNumber(0)
    inputRef.current.blur()

    if (path !== resourcesPagePath) {
      route(getRouteUrl(PageType.RESOURCES))
    }
  }

  return (
    <div
      className={`${styles['search-bar']} ${
        displayedSearchTerms && hasFocus ? styles.open : ''
      } ${hasFocus ? styles.focus : ''}`}
    >
      <div className={styles['search-input-group']}>
        <i className={`${styles['search-icon']} fas fa-search`} />
        <input
          ref={inputRef}
          className="FormControl"
          type="search"
          placeholder={t('core.navbar.searchbox.ressource_placeholder')}
          value={displayedSearchTerms}
          onInput={fromEvent(NextResourceSearchTerms)}
          onKeyDown={handleEnterKey(validateSearch)}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
        {!!displayedSearchTerms && (
          <Button
            size="sm"
            iconOnly
            style="link"
            className={styles.clear}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              NextResourceSearchTerms('')
              ResourceSearchTerms('')
              CurrentResourcePageNumber(0)
              inputRef.current.blur()
            }}
          >
            <i className="fas fa-times-circle" />
          </Button>
        )}
      </div>
      <ul className={styles['results-dropdown']}>
        {displayedSearchTerms && hasFocus ? (
          <div
            className={styles['send-tip']}
            onClick={validateSearch}
            onMouseDown={(e) => e.preventDefault()}
          >
            <i
              className={`fas fa-level-down-alt fa-rotate-90 ${styles['enter-icon']}`}
            />
            {t('core.navbar.searchbox.ressource_send_tip')}
          </div>
        ) : null}
      </ul>
    </div>
  )
}
