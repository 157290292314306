import { h, FunctionalComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { DropdownProperties, DropdownSetProps } from 'services/DropdownManager'

import styles from './styles.scss'

export const Dropdown: FunctionalComponent<
  DropdownProperties & { setProps: DropdownSetProps; id: string }
> = ({
  children,
  className = '',
  align,
  for: forElement,
  show,
  setProps,
  id,
  dismissOnClick = true,
}) => {
  const [pos, setPos] = useState({
    top: 0,
    left: 0,
    right: 0,
    height: 0,
  })

  useEffect(() => {
    const elem = forElement.current
    if (!elem) {
      setProps({ show: false })
      return
    }

    elem.setAttribute('data-dropdown-id', id)
    const handler = (e: Event): void => {
      if (e.target !== elem) {
        e.preventDefault()
        e.stopImmediatePropagation()
        elem.click()
      }
      setProps({ show: !show })
    }
    const scrollHandler = (): void => {
      const { top, left, right, height } = elem.getBoundingClientRect()
      setPos({ top, left, right, height })
    }

    elem.addEventListener('click', handler, { capture: true })
    if (show) {
      scrollHandler()
      window.addEventListener('scroll', scrollHandler, {
        capture: true,
        passive: true,
      })
    }

    return (): void => {
      elem.removeEventListener('click', handler, { capture: true })
      window.removeEventListener('scroll', scrollHandler, {
        capture: true,
      })
    }
  }, [show, forElement, setProps, id, pos])

  if (!forElement.current || !show) {
    return null
  }

  const { top, left, right, height } = pos

  const dpdTop = top + height + window.pageYOffset
  const dpdLeft = (align === 'right' ? right : left) + window.pageXOffset

  return (
    <ul
      style={{
        top: `${dpdTop}px`,
        left: `${dpdLeft}px`,
      }}
      className={`${styles.dropdown} ${show ? styles.expanded : ''} ${
        align === 'right' ? styles.right : ''
      } ${className}`}
      onClick={!dismissOnClick ? (e) => e.stopPropagation() : undefined}
    >
      {children()}
    </ul>
  )
}
