import { gql } from '@apollo/client'

export const FullFileFragment = gql`
  fragment FullFile on File {
    id
    url
    bucket
    key
    filename
    mimetype
    size
  }
`
