import { h, FunctionalComponent } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import { LegacyComponent } from 'legacy/compat'
import IndexPage from 'legacy/src/core/js/forum/components/IndexPage'
import { DiscussionComposer } from 'ui/organisms/DiscussionComposer'
import {
  EditorToolbarUpload,
  EditorToolbarUploadState,
} from 'ui/molecules/TextEditorToolbar/ToolbarUpload'
import { useMutation } from '@apollo/client'
import {
  CreateDiscussionInput,
  CreateDiscussionResult,
  CREATE_DISCUSSION,
} from 'store/operations/discussion'
import { route } from 'preact-router'
import { getRouteUrl, PageType } from 'common/routing'
import { getApp } from 'legacy/app'
import TagDiscussionModal from 'legacy/src/extensions/tags/js/src/forum/components/TagDiscussionModal'
import { useAlerts } from 'services/AlertManager'
import { useGlobalModal } from 'services/ModalManager'
import styles from './styles.scss'

export const DiscussionsPage: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()

  const [title, setTitle] = useState<string>('')
  const [newPost, setNewPost] = useState('')
  const [uploads, setUploads] = useState<EditorToolbarUpload[]>([])
  const [showComposer, setShowComposer] = useState(false)

  const [loginModal] = useGlobalModal('login')

  const [createDiscussion, { loading, error }] = useMutation<
    CreateDiscussionResult,
    CreateDiscussionInput
  >(CREATE_DISCUSSION)

  const closeComposer = (): void => {
    setNewPost('')
    setUploads([])
    setShowComposer(false)
  }

  const onUploadRemove = (toBeRemoved: EditorToolbarUpload) =>
    setUploads((state) => {
      // TODO: Here we should handle upload abortion as well
      return state.filter((u) => u !== toBeRemoved)
    })

  const onSend = (): void => {
    const executeSend = (tagIds: string[]): void => {
      void createDiscussion({
        variables: {
          title,
          tagIds,
          markdownContent: newPost,
          // Send only completed upload. At this point all should be completed
          // as ensured by the DiscussionComposer component.
          files: uploads
            .filter(
              (
                u,
              ): u is Extract<
                EditorToolbarUpload,
                { state: EditorToolbarUploadState.Uploaded }
              > => u.state === EditorToolbarUploadState.Uploaded,
            )
            .map((u) => ({ ...u.file, __typename: undefined })),
        },
      })
        .then(({ data }) => {
          if (data) {
            route(
              getRouteUrl(PageType.DISCUSSION, {
                id: data?.createDiscussion.id,
              }),
            )
          }
          closeComposer()
        })
        .catch((e: Error) => {
          showAlert({ message: e.message, type: 'error' })
        })
    }

    getApp().modal.show(
      new (TagDiscussionModal as {
        new (args: {
          selectedTagIds: string[]
          onEdit: (tagIds: string[]) => void
        }): void
      })({
        selectedTagIds: [],
        onEdit: (tagIds: string[]) => executeSend(tagIds),
      }),
    )
  }

  return (
    <div className={styles['page-wrapper']}>
      <LegacyComponent
        component={IndexPage}
        showDiscussionComposer={setShowComposer}
        openLoginModal={loginModal?.show}
      />
      <DiscussionComposer
        values={{ text: newPost, uploads, title }}
        onChange={{ text: setNewPost, uploads: setUploads, title: setTitle }}
        onUploadRemove={onUploadRemove}
        onDismiss={closeComposer}
        placeholder={t(
          'core.community.discussions_page.composer_body_placeholder',
        )}
        show={showComposer}
        onSend={onSend}
        loading={loading}
      />
    </div>
  )
}

export default DiscussionsPage
