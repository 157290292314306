import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Reference,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { hasNonNullableKeys } from '@modbox/ts-utils'

import { Resource } from 'types/resource'
import {
  GetResourcesInput,
  GetUserResourcesResult,
} from './operations/resource'
import { GetDiscussionPostsResult, GetUserPostsResult } from './operations/post'
import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'
import { Post } from 'types/post'
import { GetUserDiscussionsResult } from './operations/discussion'
import { CurrentToken } from './local/user'
import { GetUserNotificationsResult } from './operations/notification'

const getRange = (min: number, max: number): number[] =>
  Array(max - min)
    .fill(null)
    .map((_, idx) => idx)

type PostSegment = Omit<
  GetDiscussionPostsResult['getDiscussionPosts'],
  'totalCount' | 'near'
>

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getUserNotifications: {
          keyArgs: false,
          merge(
            existing:
              | GetUserNotificationsResult['getUserNotifications']
              | undefined,
            incoming: GetUserNotificationsResult['getUserNotifications'],
            { variables },
          ): GetUserNotificationsResult['getUserNotifications'] | undefined {
            if (!incoming || variables === undefined) {
              return existing
            }

            const start = variables.offset as number
            const end = start + incoming.count

            const notifications = existing?.notifications.slice() ?? []
            Array(end - start)
              .fill(null)
              .forEach((_, idx) => {
                notifications[start + idx] = incoming.notifications[idx]
              })

            return {
              notifications,
              count: notifications.length,
              totalCount: incoming.totalCount,
              offset: existing?.offset ?? 0,
            }
          },
        },
        getUserResources: {
          keyArgs: ['userId'],
          merge(
            existing: GetUserResourcesResult['getUserResources'] | undefined,
            incoming: GetUserResourcesResult['getUserResources'],
            { variables },
          ): GetUserResourcesResult['getUserResources'] | undefined {
            if (!incoming || variables === undefined) {
              return existing
            }

            const start = variables.offset as number
            const end = start + incoming.count

            const resources = existing?.resources.slice() ?? []
            Array(end - start)
              .fill(null)
              .forEach((_, idx) => {
                resources[start + idx] = incoming.resources[idx]
              })

            return {
              resources,
              count: resources.length,
              totalCount: incoming.totalCount,
              offset: existing?.offset ?? 0,
            }
          },
        },
        getUserPosts: {
          keyArgs: ['userId'],
          merge(
            existing: GetUserPostsResult['getUserPosts'] | undefined,
            incoming: GetUserPostsResult['getUserPosts'],
            { variables },
          ): GetUserPostsResult['getUserPosts'] | undefined {
            if (!incoming || variables === undefined) {
              return existing
            }

            const start = variables.offset as number
            const end = start + incoming.count

            const posts = existing?.posts.slice() ?? []
            Array(end - start)
              .fill(null)
              .forEach((_, idx) => {
                posts[start + idx] = incoming.posts[idx]
              })

            return {
              posts,
              count: posts.length,
              totalCount: incoming.totalCount,
              offset: existing?.offset ?? 0,
            }
          },
        },
        getUserDiscussions: {
          keyArgs: ['userId'],
          merge(
            existing:
              | GetUserDiscussionsResult['getUserDiscussions']
              | undefined,
            incoming: GetUserDiscussionsResult['getUserDiscussions'],
            { variables },
          ): GetUserDiscussionsResult['getUserDiscussions'] | undefined {
            if (!incoming || variables === undefined) {
              return existing
            }

            const start = variables.offset as number
            const end = start + incoming.count

            const discussions = existing?.discussions.slice() ?? []
            Array(end - start)
              .fill(null)
              .forEach((_, idx) => {
                discussions[start + idx] = incoming.discussions[idx]
              })

            return {
              discussions,
              count: discussions.length,
              totalCount: incoming.totalCount,
              offset: existing?.offset ?? 0,
            }
          },
        },
        getDiscussionPosts: {
          keyArgs: ['discussionId'],
          merge(
            existing:
              | {
                  totalCount: number
                  postSegments: {
                    offset: number
                    count: number
                    posts: Post[]
                  }[]
                }
              | undefined,
            incoming: GetDiscussionPostsResult['getDiscussionPosts'],
            { readField },
          ) {
            if (incoming.count === 0) {
              return {
                totalCount: incoming.totalCount,
                postSegments: existing?.postSegments || [],
              }
            }

            const mergeWithIdx =
              existing?.postSegments.findIndex((segment) => {
                const incomingRange = getRange(
                  incoming.offset,
                  incoming.offset + incoming.count,
                )
                const existingRange = getRange(
                  segment.offset,
                  segment.offset + segment.count,
                )

                const overlap = incomingRange.some((n) =>
                  existingRange.includes(n),
                )
                return overlap
              }) ?? -1
            const mergeWith: {
              offset?: number
              count?: number
              posts?: Post[]
            } =
              existing && mergeWithIdx !== -1
                ? existing.postSegments[mergeWithIdx]
                : {}

            const segmentPosts = mergeWith.posts
              ? mergeWith.posts
                  .reduce<Post[]>((acc, p) => {
                    if (
                      acc.findIndex(
                        (item) =>
                          readField(
                            'number',
                            (item as unknown) as Reference,
                          ) ===
                          readField('number', (p as unknown) as Reference),
                      ) >= 0
                    ) {
                      return acc
                    }

                    return acc.concat(p)
                  }, incoming.posts.slice())
                  .sort(
                    (a, b) =>
                      (readField(
                        'number',
                        (a as unknown) as Reference,
                      ) as number) -
                      ((readField(
                        'number',
                        (b as unknown) as Reference,
                      ) as number) || 0),
                  )
              : incoming.posts

            const segment = {
              offset:
                typeof mergeWith.offset === 'number'
                  ? Math.min(incoming.offset, mergeWith.offset)
                  : incoming.offset,
              count: segmentPosts.length,
              posts: segmentPosts,
            }

            return {
              totalCount: incoming.totalCount,
              near: incoming.near,
              postSegments:
                mergeWithIdx >= 0
                  ? [
                      ...(existing?.postSegments.slice(0, mergeWithIdx) || []),
                      segment,
                      ...(existing?.postSegments.slice(mergeWithIdx + 1) || []),
                    ]
                  : [...(existing?.postSegments || []), segment],
            }
          },
          read(
            existing:
              | {
                  totalCount: number
                  near: number | null
                  postSegments: {
                    offset: number
                    count: number
                    posts: Post[]
                  }[]
                }
              | undefined,
            { variables, readField }, // : FieldFunctionOptions<null, GetDiscussionPostsInput>,
          ) {
            if (!existing || !variables) {
              return undefined
            }

            const getMatchingSegmentFromOffset = (
              offset: number,
            ): PostSegment | undefined =>
              existing.postSegments.find((segment) => {
                const segmentRange = getRange(
                  segment.offset,
                  segment.offset + segment.count,
                )
                const requestedRange = getRange(
                  offset,
                  // Corresponding to backend capping of limit
                  offset +
                    (variables.limit > 30 ? 30 : (variables.limit as number)),
                )

                return !requestedRange.every((n) => segmentRange.includes(n))
              })
            const getMatchingSegmentFromNear = (
              near: number,
            ): PostSegment | undefined =>
              existing.postSegments.find((segment) =>
                segment.posts.find(
                  (p) =>
                    readField('number', (p as unknown) as Reference) === near,
                ),
              )

            const matchingSegment = hasNonNullableKeys(variables, ['offset'])
              ? getMatchingSegmentFromOffset(variables.offset)
              : hasNonNullableKeys(variables, ['near'])
              ? getMatchingSegmentFromNear(variables.near)
              : existing.postSegments[0]

            return (
              matchingSegment && {
                ...matchingSegment,
                totalCount: existing.totalCount,
                near: existing.near,
              }
            )
          },
        },
      },
    },
    Resource: {
      fields: {
        metadatas: {
          keyArgs: (
            _,
            {
              variables,
            }: Parameters<KeyArgsFunction>[1] & {
              variables?: GetResourcesInput
            },
          ) => {
            // Scope metadatas by tagsFilter, teacherTagNodeId and pageItemCount
            // to prevent that subsequent requests with different filters replace
            // and mix up cache data. Each combinaison of these params has their
            // own cached matadatas per Resource.
            if (
              variables &&
              hasNonNullableKeys(variables, ['tagsFilter', 'pageItemCount']) &&
              (variables.allPublic || !!variables.teacherTagNodeId)
            ) {
              variables.pageItemCount
              return `${
                variables.tagsFilter.length
                  ? variables.tagsFilter.join('-')
                  : 'no-tag-filter'
              }/${
                variables.allPublic
                  ? 'all-public'
                  : variables.teacherTagNodeId || 'no-teacher-tag-node-id'
              }/${variables.pageItemCount}`
            }

            // Return undefined when metadata is requested outside of the
            // ResourcesPage scope
            return undefined
          },
        },
      },
    },
    PaginatedResourcesOutput: {
      fields: {
        resources: {
          keyArgs: [
            'tagsFilter',
            'teacherTagNodeId',
            'pageItemCount',
            'allPublic',
          ],
          merge(
            existing: (Resource | Reference)[] = [],
            incoming: (Resource | Reference)[],
            { variables },
          ) {
            if (
              !incoming ||
              !incoming.length ||
              variables?.pageItemCount === undefined
            ) {
              return existing
            }

            const start = (variables.pageIndex || 0) * variables.pageItemCount
            const end = start + incoming.length

            const resources = existing.slice()
            Array(end - start)
              .fill(null)
              .forEach((_, idx) => {
                resources[start + idx] = incoming[idx]
              })

            return resources
          },

          read(existing: Resource[] | undefined, { variables }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            if (!existing || typeof variables?.pageItemCount !== 'number') {
              return undefined
            }

            const start = (+variables.pageIndex || 0) * variables.pageItemCount
            const end = start + variables.pageItemCount

            const page = existing.slice(start, end).filter((x) => x)

            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page.length > 0) {
              return page
            }

            return undefined
          },
        },
      },
    },
  },
})

const httpLink = createUploadLink({
  uri: `${process.env.PROTOCOL || ''}://${process.env.DOMAIN || ''}/graphql`,
})
const authenticationLink = setContext(() => {
  const token = CurrentToken()
  return token ? { headers: { ['X-App-Authorization']: `Token ${token}` } } : {}
})

export const client = new ApolloClient({
  cache,
  // FIXME: Fix this type problem
  link: authenticationLink.concat((httpLink as unknown) as ApolloLink),
  resolvers: {
    Mutation: {},
  },
  connectToDevTools: process.env.NODE_ENV === 'development',
})
