import { useMutation, useQuery } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { FunctionalComponent, h } from 'preact'
import { useRouter } from 'preact-router'
import { useContext, useState } from 'preact/hooks'
import { getRouteUrl, PageType } from 'common/routing'
import {
  IsResetPasswordTokenValidResult,
  IsResetPasswordTokenValidInput,
  IS_RESET_PASSWORD_TOKEN_VALID,
  ResetPasswordResult,
  ResetPasswordInput,
  RESET_PASSWORD,
} from 'store/operations/user'

import { Button } from 'ui/atoms/Button'
import { Loader } from 'ui/atoms/Loader'
import { fromEvent } from 'utils/components'
import styles from './styles.scss'

const ResetPasswordPage: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const [
    {
      matches: { token },
    },
    route,
  ] = useRouter<{ token: string }>()

  const [newPassword, setNewPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const isSame = newPassword === confirmation
  const isLongEnough = newPassword.length >= 6
  const formError = !isSame
    ? t('core.reset_password_page.no_match_error')
    : !isLongEnough
    ? t('core.reset_password_page.too_short_error')
    : null

  const {
    data: isTokenValidData,
    loading: isTokenValidLoading,
    error,
  } = useQuery<IsResetPasswordTokenValidResult, IsResetPasswordTokenValidInput>(
    IS_RESET_PASSWORD_TOKEN_VALID,
    { variables: { token } },
  )

  const [
    resetPassword,
    {
      data: resetPasswordResult,
      loading: resetPasswordLoading,
      error: resetPasswordError,
      called: resetPasswordCalled,
    },
  ] = useMutation<ResetPasswordResult, ResetPasswordInput>(RESET_PASSWORD, {
    variables: { token, password: newPassword },
  })

  if (error) {
    route(getRouteUrl(PageType.NOT_FOUND))
    return null
  }

  if (isTokenValidLoading) {
    return <Loader loaded={false} className={styles.loader} size="md" />
  }

  if (!isTokenValidData?.isResetPasswordTokenValid.ok || !!resetPasswordError) {
    return (
      <section className={styles['section-container']}>
        <div className={styles.head}>
          {t('core.reset_password_page.invalid_token_or_failure_title')}
        </div>
      </section>
    )
  }

  if (resetPasswordCalled && resetPasswordResult?.resetPassword.ok === true) {
    return (
      <section className={styles['section-container']}>
        <div className={styles.head}>
          <i className={`fas fa-check-circle ${styles['success-icon']}`} />{' '}
          {t('core.reset_password_page.success_title')}
        </div>
      </section>
    )
  }

  return (
    <section className={styles['section-container']}>
      <div className={styles.head}>{t('core.reset_password_page.title')}</div>
      <div className={`Form Form--centered ${styles.form}`}>
        <div className="Form-group">
          <input
            className="FormControl"
            type="password"
            placeholder={t('core.reset_password_page.password_placeholder')}
            disabled={resetPasswordLoading}
            value={newPassword}
            onChange={fromEvent(setNewPassword)}
          />
        </div>
        <div className="Form-group">
          <input
            className="FormControl"
            type="password"
            placeholder={t(
              'core.reset_password_page.confirm_password_placeholder',
            )}
            disabled={resetPasswordLoading}
            value={confirmation}
            onChange={fromEvent(setConfirmation)}
          />
        </div>
        <div className="Form-group">
          <Button
            fill
            disabled={!!formError}
            onClick={() => resetPassword()}
            loading={resetPasswordLoading}
          >
            {t('core.reset_password_page.submit_button')}
          </Button>
        </div>
        <div className={styles['form-errors']}>{formError}</div>
      </div>
    </section>
  )
}

export default ResetPasswordPage
