/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const UserControls = flarum.core.compat['utils/UserControls']
const Button = flarum.core.compat['components/Button']
const Badge = flarum.core.compat['components/Badge']
const Model = flarum.core.compat['Model']
const User = flarum.core.compat['models/User']

import SuspendUserModal from './components/SuspendUserModal'
import UserSuspendedNotification from './components/UserSuspendedNotification'
import UserUnsuspendedNotification from './components/UserUnsuspendedNotification'

app.initializers.add('flarum-suspend', () => {
  app.notificationComponents.userSuspended = UserSuspendedNotification
  app.notificationComponents.userUnsuspended = UserUnsuspendedNotification

  User.prototype.canSuspend = Model.attribute('canSuspend')
  User.prototype.suspendedUntil = Model.attribute(
    'suspendedUntil',
    Model.transformDate,
  )

  extend(UserControls, 'moderationControls', (items, user) => {
    if (user.canSuspend()) {
      items.add(
        'suspend',
        Button.component({
          children: app.translator.trans(
            'flarum-suspend.forum.user_controls.suspend_button',
          ),
          icon: 'fas fa-ban',
          onclick: () => app.modal.show(new SuspendUserModal({ user })),
        }),
      )
    }
  })

  extend(User.prototype, 'badges', function(items) {
    const until = this.suspendedUntil()

    if (new Date() < until) {
      items.add(
        'suspended',
        Badge.component({
          icon: 'fas fa-ban',
          type: 'suspended',
          label: app.translator.trans(
            'flarum-suspend.forum.user_badge.suspended_tooltip',
          ),
        }),
      )
    }
  })
})
