/** @jsx m */

import Page from './Page'
import illustration from '../../../../../assets/landing-illustration.png'
import jjProfile from '../../../../../assets/landing-jj-profile.png'
import jmProfile from '../../../../../assets/landing-jm-profile.png'
import Button from '../../common/components/Button'

/**
 * The `LandingPage` component displays the landing page
 */
export default class LandingPage extends Page {
  init() {
    super.init()

    app.history.push(
      'index',
      app.translator.trans('core.forum.header.back_to_index_tooltip'),
    )

    this.bodyClass = 'App--landing'
  }

  view() {
    return (
      <div className="LandingPage">
        <div class="Landing-hero">
          <div class="container flex">
            <div class="Hero-text">
              <div class="landing-title text-xxxl text-bold">
                Enseigner le climat,
                <br />
                auprès et pour
                <br />
                les générations
                <br />
                futures.
              </div>
              <div class="text-xxl">
                Des <span class="text-bold">ressources</span> et une{' '}
                <span class="text-bold">communauté</span> pour enseigner la
                crise climatique.
              </div>
            </div>
            <img src={illustration} class="Hero-illustration" />
          </div>
          <div class="container flex">
            {Button.component(
              { className: 'Button Button--punch landing-hero-cta' },
              'DEMARRER',
            )}
          </div>
        </div>

        <div class="Landing-body">
          <div class="landing-search container flex space-between-duo">
            <div class="landing-search-example">
              <div class="landing-search-box">
                <i class="fas fa-search text-lg" />
                <span class="text-sm">cours physique université climat</span>
              </div>

              <div class="landing-search-pane">
                <div class="text-sm text-bold">DISCUSSIONS</div>

                <div>
                  <div class="text-sm text-bold">
                    Fil conducteur pour une introduction à l’anthropo...
                  </div>
                  <div class="text-xs">
                    Le but de cet enseignement est de fournir aux jeunes
                    étudiants des éléments de ....
                  </div>
                </div>
                <div>
                  <div class="text-sm text-bold">
                    Aperçu des crises du 21ème siècle
                  </div>
                  <div class="text-xs">
                    Le cours consiste en 24 séances d’une heure et demie,
                    réparties sur l’année universitaire.
                  </div>
                </div>
                <div>
                  <div class="text-sm text-bold">
                    Chimie de la haute atmosphère
                  </div>
                  <div class="text-xs">
                    Ce cours, réalisé pour la première fois à l’ecole des mines
                    de Paris, traite des processus chimiques qui ont lieu ....
                  </div>
                </div>
              </div>

              <div class="landing-search-pane tags-pane">
                <div class="text-sm text-bold">THÈMES</div>

                <div>
                  <div class="TagLabel colored Tag-orange">
                    <span class="TagLabel-text">licence</span>
                  </div>
                  <div class="TagLabel colored Tag-green">
                    <span class="TagLabel-text">anthropocène</span>
                  </div>
                  <div class="TagLabel colored Tag-green">
                    <span class="TagLabel-text">effet de serre</span>
                  </div>
                  <div class="TagLabel colored Tag-orange">
                    <span class="TagLabel-text">collège</span>
                  </div>
                  <div class="TagLabel colored Tag-green">
                    <span class="TagLabel-text">6ème extinction</span>
                  </div>
                  <br />
                  <div class="TagLabel colored Tag-puzzleblue">
                    <span class="TagLabel-text">chimie</span>
                  </div>
                  <div class="TagLabel colored Tag-green">
                    <span class="TagLabel-text">thermodynamique</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="landing-body-text flex flex-column justify-center">
              <div class="landing-title text-xxxl text-bold">
                Des ressources pour vous aider
              </div>
              <div class="text-xxl">
                Découvrez ou partagez{' '}
                <span class="text-bold">des cours et supports</span> pour
                accélérer l’enseignement des questions climatiques.
              </div>
            </div>
          </div>

          <div class="landing-community container flex space-between-duo">
            <div class="landing-body-text flex flex-column">
              <div class="landing-title text-xxxl text-bold">
                Une communauté à vos côtés
              </div>
              <div class="text-xxl">
                <span class="text-bold">Echangez avec vos pairs</span> qui
                enseignent la crise climatique et les questions
                environnementales
              </div>
            </div>
            <div class="landing-community-example">
              <div class="chat-card">
                <div class="author flex">
                  <img src={jmProfile} />
                  <div class="author-info flex flex-column">
                    <span class="text-sm text-bold">Jean-Marc Jancovici</span>
                    <span class="text-xs">
                      Enseignant à l’école des Mines de Paris
                    </span>
                  </div>
                </div>
                <div class="message text-sm">
                  Bonjour à tous,
                  <br />
                  <br />
                  Je souhaite réaliser une conférence dans une grande école
                  d’ingénieur sur la problématique énergie-climat, en 2 heures.
                  <br />
                  <br />
                  Cependant, je sèche sur un point : l’ozone...
                </div>
              </div>

              <div class="chat-card response">
                <div class="author flex">
                  <img src={jjProfile} />
                  <div class="author-info flex flex-column">
                    <span class="text-sm text-bold">Jean Jouzel</span>
                    <span class="text-xs">Glaciologue & Enseignant</span>
                  </div>
                </div>
                <div class="message text-sm">
                  Bonjour Jean-Marc,
                  <br />
                  <br />
                  Rien de plus simple ! Voici un document pour t’expliquer le
                  rôle de l’ozone dans le cadre de la formation de la glace en
                  haute altitudes ...
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="Landing-faq">
          <div class="container">
            <div class="landing-title faq-title text-xxxl">
              Toutes vos questions
            </div>
            <div class="faq-item">
              <div class="text-xxl text-bold">
                Qu’est-ce que enseignerleclimat.org ?
              </div>
              <div class="text-xl">
                Il s'agit d'une plateforme pédagogique collaborative visant à
                permettre le partage de ressources et d’expérience entre
                enseignants de toutes disciplines (...)
              </div>
            </div>
            <div class="faq-item">
              <div class="text-xxl text-bold">Qui peut s’inscrire ?</div>
              <div class="text-xl">
                Cette plateforme s’adresse plutôt aux enseignants et assimilés
                (internvenants, conférenciers,...) qui recherchent ou souhaitent
                partager du contenu pédagogique avec leurs pairs.
              </div>
            </div>
          </div>
        </div>

        <div class="Landing-footer">
          <div class="container footer-container">
            <div class="text-xxl footer-title">enseignerleclimat.org</div>
            <ul class="text-md text-bold">
              <li>Tous les contenus</li>
              <li>Inscriptions</li>
              <li>Nous contacter</li>
              <li>Mentions légales</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
