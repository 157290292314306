/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { h, Component, JSX } from 'preact'
import { route } from 'preact-router'

import './style.less'
import { restoreSessionMeta, updateAuthenticatedUser } from 'store/cache/user'
import { client, cache } from 'store'
import {
  GET_AUTHENTICATED_USER,
  GetAuthenticatedUserResult,
} from 'store/operations/user'
import { getApp } from './app'
import Session from './src/core/js/common/Session'
import { startup } from 'legacy/src/forum-init'
import { CurrentAccessToken, CurrentToken } from 'store/local/user'

const m = (global as any).m

export type CompatApp = {
  session: Session
  store: unknown
  forum: {
    attribute: (attr: string) => unknown
  }
  modal: {
    show: (...args: any[]) => void
  }
}
export type CompatRouterData = {
  url: string
  path: string
  matches: Record<string, string>
  [key: string]: any
}

class CompatRouter {
  subscribers: Array<(data: CompatRouterData) => void> = []
  data: CompatRouterData = {
    url: '',
    path: '',
    matches: {},
  }

  param = (param: string): string => {
    return this.data.matches[param]
  }

  path = (): string => {
    return this.data.path
  }

  subscribe = (fn: (data: CompatRouterData) => void): (() => void) => {
    this.subscribers.push(fn)

    return () => {
      const idx = this.subscribers.indexOf(fn)
      idx !== -1 && this.subscribers.splice(idx, 1)
    }
  }

  updateRouterData = (data: CompatRouterData): void => {
    this.data = data
    this.subscribers.forEach((s) => s(this.data))
    m.lazyRedraw && m.lazyRedraw()
  }

  route(routeUrl?: string, shouldReplace?: boolean): string | boolean {
    if (!routeUrl) {
      return this.data.url
    }

    return route(routeUrl, shouldReplace)
  }
}

export const compatRouter = new CompatRouter()

const buildQueryString = m.route.buildQueryString
m.route = function (
  routeUrl?: string,
  shouldReplace?: boolean,
): string | boolean {
  return compatRouter.route(routeUrl, shouldReplace)
}

Object.assign(m.route, {
  mode: 'pathname',
  buildQueryString,
  param: compatRouter.param,
  path: compatRouter.path,
})

export type LegacyComponentParam = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: (props: Record<string, any> | undefined, children?: any) => object
}
export class LegacyComponent extends Component<
  {
    component: LegacyComponentParam
    [k: string]: unknown
  },
  {
    actualProps?: { component: LegacyComponentParam; [k: string]: unknown }
    prevRef: HTMLSpanElement | null
    ref: HTMLSpanElement | null
    shouldUpdate: boolean
    url?: string
  }
> {
  instance: unknown
  mountedInstance: unknown
  unsubscribe: (() => void) | null = null
  state: {
    actualProps?: { component: LegacyComponentParam; [k: string]: unknown }
    prevRef: HTMLSpanElement | null
    ref: HTMLSpanElement | null
    shouldUpdate: boolean
  } = {
    prevRef: null,
    ref: null,
    shouldUpdate: false,
  }

  static getDerivedStateFromProps(
    newProps: { component: LegacyComponentParam; [k: string]: unknown },
    state: {
      actualProps?: { component: LegacyComponentParam; [k: string]: unknown }
      prevRef: HTMLSpanElement | null
      ref: HTMLSpanElement | null
      shouldUpdate: boolean
      url?: string
    },
  ): {
    actualProps?: { component: LegacyComponentParam; [k: string]: unknown }
    shouldUpdate: boolean
    prevRef?: HTMLSpanElement | null
  } | null {
    newProps.compatUrl = state.url
    const propsChanged = state.actualProps
      ? Object.keys(state.actualProps).some(
          (k) => state.actualProps && state.actualProps[k] !== newProps[k],
        ) ||
        Object.keys(state.actualProps).length !== Object.keys(newProps).length
      : true

    if (!propsChanged) {
      if (state.prevRef !== state.ref) {
        return {
          prevRef: state.ref,
          shouldUpdate: true,
        }
      }

      return state.shouldUpdate ? { shouldUpdate: false } : null
    }

    return {
      actualProps: newProps,
      shouldUpdate: true,
    }
  }

  getRefAndProps = (): {
    ref: HTMLSpanElement | null
    props: {
      [k: string]: unknown
    }
  } => {
    const ref = this.state.ref
    const props: {
      component?: LegacyComponentParam
      [k: string]: unknown
    } = Object.assign({}, this.props)
    delete props.component
    delete props.class

    return { ref, props }
  }

  mount = (): void => {
    const { ref, props } = this.getRefAndProps()
    this.instance = this.props.component.component(props)
    if (ref) {
      this.mountedInstance = m.mount(ref, this.instance)
    }
  }

  componentDidMount(): void {
    this.unsubscribe = compatRouter.subscribe(({ url }) =>
      this.setState({ url }),
    )
  }

  componentWillUnmount(): void {
    const { ref } = this.getRefAndProps()

    this.unsubscribe && this.unsubscribe()
    if (ref) {
      m.mount(ref, null)
    }
  }

  componentDidUpdate(): void {
    if (this.state.ref && this.state.shouldUpdate) {
      this.mount()
    }
  }

  render(): JSX.Element {
    return (
      <span
        ref={(ref) => {
          if (this.state.prevRef !== ref && ref) {
            this.setState({ ref })
          }
        }}
      />
    )
  }
}

/**
 * Setup globals for flarum legacy code to work
 * and fetch prerequisites data
 */
export const init = async (): Promise<void> => {
  getApp().session = new Session()
  const sessionMeta = restoreSessionMeta()
  if (sessionMeta.accessToken && sessionMeta.userId) {
    CurrentAccessToken({
      token: sessionMeta.accessToken,
      userId: sessionMeta.userId,
    })
    CurrentToken(sessionMeta.accessToken)

    try {
      const result = await client.query<GetAuthenticatedUserResult>({
        query: GET_AUTHENTICATED_USER,
      })
      updateAuthenticatedUser(cache, {
        authenticatedUser: result.data?.getAuthenticatedUser || null,
      })
    } catch (e) {
      console.error(e)
    }
  }

  await startup(sessionMeta)
  return
}
