import { h, FunctionalComponent } from 'preact'
import { useContext, useMemo, useRef } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useLazyQuery, useQuery } from '@apollo/client'

import { Button } from 'ui/atoms/Button'
import { useDropdown } from 'services/DropdownManager'
import { NotificationList } from 'ui/organisms/NotificationList'
import { NotificationItem } from '../NotificationItem'
import {
  GetModerationFlagsCountInput,
  GetModerationFlagsCountResult,
  GetModerationFlagsInput,
  GetModerationFlagsResult,
  GET_MODERATION_FLAGS,
  GET_MODERATION_FLAGS_COUNT,
} from 'store/operations/moderationFlags'
import { getRouteUrl, PageType } from 'common/routing'
import { ModerationFlagType } from 'types/moderationFlag'
import styles from './styles.scss'
import { NumericBadge } from 'ui/atoms/NumericBadge'

export const ModerationFlagsDropdown: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const moderationDropdownButton = useRef<HTMLDivElement>(null)

  const { data: moderationFlagsCountData } = useQuery<
    GetModerationFlagsCountResult,
    GetModerationFlagsCountInput
  >(GET_MODERATION_FLAGS_COUNT)
  const [getFlags, { data, loading, error }] = useLazyQuery<
    GetModerationFlagsResult,
    GetModerationFlagsInput
  >(GET_MODERATION_FLAGS, { fetchPolicy: 'network-only' })
  const moderationFlagsCount =
    moderationFlagsCountData?.getModerationFlagsCount.count ?? 0

  const [, isShown] = useDropdown({
    for: moderationDropdownButton,
    className: 'max-h-[60vh] overflow-scroll !pt-0',
    children: useMemo(
      () => () => {
        const isEmpty =
          (!loading && data?.getModerationFlags.length === 0) || !!error
        const emptyMessage = error
          ? t('core.lib.error.generic_message')
          : t('core.navbar.moderation_flags.empty')
        const items = data?.getModerationFlags ?? []

        return (
          <div>
            <h4
              className={`py-5 px-4 border-b-[1px_solid_hsl(140,_50%,_93%)] font-roboto text-xs uppercase font-bold text-green-desaturated leading-normal sticky top-0 left-0 bg-white`}
            >
              {t('core.navbar.moderation_flags.title')}{' '}
            </h4>

            <NotificationList
              loading={loading}
              empty={isEmpty}
              emptyMessage={emptyMessage}
            >
              {items.map((flag) => {
                const routeUrl =
                  flag.type === ModerationFlagType.POST
                    ? getRouteUrl(PageType.DISCUSSION_MODERATION, {
                        id: flag.post.discussionId.toString(),
                        moderatedPostNumber: flag.post.number.toString(),
                        moderatedFlagId: flag.id,
                      })
                    : getRouteUrl(PageType.RESOURCE_MODERATION, {
                        id: flag.resourceId,
                        moderatedFlagId: flag.id,
                      })
                const target =
                  flag.type === ModerationFlagType.POST
                    ? flag.post.discussionTitle
                    : flag.resource.title

                return (
                  <NotificationItem
                    key={`moderation-flag-${flag.id}`}
                    content={flag.reasonDetail}
                    date={flag.createdAt}
                    sender={flag.user}
                    notificationTarget={target}
                    redirectUrl={routeUrl}
                  />
                )
              })}
            </NotificationList>
          </div>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, loading, error],
    ),
    dismissOnClick: true,
    align: 'right',
  })

  return (
    <Button
      ref={moderationDropdownButton}
      iconOnly
      rounded
      style="link-primary-faded-hover"
      size="sm"
      onClick={() => getFlags()}
      textNoWrap
      active={isShown}
    >
      <span className={styles['icon-wrapper']}>
        {moderationFlagsCount > 0 && (
          <NumericBadge
            value={moderationFlagsCount}
            limit={99}
            className={styles['numeric-badge']}
          />
        )}
        <i className="fas fa-flag" />
      </span>
    </Button>
  )
}
