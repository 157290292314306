import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext, useState } from 'preact/hooks'

import { fromEvent } from 'utils/components'
import { Button } from 'ui/atoms/Button'

export const FlagPostForm: FunctionalComponent<{
  onFlag: (reason: string, reasonDetail?: string) => void
  loading?: boolean
}> = ({ onFlag, loading = false }) => {
  const { t } = useContext(TranslateContext)
  const [reason, setReason] = useState<
    'off_topic' | 'inappropriate' | 'spam' | 'other' | null
  >(null)
  const [reasonDetail, setReasonDetail] = useState<string>('')

  return (
    <div className="Form Form--centered">
      <div className="Form-group">
        <div>
          <label className="checkbox">
            <input
              type="radio"
              name="reason"
              checked={reason === 'off_topic'}
              value="off_topic"
              onClick={fromEvent<'off_topic'>(setReason)}
            />
            <strong>
              {t('core.community.post.flag_post.reason_off_topic_label')}
            </strong>
            {t('core.community.post.flag_post.reason_off_topic_text')}
            {reason === 'off_topic' && (
              <textarea
                className="FormControl"
                placeholder={t(
                  'core.community.post.flag_post.reason_details_placeholder',
                )}
                value={reasonDetail}
                onInput={fromEvent(setReasonDetail)}
              />
            )}
          </label>

          <label className="checkbox">
            <input
              type="radio"
              name="reason"
              checked={reason === 'inappropriate'}
              value="inappropriate"
              onClick={fromEvent<'inappropriate'>(setReason)}
            />
            <strong>
              {t('core.community.post.flag_post.reason_inappropriate_label')}
            </strong>
            {t('core.community.post.flag_post.reason_inappropriate_text')}
            {reason === 'inappropriate' && (
              <textarea
                className="FormControl"
                placeholder={t(
                  'core.community.post.flag_post.reason_details_placeholder',
                )}
                value={reasonDetail}
                onInput={fromEvent(setReasonDetail)}
              />
            )}
          </label>

          <label className="checkbox">
            <input
              type="radio"
              name="reason"
              checked={reason === 'spam'}
              value="spam"
              onClick={fromEvent<'spam'>(setReason)}
            />
            <strong>
              {t('core.community.post.flag_post.reason_spam_label')}
            </strong>
            {t('core.community.post.flag_post.reason_spam_text')}
            {reason === 'spam' && (
              <textarea
                className="FormControl"
                placeholder={t(
                  'core.community.post.flag_post.reason_details_placeholder',
                )}
                value={reasonDetail}
                onInput={fromEvent(setReasonDetail)}
              />
            )}
          </label>

          <label className="checkbox">
            <input
              type="radio"
              name="reason"
              checked={reason === 'other'}
              value="other"
              onClick={fromEvent<'other'>(setReason)}
            />
            <strong>
              {t('core.community.post.flag_post.reason_other_label')}
            </strong>
            {reason === 'other' && (
              <textarea
                className="FormControl"
                value={reasonDetail}
                onInput={fromEvent(setReasonDetail)}
              />
            )}
          </label>
        </div>
      </div>

      <div className="Form-group">
        <Button
          loading={loading}
          disabled={!reason || (reason === 'other' && !reasonDetail)}
          onClick={() => reason && onFlag(reason, reasonDetail)}
        >
          {t('core.community.post.flag_post.submit_button')}
        </Button>
      </div>
    </div>
  )
}
