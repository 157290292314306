import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'
import { Badge } from 'ui/atoms/Badge'
import { Composer, ComposerProps } from 'ui/molecules/Composer'

export const PostComposer: FunctionalComponent<
  ComposerProps & {
    title: string
  }
> = ({ title, ...composerProps }) => {
  return (
    <Composer {...composerProps}>
      <div className={styles.header}>
        <Badge
          className={styles['header-badge']}
          icon={<i className="fas fa-reply" />}
          value={title}
        />
      </div>
    </Composer>
  )
}
