import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'
import { PartenersLogo } from 'ui/molecules/PartenersLogo'

export const AboutPage: FunctionalComponent = () => {
  return (
    <div className={`${styles['about-page']}`}>
      <section className={`${styles['about-hero']}`}>
        <div className="container">
          <h1>
            EnseignerLeClimat.org : la plateforme de partage de ressources
            pédagogiques pour enseigner la transition
          </h1>

          <div>
            Vous êtes enseignant et vous ne savez pas trop comment aborder les
            enjeux de transition avec vos élèves ? La plateforme
            enseignerleclimat.org est faite pour vous – du moins, nous
            l’espérons !
            <br />
            <br />
            Elle a pour objectif de favoriser le partage de ressources et
            d’expériences entre enseignants et autres acteurs de la communauté
            pédagogique sur l’enseignement des enjeux socio-écologiques. Cette
            plateforme est ouverte aux professionnels de l’enseignement, quelque
            soit leurs disciplines, du primaire au supérieur !
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h1>Une initiative collective, et indépendante</h1>

          <div>
            <a
              href="https://theshiftproject.org/lavenir-de-la-planete-dans-lenseignement-superieur/"
              target="_blank"
            >
              Les premiers travaux du Shift sur les questions d’enseignement
            </a>{' '}
            ont permis de mettre en évidence un besoin largement partagé de la
            communauté enseignante de ressources pour mieux enseigner la
            transition à tous, et partout.
            <br />
            <br />
            Faute d’outil existant répondant à cette demande spécifique, les
            shifters, bénévoles du Shift Project, ont proposé d’apporter leur
            aide et d’élaborer une plateforme répondant au besoin.
            <br />
            <br />
            Enseignerleclimat.org, loin de se limiter aux des climatiques, prend
            pour périmètre l’ensemble des enjeux liés aux limites planétaires
            (ressources, biodiversité, énergie…) et les enjeux sociaux et
            sociétaux afférents (santé, pollution, inégalités, conflits, comme
            le stipulent les{' '}
            <a
              href="https://docs.google.com/document/d/e/2PACX-1vRBWIvRx0ewYtsGiwT6sLH9Wko0OdgZ0HzuknEnSIBPPykcLQjNUzERKuq9BHOFSue67RWJ7kGlX2vX/pub"
              target="_blank"
            >
              conditions générales d’utilisation
            </a>
            ), et laisse in fine les utilisateurs libres de définir la manière
            dont ils ont souhaité s’approprier ce vaste sujet.
            <br />
            <br />
            Cette initiative associe pour l’instant plusieurs organisations :
            <a
              href="https://theshiftproject.org/equipe/#benevoles"
              target="_blank"
            >
              The Shifters
            </a>
            ,{' '}
            <a
              href="https://www.enseignantsdelatransition.org/"
              target="_blank"
            >
              Enseignants de la transition
            </a>
            ,{' '}
            <a href="https://theshiftproject.org/" target="_blank">
              The Shift Project
            </a>
            . Elle bénéficie aussi du soutien d’autres organisations telles que
            l’
            <a href="https://www.uved.fr/" target="_blank">
              UVED
            </a>{' '}
            (l’Université Virtuelle Environnement et Développement durable), et
            sans doute d’autres à venir !
          </div>
          <div className={styles['about-partners']}>
            <PartenersLogo className="grid-cols-2 md:grid-cols-4" />
          </div>
        </div>
      </section>

      <section className={`${styles['about-section-with-bg']}`}>
        <div className="container">
          <h1>
            Enseignants, partagez des ressources, échangez vos expériences !
          </h1>

          <div>
            La plateforme est divisée en deux parties : nous avons les
            Ressources qui vous permettent de partager des cours et du contenu
            et la Communauté pour les échanges entre vous.
          </div>

          <h1>
            Une plateforme développée par des bénévoles, que vous pouvez
            contribuer à améliorer !
          </h1>

          <div>
            La plateforme est gérée par des bénévoles, mais sa maintenance exige
            des moyens non-négligeables : n’hésitez pas à lui apporter votre
            soutien financier en participant à son financement via la{' '}
            <a
              href="https://www.helloasso.com/associations/enseignerleclimat/formulaires/1"
              target="_blank"
            >
              page HelloAsso
            </a>{' '}
            qui lui est consacrée !
            <br />
            <br />
            Développée par une équipe de bénévoles passionnés par les enjeux
            socio-écologiques, la plateforme enseignerleclimat.org est en
            constante évolution pourra toujours être améliorée grâce à vos
            retours ! Signalez à l’équipe projet quelqu’anomalies que vous
            rencontreriez sur la plateforme, et vos idées pour l’améliorer.
          </div>
        </div>
      </section>
    </div>
  )
}
