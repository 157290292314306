import { gql } from '@apollo/client'

import { CommunityTagFragment } from './communityTag'
import { DisplayUserFragment } from './user'

export const DiscussionFragment = gql`
  fragment DiscussionFragment on Discussion {
    id
    title
    commentCount
    postNumberIndex
    createdAt
    tags {
      ...CommunityTagFragment
    }
    author {
      ...DisplayUser
    }
    isSticky
    isLocked
    canPost
    canSubscribe
    canUpdate
    canDelete
    canManage
    subscription
  }

  ${DisplayUserFragment}
  ${CommunityTagFragment}
`

export const DiscussionWithLastPostDataFragment = gql`
  fragment DiscussionWithLastPostDataFragment on Discussion {
    ...DiscussionFragment
    lastPostedUser {
      id
      username
    }
    lastPostNumber
    lastPostedAt
  }

  ${DiscussionFragment}
`
