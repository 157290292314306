/** @jsx m */

import UserPage from './UserPage'
import ItemList from '../../common/utils/ItemList'
import Switch from '../../common/components/Switch'
import Button from '../../common/components/Button'
import FieldSet from '../../common/components/FieldSet'
import NotificationGrid from './NotificationGrid'
import ChangePasswordModal from './ChangePasswordModal'
import ChangeEmailModal from './ChangeEmailModal'
import listItems from '../../common/helpers/listItems'

/**
 * The `SettingsPage` component displays the user's settings control panel, in
 * the context of their user profile.
 */
export default class SettingsPage extends UserPage {
  init() {
    super.init()

    this.show(app.session.user)
    app.setTitle(app.translator.trans('core.forum.settings.title'))
  }

  content() {
    return (
      <div className="SettingsPage">
        <ul>{listItems(this.settingsItems().toArray())}</ul>
      </div>
    )
  }

  /**
   * Build an item list for the user's settings controls.
   *
   * @return {ItemList}
   */
  settingsItems() {
    const items = new ItemList()

    items.add(
      'account',
      FieldSet.component({
        label: app.translator.trans('core.forum.settings.account_heading'),
        className: 'Settings-account',
        children: this.accountItems().toArray(),
      }),
    )

    items.add(
      'notifications',
      FieldSet.component({
        label: app.translator.trans(
          'core.forum.settings.notifications_heading',
        ),
        className: 'Settings-notifications',
        children: this.notificationsItems().toArray(),
      }),
    )

    items.add(
      'privacy',
      FieldSet.component({
        label: app.translator.trans('core.forum.settings.privacy_heading'),
        className: 'Settings-privacy',
        children: this.privacyItems().toArray(),
      }),
    )

    items.add(
      'deletion',
      FieldSet.component({
        label: 'Supprimer mon compte',
        className: 'Settings-privacy',
        children: (
          <p>
            Pour supprimer votre compte, merci de nous écrire via email à{' '}
            <a href="mailto:support@enseignerleclimat.org">
              support@enseignerleclimat.org
            </a>{' '}
            avec l'adresse email associée à votre compte afin que nous
            procédions à la suppression.
          </p>
        ),
      }),
    )

    return items
  }

  /**
   * Build an item list for the user's account settings.
   *
   * @return {ItemList}
   */
  accountItems() {
    const items = new ItemList()

    items.add(
      'changePassword',
      Button.component({
        children: app.translator.trans(
          'core.forum.settings.change_password_button',
        ),
        className: 'Button',
        onclick: () =>
          app.modal.show(
            new ChangePasswordModal({
              updatePassword: this.props.updatePassword,
            }),
          ),
      }),
    )

    items.add(
      'changeEmail',
      Button.component({
        children: app.translator.trans(
          'core.forum.settings.change_email_button',
        ),
        className: 'Button',
        onclick: () =>
          app.modal.show(
            new ChangeEmailModal({
              updateEmail: this.props.updateEmail,
            }),
          ),
      }),
    )

    return items
  }

  /**
   * Build an item list for the user's notification settings.
   *
   * @return {ItemList}
   */
  notificationsItems() {
    const items = new ItemList()

    // items.add(
    //   'notificationGrid',
    //   NotificationGrid.component({ user: this.user }),
    // )

    items.add(
      'newsletterSubscription',
      Switch.component({
        children: app.translator.trans(
          'core.forum.settings.newsletter_subscription_label',
        ),
        state: this.user.preferences().newsletterSubscription,
        onchange: (value, component) => {
          this.preferenceSaver('newsletterSubscription')(value, component)
        },
      }),
    )

    return items
  }

  /**
   * Generate a callback that will save a value to the given preference.
   *
   * @param {String} key
   * @return {Function}
   */
  preferenceSaver(key) {
    return (value, component) => {
      if (component) component.loading = true
      ;(this.props.compatRedraw || m.redraw)()

      this.user.savePreferences({ [key]: value }).then(() => {
        if (component) component.loading = false
        ;(this.props.compatRedraw || m.redraw)()
      })
    }
  }

  /**
   * Build an item list for the user's privacy settings.
   *
   * @return {ItemList}
   */
  privacyItems() {
    const items = new ItemList()

    items.add(
      'discloseOnline',
      Switch.component({
        children: app.translator.trans(
          'core.forum.settings.privacy_disclose_online_label',
        ),
        state: this.user.preferences().discloseOnline,
        onchange: (value, component) => {
          this.user.pushAttributes({ lastSeenAt: null })
          this.preferenceSaver('discloseOnline')(value, component)
        },
      }),
    )

    return items
  }
}
