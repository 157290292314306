import { h, FunctionalComponent } from 'preact'
import { useContext, useMemo, useRef, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import {
  ApolloCache,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'

import { Button } from 'ui/atoms/Button'
import { useDropdown } from 'services/DropdownManager'
import { NotificationList } from 'ui/organisms/NotificationList'
import { NotificationItem } from '../NotificationItem'
import styles from './styles.scss'
import { NumericBadge } from 'ui/atoms/NumericBadge'
import {
  GetUserNotificationsCountResult,
  GetUserNotificationsCountInput,
  GetUserNotificationsInput,
  GetUserNotificationsResult,
  GET_USER_NOTIFICATIONS_COUNT,
  GET_USER_NOTIFICATIONS,
  MarkNotificationAsReadResult,
  MarkNotificationAsReadInput,
  MARK_NOTIFICATION_AS_READ,
} from 'store/operations/notification'
import { NotificationType, Notification } from 'types/notification'
import { cache } from 'store'

const NOTIFICATION_DATA: {
  [type in NotificationType]: {
    getTarget: (notif: Notification) => string | null
    icon: string
    textTranslationKey: string
  }
} = {
  [NotificationType.NEW_POST]: {
    getTarget: (notif) => notif.discussion.title,
    icon: 'fas fa-reply',
    textTranslationKey: 'core.notifications.list_item.action_text.new_post',
  },
  [NotificationType.POST_LIKED]: {
    getTarget: (notif) => notif.discussion.title,
    icon: 'far fa-thumbs-up',
    textTranslationKey: 'core.notifications.list_item.action_text.post_liked',
  },
  [NotificationType.DISCUSSION_LOCKED]: {
    getTarget: (notif) => notif.discussion.title,
    icon: 'fas fa-lock',
    textTranslationKey:
      'core.notifications.list_item.action_text.discussion_locked',
  },
  [NotificationType.DISCUSSION_RENAMED]: {
    getTarget: (notif) => notif.discussion.title,
    icon: 'fas fa-pen',
    textTranslationKey:
      'core.notifications.list_item.action_text.discussion_renamed',
  },
  [NotificationType.RESOURCE_RECOMMENDED]: {
    getTarget: () => null,
    icon: 'fas fa-heart',
    textTranslationKey:
      'core.notifications.list_item.action_text.resource_recommended',
  },
}

export const NotificationsDropdown: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const notificationsDropdownButton = useRef<HTMLDivElement>(null)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [markAsReadLoading, setMarkAsReadLoading] = useState<string[]>([])

  const {
    data: notificationsCountData,
    refetch: refetchNotificationCount,
  } = useQuery<GetUserNotificationsCountResult, GetUserNotificationsCountInput>(
    GET_USER_NOTIFICATIONS_COUNT,
    { fetchPolicy: 'network-only' },
  )
  const [
    getUserNotifications,
    { data, loading, error, fetchMore },
  ] = useLazyQuery<GetUserNotificationsResult, GetUserNotificationsInput>(
    GET_USER_NOTIFICATIONS,
    { fetchPolicy: 'network-only' },
  )
  const [markAsRead] = useMutation<
    MarkNotificationAsReadResult,
    MarkNotificationAsReadInput
  >(MARK_NOTIFICATION_AS_READ)
  const notificationsCount =
    notificationsCountData?.getUserNotificationsCount.count ?? 0

  const markNotificationAsReadHandler = (notifId: string): void => {
    setMarkAsReadLoading((state) => [...state, notifId])
    // Mark notification as read
    void markAsRead({
      variables: { notificationId: notifId },
      update: markNotificationAsReadInCache(notifId),
      refetchQueries: [{ query: GET_USER_NOTIFICATIONS_COUNT }],
    }).finally(() =>
      setMarkAsReadLoading((state) => state.filter((id) => id !== notifId)),
    )
  }

  const [, isShown] = useDropdown({
    for: notificationsDropdownButton,
    className: 'max-h-[60vh] overflow-scroll !pt-0',
    onShow: () => {
      getUserNotifications({ variables: { limit: 5, offset: 0 } })
      void refetchNotificationCount()
    },
    onHide: () => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getUserNotifications',
      }) && cache.gc()
    },
    children: useMemo(
      () => () => {
        const isEmpty =
          (!loading && data?.getUserNotifications.notifications.length === 0) ||
          !!error
        const emptyMessage = error
          ? t('core.lib.error.generic_message')
          : t('core.navbar.notifications.empty')
        const items = data?.getUserNotifications.notifications ?? []

        return (
          <div>
            <h4
              className={`py-5 px-4 border-b-[1px_solid_hsl(140,_50%,_93%)] font-roboto text-xs uppercase font-bold text-green-desaturated leading-normal sticky top-0 left-0 bg-white`}
            >
              {t('core.navbar.notifications.title')}
            </h4>

            <NotificationList
              loading={loading}
              empty={isEmpty}
              emptyMessage={emptyMessage}
              canLoadMore={
                !!data && data.getUserNotifications.totalCount > items.length
              }
              onLoadMore={() => {
                if (!fetchMore) {
                  return
                }

                setIsFetchingMore(() => true)
                void fetchMore({
                  variables: { limit: 5, offset: items.length },
                }).then(() => setIsFetchingMore(() => false))
              }}
              loadMoreLoading={isFetchingMore}
            >
              {items.map((notif) => {
                const notifData = NOTIFICATION_DATA[notif.type]
                const isMarkAsReadLoading = markAsReadLoading.includes(notif.id)

                return (
                  <NotificationItem
                    key={`notif-item-${notif.id}`}
                    content={notif.body ?? ''}
                    date={notif.createdAt}
                    sender={notif.fromUser ?? undefined}
                    notificationTarget={notifData.getTarget(notif)}
                    notificationText={t(notifData.textTranslationKey)}
                    notificationIcon={notifData.icon}
                    canMarkAsRead={notif.readAt === null}
                    onMarkAsRead={() => markNotificationAsReadHandler(notif.id)}
                    markAsReadLoading={isMarkAsReadLoading}
                    redirectUrl={notif.redirectUrl ?? '#'}
                  />
                )
              })}
            </NotificationList>
          </div>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, loading, error, isFetchingMore],
    ),
    dismissOnClick: true,
    align: 'right',
  })

  return (
    <Button
      ref={notificationsDropdownButton}
      iconOnly
      rounded
      style="link-primary-faded-hover"
      size="sm"
      textNoWrap
      active={isShown}
    >
      <span className={styles['icon-wrapper']}>
        {notificationsCount > 0 && (
          <NumericBadge
            value={notificationsCount}
            limit={99}
            className={styles['numeric-badge']}
          />
        )}
        <i className="fas fa-bell" />
      </span>
    </Button>
  )
}

const markNotificationAsReadInCache = (notificationId: string) => (
  cache: ApolloCache<unknown>,
) => {
  cache.writeFragment({
    fragment: gql`
      fragment NotifRead on Notification {
        readAt
      }
    `,
    id: `Notification:${notificationId}`,
    data: {
      readAt: new Date().toISOString(),
    },
  })
}
