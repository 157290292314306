export enum FileType {
  WORD = 'word',
  IMAGE = 'image',
  POWERPOINT = 'powerpoint',
  PDF = 'pdf',
  EXCEL = 'excel',
}

const getGoogleFilePreviewUrl = (url: string): string => {
  return `https://docs.google.com/gview?url=${url}&embedded=true`
}

const getOfficeFilePreviewUrl = (url: string): string => {
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
}

export const FILE_INFO: {
  [key in FileType]: {
    mime: string
    icon: string
    getPreviewUrl: (url: string) => string
  }
} = {
  word: {
    mime:
      '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'far fa-file-word',
    getPreviewUrl: getOfficeFilePreviewUrl,
  },
  image: {
    mime: 'image/gif,image/jpeg,image/pjpeg,image/png,image/tiff,image/x-tiff',
    icon: 'far fa-file-image',
    getPreviewUrl: (x) => x,
  },
  powerpoint: {
    mime:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,application/mspowerpoint',
    icon: 'far fa-file-powerpoint',
    getPreviewUrl: getOfficeFilePreviewUrl,
  },
  pdf: {
    mime: 'application/pdf',
    icon: 'far fa-file-pdf',
    getPreviewUrl: getGoogleFilePreviewUrl,
  },
  excel: {
    mime:
      '.xls,.xlsx,application/vnd.ms-excel,application/vnd.ms-excel.template.macroEnabled.12,application/vnd.ms-excel.addin.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msexcel',
    icon: 'far fa-file-excel',
    getPreviewUrl: getOfficeFilePreviewUrl,
  },
}

export const ANON_RECOMMENDATION_TIP_READ_KEY = 'AnonRecommendationTipRead'
export const AUTHENTICATED_RECOMMENDATION_TIP_READ_KEY =
  'AuthenticatedRecommendationTipRead'
