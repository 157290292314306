/** @jsx m */

const { extend } = flarum.core.compat['extend']
const Button = flarum.core.compat['components/Button']
const CommentPost = flarum.core.compat['components/CommentPost']

import reply from './utils/reply'

export default function() {
  extend(CommentPost.prototype, 'actionItems', function(items) {
    const post = this.props.post

    if (post.isHidden() || (app.session.user && !post.discussion().canReply()))
      return

    items.add(
      'reply',
      Button.component({
        className: 'Button Button--link',
        children: app.translator.trans('flarum-mentions.forum.post.reply_link'),
        onclick: () => reply(post),
      }),
    )
  })
}
