/** @jsx m */

import History from './utils/History'
import Pane from './utils/Pane'
import Search from './components/Search'
import ReplyComposer from './components/ReplyComposer'
import DiscussionPage from './components/DiscussionPage'
import SignUpModal from './components/SignUpModal'
import Composer from './components/Composer'
import DiscussionRenamedNotification from './components/DiscussionRenamedNotification'
import CommentPost from './components/CommentPost'
import DiscussionRenamedPost from './components/DiscussionRenamedPost'
import routes from './routes'
// import alertEmailConfirmation from './utils/alertEmailConfirmation'
import Application from '../common/Application'
import Navigation from '../common/components/Navigation'
import NavBar from './components/NavBar'

export default class ForumApplication extends Application {
  /**
   * A map of notification types to their components.
   *
   * @type {Object}
   */
  notificationComponents = {
    discussionRenamed: DiscussionRenamedNotification,
  }
  /**
   * A map of post types to their components.
   *
   * @type {Object}
   */
  postComponents = {
    comment: CommentPost,
    discussionRenamed: DiscussionRenamedPost,
  }

  /**
   * The page's search component instance.
   *
   * @type {Search}
   */
  search = null

  /**
   * An object which controls the state of the page's side pane.
   *
   * @type {Pane}
   */
  pane = null

  /**
   * An object which controls the state of the page's drawer.
   *
   * @type {Drawer}
   */
  drawer = null

  /**
   * The app's history stack, which keeps track of which routes the user visits
   * so that they can easily navigate back to the previous route.
   *
   * @type {History}
   */
  history = new History()

  constructor() {
    super()

    routes(this)
  }

  /**
   * @inheritdoc
   */
  mount() {
    const homeRoute = 'index'
    this.history.push(
      homeRoute,
      this.translator.trans('core.forum.header.back_to_index_tooltip'),
      this.routes[homeRoute].path,
    )

    this.pane = new Pane(document.getElementById('app'))
    this.composer = m.mount(
      document.getElementById('composer'),
      Composer.component(),
    )

    m.route.mode = 'pathname'
    super.mount(this.forum.attribute('basePath'))

    // alertEmailConfirmation(this)
  }

  /**
   * Check whether or not the user is currently composing a reply to a
   * discussion.
   *
   * @param {Discussion} discussion
   * @return {Boolean}
   */
  composingReplyTo(discussion) {
    return (
      this.composer.component instanceof ReplyComposer &&
      this.composer.component.props.discussion === discussion &&
      this.composer.position !== Composer.PositionEnum.HIDDEN
    )
  }

  /**
   * Check whether or not the user is currently viewing a discussion.
   *
   * @param {Discussion} discussion
   * @return {Boolean}
   */
  viewingDiscussion(discussion) {
    return (
      this.current instanceof DiscussionPage &&
      this.current.discussion === discussion
    )
  }

  /**
   * Callback for when an external authenticator (social login) action has
   * completed.
   *
   * If the payload indicates that the user has been logged in, then the page
   * will be reloaded. Otherwise, a SignUpModal will be opened, prefilled
   * with the provided details.
   *
   * @param {Object} payload A dictionary of props to pass into the sign up
   *     modal. A truthy `loggedIn` prop indicates that the user has logged
   *     in, and thus the page is reloaded.
   * @public
   */
  authenticationComplete(payload) {
    if (payload.loggedIn) {
      window.location.reload()
    } else {
      const modal = new SignUpModal(payload)
      this.modal.show(modal)
    }
  }
}
