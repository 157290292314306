import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'

export const ButtonGroup: FunctionalComponent<{ className?: string }> = ({
  children,
  className = '',
}) => {
  return (
    <div className={`${styles['button-group']} ${className}`}>{children}</div>
  )
}
