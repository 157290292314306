/** @jsx m */

const app = flarum.core.compat['app']
const Model = flarum.core.compat['Model']

import Flag from './models/Flag'
import FlagsPage from './components/FlagsPage'
import addFlagControl from './addFlagControl'
import addFlagsDropdown from './addFlagsDropdown'
import addFlagsToPosts from './addFlagsToPosts'

app.initializers.add('flarum-flags', () => {
  app.store.models.posts.prototype.flags = Model.hasMany('flags')
  app.store.models.posts.prototype.canFlag = Model.attribute('canFlag')

  app.store.models.flags = Flag

  app.routes.flags = { path: '/flags', component: <FlagsPage /> }

  addFlagControl()
  addFlagsDropdown()
  addFlagsToPosts()
})

// Expose compat API
import flagsCompat from './compat'
const { compat } = flarum.core

Object.assign(compat, flagsCompat)
