import { h, FunctionalComponent } from 'preact'
import { useRef, useState } from 'preact/hooks'

import { DropdownButton } from 'ui/molecules/DropdownButton'
import { Button } from '../../atoms/Button'
import styles from './styles.scss'

export const AttachmentItem: FunctionalComponent<{
  title: string
  iconClassName?: string
  iconUrl?: string
  iconFallbackFromUrlToClassName?: string
  hoverIconClassName?: string
  onClick?: () => void
  loading?: boolean
  className?: string
}> = ({
  title,
  iconClassName = '',
  iconUrl,
  iconFallbackFromUrlToClassName = '',
  hoverIconClassName,
  children,
  onClick,
  loading = false,
  className = '',
}) => {
  const [fallbackMode, setFallbackMode] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const dropdownButton = useRef<HTMLDivElement>(null)

  return (
    <DropdownButton
      className={`${className} ${styles['dropdown-group-btn']}`}
      align="right"
      for={dropdownButton}
      onExpandChange={setExpanded}
      buttons={[
        <Button
          key={`attachement-btn-1-${title}`}
          style="btn-primary"
          iconOnly
          size="sm"
          onClick={onClick}
          loading={loading}
        >
          {iconUrl && !fallbackMode ? (
            <img
              className={iconClassName}
              src={iconUrl}
              onError={() =>
                iconFallbackFromUrlToClassName && setFallbackMode(true)
              }
            />
          ) : (
            <i
              className={`${
                fallbackMode ? iconFallbackFromUrlToClassName : iconClassName
              }`}
            />
          )}
        </Button>,
        <Button
          key={`attachement-btn-2-${title}`}
          style="btn-primary-faded"
          size="sm"
          padding="xs"
          onClick={onClick}
          fill
          bold={false}
        >
          <span title={title}>{title}</span>
        </Button>,
        <Button
          ref={dropdownButton}
          key={`attachement-btn-3-${title}`}
          style="btn-primary-faded"
          size="sm"
          iconOnly
          active={expanded}
          className={styles['dropdown-btn']}
        >
          <i
            className={`${
              hoverIconClassName ? styles['hide-on-hover'] : ''
            } fas fa-ellipsis-h`}
          />
          {hoverIconClassName && (
            <i
              className={`${styles['display-on-hover']} ${hoverIconClassName}`}
            />
          )}
        </Button>,
      ]}
    >
      {children}
    </DropdownButton>
  )
}
