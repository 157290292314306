import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation, useQuery } from '@apollo/client'

import styles from './styles.scss'
import { getGraphQLErrors } from 'utils/components'
import {
  GET_CATEGORIES_WITH_TAGS,
  GetCategoriesWithTagsResult,
} from 'store/operations/tagsCategory'
import { Button } from 'ui/atoms/Button'
import {
  UpdateResourceInput,
  UpdateResourceResult,
  UPDATE_RESOURCE,
} from 'store/operations/resource'
import { Stepper } from 'ui/organisms/Stepper'
import { Resource, UpdateResourcePayload } from 'types/resource'
import { ModalContent } from 'services/ModalManager'
import { useAlerts } from 'services/AlertManager'
import { ResourceAuthorStep } from './ResourceAuthorStep'
import { ResourceContentStep } from './ResourceContentStep'
import { ResourceDescriptionStep } from './ResourceDescriptionStep'
import { uploadModule } from 'utils/upload'
import { ProgressBar } from 'ui/atoms/ProgressBar'

export const UpdateResourceModal: ModalContent<{ resource: Resource }> = ({
  resource,
  onDismiss,
  setAlerts,
}) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()

  const [filesToAdd, setFilesToAdd] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<{
    total: number
    completed: number
  }>({ total: 0, completed: 0 })
  const [isUploading, setIsUploading] = useState(false)
  const [cancelUpload, setCancelUpload] = useState<(() => void) | undefined>(
    undefined,
  )
  const [resourcePayload, setResourcePayload] = useState<UpdateResourcePayload>(
    {
      title: resource.title,
      markdownDescription: resource.markdownDescription,
      keywords: resource.keywords,
      tags: resource.tags?.map((t) => t.id) ?? [],
      hyperlinks: resource.hyperlinks,
      originalAuthor: resource.originalAuthor,
      filesToAdd: [],
      filesToDelete: [],
    },
  )

  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)

  const [
    updateResource,
    { error: updateResourceError, loading: updateResourceLoading },
  ] = useMutation<UpdateResourceResult, UpdateResourceInput>(UPDATE_RESOURCE, {
    onCompleted: () => {
      onDismiss()
      showAlert({
        type: 'success',
        message: t(
          'core.resources.update_resource_modal.success_alert_message',
        ),
        duration: 10000,
      })
    },
  })

  setAlerts(getGraphQLErrors([updateResourceError, getTagsError]))

  const steps = [
    {
      label: t('core.resources.update_resource_modal.description_step'),
      component: ResourceDescriptionStep,
      validate: (resourcePayload: UpdateResourcePayload) =>
        !!resourcePayload.title &&
        !!resourcePayload.markdownDescription &&
        !!resourcePayload.keywords &&
        !!resourcePayload.tags.length,
    },
    {
      label: t('core.resources.update_resource_modal.content_step'),
      component: ResourceContentStep,
      validate: (resourcePayload: UpdateResourcePayload) =>
        !!resourcePayload.hyperlinks ||
        !!(
          (resource.files?.length ?? 0) +
          filesToAdd.length -
          resourcePayload.filesToDelete.length
        ),
    },
    {
      label: t('core.resources.update_resource_modal.author_step'),
      component: ResourceAuthorStep,
      validate: () => true,
    },
  ]
  const [currentStep, setCurrentStep] = useState(0)
  const isValid = steps[currentStep].validate(resourcePayload)
  const CurrentStep = steps[currentStep].component

  return (
    <div className="Modal-body">
      <Stepper
        steps={steps.map((s) => s.label)}
        currentStep={currentStep}
        className={styles['new-resource-stepper']}
      />

      <CurrentStep
        payload={resourcePayload}
        filesToAdd={filesToAdd}
        onChange={(payload, newFilesToAdd) => {
          setResourcePayload(payload)
          newFilesToAdd && setFilesToAdd(newFilesToAdd)
        }}
        originalResource={resource}
      />

      <div className={`Form ${styles.form} ${styles['form-right']}`}>
        <div className="Form-group">
          {!isUploading && (
            <Button
              style="btn-info"
              className={`${styles['navigate-button']}`}
              disabled={updateResourceLoading}
              onClick={() => {
                if (currentStep > 0) {
                  setCurrentStep(currentStep - 1)
                }
              }}
            >
              {t('core.resources.update_resource_modal.prev_button')}
            </Button>
          )}
          {!isUploading && currentStep === steps.length - 1 ? (
            <Button
              className={`${styles['send-button']}`}
              disabled={!isValid || updateResourceLoading}
              loading={updateResourceLoading}
              onClick={async () => {
                try {
                  const pendingUploads = await uploadModule.ResourceFiles.uploadMany(
                    filesToAdd,
                    {
                      onProgress: ({
                        completedChunksCount,
                        totalChunksCount,
                      }) => {
                        setUploadProgress({
                          completed: completedChunksCount,
                          total: totalChunksCount,
                        })
                      },
                    },
                  )
                  setCancelUpload(() => pendingUploads.cancelAll)
                  setIsUploading(true)

                  const uploadedFiles = await pendingUploads.promise
                  setCancelUpload(undefined)
                  setIsUploading(false)
                  void updateResource({
                    variables: {
                      resourceId: resource.id,
                      ...resourcePayload,
                      filesToAdd: uploadedFiles,
                    },
                  })
                } catch (e) {
                  console.log('Upload aborted')
                  setIsUploading(false)
                  setCancelUpload(undefined)
                  setUploadProgress({ completed: 0, total: 1 })
                }
              }}
            >
              {t('core.resources.update_resource_modal.send_button')}
            </Button>
          ) : !isUploading ? (
            <Button
              style="btn-primary"
              className={`${styles['navigate-button']}`}
              disabled={!isValid}
              onClick={() => {
                if (currentStep < steps.length) {
                  setCurrentStep(currentStep + 1)
                }
              }}
            >
              {t('core.resources.update_resource_modal.next_button')}
            </Button>
          ) : (
            <div className={styles['progress-wrapper']}>
              <div className={styles['progress-bar-wrapper']}>
                <ProgressBar
                  progressPercentage={
                    (uploadProgress.completed / uploadProgress.total) * 100
                  }
                />
              </div>
              <Button
                style="btn-danger"
                size="sm"
                onClick={() => cancelUpload && cancelUpload()}
              >
                {t('core.resources.update_resource_modal.cancel_upload')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
