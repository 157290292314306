import { h, FunctionalComponent, Fragment } from 'preact'
import { Ref, useMemo, useRef } from 'preact/hooks'

import styles from './styles.scss'
import { ButtonGroup } from '../ButtonGroup'
import { Tooltip } from 'ui/atoms/Tooltip'
import { useDropdown } from 'services/DropdownManager'

export const DropdownButton: FunctionalComponent<{
  align?: 'right' | 'left'
  buttons?: h.JSX.Element[]
  className?: string
  inline?: boolean
  tooltip?: string
  tooltipPosition?: 'top' | 'left' | 'right' | 'bottom'
  for: Ref<HTMLElement | null>
  onExpandChange?: (isExpanded: boolean) => void
}> = ({
  align,
  children,
  buttons = [],
  className = '',
  inline = false,
  tooltip,
  tooltipPosition = 'top',
  for: forElement,
  onExpandChange,
}) => {
  const childrenFn = useMemo(() => () => children, [children])
  const [, isShown] = useDropdown({
    for: forElement,
    children: childrenFn,
    align,
  })
  const ref = useRef<HTMLDivElement | null>(null)
  onExpandChange && onExpandChange(isShown)

  return (
    <Fragment>
      <div
        ref={ref}
        className={`${styles['dropdown-button-container']} ${
          inline ? styles.inline : ''
        } ${className}`}
      >
        <ButtonGroup>{buttons}</ButtonGroup>
      </div>
      {tooltip && (
        <Tooltip content={tooltip} position={tooltipPosition} for={ref} />
      )}
    </Fragment>
  )
}
