import { h, FunctionalComponent } from 'preact'
import { Loader } from '../Loader'

import styles from './styles.scss'

export const ProgressBar: FunctionalComponent<{
  progressPercentage: number
}> = ({ progressPercentage }) => {
  const percentage = Math.floor(progressPercentage)

  return (
    <div className={styles.progress}>
      <div className={styles.percentage}>
        {percentage}% <Loader size="sm" className={styles.spinner} />
      </div>
      <div
        className={styles['progress-bar']}
        style={{ width: `${percentage}%` }}
      />
    </div>
  )
}
