import { FunctionalComponent, h } from 'preact'
import { useMutation, useQuery } from '@apollo/client'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import {
  GetModerationFlagByIdResult,
  GetModerationFlagByIdInput,
  GET_MODERATION_FLAG_BY_ID,
  MARK_FLAG_AS_REVIEWED,
  MarkFlagAsReviewedResult,
  MarkFlagAsReviewedInput,
  GET_MODERATION_FLAGS_COUNT,
} from 'store/operations/moderationFlags'
import { Loader } from 'ui/atoms/Loader'
import { Button } from 'ui/atoms/Button'
import { useAlerts } from 'services/AlertManager'
import { getRouteUrl, PageType } from 'common/routing'

export const ModerationFlag: FunctionalComponent<{
  elementId?: string
  flagId: string
  onFlagReviewed: () => void
  className?: string
}> = ({ flagId, elementId, onFlagReviewed, className = '' }) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()

  const {
    data: moderationFlagData,
    loading: moderationFlagLoading,
    error: moderationFlagError,
  } = useQuery<GetModerationFlagByIdResult, GetModerationFlagByIdInput>(
    GET_MODERATION_FLAG_BY_ID,
    {
      variables: { flagId },
    },
  )
  const [
    markFlagAsReviewed,
    { loading: markFlagAsReviewedLoading },
  ] = useMutation<MarkFlagAsReviewedResult, MarkFlagAsReviewedInput>(
    MARK_FLAG_AS_REVIEWED,
    {
      variables: { flagId },
      refetchQueries: [{ query: GET_MODERATION_FLAGS_COUNT }],
      onCompleted: onFlagReviewed,
      onError: () =>
        showAlert({
          type: 'error',
          message: t('core.moderation.moderation_flag.mark_as_reviewed_error'),
        }),
    },
  )

  if (moderationFlagError) {
    return (
      <div
        className={`${styles['moderation-flag']} ${className}`}
        id={elementId}
      >
        <div className={styles.title}>
          {t('core.moderation.moderation_flag.error')}
        </div>
      </div>
    )
  } else if (moderationFlagLoading || !moderationFlagData) {
    return (
      <div
        className={`${styles['moderation-flag']} ${className}`}
        id={elementId}
      >
        <Loader color="#fff" />
      </div>
    )
  }

  return (
    <div className={`${styles['moderation-flag']} ${className}`} id={elementId}>
      <div>
        <div className={styles.title}>
          {t('core.moderation.moderation_flag.signaled_by')}{' '}
          <a
            href={getRouteUrl(PageType.USER_PROFILE, {
              username: moderationFlagData.getModerationFlagById.user.username,
            })}
            className="text-white"
          >
            {moderationFlagData.getModerationFlagById.user.username}
          </a>{' '}
          {t('core.moderation.moderation_flag.signaled_as', {
            reason: t(
              `core.moderation.moderation_flag.reason.${moderationFlagData.getModerationFlagById.reason}`,
            ),
          })}
        </div>
        <div>{moderationFlagData?.getModerationFlagById.reasonDetail}</div>
      </div>

      <div className={styles.actions}>
        <Button
          style="link-darken-hover"
          padding="md"
          bold={false}
          loading={markFlagAsReviewedLoading}
          onClick={() => {
            void markFlagAsReviewed()
          }}
        >
          <i className={`fas fa-check ${styles['check-icon']}`} />
          {t('core.moderation.moderation_flag.mark_as_reviewed')}
        </Button>
      </div>
    </div>
  )
}
