import { h, FunctionalComponent } from 'preact'

export const BurgerButton: FunctionalComponent<{
  onClick: () => void
  isOpened: boolean
  className?: string
}> = (props) => {
  return (
    <button
      className={`text-gray-500 w-10 h-10 relative focus:outline-none ${
        props.className || ''
      }`}
      onClick={props.onClick}
    >
      <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span
          aria-hidden="true"
          className={`block absolute h-0.5 w-5 bg-black transform transition duration-150 ease-in-out ${
            props.isOpened ? '-rotate-45' : 'translate-y-1.5'
          }`}
        />
        <span
          aria-hidden="true"
          className={`block absolute h-0.5 w-5 bg-black transform transition duration-150 ease-in-out ${
            props.isOpened ? 'opacity-0' : ''
          }`}
        />
        <span
          aria-hidden="true"
          className={`block absolute h-0.5 w-5 bg-black transform transition duration-150 ease-in-out ${
            props.isOpened ? 'rotate-45' : '-translate-y-1.5'
          }`}
        />
      </div>
    </button>
  )
}
