import { FunctionalComponent, h } from 'preact'
import { useContext, useRef } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { FILE_INFO } from 'utils/constants'
import { Resource, UpdateResourcePayload } from 'types/resource'
import { Button } from 'ui/atoms/Button'
import { HyperlinkItem } from 'ui/molecules/HyperlinkItem'
import { FileItem } from 'ui/molecules/FileItem'
import { handleEnterKey } from 'utils/components'

export const ResourceContentStep: FunctionalComponent<{
  payload: UpdateResourcePayload
  filesToAdd: File[]
  onChange: (
    partialResource: UpdateResourcePayload,
    filesToAdd?: File[],
  ) => void
  originalResource: Resource
}> = ({ payload, filesToAdd, onChange, originalResource }) => {
  const { t } = useContext(TranslateContext)
  const hyperlinkInputRef = useRef<HTMLInputElement | null>(null)

  const hyperlinks = (payload.hyperlinks && payload.hyperlinks.split(' ')) || []
  const { filesToDelete } = payload
  const { files } = originalResource

  const addHyperlink = (): void => {
    const hyperlinkValue = hyperlinkInputRef.current?.value
    if (
      !hyperlinkInputRef.current ||
      !hyperlinkValue ||
      hyperlinkValue.length < 4
    ) {
      return
    }

    const hyperlink =
      hyperlinkValue.startsWith('http://') ||
      hyperlinkValue.startsWith('https://')
        ? hyperlinkValue
        : `http://${hyperlinkValue}`
    hyperlinkInputRef.current.value = ''
    onChange({
      ...payload,
      hyperlinks: hyperlinks.concat(hyperlink).map(encodeURI).join(' '),
    })
  }
  const removeHyperlink = (idx: number): void => {
    const newHyperlinks = hyperlinks.slice()
    newHyperlinks.splice(idx, 1)

    onChange({
      ...payload,
      hyperlinks: newHyperlinks.length
        ? newHyperlinks.map(encodeURI).join(' ')
        : null,
    })
  }
  const addFile = (newFileList: FileList): void => {
    const nbFiles = newFileList.length + filesToAdd.length
    const newFiles = Array(nbFiles)
      .fill(null)
      .reduce<File[]>((acc, _, idx) => {
        const f = newFileList.item(idx)
        if (!f) {
          return acc
        }

        return acc.concat(f)
      }, filesToAdd)

    onChange(payload, newFiles)
  }
  const removeExistingFile = (fileId: string): void =>
    onChange({
      ...payload,
      filesToDelete: filesToDelete.concat(fileId),
    })
  const removeAddedFile = (idx: number): void => {
    const newFiles = filesToAdd.slice()
    newFiles.splice(idx, 1)

    onChange(payload, newFiles)
  }

  return (
    <div className={`Form Form--centered ${styles.form}`}>
      <div className={styles['form-group-duo']}>
        <div>
          {!!files &&
            files
              .filter((f) =>
                filesToDelete.every((idToDelete) => idToDelete !== f.id),
              )
              .map((f, idx) => (
                <FileItem
                  key={`local-file-item-${f.filename}-${idx}`}
                  file={{
                    filename: f.filename,
                    mimetype: f.mimetype,
                  }}
                  canRemove
                  onAction={{ remove: () => removeExistingFile(f.id) }}
                  className={styles['attachment-item']}
                />
              ))}
          {filesToAdd.map((f, idx) => (
            <FileItem
              key={`local-file-item-${f.name}-${idx}`}
              file={{ filename: f.name, mimetype: f.type }}
              canRemove
              onAction={{ remove: () => removeAddedFile(idx) }}
              className={styles['attachment-item']}
            />
          ))}
          {!((files?.length ?? 0) - filesToDelete.length) &&
            !filesToAdd.length && (
              <div>{t('core.resources.update_resource_modal.no_file')}</div>
            )}
        </div>

        <div>
          {hyperlinks.length ? (
            hyperlinks.map((link, idx) => (
              <HyperlinkItem
                key={`added-hyperlink-item-${link}-${idx}`}
                onRemoveClick={() => removeHyperlink(idx)}
                removable
                url={link}
                className={styles['attachment-item']}
              />
            ))
          ) : (
            <div>{t('core.resources.update_resource_modal.no_hyperlink')}</div>
          )}
        </div>
      </div>

      <div className={styles['form-group-duo']}>
        <div className="Form-group">
          <label
            className={styles['custom-file-uploader']}
            htmlFor="new-resource-file-upload"
          >
            <div className={styles['upload-text']}>
              {t('core.resources.update_resource_modal.upload_button_label')}
            </div>
            <div className={styles['upload-icon']}>
              <i className="fa fa-upload" />
            </div>
          </label>
          <input
            className={styles['hidden-input']}
            id="new-resource-file-upload"
            type="file"
            accept={Object.values(FILE_INFO).reduce(
              (mime, i) => `${mime},${i.mime}`,
              '',
            )}
            multiple
            onChange={(event: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
              const newFileList = (
                event.target as unknown as {
                  files: FileList
                }
              ).files
              addFile(newFileList)
            }}
          />
        </div>

        <div className="Form-group">
          <div className={styles['add-link-input-container']}>
            <input
              ref={hyperlinkInputRef}
              className={`FormControl  ${styles.FormControl} ${styles['add-link-input']}`}
              id="new-resource-add-link"
              type="text"
              placeholder={t(
                'core.resources.update_resource_modal.link_placeholder',
              )}
              onKeyPress={handleEnterKey(addHyperlink)}
            />
            <Button
              className={styles['add-link-button']}
              onClick={addHyperlink}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
