import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { DropdownItem } from '../../atoms/DropdownItem'
import { AttachmentItem } from '../AttachmentItem'
import styles from './styles.scss'

export const HyperlinkItem: FunctionalComponent<{
  url: string
  onClick?: () => void
  onRemoveClick?: () => void
  removable?: boolean
  className?: string
}> = ({ url, onClick, removable = false, onRemoveClick, className }) => {
  const { t } = useContext(TranslateContext)
  const execOnClick = onClick || (() => window.open(url, '_blank'))

  return (
    <AttachmentItem
      iconClassName="fas fa-globe"
      title={url}
      onClick={execOnClick}
      className={className}
    >
      <DropdownItem
        hasIcon
        iconClassName="fas fa-external-link-square-alt"
        onClick={execOnClick}
      >
        {t('core.upload.attachment.external_link_button')}
      </DropdownItem>
      {removable && (
        <DropdownItem
          hasIcon
          iconClassName={`fas fa-trash-alt ${styles.red}`}
          onClick={onRemoveClick}
        >
          {t('core.upload.attachment.remove_button')}
        </DropdownItem>
      )}
    </AttachmentItem>
  )
}
