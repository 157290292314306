import { gql } from '@apollo/client'
import { UploadedFile, S3Object } from '@modbox/s3-uploads-client'

import { Post, UserPost } from 'types/post'
import { PostFragment } from './fragments/post'

export type GetPostByIdInput = {
  id: string
}
export type GetPostByIdResult = {
  getPostById: Post
}
export const GET_POST_BY_ID = gql`
  query GetPostById($id: ID!) {
    getPostById(id: $id) {
      ...PostFragment
    }
  }

  ${PostFragment}
`

export type TogglePostLikeInput = {
  id: string
}
export type TogglePostLikeResult = {
  togglePostLike: Post
}
export const TOGGLE_POST_LIKE = gql`
  mutation TogglePostLike($id: ID!) {
    togglePostLike(id: $id) {
      ...PostFragment
    }
  }

  ${PostFragment}
`

export type AddPostInput = {
  discussionId: string
  markdownContent: string
  files?: UploadedFile[]
}
export type AddPostResult = {
  addPost: Post
}
export const ADD_POST = gql`
  mutation AddPost(
    $markdownContent: String!
    $discussionId: ID!
    $files: [UploadedFile!]
  ) {
    addPost(
      markdownContent: $markdownContent
      discussionId: $discussionId
      files: $files
    ) {
      ...PostFragment
    }
  }

  ${PostFragment}
`

export type EditPostInput = {
  postId: string
  markdownContent: string
  filesToAdd?: UploadedFile[]
  filesToDelete?: S3Object[]
}
export type EditPostResult = {
  editPost: Post
}
export const EDIT_POST = gql`
  mutation EditPost(
    $markdownContent: String!
    $postId: ID!
    $filesToAdd: [UploadedFile!]
    $filesToDelete: [S3ObjectInput!]
  ) {
    editPost(
      markdownContent: $markdownContent
      postId: $postId
      filesToAdd: $filesToAdd
      filesToDelete: $filesToDelete
    ) {
      ...PostFragment
    }
  }

  ${PostFragment}
`

export type DeletePostInput = {
  postId: string
}
export type DeletePostResult = {
  deletePost: Post
}
export const DELETE_POST = gql`
  mutation DeletePost($postId: ID!) {
    deletePost(postId: $postId) {
      ...PostFragment
    }
  }

  ${PostFragment}
`

export type PermanentlyDeletePostInput = {
  postId: string
}
export type PermanentlyDeletePostResult = {
  permanentlyDeletePost: { deletedId: string }
}
export const PERMANENTLY_DELETE_POST = gql`
  mutation PermanentlyDeletePost($postId: ID!) {
    permanentlyDeletePost(postId: $postId) {
      deletedId
    }
  }

  ${PostFragment}
`

export type GetDiscussionPostsInput = {
  discussionId: string
  limit: number
  near?: number
  offset?: number
  direction?: 'previous' | 'next'
}
export type GetDiscussionPostsResult = {
  getDiscussionPosts: {
    posts: Post[]
    offset: number
    count: number
    totalCount: number
    near: number | null
  }
}
export const GET_DISCUSSION_POSTS = gql`
  query GetDiscussionPosts(
    $discussionId: ID!
    $limit: Int!
    $near: Int
    $offset: Int
    $direction: String
  ) {
    getDiscussionPosts(
      discussionId: $discussionId
      limit: $limit
      near: $near
      offset: $offset
      direction: $direction
    ) {
      offset
      count
      totalCount
      near
      posts {
        ...PostFragment
      }
    }
  }

  ${PostFragment}
`

export type GetUserPostsInput = {
  limit: number
  offset: number
  userId: string
}
export type GetUserPostsResult = {
  getUserPosts: {
    posts: UserPost[]
    offset: number
    count: number
    totalCount: number
  }
}
export const GET_USER_POSTS = gql`
  query GetUserPosts($limit: Int!, $offset: Int!, $userId: ID!) {
    getUserPosts(limit: $limit, offset: $offset, userId: $userId) {
      offset
      count
      totalCount
      posts {
        ...PostFragment
      }
    }
  }

  ${PostFragment}
`
