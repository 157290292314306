/** @jsx m */

const app = flarum.core.compat['app']
const { extend } = flarum.core.compat['extend']
const Post = flarum.core.compat['components/Post']

/* global $ */

export default function() {
  extend(Post.prototype, 'config', function(isInitialized) {
    if (isInitialized) return

    this.$('[data-fof-upload-download-uuid]')
      .unbind('click')
      .on('click', e => {
        e.preventDefault()
        e.stopPropagation()

        if (!app.forum.attribute('fof-upload.canDownload')) {
          alert(app.translator.trans('fof-upload.forum.states.unauthorized'))
          return
        }

        let url = app.forum.attribute('apiUrl') + '/fof/download'

        url += '/' + e.currentTarget.dataset.fofUploadDownloadUuid
        url += '/' + this.props.post.id()

        app
          .request({
            method: 'GET',
            url,
            dataType: 'blob',
            extract: response => response,
            serialize: v => v,
            deserialize: v => v,
          })
          .then(response => {
            const contentDispo = response.getResponseHeader(
              'Content-Disposition',
            )
            const fileName = contentDispo.match(
              /filename[^;=\n]*=(?:['"](.*?)['"]|([^;\n]*))/,
            )[1]

            const tempEl = document.createElement('a')
            tempEl.style = 'display: none'
            document.body.appendChild(tempEl)
            url = window.URL.createObjectURL(response.response)
            tempEl.href = url
            tempEl.download = fileName
            tempEl.click()
            window.URL.revokeObjectURL(url)
          })
      })
  })
}
