import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import styles from './styles.scss'
import { Loader } from 'ui/atoms/Loader'
import { TranslateContext } from '@denysvuika/preact-translate'
import { Button } from 'ui/atoms/Button'

export const NotificationList: FunctionalComponent<{
  loading?: boolean
  empty?: boolean
  emptyMessage?: string
  canLoadMore?: boolean
  onLoadMore?: () => void
  loadMoreLoading?: boolean
}> = ({
  loading = false,
  children,
  empty = false,
  emptyMessage,
  canLoadMore = false,
  onLoadMore,
  loadMoreLoading = false,
}) => {
  const { t } = useContext(TranslateContext)

  return (
    <div className={styles['notification-list']}>
      <ul className={styles['list-content']}>
        {loading && (
          <div className={styles['loader-container']}>
            <Loader />
          </div>
        )}
        {!loading && !empty && children}
        {!loading && empty && (
          <div className={styles['empty-message']}>{emptyMessage}</div>
        )}
      </ul>
      {canLoadMore && onLoadMore && (
        <div className="flex">
          <Button
            style="link"
            bold
            className="mx-auto"
            size="sm"
            loading={loadMoreLoading}
            onClick={(e) => {
              e.stopImmediatePropagation()
              onLoadMore()
            }}
          >
            {t('core.navbar.notifications.load_more')}
          </Button>
        </div>
      )}
    </div>
  )
}
