const conversionMap = {
  '': / +/g,
  a: /[äàáâãåā]/g,
  c: /çćč/g,
  e: /[ëèéêęėē]/g,
  i: /[ïìíîįī]/gu,
  o: /[öòóôºõøō]/g,
  u: /[üùúûū]/g,
  n: /ñń/g,
  y: /ÿ/g,
  A: /[ÄÀÁÂÃÅĀ]/g,
  C: /ÇĆČ/g,
  E: /[ËÈÉÊĘĖĒ]/g,
  I: /[ÏÌÍÎĮĪ]/g,
  O: /[ÖÒÓÔºÕØŌ]/g,
  U: /[ÜÙÚÛŪ]/g,
  N: /ÑŃ/g,
  Y: /Ÿ/g,
  oe: /œ/g,
  OE: /Œ/g,
  ae: /æ/g,
  AE: /Æ/g,
  _: /[^\w.]/g,
}

export const sanitizeUsername = (username: string): string => {
  return Object.entries(conversionMap).reduce(
    (acc, [deaccentuated, accentuated]) =>
      acc.replace(accentuated, deaccentuated),
    username,
  )
}

export const omitUndefinedValues = (obj: Record<string, unknown>): unknown => {
  const entries = Object.entries(obj)
  const filtered = entries.filter(([, value]) => value !== undefined)

  return filtered.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {},
  )
}
