import {
  setupUploadModule,
  InitiatedUpload,
  MultipartUploadChunk,
  PresignedRequestInfo,
} from '@modbox/s3-uploads-client'

import { client } from 'store'
import {
  InitiateUploadResult,
  InitiateUploadInput,
  INITIATE_UPLOAD,
  GetPartUploadPresignedRequestInput,
  GetPartUploadPresignedRequestResult,
  GET_PART_UPLOAD_PRESIGNED_REQUEST,
} from 'store/operations/upload'

const initiateUpload = async (
  uploadType: UploadType,
  files: File[],
): Promise<InitiatedUpload[]> => {
  const {
    data: { initiateUpload: initiatedUploads },
  } = await client.query<InitiateUploadResult, InitiateUploadInput>({
    query: INITIATE_UPLOAD,
    fetchPolicy: 'no-cache',
    variables: {
      files: files.map((file) => ({
        filename: file.name,
        size: file.size,
        mimetype: file.type,
      })),
      uploadType,
    },
  })

  return initiatedUploads
}

const getPartRequest = async (
  chunk: MultipartUploadChunk,
): Promise<PresignedRequestInfo> => {
  const { data: result } = await client.query<
    GetPartUploadPresignedRequestResult,
    GetPartUploadPresignedRequestInput
  >({
    query: GET_PART_UPLOAD_PRESIGNED_REQUEST,
    fetchPolicy: 'no-cache',
    variables: {
      uploadId: chunk.uploadId,
      key: chunk.key,
      partNumber: chunk.partNumber,
      uploadType: chunk.uploadType as UploadType,
    },
  })

  return {
    url: result.getPartUploadPresignedRequest.url,
    headers: result.getPartUploadPresignedRequest.headers,
    fields: result.getPartUploadPresignedRequest.fields,
  }
}

export enum UploadType {
  Avatar = 'Avatar',
  CommunityPost = 'CommunityPost',
  ResourceFiles = 'ResourceFiles',
}

export const uploadModule = setupUploadModule({
  maxConcurrentUploads: 2,
  uploads: {
    [UploadType.Avatar]: {
      initiate: (files: File[]): Promise<InitiatedUpload[]> => {
        return initiateUpload(UploadType.Avatar, files)
      },
    },
    [UploadType.CommunityPost]: {
      initiate: (files: File[]): Promise<InitiatedUpload[]> => {
        return initiateUpload(UploadType.CommunityPost, files)
      },
      getPartRequest,
    },
    [UploadType.ResourceFiles]: {
      initiate: (files: File[]): Promise<InitiatedUpload[]> => {
        return initiateUpload(UploadType.ResourceFiles, files)
      },
      getPartRequest,
    },
  },
})

export const toFileArray = (files: FileList): File[] => {
  return Array<File>(files.length)
    .fill(null as never)
    .map<File>((_, idx) => files.item(idx) as File)
}
