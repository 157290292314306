import { gql } from '@apollo/client'

import { FullFileFragment } from './file'
import { DisplayUserFragment } from './user'
import {
  FilteredResourceMetadatasFragment,
  ResourceMetadatasFragment,
} from './metadata'
import { FullTagFragment } from './tag'

export const DisplayResourceFragment = gql`
  fragment DisplayResource on Resource {
    id
    title
    description
    markdownDescription
    slug
    authorId
    createdAt
    hyperlinks
    originalAuthor
    keywords
    updatedAt
    postedForTagNodeId
  }
`

export const ResourceWithoutMetadatasFragment = gql`
  fragment ResourceWithoutMetadatas on Resource {
    ...DisplayResource
    canFlag
    canUpdate
    tags {
      ...FullTag
    }
    author {
      ...DisplayUser
    }
    files {
      ...FullFile
    }
    updatedBy {
      ...DisplayUser
    }
  }

  ${DisplayResourceFragment}
  ${FullTagFragment}
  ${FullFileFragment}
  ${DisplayUserFragment}
`

export const FullResourceFragment = gql`
  fragment FullResource on Resource {
    ...ResourceWithoutMetadatas
    metadatas {
      ...ResourceMetadatas
    }
  }

  ${ResourceWithoutMetadatasFragment}
  ${ResourceMetadatasFragment}
`

export const FullFilteredResourceFragment = gql`
  fragment FullFilteredResource on Resource {
    ...ResourceWithoutMetadatas
    metadatas {
      ...FilteredResourceMetadatas
    }
  }

  ${ResourceWithoutMetadatasFragment}
  ${FilteredResourceMetadatasFragment}
`
