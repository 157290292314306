import { useQuery } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { route } from 'preact-router'

import {
  GetUserPostsInput,
  GetUserPostsResult,
  GET_USER_POSTS,
} from 'store/operations/post'
import { Loader } from 'ui/atoms/Loader'
import { Post } from 'ui/organisms/Post'
import styles from './styles.scss'
import { getRouteUrl, PageType } from 'common/routing'
import { Button } from 'ui/atoms/Button'

const POST_LIMIT_PER_REQUEST = 10
const getPostElementId = (discussionId: number, postNumber: number): string =>
  `${discussionId}-post-number-${postNumber}`

export const UserPosts: FunctionalComponent<{ userId: string }> = ({
  userId,
}) => {
  const { t } = useContext(TranslateContext)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const {
    data: getUserPostsData,
    loading: getUserPostsLoading,
    fetchMore,
  } = useQuery<GetUserPostsResult, GetUserPostsInput>(GET_USER_POSTS, {
    variables: { limit: POST_LIMIT_PER_REQUEST, offset: 0, userId },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <div className={styles['user-posts']}>
      <Loader
        loaded={!getUserPostsLoading}
        passingProps={{ userPosts: getUserPostsData?.getUserPosts }}
        className={styles['user-posts-loader']}
      >
        {({ userPosts: { posts, totalCount, count } }) => (
          <Fragment>
            {posts?.map((p, idx) => [
              <div
                key={`${getPostElementId(
                  p.discussionId,
                  p.number,
                )}-discussion-title`}
                className={styles['discussion-title']}
                onClick={() =>
                  route(
                    getRouteUrl(PageType.DISCUSSION, {
                      id: `${p.discussionId}`,
                      near: `${p.number}`,
                    }),
                  )
                }
              >
                {t('core.user_profile.user_posts.in_discussion_prefix')}{' '}
                <span>{p.discussionTitle}</span>
              </div>,
              <Post
                noActions
                post={p}
                className={`${styles.post} ${
                  posts.length === idx + 1 ? styles['last-post-in-stream'] : ''
                }`}
                key={getPostElementId(p.discussionId, p.number)}
              />,
            ])}
            {totalCount > count && (
              <Button
                style="btn-info"
                className={styles['load-more-btn']}
                onClick={async () => {
                  setIsFetchingMore(true)
                  await fetchMore({
                    variables: {
                      limit: POST_LIMIT_PER_REQUEST,
                      offset: count,
                      userId,
                    },
                  })
                  setIsFetchingMore(false)
                }}
                loading={isFetchingMore}
                disabled={isFetchingMore}
              >
                {t('core.user_profile.user_posts.load_more_button')}
              </Button>
            )}
            {totalCount === 0 && (
              <div className={styles['no-item']}>
                {t('core.user_profile.user_posts.no_posts')}
              </div>
            )}
          </Fragment>
        )}
      </Loader>
    </div>
  )
}
