import { gql } from '@apollo/client'
import { UploadedFile } from '@modbox/s3-uploads-client'

import { SimpleResult } from 'types/graphql'
import { AuthenticatedUser, User, DisplayUser } from 'types/user'
import {
  DisplayUserFragment,
  FullAccessTokenFragment,
  FullUserFragment,
} from './fragments/user'

export type GetAuthenticatedUserResult = {
  getAuthenticatedUser: AuthenticatedUser | null
}
export const GET_AUTHENTICATED_USER = gql`
  query GetAuthenticatedUser {
    getAuthenticatedUser {
      accessToken {
        ...FullAccessToken
      }
      user {
        ...FullUser
      }
    }
  }

  ${FullAccessTokenFragment}
  ${FullUserFragment}
`

export type LogUserInResult = {
  login: AuthenticatedUser
}
export const LOG_USER_IN = gql`
  mutation LogUserIn(
    $identification: String!
    $password: String!
    $remember: Boolean
  ) {
    login(
      identification: $identification
      password: $password
      remember: $remember
    ) {
      accessToken {
        ...FullAccessToken
      }
      user {
        ...FullUser
      }
    }
  }

  ${FullAccessTokenFragment}
  ${FullUserFragment}
`

export type LogUserOutResult = { logout: boolean }
export const LOG_USER_OUT = gql`
  mutation LogUserOut {
    logout
  }
`

export interface RegisterInput {
  email: string
  username: string
  firstName: string | null
  lastName: string | null
  password: string
  subscribeToNewsletter: boolean
  discloseEmail: boolean
  isTeacher: boolean
  tagNodeId: string | null
}
export type RegisterResult = { register: AuthenticatedUser | null }
export const REGISTER = gql`
  mutation Register(
    $email: String!
    $username: String!
    $firstName: String
    $lastName: String
    $password: String!
    $subscribeToNewsletter: Boolean!
    $discloseEmail: Boolean!
    $isTeacher: Boolean!
    $tagNodeId: ID
  ) {
    register(
      email: $email
      username: $username
      firstName: $firstName
      lastName: $lastName
      password: $password
      subscribeToNewsletter: $subscribeToNewsletter
      discloseEmail: $discloseEmail
      isTeacher: $isTeacher
      tagNodeId: $tagNodeId
    ) {
      accessToken {
        ...FullAccessToken
      }
      user {
        ...FullUser
      }
    }
  }

  ${FullUserFragment}
  ${FullAccessTokenFragment}
`

export type GetUserByUsernameInput = {
  username: string
}
export type GetUserByUsernameResult = {
  getUserByUsername: DisplayUser
}
export const GET_USER_BY_USERNAME = gql`
  query GetUserByUsername($username: String!) {
    getUserByUsername(username: $username) {
      ...DisplayUser
    }
  }

  ${DisplayUserFragment}
`

export type UpdateUserInput = {
  user: Partial<
    Omit<User, 'preferences'> & { preferences: Partial<User['preferences']> }
  > &
    Pick<User, 'id'>
  newAvatarFile?: UploadedFile
}
export type UpdateUserResult = {
  updateUser: User
}
export const UPDATE_USER = gql`
  mutation UpdateUser($user: UserInputWithId!, $newAvatarFile: UploadedFile) {
    updateUser(user: $user, newAvatarFile: $newAvatarFile) {
      ...FullUser
    }
  }

  ${FullUserFragment}
`

export type UpdateUserEmailInput = {
  password: string
  newEmail: string
}
export type UpdateUserEmailResult = {
  updateUserEmail: User
}
export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($password: String!, $newEmail: String!) {
    updateUserEmail(password: $password, newEmail: $newEmail) {
      ...FullUser
    }
  }

  ${FullUserFragment}
`

export type SendConfirmationEmailInput = { sendConfirmationEmail: SimpleResult }
export type SendConfirmationEmailResult = { sendConfirmationEmail: boolean }
export const SEND_CONFIRMATION_EMAIL = gql`
  mutation SendConfirmationEmail {
    sendConfirmationEmail {
      ok
    }
  }
`

export type ForgotPasswordInput = {
  email: string
  update?: boolean
}
export type ForgotPasswordResult = { forgotPassword: SimpleResult }
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!, $update: Boolean) {
    forgotPassword(email: $email, update: $update) {
      ok
    }
  }
`

export type IsResetPasswordTokenValidInput = {
  token: string
}
export type IsResetPasswordTokenValidResult = {
  isResetPasswordTokenValid: SimpleResult
}
export const IS_RESET_PASSWORD_TOKEN_VALID = gql`
  query IsResetPasswordTokenValid($token: String!) {
    isResetPasswordTokenValid(token: $token) {
      ok
    }
  }
`

export type ResetPasswordInput = {
  token: string
  password: string
}
export type ResetPasswordResult = {
  resetPassword: SimpleResult
}
export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      ok
    }
  }
`
