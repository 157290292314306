/** @jsx m */

const { extend } = flarum.core.compat['extend']
import 'autolink-js'
const computed = flarum.core.compat['utils/computed']
const UserCard = flarum.core.compat['components/UserCard']
const User = flarum.core.compat['models/User']
const Model = flarum.core.compat['Model']
import UserBio from './components/UserBio'

app.initializers.add('fof-user-bio', () => {
  User.prototype.bio = Model.attribute('bio')
  User.prototype.bioHtml = computed('bio', bio =>
    bio
      ? '<p>' +
        $('<div/>')
          .text(bio)
          .html()
          .replace(/\n/g, '<br>')
          .autoLink({ rel: 'nofollow' }) +
        '</p>'
      : '',
  )

  extend(UserCard.prototype, 'infoItems', function(items) {
    let user = this.props.user
    items.add(
      'bio',
      UserBio.component({
        user,
        editable: this.props.editable,
      }),
    )
  })
})
