import { h, FunctionalComponent } from 'preact'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { AlertAttributes } from 'services/AlertManager'
import { Button } from 'ui/atoms/Button'
import { useContext } from 'preact/hooks'

export const Alert: FunctionalComponent<AlertAttributes> = ({
  className = '',
  message,
  type,
  dismissible,
  controls,
  onDismiss,
}) => {
  const { t } = useContext(TranslateContext)

  return (
    <div className={`${styles.alert} ${styles[type]} ${className}`}>
      <span>{t(message)}</span>
      <div className={styles.controls}>
        {controls}
        {dismissible && (
          <Button onClick={onDismiss} style="link" size="sm" padding="none">
            <i className="fas fa-times" />
          </Button>
        )}
      </div>
    </div>
  )
}
