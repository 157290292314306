/** @jsx m */

import Component from '../../common/Component'
import Button from '../../common/components/Button'
import ItemList from '../../common/utils/ItemList'
import listItems from '../../common/helpers/listItems'

/**
 * The `HeaderPrimary` component displays primary header controls. On the
 * default skin, these are shown just to the right of the forum title.
 */
export default class HeaderPrimary extends Component {
  view() {
    return (
      <ul className="Header-controls">{listItems(this.items().toArray())}</ul>
    )
  }

  config(isInitialized, context) {
    // Since this component is 'above' the content of the page (that is, it is a
    // part of the global UI that persists between routes), we will flag the DOM
    // to be retained across route changes.
    context.retain = true
  }

  /**
   * Build an item list for the controls.
   *
   * @return {ItemList}
   */
  items() {
    const items = new ItemList()

    items.add(
      'discussions_link',
      Button.component({
        children: app.translator.trans('core.forum.header.discussions_link'),
        onclick: () => m.route(app.route('index')),
        className: `Button Button--link${
          m.route() === '/discussions' ? ' active' : ''
        }`,
      }),
      1,
    )

    return items
  }
}
