import { h, FunctionalComponent, ComponentChild } from 'preact'
import { useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks'

import { Loader } from '../Loader'
import styles from './styles.scss'

const getTopOffset = (el: HTMLElement | null): number => {
  let offset = 0
  while (el && !isNaN(el.offsetTop)) {
    offset += el.offsetTop - el.scrollTop
    el = el.offsetParent as HTMLElement | null
  }

  return offset
}

const getLoadListener =
  (
    hasMore: { previous: boolean; next: boolean },
    onLoadMore: (direction: 'next' | 'previous') => void,
  ): ScrollChangeHandler<void> =>
  (listElement, scrollTop) => {
    const height = listElement.offsetHeight

    const direction =
      scrollTop < 100
        ? 'previous'
        : scrollTop > height + 100 - document.documentElement.offsetHeight
        ? 'next'
        : null

    if (direction && hasMore[direction]) {
      onLoadMore(direction)
    }
  }

const getIndexListener =
  (onItemIndexChange: (index: number) => void): ScrollChangeHandler<number> =>
  (listElement, scrollTop, itemIndex) => {
    let idx = -1
    let cumulatedHeight = 0
    while (
      idx < listElement.children.length - 1 &&
      cumulatedHeight < scrollTop
    ) {
      const el = listElement.children.item(++idx) as HTMLElement
      cumulatedHeight += el.offsetHeight - el.scrollTop
    }

    idx = idx > 0 ? idx : 0
    itemIndex !== idx && onItemIndexChange(idx)
    return idx
  }

type ScrollChangeHandler<S = unknown> = (
  listElement: HTMLElement,
  scrollTop: number,
  state: S,
) => S
class ScrollListener {
  parentElement: HTMLElement | null = null
  listeners: Record<
    string,
    { handler: ScrollChangeHandler<unknown>; state: unknown } | null
  > = {}
  savedScrollRelativeToBottom: number | null = null
  listenerOptions = {
    capture: true,
    passive: true,
  }

  constructor(elem?: HTMLElement | null) {
    this.onScroll = this.onScroll.bind(this)
    elem && this.setParentElement(elem)
  }

  setParentElement(newElement: HTMLElement | null): void {
    if (this.parentElement) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.removeEventListener('scroll', this.onScroll, this.listenerOptions)
    }

    if (newElement) {
      this.parentElement = newElement
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('scroll', this.onScroll, this.listenerOptions)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addListener(id: string, handler: ScrollChangeHandler<any>): void {
    const previousState = this.listeners[id]?.state
    this.listeners[id] = { handler, state: previousState }
  }
  removeListener(id: string): void {
    this.listeners[id] = null
  }

  saveScrollRelativeToBottom(): void {
    if (!this.parentElement || !this.parentElement.lastElementChild) {
      return
    }

    const elem = this.parentElement.lastElementChild
    this.savedScrollRelativeToBottom = getTopOffset(elem as HTMLElement)
  }
  restoreScrollRelativeToBottom(): void {
    if (
      !this.parentElement ||
      !this.parentElement.lastElementChild ||
      this.savedScrollRelativeToBottom === null
    ) {
      return
    }

    const elem = this.parentElement.lastElementChild
    const diff =
      getTopOffset(elem as HTMLElement) - this.savedScrollRelativeToBottom
    window.scrollTo({ top: window.pageYOffset + diff })
    this.savedScrollRelativeToBottom = null
  }

  onScroll(): void {
    const { parentElement } = this
    if (!parentElement) {
      return
    }

    const topOffset = getTopOffset(this.parentElement)
    const documentScrolled = window.pageYOffset
    const scrollTop = documentScrolled - topOffset

    Object.values(this.listeners).forEach((listener) => {
      if (!listener) {
        return
      }

      const newState = listener.handler(
        parentElement,
        scrollTop,
        listener.state,
      )
      listener.state = newState
    })
  }
}

export const InfiniteScroll: FunctionalComponent<{
  keepScrollPosition?: boolean
  loading: { previous: boolean; next: boolean }
  onLoadMore: (direction: 'next' | 'previous') => void
  hasMore: { previous: boolean; next: boolean }
  onScrollItemIndexChange?: (index: number) => void
  children: ComponentChild[]
}> = ({
  keepScrollPosition = false,
  children,
  onLoadMore,
  loading: { next: loadingNext, previous: loadingPrevious },
  onScrollItemIndexChange,
  hasMore,
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [scrollListener] = useState<ScrollListener>(new ScrollListener())

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => scrollListener.setParentElement(ref.current), [ref])
  useEffect(() => {
    // Remove previous listener if any when loading is on or ref is null
    // or there is nothing left to load
    if (
      loadingNext ||
      loadingPrevious ||
      (!hasMore.next && !hasMore.previous)
    ) {
      return scrollListener.removeListener('load-listener')
    }

    // Else, create a new listener as ref, hasMore or loading state might have changed
    const listener = getLoadListener(hasMore, onLoadMore)
    scrollListener.addListener('load-listener', listener)

    // Ensure that the listener is removed when this component is unmounted
    return () => scrollListener.removeListener('load-listener')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPrevious, loadingNext, ref, hasMore])

  useEffect(() => {
    if (onScrollItemIndexChange) {
      const listener = getIndexListener(onScrollItemIndexChange)
      scrollListener.addListener('index-listener', listener)

      // Ensure that the listener is removed when this component is unmounted
      return () => scrollListener.removeListener('index-listener')
    }

    scrollListener.removeListener('index-listener')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrollItemIndexChange])

  useLayoutEffect(() => {
    if (!keepScrollPosition) {
      return
    }

    if (
      loadingPrevious &&
      scrollListener.savedScrollRelativeToBottom === null
    ) {
      return scrollListener.saveScrollRelativeToBottom()
    }

    if (scrollListener.savedScrollRelativeToBottom !== null) {
      return scrollListener.restoreScrollRelativeToBottom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPrevious, keepScrollPosition])

  return (
    <div ref={ref}>
      {loadingPrevious && <Loader size="md" className={styles.loader} />}
      {children}
      {loadingNext && <Loader size="md" className={styles.loader} />}
    </div>
  )
}
