import { Discussion } from './discussion'
import { DisplayPost } from './post'
import { DisplayUser } from './user'

export enum NotificationType {
  NEW_POST = 'NEW_POST',
  POST_LIKED = 'POST_LIKED',
  DISCUSSION_LOCKED = 'DISCUSSION_LOCKED',
  DISCUSSION_RENAMED = 'DISCUSSION_RENAMED',
  RESOURCE_RECOMMENDED = 'RESOURCE_RECOMMENDED',
}

export type Notification =
  | {
      id: string
      type: NotificationType.NEW_POST
      discussionId: string
      postId: string
      resourceId: null
      body: string
      redirectUrl: string | null
      createdAt: Date
      readAt: Date | null
      fromUser: DisplayUser | null
      post: DisplayPost
      discussion: { id: string; title: string }
    }
  | {
      id: string
      type: NotificationType.POST_LIKED
      discussionId: string
      postId: string
      resourceId: null
      body: string | null
      redirectUrl: string | null
      createdAt: Date
      readAt: Date | null
      fromUser: DisplayUser | null
      post: DisplayPost
      discussion: Discussion
    }
  | {
      id: string
      type: NotificationType.DISCUSSION_LOCKED
      discussionId: string
      postId: null
      resourceId: null
      body: string
      redirectUrl: string | null
      createdAt: Date
      readAt: Date | null
      fromUser: DisplayUser | null
      post: null
      discussion: Discussion
    }
  | {
      id: string
      type: NotificationType.DISCUSSION_RENAMED
      discussionId: string
      postId: null
      resourceId: null
      body: string
      redirectUrl: string | null
      createdAt: Date
      readAt: Date | null
      fromUser: DisplayUser | null
      post: null
      discussion: Discussion
    }
  | {
      id: string
      type: NotificationType.RESOURCE_RECOMMENDED
      discussionId: null
      postId: null
      resourceId: string
      body: string
      redirectUrl: string | null
      createdAt: Date
      readAt: Date | null
      fromUser: DisplayUser | null
      post: null
      discussion: Discussion
    }
