import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext } from 'preact/hooks'

import { NotificationType } from 'types/notification'
import { UserPreferences } from 'types/user'
import { Loader } from 'ui/atoms/Loader'
import { PreferenceItem } from './PreferenceItem'

type NotificationTypeSetting = {
  label: string
  icon: string
} & (
  | { isWeb: true; webPreferencesKey: keyof UserPreferences }
  | { isWeb: false }
) &
  (
    | { isEmail: true; emailPreferencesKey: keyof UserPreferences }
    | { isEmail: false }
  )

const NOTIFICATIONS_SETTINGS: {
  [type in NotificationType]: NotificationTypeSetting
} = {
  [NotificationType.NEW_POST]: {
    label: 'core.user_profile.settings.notifications.new_post',
    icon: 'fas fa-reply',
    isWeb: true,
    isEmail: true,
    emailPreferencesKey: 'notifyNewPostEmail',
    webPreferencesKey: 'notifyNewPostAlert',
  },
  [NotificationType.POST_LIKED]: {
    label: 'core.user_profile.settings.notifications.post_liked',
    icon: 'far fa-thumbs-up',
    isWeb: true,
    isEmail: false,
    webPreferencesKey: 'notifyPostLikedAlert',
  },
  [NotificationType.DISCUSSION_LOCKED]: {
    label: 'core.user_profile.settings.notifications.discussion_locked',
    icon: 'fas fa-lock',
    isWeb: true,
    isEmail: false,
    webPreferencesKey: 'notifyDiscussionLockedAlert',
  },
  [NotificationType.DISCUSSION_RENAMED]: {
    label: 'core.user_profile.settings.notifications.discussion_renamed',
    icon: 'fas fa-pen',
    isWeb: true,
    isEmail: false,
    webPreferencesKey: 'notifyDiscussionRenamedAlert',
  },
  [NotificationType.RESOURCE_RECOMMENDED]: {
    label: 'core.user_profile.settings.notifications.resource_recommended',
    icon: 'fas fa-heart',
    isWeb: true,
    isEmail: false,
    webPreferencesKey: 'notifyResourceRecommendedAlert',
  },
}

export const NotificationPreferencesEditor: FunctionalComponent<{
  preferences: UserPreferences
  onChange: (preference: keyof UserPreferences, value: boolean) => void
  loaders?: { [key in keyof UserPreferences]?: boolean }
  className?: string
}> = ({ preferences, onChange, loaders = {}, className = '' }) => {
  const { t } = useContext(TranslateContext)
  const notificationTypesToDisplay = Object.keys(
    NOTIFICATIONS_SETTINGS,
  ) as NotificationType[]

  return (
    <div className={`${className} flex rounded overflow-hidden`}>
      <div className="grid grid-cols-[minmax(200px,400px)_100px_100px] gap-y-0.5 rounded overflow-hidden">
        <div className="bg-green-pale col-span-3">
          <div className="grid grid-cols-[minmax(200px,400px)_100px_100px] gap-y-0 rounded overflow-hidden">
            <div className="bg-green-pale p-2" />
            <div className="bg-green-pale p-2">
              <div className="flex flex-col text-opacity-50 text-black text-center">
                <i className="fas fa-bell m-1" />
                <span className="font-bold">
                  {t('core.user_profile.settings.notifications.web_header')}
                </span>
              </div>
            </div>
            <div className="bg-green-pale p-2">
              <div className="flex flex-col text-opacity-50 text-black text-center">
                <i className="far fa-envelope m-1" />
                <span className="font-bold">
                  {t('core.user_profile.settings.notifications.email_header')}
                </span>
              </div>
            </div>

            <div className="bg-green-pale p-2" />
            <div className="col-span-2 bg-green-pale p-2 flex justify-center items-center text-opacity-50 text-black text-center">
              {t('core.user_profile.settings.notifications.table_tip')}
            </div>
          </div>
        </div>

        {notificationTypesToDisplay.map((type) => {
          const setting = NOTIFICATIONS_SETTINGS[type]
          const webPref = setting.isWeb
            ? !!preferences[setting.webPreferencesKey]
            : undefined
          const emailPref = setting.isEmail
            ? !!preferences[setting.emailPreferencesKey]
            : undefined
          const isWebLoading = setting.isWeb
            ? loaders[setting.webPreferencesKey]
            : false
          const isEmailLoading = setting.isEmail
            ? loaders[setting.emailPreferencesKey]
            : false

          return (
            <Fragment key={`pref-${type}-settings`}>
              <div className="bg-green-pale p-2">
                <i className={`ml-4 text-sm w-[16px] mr-2 ${setting.icon}`} />
                <span className="text-sm">{t(setting.label)}</span>
              </div>
              <div
                className={`bg-green-pale p-2 text-center hover:bg-green-pale-darken ${
                  webPref !== undefined ? 'cursor-pointer' : ''
                }`}
                onClick={
                  setting.isWeb
                    ? () => onChange(setting.webPreferencesKey, !webPref)
                    : undefined
                }
              >
                <Loader
                  loaded={!isWebLoading}
                  passingProps={{
                    value: !!webPref,
                  }}
                  className="mx-auto"
                  size="sm"
                >
                  {({ value }) => (
                    <PreferenceItem
                      disabled={webPref === undefined}
                      value={value}
                    />
                  )}
                </Loader>
              </div>
              <div
                className={`bg-green-pale p-2 text-center hover:bg-green-pale-darken ${
                  emailPref !== undefined
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed'
                }`}
                onClick={
                  setting.isEmail
                    ? () => onChange(setting.emailPreferencesKey, !emailPref)
                    : undefined
                }
              >
                <Loader
                  loaded={!isEmailLoading}
                  passingProps={{
                    value: !!emailPref,
                  }}
                  className="mx-auto"
                  size="sm"
                >
                  {({ value }) => (
                    <PreferenceItem
                      disabled={emailPref === undefined}
                      value={value}
                    />
                  )}
                </Loader>
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
