import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext } from 'preact/hooks'

import { DisplayUser } from 'types/user'
import { Avatar } from 'ui/atoms/Avatar'
import { Button } from 'ui/atoms/Button'
import { formatDateAsDelay } from 'utils/date'
import { getDeletedUserPlaceholder } from 'utils/user'
import styles from './styles.scss'

export const NotificationItem: FunctionalComponent<{
  notificationTarget: string | null
  content: string
  date: Date | string
  sender?: DisplayUser
  notificationIcon?: string
  notificationText?: string
  canMarkAsRead?: boolean
  onMarkAsRead?: () => void
  markAsReadLoading?: boolean
  redirectUrl: string
}> = ({
  content,
  date,
  sender,
  notificationTarget,
  notificationIcon,
  notificationText,
  canMarkAsRead = false,
  onMarkAsRead,
  markAsReadLoading = false,
  redirectUrl,
}) => {
  const { t } = useContext(TranslateContext)
  const senderUser = sender || getDeletedUserPlaceholder(t)

  return (
    <li>
      <a
        href={redirectUrl}
        onClick={() => onMarkAsRead && onMarkAsRead()}
        className={styles['notification-item']}
      >
        <Avatar
          size="sm"
          url={senderUser.avatarUrl}
          username={senderUser.username}
        />
        {notificationIcon ? (
          <i className={`mr-1 ${notificationIcon}`} />
        ) : (
          // Icon placeholder to keep grid layout together
          <span />
        )}
        <div className={styles.title}>
          <strong>{senderUser.username}</strong>{' '}
          {notificationText ? (
            <span className="font-bold">{notificationText} </span>
          ) : (
            ''
          )}
          {notificationTarget && (
            <Fragment>
              {t('core.notifications.list_item.in')}{' '}
              <em className={styles.target}>{notificationTarget}</em>
            </Fragment>
          )}
        </div>
        <span className={styles.date}>{formatDateAsDelay(t, date)}</span>
        <div className={styles.content}>{content}</div>
        {canMarkAsRead && (
          <Button
            iconOnly
            size="sm"
            style="link-darken-hover"
            rounded
            loading={markAsReadLoading}
            className={`my-auto ml-auto ${styles['mark-as-read']}`}
            onClick={(e) => {
              e.stopImmediatePropagation()
              e.preventDefault()
              onMarkAsRead && onMarkAsRead()
            }}
          >
            <i className={`fas fa-check text-green`} />
          </Button>
        )}
      </a>
    </li>
  )
}
