/** @jsx m */

const Notification = flarum.core.compat['components/Notification']
const username = flarum.core.compat['helpers/username']

export default class NewPostNotification extends Notification {
  icon() {
    return 'fas fa-star'
  }

  href() {
    const notification = this.props.notification
    const discussion = notification.subject()
    const content = notification.content() || {}

    return app.route.discussion(discussion, content.postNumber)
  }

  content() {
    return app.translator.trans(
      'flarum-subscriptions.forum.notifications.new_post_text',
      { user: this.props.notification.fromUser() },
    )
  }
}
