import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation, useQuery } from '@apollo/client'

import styles from './styles.scss'
import { getGraphQLErrors } from 'utils/components'
import {
  GET_CATEGORIES_WITH_TAGS,
  GetCategoriesWithTagsResult,
} from 'store/operations/tagsCategory'
import { Button } from 'ui/atoms/Button'
import {
  CreateResourceResult,
  CREATE_RESOURCE,
  CreateResourceInput,
} from 'store/operations/resource'
import { Stepper } from 'ui/organisms/Stepper'
import { CreateResourcePayload } from 'types/resource'
import { getTagParent, getAllTagsFromTagsCategories } from 'utils/tags'
import { route } from 'preact-router'
import { getRouteUrl, PageType } from 'common/routing'
import { ModalContent } from 'services/ModalManager'
import { useAlerts } from 'services/AlertManager'
import { ResourceAuthorStep } from './ResourceAuthorStep'
import { ResourceContentStep } from './ResourceContentStep'
import { ResourceDescriptionStep } from './ResourceDescriptionStep'
import { CurrentUser } from 'store/local/user'
import { ProgressBar } from 'ui/atoms/ProgressBar'
import { uploadModule } from 'utils/upload'

export const CreateResourceModal: ModalContent = ({ onDismiss, setAlerts }) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()

  const [resourcePayload, setResourcePayload] = useState<CreateResourcePayload>(
    {
      title: '',
      markdownDescription: t(
        'core.resources.new_resource_modal.description_placeholder',
      ),
      keywords: '',
      tags: [],
      postedForTagNodeId: CurrentUser()?.tagNodeId ?? undefined,
      files: [],
    },
  )
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<{
    total: number
    completed: number
  }>({ total: 1, completed: 0 })
  const [cancelUpload, setCancelUpload] = useState<(() => void) | undefined>(
    undefined,
  )
  const [isUploading, setIsUploading] = useState(false)

  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)

  const [
    createResource,
    { error: createResourceError, loading: createResourceLoading },
  ] = useMutation<CreateResourceResult, CreateResourceInput>(CREATE_RESOURCE, {
    onCompleted: ({ createResource: { id } }) => {
      onDismiss()
      showAlert({
        type: 'success',
        message: t('core.resources.new_resource_modal.success_alert_message'),
        duration: 10000,
      })
      route(getRouteUrl(PageType.RESOURCE, { id }))
    },
  })

  setAlerts(getGraphQLErrors([createResourceError, getTagsError]))

  const steps = [
    {
      label: t('core.resources.new_resource_modal.description_step'),
      component: ResourceDescriptionStep,
      validate: (resourcePayload: CreateResourcePayload) =>
        !!resourcePayload.title &&
        !!resourcePayload.markdownDescription &&
        !!resourcePayload.keywords &&
        !!resourcePayload.tags.length,
    },
    {
      label: t('core.resources.new_resource_modal.content_step'),
      component: ResourceContentStep,
      validate: (resourcePayload: CreateResourcePayload) =>
        !!resourcePayload.hyperlinks || !!filesToUpload.length,
    },
    {
      label: t('core.resources.new_resource_modal.author_step'),
      component: ResourceAuthorStep,
      validate: (resourcePayload: CreateResourcePayload) =>
        (getTagsData?.getCategoriesWithTags &&
          !!resourcePayload.postedForTagNodeId &&
          !!getTagParent(
            getAllTagsFromTagsCategories(getTagsData.getCategoriesWithTags),
            resourcePayload.postedForTagNodeId,
          )) ||
        resourcePayload.postedForTagNodeId === null,
    },
  ]
  const [currentStep, setCurrentStep] = useState(0)
  const isValid = steps[currentStep].validate(resourcePayload)
  const CurrentStep = steps[currentStep].component

  return (
    <div className="Modal-body">
      <Stepper
        steps={steps.map((s) => s.label)}
        currentStep={currentStep}
        className={styles['new-resource-stepper']}
      />

      <CurrentStep
        partialResource={resourcePayload}
        filesToUpload={filesToUpload}
        onChange={(partialResource, newFilesToUpload?: File[]) => {
          setResourcePayload(partialResource)
          newFilesToUpload && setFilesToUpload(newFilesToUpload)
        }}
      />

      <div className={`Form ${styles.form} ${styles['form-right']}`}>
        <div className="Form-group">
          {!isUploading && (
            <Button
              style="btn-info"
              className={`${styles['navigate-button']}`}
              disabled={createResourceLoading}
              onClick={() => {
                if (currentStep > 0) {
                  setCurrentStep(currentStep - 1)
                }
              }}
            >
              {t('core.resources.new_resource_modal.prev_button')}
            </Button>
          )}
          {!isUploading && currentStep === steps.length - 1 ? (
            <Button
              className={`${styles['send-button']}`}
              disabled={!isValid || createResourceLoading}
              loading={createResourceLoading}
              onClick={async () => {
                try {
                  const pendingUploads = await uploadModule.ResourceFiles.uploadMany(
                    filesToUpload,
                    {
                      onProgress: ({
                        completedChunksCount,
                        totalChunksCount,
                      }) => {
                        setUploadProgress({
                          completed: completedChunksCount,
                          total: totalChunksCount,
                        })
                      },
                    },
                  )
                  setCancelUpload(() => pendingUploads.cancelAll)
                  setIsUploading(true)

                  const uploadedFiles = await pendingUploads.promise
                  setCancelUpload(undefined)
                  setIsUploading(false)
                  void createResource({
                    variables: {
                      ...resourcePayload,
                      files: uploadedFiles,
                    },
                  })
                } catch (e) {
                  console.log('Upload aborted')
                  setIsUploading(false)
                  setCancelUpload(undefined)
                  setUploadProgress({ completed: 0, total: 1 })
                }
              }}
            >
              {t('core.resources.new_resource_modal.send_button')}
            </Button>
          ) : !isUploading ? (
            <Button
              style="btn-primary"
              className={`${styles['navigate-button']}`}
              disabled={!isValid}
              onClick={() => {
                if (currentStep < steps.length) {
                  setCurrentStep(currentStep + 1)
                }
              }}
            >
              {t('core.resources.new_resource_modal.next_button')}
            </Button>
          ) : (
            <div className={styles['progress-wrapper']}>
              <div className={styles['progress-bar-wrapper']}>
                <ProgressBar
                  progressPercentage={
                    (uploadProgress.completed / uploadProgress.total) * 100
                  }
                />
              </div>
              <Button
                style="btn-danger"
                size="sm"
                onClick={() => cancelUpload && cancelUpload()}
              >
                {t('core.resources.new_resource_modal.cancel_upload')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
