import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { Post } from 'types/post'
import styles from './styles.scss'

export const DeletedPost: FunctionalComponent<{
  post: Post & {
    deletedByUsername: NonNullable<Post['deletedByUsername']>
    deletedAt: NonNullable<Post['deletedAt']>
  }
  createdAtLabel: string
  className?: string
  elementId?: string
}> = ({ post, className = '', createdAtLabel, elementId }) => {
  const { t } = useContext(TranslateContext)
  const date = new Date(post.deletedAt)

  return (
    <div className={`${styles['post-wrapper']} ${className}`} id={elementId}>
      <div className={`${styles.post} ${styles.deleted}`}>
        <div className={styles['post-content']}>
          <div className={styles.header}>
            <span className={styles.username}>
              {post.author?.username || t('core.community.post.deleted_user')}
            </span>
            <span className={styles.date}>{createdAtLabel}</span>
            <span className={styles['deletion-label']}>
              (
              {t('core.community.post.deleted_post_label', {
                deletedBy: post.deletedByUsername,
                deletedAt: `${date.getDate()} ${date.toLocaleString('fr', {
                  month: 'long',
                })} ${date.getFullYear()}`,
              })}
              )
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
