import { FunctionalComponent, h } from 'preact'
import { useContext, useRef, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useQuery } from '@apollo/client'

import styles from './styles.scss'
import { fromEvent } from 'utils/components'
import {
  GET_CATEGORIES_WITH_TAGS,
  GetCategoriesWithTagsResult,
} from 'store/operations/tagsCategory'
import { UpdateResourcePayload } from 'types/resource'
import { Tag } from 'types/tag'
import { TextEditor } from 'ui/molecules/TextEditor'
import { TextEditorToolbar } from 'ui/molecules/TextEditorToolbar'

export const ResourceDescriptionStep: FunctionalComponent<{
  payload: UpdateResourcePayload
  onChange: (payload: UpdateResourcePayload) => void
}> = ({ payload, onChange }) => {
  const { t } = useContext(TranslateContext)

  const editorRef = useRef<HTMLTextAreaElement | null>(null)
  const [isPreview, setIsPreview] = useState(false)
  const { title, markdownDescription, keywords, tags } = payload
  const partialUpdate = (update: Partial<UpdateResourcePayload>): void =>
    onChange({
      ...payload,
      ...update,
    })

  const toggleTag = (tagId: string) => () => {
    if (tags.includes(tagId)) {
      return partialUpdate({
        tags: tags.filter((t) => t !== tagId),
      })
    }

    return partialUpdate({
      tags: tags.concat(tagId),
    })
  }

  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)

  return (
    <div className={`Form Form--centered ${styles.form}`}>
      <div className="Form-group">
        <input
          className={`FormControl ${styles.FormControl}`}
          name="title"
          type="text"
          placeholder={t(
            'core.resources.update_resource_modal.title_placeholder',
          )}
          value={title}
          onInput={fromEvent((title) => partialUpdate({ title }))}
        />
      </div>

      <div className="Form-group">
        <input
          className={`FormControl ${styles.FormControl}`}
          name="keywords"
          type="text"
          placeholder={t(
            'core.resources.update_resource_modal.keywords_placeholder',
          )}
          value={keywords}
          onInput={fromEvent((keywords) => partialUpdate({ keywords }))}
        />
      </div>

      <div className="Form-group">
        <h3>{t('core.resources.update_resource_modal.tags_selection_head')}</h3>
        <div className={styles['tags-selection']}>
          {getTagsData?.getCategoriesWithTags
            .reduce<Tag[]>((acc, item) => {
              if (!item.isForResource || item.isForUser) {
                return acc
              }
              return acc.concat(item.tags)
            }, [])
            .map((tag) => (
              <div
                key={`tag-${tag.slug}-${tag.id}`}
                className={styles['tag-checkbox']}
              >
                <input
                  id={`tag-${tag.slug}-${tag.id}`}
                  type="checkbox"
                  checked={tags.includes(tag.id)}
                  onClick={toggleTag(tag.id)}
                />
                <label htmlFor={`tag-${tag.slug}-${tag.id}`}>{tag.name}</label>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.editor}>
        <TextEditor
          editorRef={editorRef}
          value={markdownDescription}
          onBlur={(markdownDescription) =>
            partialUpdate({ markdownDescription })
          }
          preview={isPreview}
        />
        <TextEditorToolbar
          className={styles.toolbar}
          editorRef={editorRef}
          values={{
            preview: isPreview,
            canUpload: false,
            showSendButton: false,
          }}
          onEvent={{ preview: setIsPreview }}
        />
      </div>
    </div>
  )
}
