/** @jsx m */

const Component = flarum.core.compat['Component']
const icon = flarum.core.compat['helpers/icon']

export default class SubscriptionMenuItem extends Component {
  view() {
    return (
      <button
        className="SubscriptionMenuItem hasIcon"
        onclick={this.props.onclick}
      >
        {this.props.active
          ? icon('fas fa-check', { className: 'Button-icon' })
          : ''}
        <span className="SubscriptionMenuItem-label">
          {icon(this.props.icon, { className: 'Button-icon' })}
          <strong>{this.props.label}</strong>
          <span className="SubscriptionMenuItem-description">
            {this.props.description}
          </span>
        </span>
      </button>
    )
  }
}
