/** @jsx m */

const { extend } = flarum.core.compat['extend']
const Discussion = flarum.core.compat['models/Discussion']
const Badge = flarum.core.compat['components/Badge']

export default function addSubscriptionBadge() {
  extend(Discussion.prototype, 'badges', function (badges) {
    let badge

    switch (this.subscription()) {
      case 'follow':
        badge = Badge.component({
          label: app.translator.trans(
            'flarum-subscriptions.forum.badge.following_tooltip',
          ),
          icon: 'fas fa-bookmark',
          type: 'following',
        })
        break

      case 'ignore':
        badge = Badge.component({
          label: app.translator.trans(
            'flarum-subscriptions.forum.badge.ignoring_tooltip',
          ),
          icon: 'far fa-eye-slash',
          type: 'ignoring',
        })
        break

      default:
      // no default
    }

    if (badge) {
      badges.add('subscription', badge)
    }
  })
}
