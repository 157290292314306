import { h } from 'preact'
import { useMutation } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useContext } from 'preact/hooks'

import { ModalContent } from 'services/ModalManager'
import { FlagResourceForm } from './FlagResourceForm'
import { FlagResourceSuccess } from './FlagResourceSuccess'
import {
  AddModerationFlagInput,
  AddModerationFlagResult,
  ADD_MODERATION_FLAG,
} from 'store/operations/moderationFlags'

export const FlagResourceModal: ModalContent<{
  resourceId: string
}> = ({ resourceId, onDismiss, setAlerts }) => {
  const { t } = useContext(TranslateContext)

  const [flagResource, { loading, error, called }] = useMutation<
    AddModerationFlagResult,
    AddModerationFlagInput
  >(ADD_MODERATION_FLAG)
  const showForm = !called || loading || error

  setAlerts(error ? [t('core.lib.error.generic_message')] : [])

  return showForm ? (
    <div className="Modal-body">
      <FlagResourceForm
        loading={loading}
        onFlag={(reason, reasonDetail) =>
          void flagResource({
            variables: { resourceId, reason, reasonDetail },
          })
        }
      />
    </div>
  ) : (
    <FlagResourceSuccess onDismiss={onDismiss} />
  )
}
