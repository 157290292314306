import { h, Component, JSX } from 'preact'
import { Route, Router, RouterOnChangeArgs } from 'preact-router'
import { TranslateProvider } from '@denysvuika/preact-translate'
import { ApolloProvider } from '@apollo/client'

import { init, compatRouter, CompatRouterData } from 'legacy/compat'
import { phrases } from 'translations'
import { client } from 'store'
import { NavBar } from 'ui/organisms/NavBar'
import { PageType, ROUTES } from 'common/routing'
import { CurrentRouterPath, CurrentRouterUrl } from 'store/local/navigation'
import { Footer } from 'ui/organisms/Footer'
import { getRouteComponent } from 'routing/components'
import { AlertProvider } from 'services/AlertManager'
import { AlertGroup } from 'ui/molecules/AlertGroup'
import { ModalConsumer, ModalProvider } from 'services/ModalManager'
import { ModalRoot } from 'ui/molecules/ModalRoot'
import { DropdownProvider } from 'services/DropdownManager'
import { DropdownRoot } from 'ui/molecules/DropdownRoot'
import { MobileWarning } from 'ui/atoms/MobileWarning'
import { MatomoHandler } from 'services/MatomoAnalytics'
import {
  GetUserNotificationsCountInput,
  GetUserNotificationsCountResult,
  GET_USER_NOTIFICATIONS_COUNT,
} from 'store/operations/notification'

if ((module as { hot: unknown }).hot) {
  require('preact/debug')
}

class App extends Component<
  Record<string, never>,
  {
    additionnalClassNames: string
    initialized: boolean
    isMobileWarningShowed: boolean
    showMobileWarning: boolean
  }
> {
  state = {
    initialized: false,
    additionnalClassNames: '',
    isMobileWarningShowed: !!sessionStorage.getItem('isMobileWarningShowed'),
    showMobileWarning:
      window.matchMedia('(max-width: 992px)').matches &&
      !sessionStorage.getItem('isMobileWarningShowed'),
  }

  componentDidMount(): void {
    if (!this.state.showMobileWarning) {
      void init().then(() => this.setState({ initialized: true }))
    }
  }

  handleRoute = (e: RouterOnChangeArgs): void => {
    // Update routing variables
    compatRouter.updateRouterData(e as CompatRouterData)
    CurrentRouterPath(e.path)
    CurrentRouterUrl(e.url)

    // Always refetch notification count on page change
    void client.query<
      GetUserNotificationsCountResult,
      GetUserNotificationsCountInput
    >({
      query: GET_USER_NOTIFICATIONS_COUNT,
      fetchPolicy: 'network-only',
    })
  }

  render(): JSX.Element {
    if (this.state.showMobileWarning) {
      return (
        <TranslateProvider
          translations={{ fr: phrases, en: { dummy: '' } }}
          lang="fr"
        >
          return{' '}
          <MobileWarning
            onContinue={() => {
              sessionStorage.setItem('isMobileWarningShowed', 'true')
              this.setState({
                showMobileWarning: false,
                isMobileWarningShowed: true,
              })
              void init().then(() => this.setState({ initialized: true }))
            }}
          />
        </TranslateProvider>
      )
    }

    return (
      <TranslateProvider
        translations={{ fr: phrases, en: { dummy: '' } }}
        lang="fr"
      >
        <ApolloProvider client={client}>
          <ModalProvider>
            <AlertProvider>
              <DropdownProvider>
                <MatomoHandler>
                  <ModalConsumer>
                    {(modalContext) => (
                      <div
                        className={`body-wrapper ${
                          modalContext[0].some((m) => m.properties.show)
                            ? 'modal-open'
                            : ''
                        }`}
                      >
                        <DropdownRoot />
                        <ModalRoot />
                        <AlertGroup />
                        <div id="modal-legacy" />
                        <div id="alerts-legacy" />

                        <div
                          id="app"
                          className="App" /*class={`App ${this.state.additionnalClassNames}`}*/
                        >
                          {this.state.initialized ? <NavBar /> : null}

                          <main class="App-content App-content-flex">
                            {this.state.initialized ? (
                              <div id="content">
                                <Router onChange={this.handleRoute}>
                                  {Object.entries(ROUTES).map(
                                    ([pageType, r], idx) => (
                                      <Route
                                        key={`app-route-component-${idx}`}
                                        {...r}
                                        component={getRouteComponent(
                                          (pageType as unknown) as PageType,
                                        )}
                                      />
                                    ),
                                  )}
                                </Router>
                              </div>
                            ) : (
                              <div id="flarum-loading">Chargement...</div>
                            )}

                            <noscript>
                              <div class="Alert">
                                <div class="container">
                                  Ce site est optimisé pour être consulté depuis
                                  un navigateur moderne dans lequel JavaScript
                                  est activé.
                                </div>
                              </div>
                            </noscript>

                            <div
                              id="flarum-loading-error"
                              style="display: none"
                            >
                              <div class="Alert">
                                <div class="container">
                                  Une erreur est survenue lors de la tentative
                                  de chargement de la version complète de ce
                                  site. Veuillez purger le cache de votre
                                  navigateur et rafraîchir cette page pour
                                  tenter de corriger cette erreur.
                                </div>
                              </div>
                            </div>

                            <div class="App-composer">
                              <div id="composer" />
                            </div>
                          </main>
                          <Footer />
                        </div>
                      </div>
                    )}
                  </ModalConsumer>
                </MatomoHandler>
              </DropdownProvider>
            </AlertProvider>
          </ModalProvider>
        </ApolloProvider>
      </TranslateProvider>
    )
  }
}

export default App
