import { h, Fragment, ComponentChildren } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Spinner } from 'spin.js'

import styles from './styles.scss'

const options = {
  scale: 1.0,
  corners: 1,
  color: '#000',
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 999,
  shadow: false,
  hwaccel: false,
  position: 'relative',
}
const sizesOptions = {
  sm: {
    lines: 8,
    length: 2,
    width: 2,
    radius: 3,
    top: '7px',
  },
  md: {
    lines: 8,
    length: 4,
    width: 3,
    radius: 5,
    top: '12px',
  },
  lg: {
    lines: 10,
    length: 8,
    width: 4,
    radius: 8,
    top: '20px',
  },
}

type RequiredProps<Props extends Record<string, unknown>> = {
  [P in keyof Props]-?: NonNullable<Props[P]>
}
type LoaderProps<
  Props extends Record<string, unknown>,
  Children = Record<string, never> extends Required<Props>
    ? ComponentChildren
    : (passingProps: RequiredProps<Props>) => h.JSX.Element,
> = {
  loaded?: boolean
  className?: string
  size?: 'sm' | 'md' | 'lg'
  color?: string
  passingProps?: Props
  children?: Children
}

export const Loader = <
  Props extends Record<string, unknown> = Record<string, never>,
>({
  loaded = false,
  children,
  passingProps,
  className = '',
  size = 'md',
  color = '#000',
}: LoaderProps<Props>): h.JSX.Element => {
  const spinnerRef = useRef<HTMLDivElement | null>(null)
  const [spinner, setSpinner] = useState<Spinner | null>(null)

  useEffect(() => {
    if (spinner) {
      spinner.stop()
    }
    if (spinnerRef.current) {
      setSpinner(
        new Spinner({
          ...options,
          ...sizesOptions[size],
          color,
        }).spin(spinnerRef.current),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spinnerRef, size])

  return loaded ? (
    <Fragment>
      {children instanceof Function
        ? children(passingProps as RequiredProps<Props>)
        : children}
    </Fragment>
  ) : (
    <div
      ref={spinnerRef}
      key="spinner"
      className={`${styles.spinner} ${styles[size]} ${className}`}
    />
  )
}
