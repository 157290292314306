/* eslint-disable react/display-name */

import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { route } from 'preact-router'
import { useContext } from 'preact/hooks'

import { DisplayUser } from 'types/user'
import styles from './styles.scss'

export const PostLikes: FunctionalComponent<{
  likedByUsers: DisplayUser[]
  className?: string
}> = ({ likedByUsers, className = '' }) => {
  const { t } = useContext(TranslateContext)

  const getUsernameLink = (username: string): h.JSX.Element => (
    <span
      className={styles['user-link']}
      onClick={() => route(`/u/${username}`)}
    >
      {username}
    </span>
  )

  const resolvers: {
    match: () => boolean
    text: () => h.JSX.Element
  }[] = [
    {
      match: () => likedByUsers.length === 0,
      text: () => <span />,
    },
    {
      match: () => likedByUsers.length === 1,
      text: () => (
        <span>
          {getUsernameLink(likedByUsers[0].username)}{' '}
          {t('core.community.post.one_like_text')}
        </span>
      ),
    },
    {
      match: () => likedByUsers.length <= 4,
      text: () => (
        <span>
          {likedByUsers
            .slice(0, likedByUsers.length - 1)
            .reduce<(h.JSX.Element | string)[]>(
              (elements, u, idx, array) => [
                ...elements,
                getUsernameLink(u.username),
                ...(idx < array.length - 1 ? [', '] : []),
              ],
              [],
            )}{' '}
          {t('core.community.post.likes_and_text')}{' '}
          {getUsernameLink(likedByUsers[likedByUsers.length - 1].username)}{' '}
          {t('core.community.post.several_likes_text')}
        </span>
      ),
    },
    {
      match: () => likedByUsers.length > 4,
      text: () => (
        <span>
          {likedByUsers
            .slice(0, 3)
            .reduce<(h.JSX.Element | string)[]>(
              (elements, u, idx, array) => [
                ...elements,
                getUsernameLink(u.username),
                ...(idx < array.length - 1 ? [', '] : []),
              ],
              [],
            )}{' '}
          {t('core.community.post.likes_and_text')}{' '}
          {t('core.community.post.likes_others_text', {
            nb: likedByUsers.length - 3,
          })}{' '}
          {t('core.community.post.several_likes_text')}
        </span>
      ),
    },
  ]

  const { text } = resolvers.find((r) => r.match()) || {
    text: () => <span />,
  }

  return (
    <div className={`${styles['post-likes']} ${className}`}>
      {likedByUsers.length ? <i className="far fa-thumbs-up" /> : null}
      {text()}
    </div>
  )
}
