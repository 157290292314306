import { h, FunctionalComponent } from 'preact'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import Router, { Route, useRouter } from 'preact-router'

import {
  GetUserByUsernameResult,
  GET_USER_BY_USERNAME,
  GetUserByUsernameInput,
  UpdateUserInput,
  UpdateUserResult,
  UPDATE_USER,
} from 'store/operations/user'
import styles from './styles.scss'
import { getRouteUrl, PageType } from 'common/routing'
import { Redirect } from 'routing/Redirect'
import { Loader } from 'ui/atoms/Loader'
import { CurrentUser } from 'store/local/user'
import { UserCard } from 'ui/organisms/UserCard/UserCard'
import { UserNav } from './UserNav'
import { UserPosts } from './UserPosts'
import { UserDiscussions } from './UserDiscussions'
import { UserResources } from './UserResources'
import { UserSettings } from './UserSettings'
import { updateCachedUser } from 'store/cache/user'
import { fromMutationResult } from 'store/cache/utils'

export const UserPage: FunctionalComponent = () => {
  const [{ matches }] = useRouter<{ username: string }>()
  const { data: userData, loading, error } = useQuery<
    GetUserByUsernameResult,
    GetUserByUsernameInput
  >(GET_USER_BY_USERNAME, {
    variables: { username: matches.username },
  })

  const baseUrl = getRouteUrl(PageType.USER_PROFILE, {
    username: matches.username,
  })

  const authenticatedUser = useReactiveVar(CurrentUser)

  const [update, { loading: updateLoading }] = useMutation<
    UpdateUserResult,
    UpdateUserInput
  >(UPDATE_USER, {})

  return (
    <Loader
      loaded={!loading}
      passingProps={{ user: userData?.getUserByUsername }}
      className={styles.loader}
    >
      {({ user }) => {
        const isMe = authenticatedUser?.username === user.username
        // TODO: isEsditable should also be true when current user is an admin
        const isEditable = isMe

        return (
          <div>
            <UserCard
              user={user}
              isEditable={isEditable}
              update={async (partialUpdateObj, newAvatarFile) => {
                const { errors } = await update({
                  variables: {
                    user: { id: user.id, ...partialUpdateObj },
                    newAvatarFile,
                  },
                  update: fromMutationResult(updateCachedUser, {
                    incomingUser: 'updateUser',
                  }),
                })

                return !errors
              }}
              loading={updateLoading}
            />

            <div className="container">
              <div className={styles['page-grid']}>
                <UserNav showSettings={isMe} />
                <Router>
                  <Route
                    path={`${baseUrl}resources`}
                    key={`profile-route-resources`}
                    component={UserResources}
                    userId={user.id}
                  />
                  <Route
                    path={`${baseUrl}posts`}
                    key={`profile-route-posts`}
                    component={UserPosts}
                    userId={user.id}
                  />
                  <Route
                    path={`${baseUrl}discussions`}
                    key={`profile-route-discussions`}
                    component={UserDiscussions}
                    userId={user.id}
                  />
                  {isMe && !!authenticatedUser && (
                    <Route
                      path={`${baseUrl}settings`}
                      key={`profile-route-settings`}
                      component={UserSettings}
                      user={authenticatedUser}
                    />
                  )}
                  <Redirect default to={`${baseUrl}resources`} />
                </Router>
              </div>
            </div>
          </div>
        )
      }}
    </Loader>
  )
}

export default UserPage
