import { Fragment, h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import { Button } from 'ui/atoms/Button'
import { LOG_USER_IN, LogUserInResult } from 'store/operations/user'
import { fromMutationResult } from 'store/cache/utils'
import { updateAuthenticatedUser } from 'store/cache/user'
import { fromEvent, toggleEvent, handleEnterKey } from 'utils/components'
import { getApp } from 'legacy/app'
import { ModalContent, useGlobalModal } from 'services/ModalManager'

export const LoginModal: ModalContent = ({
  onDismiss,
  autoFocusRef,
  setAlerts,
}) => {
  const { t } = useContext(TranslateContext)

  const [forgotModal] = useGlobalModal('forgotPassword')
  const [signupModal] = useGlobalModal('signup')

  const [usernameOrEmail, setUsernameOrEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(true)

  const [login, { error, loading }] = useMutation<LogUserInResult>(
    LOG_USER_IN,
    {
      onCompleted: async () => {
        await getApp().session.afterLogin()
        onDismiss()
      },
      update: fromMutationResult(updateAuthenticatedUser, {
        authenticatedUser: 'login',
      }),
    },
  )
  setAlerts(error ? [error.message] : [])

  const execLogin = (): void => {
    void login({
      variables: {
        identification: usernameOrEmail,
        password,
        remember,
      },
    })
  }
  const switchToForgot = (): void => {
    onDismiss()
    forgotModal?.show()
  }
  const switchToSignup = (): void => {
    onDismiss()
    signupModal?.show()
  }

  return (
    <Fragment>
      <div className="Modal-body">
        <div className="Form Form--centered">
          <div className="Form-group">
            <input
              className="FormControl"
              name="identification"
              type="text"
              placeholder={t('core.login.username_or_email_placeholder')}
              disabled={loading}
              value={usernameOrEmail}
              onInput={fromEvent(setUsernameOrEmail)}
              ref={autoFocusRef}
              onKeyPress={handleEnterKey(execLogin)}
            />
          </div>

          <div className="Form-group">
            <input
              className="FormControl"
              name="password"
              type="password"
              placeholder={t('core.login.password_placeholder')}
              disabled={loading}
              value={password}
              onInput={fromEvent(setPassword)}
              onKeyPress={handleEnterKey(execLogin)}
            />
          </div>

          <div className="Form-group">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  disabled={loading}
                  checked={remember}
                  onInput={toggleEvent(setRemember, remember)}
                />
                {t('core.login.remember_me_label')}
              </label>
            </div>
          </div>

          <div className="Form-group">
            <Button
              style="btn-primary"
              loading={loading}
              onClick={execLogin}
              fill
            >
              {t('core.login.submit_button')}
            </Button>
          </div>
        </div>
      </div>

      <div className="Modal-footer">
        <p className="LogInModal-forgotPassword">
          <a onClick={switchToForgot}>{t('core.login.forgot_password_link')}</a>
        </p>

        <p className="LogInModal-signUp">
          {t('core.login.signup_label_text')}
          <a onClick={switchToSignup}>{t('core.login.signup_link_text')}</a>
        </p>
      </div>
    </Fragment>
  )
}
