/** @jsx m */

const Dropdown = flarum.core.compat['components/Dropdown']
const Button = flarum.core.compat['components/Button']
const icon = flarum.core.compat['helpers/icon']
const extractText = flarum.core.compat['utils/extractText']

import SubscriptionMenuItem from './SubscriptionMenuItem'

export default class SubscriptionMenu extends Dropdown {
  init() {
    this.options = [
      {
        subscription: false,
        icon: 'far fa-star',
        label: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.not_following_button',
        ),
        description: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.not_following_text',
        ),
      },
      {
        subscription: 'follow',
        icon: 'fas fa-star',
        label: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.following_button',
        ),
        description: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.following_text',
        ),
      },
      {
        subscription: 'ignore',
        icon: 'far fa-eye-slash',
        label: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.ignoring_button',
        ),
        description: app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.ignoring_text',
        ),
      },
    ]
  }

  view() {
    const discussion = this.props.discussion
    const subscription = discussion.subscription()

    let buttonLabel = app.translator.trans(
      'flarum-subscriptions.forum.sub_controls.follow_button',
    )
    let buttonIcon = 'far fa-star'
    const buttonClass = 'SubscriptionMenu-button--' + subscription

    switch (subscription) {
      case 'follow':
        buttonLabel = app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.following_button',
        )
        buttonIcon = 'fas fa-star'
        break

      case 'ignore':
        buttonLabel = app.translator.trans(
          'flarum-subscriptions.forum.sub_controls.ignoring_button',
        )
        buttonIcon = 'far fa-eye-slash'
        break

      default:
      // no default
    }

    const preferences = app.session.user.preferences()
    const notifyEmail = preferences['notify_newPost_email']
    const notifyAlert = preferences['notify_newPost_alert']
    const title = extractText(
      app.translator.trans(
        notifyEmail
          ? 'flarum-subscriptions.forum.sub_controls.notify_email_tooltip'
          : 'flarum-subscriptions.forum.sub_controls.notify_alert_tooltip',
      ),
    )

    const buttonProps = {
      className: 'Button SubscriptionMenu-button ' + buttonClass,
      icon: buttonIcon,
      children: buttonLabel,
      onclick: this.saveSubscription.bind(
        this,
        discussion,
        ['follow', 'ignore'].indexOf(subscription) !== -1 ? false : 'follow',
      ),
      title: title,
    }

    if ((notifyEmail || notifyAlert) && subscription === false) {
      buttonProps.config = element => {
        $(element).tooltip({
          container: '.SubscriptionMenu',
          placement: 'bottom',
          delay: 250,
          title,
        })
      }
    } else {
      buttonProps.config = element => $(element).tooltip('destroy')
    }

    return (
      <div className="Dropdown ButtonGroup SubscriptionMenu">
        {Button.component(buttonProps)}

        <button
          className={'Dropdown-toggle Button Button--icon ' + buttonClass}
          data-toggle="dropdown"
        >
          {icon('fas fa-caret-down', { className: 'Button-icon' })}
        </button>

        <ul className="Dropdown-menu dropdown-menu Dropdown-menu--right">
          {this.options.map(props => {
            props.onclick = this.saveSubscription.bind(
              this,
              discussion,
              props.subscription,
            )
            props.active = subscription === props.subscription

            return <li>{SubscriptionMenuItem.component(props)}</li>
          })}
        </ul>
      </div>
    )
  }

  saveSubscription(discussion, subscription) {
    discussion.save({ subscription })

    this.$('.SubscriptionMenu-button').tooltip('hide')
  }
}
