import { FunctionalComponent, h } from 'preact'
import { useContext, useRef, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useReactiveVar } from '@apollo/client'

import { DropdownItem } from 'ui/atoms/DropdownItem'
import { DropdownButton } from 'ui/molecules/DropdownButton'
import { Button } from 'ui/atoms/Button'
import { DiscussionSubscription } from 'types/discussion'
import styles from './styles.scss'
import { IsAuthenticated, IsUserVerified } from 'store/local/user'

export const DiscussionSubscriptionDropdownButton: FunctionalComponent<{
  subscription: DiscussionSubscription | null
  onSubscriptionChange: (subscription: DiscussionSubscription | null) => void
  canSubscribe: boolean
  locked: boolean
}> = ({ subscription, canSubscribe, locked, onSubscriptionChange }) => {
  const { t } = useContext(TranslateContext)

  const [expanded, setExpanded] = useState(false)
  const expandButtonRef = useRef<HTMLDivElement>(null)
  const isAuthenticated = useReactiveVar(IsAuthenticated)
  const isUserVerified = useReactiveVar(IsUserVerified)
  const cantSubscribeTooltip =
    !canSubscribe && !isAuthenticated
      ? t('core.community.discussion_page.can_t_subscribe_unauthenticated')
      : !canSubscribe && !isUserVerified
      ? t('core.community.discussion_page.can_t_subscribe_unverified')
      : !canSubscribe && locked
      ? t('core.community.discussion_page.can_t_subscribe_locked')
      : undefined

  return (
    <DropdownButton
      align="right"
      for={expandButtonRef}
      onExpandChange={setExpanded}
      tooltip={cantSubscribeTooltip}
      tooltipPosition="bottom"
      buttons={[
        <Button
          key="subscription-dpd-btn-1"
          fill
          style="btn-info"
          size="sm"
          padding="sm"
          disabled={!canSubscribe}
          onClick={() =>
            onSubscriptionChange(
              subscription !== DiscussionSubscription.FOLLOW
                ? DiscussionSubscription.FOLLOW
                : null,
            )
          }
        >
          <i
            className={`${subscription ? 'fas' : 'far'} fa-bookmark ${
              styles['btn-icon']
            }`}
          />
          <span>
            {t(
              `core.community.discussion_page.${
                subscription ? 'un' : ''
              }follow_button`,
            )}
          </span>
        </Button>,
        <Button
          ref={expandButtonRef}
          key="subscription-dpd-btn-2"
          active={expanded}
          style="btn-info"
          padding="sm"
          size="sm"
          iconOnly
          disabled={!canSubscribe}
        >
          <i className={'fas fa-caret-down'} />
        </Button>,
      ]}
    >
      <DropdownItem
        hasIcon
        iconClassName={subscription === null ? 'fas fa-check' : undefined}
        onClick={
          subscription !== null ? () => onSubscriptionChange(null) : undefined
        }
      >
        <div className={styles['subscription-dropdown-item']}>
          <div className={styles.head}>
            <i className={`${styles['item-icon']} far fa-bookmark`} />
            <span>
              {t(
                'core.community.discussion_page.subscription_dropdown.not_following_button',
              )}
            </span>
          </div>
          <div className={styles.text}>
            {t(
              'core.community.discussion_page.subscription_dropdown.not_following_text',
            )}
          </div>
        </div>
      </DropdownItem>
      <DropdownItem
        hasIcon
        iconClassName={
          subscription === DiscussionSubscription.FOLLOW
            ? 'fas fa-check'
            : undefined
        }
        onClick={
          subscription !== DiscussionSubscription.FOLLOW
            ? () => onSubscriptionChange(DiscussionSubscription.FOLLOW)
            : undefined
        }
      >
        <div className={styles['subscription-dropdown-item']}>
          <div className={styles.head}>
            <i className={`${styles['item-icon']} fas fa-bookmark`} />
            <span>
              {t(
                'core.community.discussion_page.subscription_dropdown.following_button',
              )}
            </span>
          </div>
          <div className={styles.text}>
            {t(
              'core.community.discussion_page.subscription_dropdown.following_text',
            )}
          </div>
        </div>
      </DropdownItem>
      {/* <DropdownItem hasIcon>
        <div className={styles['subscription-dropdown-item']}>
          <div className={styles.head}>
            <i className={`${styles['item-icon']} far fa-eye-slash`} />
            <span>
              {t(
                'core.community.discussion_page.subscription_dropdown.ignoring_button',
              )}
            </span>
          </div>
          <div className={styles.text}>
            {t(
              'core.community.discussion_page.subscription_dropdown.ignoring_text',
            )}
          </div>
        </div>
      </DropdownItem> */}
    </DropdownButton>
  )
}
