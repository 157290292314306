import { FunctionalComponent, h } from 'preact'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useQuery } from '@apollo/client'

import styles from './styles.scss'
import { fromEvent } from 'utils/components'
import { CreateResourcePayload } from 'types/resource'
import {
  GetCategoriesWithTagsResult,
  GET_CATEGORIES_WITH_TAGS,
} from 'store/operations/tagsCategory'
import { TeacherTagsSelect } from '../../molecules/TeacherTagsSelect'

export const ResourceAuthorStep: FunctionalComponent<{
  partialResource: CreateResourcePayload
  onChange: (partialResource: CreateResourcePayload) => void
}> = ({ partialResource, onChange }) => {
  const { t } = useContext(TranslateContext)

  const { originalAuthor, postedForTagNodeId } = partialResource

  const partialUpdate = (update: Partial<CreateResourcePayload>): void =>
    onChange({
      ...partialResource,
      ...update,
    })

  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)
  const categoriesWithTags = getTagsData?.getCategoriesWithTags

  return (
    <div className={`Form Form--centered ${styles.form}`}>
      <div className="Form-group">
        <input
          className={`FormControl  ${styles.FormControl}`}
          name="originalAuthor"
          type="text"
          placeholder={t(
            'core.resources.new_resource_modal.original_author_placeholder',
          )}
          value={originalAuthor}
          onInput={fromEvent((originalAuthor) =>
            partialUpdate({ originalAuthor }),
          )}
        />
      </div>

      <h3>{t('core.resources.new_resource_modal.teacher_tags_head')}</h3>

      <div className={styles['posted-for-all-public-checkbox']}>
        <input
          id="new-resource-all-public-checkbox"
          type="checkbox"
          checked={postedForTagNodeId === null}
          onClick={(e) =>
            partialUpdate({
              postedForTagNodeId: (
                e.target as unknown as {
                  checked: boolean
                }
              )?.checked
                ? null
                : undefined,
            })
          }
        />
        <label htmlFor="new-resource-all-public-checkbox">
          {t('core.resources.new_resource_modal.all_public_checkbox')}
        </label>
      </div>

      {categoriesWithTags && postedForTagNodeId !== null && (
        <TeacherTagsSelect
          categoriesWithTags={categoriesWithTags}
          onChange={(postedForTagNodeId) =>
            partialUpdate({ postedForTagNodeId })
          }
          tagNodeId={postedForTagNodeId || null}
        />
      )}

      <div className={styles['legal-notice']}>
        {t('core.resources.new_resource_modal.legal_notice', {
          buttonLabel: t('core.resources.new_resource_modal.send_button'),
        })}
      </div>
    </div>
  )
}
