import { useMutation, useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { getApp } from 'legacy/app'
import { LegacyComponent } from 'legacy/compat'
import { Fragment, h, FunctionalComponent } from 'preact'
import { route } from 'preact-router'
import { useContext, useRef, useState } from 'preact/hooks'
import { BurgerButton } from 'ui/atoms/BurgerButton'
import { Button } from 'ui/atoms/Button'
import { Link } from 'ui/atoms/Link'
import { ForgotPasswordModal } from '../ForgotPasswordModal'
import { LoginModal } from '../LoginModal'
import { SignupModal } from '../SignupModal'
import SessionDropdown from 'legacy/src/core/js/forum/components/SessionDropdown'
import {
  LogUserOutResult,
  LOG_USER_OUT,
  SendConfirmationEmailInput,
  SendConfirmationEmailResult,
  SEND_CONFIRMATION_EMAIL,
} from 'store/operations/user'
import { fromMutationResult } from 'store/cache/utils'
import { updateAuthenticatedUser } from 'store/cache/user'
import { CurrentRouterPath } from 'store/local/navigation'
import {
  getRoutePath,
  getRouteUrl,
  isRouteIn,
  PageGroup,
  PageType,
} from 'common/routing'
import './styles.scss'
import { modalContent, useGlobalModal } from 'services/ModalManager'
import { SearchBar } from 'ui/molecules/SearchBar'
import { CurrentUser } from 'store/local/user'
import { DropdownButton } from 'ui/molecules/DropdownButton'
import { DropdownItem } from 'ui/atoms/DropdownItem'
import { DropdownSeparator } from 'ui/atoms/DropdownSeparator'
import { AlertBanner } from 'ui/atoms/AlertBanner'
import { ModerationFlagsDropdown } from 'ui/molecules/ModerationFlagsDropdown'
import { NotificationsDropdown } from 'ui/molecules/NotificationsDropdown'

export const NavBar: FunctionalComponent = () => {
  const [isMobileMenuExpanded, setMobileMenuExpanded] = useState<boolean>(false)
  const { t } = useContext(TranslateContext)
  const authenticatedUser = useReactiveVar(CurrentUser)

  const path = useReactiveVar(CurrentRouterPath)
  const isResource = !!path && isRouteIn(PageGroup.RESOURCE, path)
  const isCommunity = !!path && isRouteIn(PageGroup.COMMUNITY, path)
  const isAbout = !!path && path === getRoutePath(PageType.ABOUT)
  const [isAboutDropdownShown, setIsAboutDropdownExpanded] = useState(false)

  const dropdownButton = useRef<HTMLDivElement>(null)

  useGlobalModal('forgotPassword', modalContent(ForgotPasswordModal), {
    title: t('core.forgot_password_modal.title'),
    size: 'small',
  })
  const [loginModal] = useGlobalModal('login', modalContent(LoginModal), {
    title: t('core.login.title'),
    size: 'small',
  })
  const [signupModal] = useGlobalModal('signup', modalContent(SignupModal), {
    title: t('core.signup.title'),
    size: 'small',
  })

  const [
    sendConfirmationEmail,
    { loading: confirmationLoading, called: confirmationCalled },
  ] = useMutation<SendConfirmationEmailResult, SendConfirmationEmailInput>(
    SEND_CONFIRMATION_EMAIL,
  )
  const [logout] = useMutation<LogUserOutResult>(LOG_USER_OUT, {
    onCompleted: getApp().session.afterLogout.bind(getApp().session),
    update: fromMutationResult(
      (cache) =>
        updateAuthenticatedUser(cache, {
          authenticatedUser: null,
        }),
      {},
    ),
  })

  return (
    <Fragment>
      <header
        className={`sticky top-0 shadow-[0px_1px_1px_0px_rgba(0,0,0,0.05)] transition-shadow z-50 w-full ${
          process.env.NODE_ENV === 'staging'
            ? 'bg-orange-400 bg-opacity-70'
            : 'bg-white'
        }`}
      >
        <div
          id="header-navigation"
          className="absolute left-20 md:left-6 top-[14px] items-center"
        />
        <div className="container">
          <div className={`h-16 p-2 flex items-center justify-between`}>
            <div className="flex items-center">
              <Link
                className="mr-4"
                href={getRouteUrl(PageType.LANDING)}
                onClick={() => {
                  setMobileMenuExpanded(false)
                }}
              >
                <i className="text-3xl fas fa-graduation-cap text-gray-dark" />
              </Link>

              <nav className="hidden md:block">
                <ul>
                  <Link
                    className={`text-base mx-2 ${
                      isResource ? 'font-bold' : ''
                    }`}
                    href="/resources"
                    onClick={() => route('/resources')}
                  >
                    {t('core.navbar.resources_link')}
                  </Link>
                  <Link
                    className={`text-base mx-2 ${
                      isCommunity ? 'font-bold' : ''
                    }`}
                    href="/discussions"
                    onClick={() => route('/discussions')}
                  >
                    {t('core.navbar.community_link')}
                  </Link>
                  <DropdownButton
                    inline
                    align="right"
                    buttons={[
                      <Link
                        key="navbar-about-dropdown-button"
                        className={`text-base mx-2 ${
                          isAbout ? 'font-bold hover:!text-inherit' : ''
                        }`}
                        ref={dropdownButton}
                      >
                        {t('core.navbar.about_dropdown')}
                        <i
                          className={`ml-2 fas fa-${
                            isAboutDropdownShown ? 'caret' : 'angle'
                          }-down`}
                        />
                      </Link>,
                    ]}
                    for={dropdownButton}
                    onExpandChange={setIsAboutDropdownExpanded}
                  >
                    <DropdownItem
                      onClick={() => route(getRoutePath(PageType.ABOUT))}
                      hasIcon
                      iconClassName="fas fa-graduation-cap"
                    >
                      {t('core.navbar.about_link')}
                    </DropdownItem>
                    <DropdownSeparator />
                    <Link
                      href="https://www.helloasso.com/associations/enseignerleclimat/formulaires/1"
                      target="_blank"
                    >
                      <DropdownItem
                        hasIcon
                        iconClassName="text-red relative scale-125 fas fa-hand-holding-heart"
                        className="text-gray-dark font-medium"
                      >
                        {t('core.navbar.donate_link')}
                      </DropdownItem>
                    </Link>
                  </DropdownButton>
                </ul>
              </nav>
            </div>
            <div id="nav-items">
              <BurgerButton
                className="md:hidden"
                onClick={() => {
                  setMobileMenuExpanded(!isMobileMenuExpanded)
                }}
                isOpened={isMobileMenuExpanded}
              />
              {authenticatedUser ? (
                <div>
                  <ul className="hidden md:block Header-controls">
                    <li className="item-search mr-8">
                      <SearchBar />
                    </li>
                    {getApp().forum.attribute('canViewFlags') ? (
                      <li className="item-flags">
                        <ModerationFlagsDropdown />
                      </li>
                    ) : null}
                    <li className="item-notifications">
                      <NotificationsDropdown />
                    </li>
                    <li className="item-session">
                      <LegacyComponent
                        component={SessionDropdown}
                        onLogout={() => {
                          route('/')
                          void logout()
                        }}
                      />
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="hidden md:flex items-center">
                  <Link
                    className="text-base mr-3"
                    onClick={() => loginModal.show()}
                  >
                    {t('core.navbar.login_link')}
                  </Link>
                  <Button size="sm" onClick={() => signupModal.show()}>
                    {t('core.navbar.signup_link')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobileMenuExpanded ? (
          <div className="flex flex-col md:hidden pb-6 w-screen shadow-2xl">
            <ul className="container mt-4">
              <li className="my-2 text-lg">
                <Link
                  href="/resources"
                  onClick={() => {
                    route('/resources')
                    setMobileMenuExpanded(false)
                  }}
                >
                  {t('core.navbar.resources_link')}
                </Link>
              </li>
              <li className="my-2 text-lg">
                <Link
                  href="/discussions"
                  onClick={() => {
                    route('/discussions')
                    setMobileMenuExpanded(false)
                  }}
                >
                  {t('core.navbar.community_link')}
                </Link>
              </li>

              <li className="mt-6 mb-2 text-lg">
                <Link
                  href="/about"
                  onClick={() => {
                    route(getRoutePath(PageType.ABOUT))
                    setMobileMenuExpanded(false)
                  }}
                >
                  {t('core.navbar.about_link_mobile')}
                </Link>
              </li>
              <li className="my-2 text-lg">
                <Link
                  href="https://www.helloasso.com/associations/enseignerleclimat/formulaires/1"
                  target="_blank"
                >
                  {t('core.navbar.donate_link')}
                </Link>
                <i className="ml-2 text-red relative scale-125 fas fa-hand-holding-heart" />
              </li>
            </ul>

            <hr className="w-3/4 mx-auto border-gray border-2" />

            {authenticatedUser ? (
              <div className="flex justify-center">
                <Button
                  className="mt-2"
                  onClick={() => {
                    void logout()
                  }}
                >
                  {t('core.navbar.logout_link')}
                </Button>
              </div>
            ) : (
              <div className="flex items-center my-2 mx-auto">
                <Link
                  className="text-lg mx-2"
                  onClick={() => {
                    loginModal.show()
                    setMobileMenuExpanded(false)
                  }}
                >
                  {t('core.navbar.login_link')}
                </Link>
                <Button
                  className="mx-2"
                  onClick={() => {
                    signupModal.show()
                    setMobileMenuExpanded(false)
                  }}
                >
                  {t('core.navbar.signup_link')}
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </header>

      {authenticatedUser && !authenticatedUser.isEmailConfirmed ? (
        <div>
          <AlertBanner dismissible={false} type="warning">
            <div className="container">
              {t('core.forum.user_email_confirmation.alert_message', {
                email: authenticatedUser.email,
              })}{' '}
              <div className="text-center">
                <Button
                  style="link-darken-hover"
                  size="sm"
                  padding="xs"
                  loading={confirmationLoading}
                  disabled={confirmationCalled}
                  onClick={() => sendConfirmationEmail()}
                >
                  {confirmationCalled && !confirmationLoading ? (
                    <Fragment>
                      <i className="fas fa-check" />{' '}
                      {t('core.forum.user_email_confirmation.sent_message')}
                    </Fragment>
                  ) : (
                    t('core.forum.user_email_confirmation.resend_button')
                  )}
                </Button>
              </div>
            </div>
          </AlertBanner>
        </div>
      ) : null}
    </Fragment>
  )
}
