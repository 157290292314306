import { Fragment, h } from 'preact'
import { forwardRef, useRef } from 'preact/compat'
import { Tooltip, TooltipProps } from '../Tooltip'

import styles from './styles.scss'

interface BadgePropsInterface {
  className?: string
  value?: string
  icon?: h.JSX.Element
  iconBadge?: boolean
  tooltip?: TooltipProps['content']
  tooltipPosition?: TooltipProps['position']
  tooltipWidth?: TooltipProps['width']
  tooltipClassName?: TooltipProps['className']
}

// eslint-disable-next-line react/display-name
export const Badge = forwardRef<HTMLDivElement, BadgePropsInterface>(
  (props, ref) => {
    const {
      value,
      className,
      icon,
      iconBadge,
      tooltip,
      tooltipPosition,
      tooltipWidth,
      tooltipClassName,
    } = props
    const localRef = useRef()

    return (
      <Fragment>
        <div
          ref={ref || localRef}
          class={`${className || ''} ${styles.tag} ${
            iconBadge ? styles['icon-badge'] : ''
          }`}
        >
          {icon}
          <span className={styles.label}>{value}</span>
        </div>
        {tooltip && (
          <Tooltip
            content={tooltip}
            position={tooltipPosition}
            width={tooltipWidth}
            for={ref || localRef}
            className={tooltipClassName}
          />
        )}
      </Fragment>
    )
  },
)
