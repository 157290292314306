import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { Button } from 'ui/atoms/Button'

export const FlagResourceSuccess: FunctionalComponent<{
  onDismiss: () => void
}> = ({ onDismiss }) => {
  const { t } = useContext(TranslateContext)

  return (
    <div className="Modal-body">
      <div className="Form Form--centered">
        <p className="helpText">
          {t('core.resources.flag_resource.confirmation_message')}
        </p>
        <div className="Form-group">
          <Button
            className="Button Button--primary Button--block"
            onClick={onDismiss}
          >
            {t('core.resources.flag_resource.dismiss_button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
