import { h, FunctionalComponent, Fragment } from 'preact'

import styles from './styles.scss'
import { TagsCategoryWithTags } from 'types/tagsCategory'
import { Tag } from 'types/tag'
import {
  getAllTagsFromTagsCategories,
  getTagAndAncestorsByCategory,
  getTagChildren,
} from 'utils/tags'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

export const ResourceFilters: FunctionalComponent<{
  selectedTagNodeId: string | null
  selectedResourceTags: string[]
  skipped: boolean
  onChange: (update: {
    tagNodeId?: string | null
    resourceTags?: string[]
    skipped?: boolean
  }) => void
  className?: string
  teacherCategoriesAndTags: TagsCategoryWithTags[]
  resourceTags: Tag[]
}> = ({
  selectedTagNodeId,
  selectedResourceTags,
  skipped,
  onChange,
  className = '',
  teacherCategoriesAndTags,
  resourceTags,
}) => {
  const { t } = useContext(TranslateContext)

  const CATEGORY_INFO_MAP: Record<string, { icon: string; label: string }> = {
    niveau: {
      icon: 'fas fa-signal',
      label: t('core.resources.filter_menu.level_label'),
    },
    domaine: {
      icon: 'fas fa-graduation-cap',
      label: t('core.resources.filter_menu.domain_label'),
    },
    discipline: {
      icon: 'fas fa-graduation-cap',
      label: t('core.resources.filter_menu.discipline_label'),
    },
    'resource-types': {
      icon: 'fas fa-file',
      label: t('core.resources.filter_menu.filter_label'),
    },
  }

  const allTags = getAllTagsFromTagsCategories(teacherCategoriesAndTags)
  const selectedTagsByCategory = selectedTagNodeId
    ? getTagAndAncestorsByCategory(teacherCategoriesAndTags, selectedTagNodeId)
    : {}
  const expandedTeacherTags = getTagChildren(allTags, selectedTagNodeId)

  // Resource type filter panel is expanded whenever whe skipped the previous steps
  // or a tagNodeId is already selected and there is no children to choose left
  const isResourceTypeExpanded =
    skipped ||
    (!!selectedTagNodeId && !getTagChildren(allTags, selectedTagNodeId).length)

  // `expanded` is the idx of the category which is expanded within `teacherCategoriesAndTags`
  // We set it to its maximum boundary when resource type panel is expanded
  const expanded = isResourceTypeExpanded
    ? teacherCategoriesAndTags.length
    : teacherCategoriesAndTags.findIndex(
        (c) => c.id === expandedTeacherTags[0].category.id,
      )

  // If a step was skipped, then the idx of this step (category) is the one that would
  // be expanded if resource type wasn't
  const skippedIdx = skipped
    ? teacherCategoriesAndTags.findIndex(
        (c) => c.id === expandedTeacherTags[0].category.id,
      )
    : null

  const toggleResourceTag = (tag: Tag) => () => {
    const newSelection = selectedResourceTags.slice()

    selectedResourceTags.includes(tag.id)
      ? newSelection.splice(selectedResourceTags.indexOf(tag.id), 1)
      : newSelection.push(tag.id)

    onChange({ resourceTags: newSelection })
  }

  return (
    <div className={`${className} ${styles.container}`}>
      {teacherCategoriesAndTags.map((category, cIdx) => {
        // Determine whether the current category is
        // already selected (the currently expanded category is in a further iteration),
        // expanded, skipped or
        // invisible (a category being jumped in the node tree because of the previous selection)
        const isAlreadySelected = expanded > cIdx
        const isExpanded = !isResourceTypeExpanded && expanded === cIdx
        const isSkipped = skippedIdx === cIdx
        const isInvisible =
          !isSkipped &&
          isAlreadySelected &&
          !selectedTagsByCategory[category.id]

        // Current category tags
        const tags = isExpanded ? expandedTeacherTags : []

        // The label that would appear if the category was skipped
        const skippedLabel = selectedTagNodeId
          ? t('core.resources.filter_menu.skip_label')
          : t('core.resources.filter_menu.all_public')
        // The label of the current category when selected or skipped
        const selectedTagLabel = isSkipped
          ? skippedLabel
          : category.tags.find(
              (t) => t.id === selectedTagsByCategory[category.id]?.id,
            )?.name
        // Is the current category clearable ?
        const canClear = isAlreadySelected && !isInvisible
        // If this category is cleared, this tagNodeId should be selected
        const onClearNodeId = isSkipped
          ? selectedTagNodeId
          : selectedTagsByCategory[category.id]?.parentId || null

        // Category icon and label
        const categoryIcon = CATEGORY_INFO_MAP[category.slug]
          ? `${CATEGORY_INFO_MAP[category.slug]?.icon}`
          : 'fas fa-question'
        const categoryLabel =
          CATEGORY_INFO_MAP[category.slug]?.label || category.name
        // Overide level icon when all public is selected
        const overrideIcon =
          isSkipped && !selectedTagNodeId ? 'fas fa-globe-africa' : null

        return (
          <Fragment key={`${category.slug}`}>
            <div
              className={`${isExpanded ? styles.expanded : ''} ${
                styles['category-item']
              } ${isAlreadySelected ? styles.selected : ''} ${
                isInvisible ? styles.invisible : ''
              }`}
            >
              <i className={`${styles.icon} ${overrideIcon || categoryIcon}`} />
              <div className={styles.label}>
                <div>{categoryLabel}</div>
                <div>{selectedTagLabel}</div>
              </div>
              {canClear ? (
                <i
                  className={`fas fa-times ${styles.clear}`}
                  onClick={() =>
                    onChange({
                      tagNodeId: onClearNodeId,
                      resourceTags: selectedResourceTags.length
                        ? []
                        : undefined,
                      skipped: skipped ? false : undefined,
                    })
                  }
                />
              ) : null}
            </div>
            <div
              className={`${styles['tag-area']} ${
                isExpanded ? styles.expanded : ''
              }${isAlreadySelected ? styles.selected : ''}`}
            >
              {!isInvisible &&
                tags.map((tag, tIdx) => (
                  <div
                    key={`tag-item-${cIdx}-${tIdx}`}
                    className={`${styles['tag-item']} ${
                      selectedTagsByCategory[category.id]?.id === tag.id
                        ? styles.selected
                        : ''
                    }`}
                  >
                    <i className={`fas fa-check`} />
                    <span
                      className={styles.label}
                      onClick={() => onChange({ tagNodeId: tag.id })}
                    >
                      {tag.name}
                    </span>
                  </div>
                ))}
              {isExpanded && (
                <div
                  key={`tag-item-${cIdx}-skip`}
                  className={`${styles['tag-item']} ${styles.skip}`}
                >
                  <i
                    className={`fas fa-${
                      selectedTagNodeId ? 'step-forward' : 'globe-africa'
                    }`}
                  />
                  <span
                    className={styles.label}
                    onClick={() => {
                      onChange({ skipped: true })
                    }}
                  >
                    {selectedTagNodeId
                      ? t('core.resources.filter_menu.skip_label')
                      : t('core.resources.filter_menu.all_public')}
                  </span>
                </div>
              )}
            </div>
          </Fragment>
        )
      })}

      {isResourceTypeExpanded ? (
        <Fragment>
          <div
            className={`${isResourceTypeExpanded ? styles.expanded : ''} ${
              styles['category-item']
            } ${styles['resource-filters']}`}
          >
            <i className={`${styles.icon} fas fa-filter`} />
            <div className={styles.label}>
              <div>{CATEGORY_INFO_MAP['resource-types']?.label}</div>
              <div />
            </div>
            {isResourceTypeExpanded && !!selectedResourceTags.length ? (
              <i
                className={`fas fa-times ${styles.clear}`}
                onClick={() => onChange({ resourceTags: [] })}
              />
            ) : null}
          </div>
          <div
            className={`${styles['tag-area']} ${
              isResourceTypeExpanded ? styles.expanded : ''
            }`}
          >
            {isResourceTypeExpanded &&
              resourceTags.map((tag, tIdx) => (
                <div
                  key={`resource-tag-item-${tIdx}`}
                  className={`${styles['tag-item']} ${
                    selectedResourceTags.includes(tag.id) ? styles.selected : ''
                  }`}
                >
                  <i className={`fas fa-check`} />
                  <span
                    className={styles.label}
                    onClick={toggleResourceTag(tag)}
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
          </div>
        </Fragment>
      ) : null}

      <div className={styles.progress}>
        {teacherCategoriesAndTags
          .reduce<boolean[]>((acc, category, cIdx, { length }) => {
            const isDisplayed =
              !!selectedTagsByCategory[category.id] || skippedIdx === cIdx
            const isAlreadySelected = expanded > cIdx
            const isInvisible = isAlreadySelected && !isDisplayed
            const isActive = isAlreadySelected || expanded === cIdx
            const isLast =
              (cIdx === length - 1 && skippedIdx === null) ||
              skippedIdx === cIdx

            return acc.concat([
              ...(!isInvisible ? [isActive] : []),
              ...(isLast ? [isAlreadySelected] : []),
            ])
          }, [])
          .map((active, idx) => {
            return [
              idx > 0 ? (
                <div
                  key={`filters-progress-step-line-${idx}`}
                  className={`${styles.line} ${active ? styles.active : ''}`}
                />
              ) : null,
              <div
                key={`filters-progress-step-${idx}`}
                className={`${styles.step} ${active ? styles.active : ''}`}
              >
                {idx + 1}
              </div>,
            ]
          })}
      </div>
    </div>
  )
}
