import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import { Button } from 'ui/atoms/Button'
import styles from './styles.scss'
import { modalContent, useModal } from 'services/ModalManager'
import { FlagResourceModal } from '../FlagResourceModal'
import { UpdateResourceModal } from '../UpdateResourceModal'
import { Resource } from 'types/resource'

type ResourceSubHeaderPropsType = {
  switchContentView: (resourceSummary: boolean) => void
  isResourceSummary: boolean
  resource: Resource
  canFlag: boolean
  canUpdate: boolean
}

export const ResourceSubHeader: FunctionalComponent<
  ResourceSubHeaderPropsType
> = ({
  switchContentView,
  isResourceSummary,
  resource,
  canFlag,
  canUpdate,
}) => {
  const { t } = useContext(TranslateContext)

  const [flagResourceModal] = useModal(modalContent(FlagResourceModal), {
    size: 'medium',
    title: t('core.resources.flag_resource.title'),
    className: 'FlagPostModal',
    resourceId: resource.id,
  })
  const [updateResourceModal] = useModal(modalContent(UpdateResourceModal), {
    size: 'large',
    title: t('core.resources.update_resource_modal.title'),
  })

  return (
    <div className={`container ${styles['resource-subheader']}`}>
      <Button
        onClick={() => switchContentView(true)}
        size="lg"
        style="link"
        padding="none"
        bold={isResourceSummary}
        active={isResourceSummary}
        noRadius
        className={`${styles['subheader-text-button']} ${
          isResourceSummary ? styles.active : ''
        }`}
      >
        {t('core.resource.subheader.resource_summary')}
      </Button>
      <Button
        onClick={() => switchContentView(false)}
        size="lg"
        style="link"
        padding="none"
        bold={!isResourceSummary}
        active={!isResourceSummary}
        noRadius
        className={`${styles['subheader-text-button']} ${
          !isResourceSummary ? styles.active : ''
        }`}
      >
        {t('core.resource.subheader.all_discussions_button')}
      </Button>
      <div className={styles['action-btns']}>
        {canUpdate && (
          <Button
            size="sm"
            style="link"
            padding="none"
            onClick={() => updateResourceModal.show({ resource })}
          >
            <Fragment>
              <i className={`fas fa-pencil-alt ${styles.icon}`} />
              {t('core.resource.subheader.update_button')}
            </Fragment>
          </Button>
        )}
        {canFlag && (
          <Button
            size="sm"
            style="link"
            padding="none"
            onClick={flagResourceModal.show}
          >
            <Fragment>
              <i className={`fas fa-flag ${styles.icon}`} />
              {t('core.resource.subheader.report_button')}
            </Fragment>
          </Button>
        )}
      </div>
    </div>
  )
}
