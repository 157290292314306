import { h, FunctionalComponent } from 'preact'
import styles from './styles.scss'

export const ResourceContentThreads: FunctionalComponent = () => {
  return (
    <div className={`${styles['resource-content-threads']}`}>
      <div className={`container ${styles.threads}`}>
        <div style="display: flex; align-content: center; padding: 18px 70px 50px; font-size: 16px;">
          Bientôt, vous pourrez consulter ici des discussions concernant cette
          ressource pédagogique et en créer de nouvelles
        </div>
      </div>
    </div>
  )
}
