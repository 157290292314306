/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const Model = flarum.core.compat['Model']
const Discussion = flarum.core.compat['models/Discussion']
const NotificationGrid = flarum.core.compat['components/NotificationGrid']

import addSubscriptionBadge from './addSubscriptionBadge'
import addSubscriptionControls from './addSubscriptionControls'
import addSubscriptionFilter from './addSubscriptionFilter'
import addSubscriptionSettings from './addSubscriptionSettings'

import NewPostNotification from './components/NewPostNotification'

app.initializers.add('subscriptions', function () {
  app.notificationComponents.newPost = NewPostNotification

  Discussion.prototype.subscription = Model.attribute('subscription')

  addSubscriptionBadge()
  addSubscriptionControls()
  addSubscriptionFilter()
  addSubscriptionSettings()

  extend(NotificationGrid.prototype, 'notificationTypes', function (items) {
    items.add('newPost', {
      name: 'newPost',
      icon: 'fas fa-bookmark',
      label: app.translator.trans(
        'flarum-subscriptions.forum.settings.notify_new_post_label',
      ),
    })
  })
})
