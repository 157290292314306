import { TranslateParams } from '@denysvuika/preact-translate/src/translateParams'

export const formatDateAsDelay = (
  t: (key: string, params?: TranslateParams | undefined) => string,
  dateValue: string | Date,
): string => {
  const date = new Date(dateValue)
  const elapsedSeconds = Math.floor((Date.now() - date.getTime()) / 1000)
  const dateLabel =
    elapsedSeconds < 60
      ? t('core.date.delay.within_minute_label')
      : elapsedSeconds < 60 * 2
      ? t('core.date.delay.one_minute_label', {
          elapsedMinutes: Math.floor(elapsedSeconds / 60),
        })
      : elapsedSeconds < 3600
      ? t('core.date.delay.within_hour_label', {
          elapsedMinutes: Math.floor(elapsedSeconds / 60),
        })
      : elapsedSeconds < 3600 * 2
      ? t('core.date.delay.one_hour_label', {
          elapsedHours: Math.floor(elapsedSeconds / 3600),
        })
      : elapsedSeconds < 3600 * 24
      ? t('core.date.delay.within_day_label', {
          elapsedHours: Math.floor(elapsedSeconds / 3600),
        })
      : elapsedSeconds < 3600 * 24 * 2
      ? t('core.date.delay.one_day_label', {
          elapsedDays: Math.floor(elapsedSeconds / (3600 * 24)),
        })
      : elapsedSeconds < 3600 * 24 * 7
      ? t('core.date.delay.within_week_label', {
          elapsedDays: Math.floor(elapsedSeconds / (3600 * 24)),
        })
      : date.getFullYear() === new Date().getFullYear()
      ? t('core.date.delay.within_year_label', {
          day: date.getDate(),
          month: date.toLocaleString('fr', { month: 'long' }),
        })
      : t('core.date.delay.later_than_year_label', {
          day: date.getDate(),
          month: date.toLocaleString('fr', { month: 'long' }),
          year: date.getFullYear(),
        })

  return dateLabel
}
