import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { getFileIcon } from 'utils/files'
import { DropdownItem } from '../../atoms/DropdownItem'
import { AttachmentItem } from '../AttachmentItem'
import styles from './styles.scss'

type FileItemActions = 'download' | 'remove' | 'preview'
export const FileItem: FunctionalComponent<{
  file: { mimetype: string; filename: string }
  onAction?: {
    preview?: () => void
    download?: () => void
    remove?: () => void
    click?: () => void
  }
  canRemove?: boolean
  canPreview?: boolean
  canDownload?: boolean
  loading?: boolean
  className?: string
  onClickAction?: FileItemActions
}> = ({
  file,
  onAction = {},
  canRemove = false,
  canPreview = false,
  canDownload = false,
  loading = false,
  className = '',
  onClickAction,
}) => {
  const { t } = useContext(TranslateContext)
  const icon = getFileIcon(file.mimetype)

  const actionMapping: {
    [action in FileItemActions]: { icon: string; active: boolean }
  } = {
    preview: { icon: 'far fa-eye', active: canPreview },
    remove: { icon: `fas fa-trash-alt ${styles.red}`, active: canRemove },
    download: { icon: 'fas fa-download', active: canDownload },
  }

  const {
    download: onDownload,
    preview: onPreview,
    remove: onRemove,
    click: onClick,
  } = onAction

  return (
    <AttachmentItem
      iconClassName={icon}
      title={file.filename}
      onClick={onClick || (onClickAction && onAction[onClickAction])}
      hoverIconClassName={
        !onClick && onClickAction
          ? actionMapping[onClickAction].icon
          : undefined
      }
      loading={loading}
      className={className}
    >
      {canPreview && (
        <DropdownItem
          key="file-attachment-preview-button"
          hasIcon
          iconClassName={actionMapping['preview'].icon}
          onClick={onPreview}
        >
          {t('core.upload.attachment.preview_button')}
        </DropdownItem>
      )}
      {canDownload && (
        <DropdownItem
          key="file-attachment-download-button"
          hasIcon
          iconClassName={actionMapping['download'].icon}
          onClick={onDownload}
        >
          {t('core.upload.attachment.download_button')}
        </DropdownItem>
      )}
      {canRemove && (
        <DropdownItem
          key="file-attachment-preview-button"
          hasIcon
          iconClassName={actionMapping['remove'].icon}
          onClick={onRemove}
        >
          {t('core.upload.attachment.remove_button')}
        </DropdownItem>
      )}
    </AttachmentItem>
  )
}
