import { h, FunctionalComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import styles from './styles.scss'

const NUMBER_OF_PAGE_BY_RANGE = 10

export const Pagination: FunctionalComponent<{
  pageCount?: number
  currentPageIndex: number
  className?: string
  onPageChange: (index: number) => void
}> = ({ currentPageIndex, pageCount, className, onPageChange }) => {
  const [visiblePageRangeIdx, setVisiblePageRangeIdx] = useState(0)

  useEffect(
    () =>
      setVisiblePageRangeIdx(
        Math.floor(currentPageIndex / NUMBER_OF_PAGE_BY_RANGE),
      ),
    [currentPageIndex],
  )

  if (typeof pageCount !== 'number') {
    return (
      <div className={`${className || ''} ${styles['pagination-container']}`}>
        <div className={`${styles['page-item']} ${styles.inactive}`}>...</div>
      </div>
    )
  }

  const lastPageRangeIdx = Math.ceil(pageCount / NUMBER_OF_PAGE_BY_RANGE) - 1
  return (
    <div className={`${className || ''} ${styles['pagination-container']}`}>
      <div
        key={`pagination-item-fast-backward`}
        className={`${styles['page-item']} ${styles.inactive} ${
          visiblePageRangeIdx === 0 ? styles.muted : ''
        }`}
        onClick={() =>
          visiblePageRangeIdx > 0 &&
          setVisiblePageRangeIdx(visiblePageRangeIdx - 1)
        }
      >
        <i className="fas fa-angle-double-left" />
      </div>
      <div
        key={`pagination-item-backward`}
        className={`${styles['page-item']} ${styles.inactive} ${
          currentPageIndex === 0 ? styles.muted : ''
        }`}
        onClick={() =>
          currentPageIndex !== 0 && onPageChange(currentPageIndex - 1)
        }
      >
        <i className="fas fa-angle-left" />
      </div>
      <div className={styles['pagination-container']}>
        {Array(lastPageRangeIdx + 1)
          .fill(null)
          .map((_, rangeIdx) => {
            const rangeStart = rangeIdx * NUMBER_OF_PAGE_BY_RANGE
            const length = Math.min(
              NUMBER_OF_PAGE_BY_RANGE,
              pageCount - rangeStart,
            )
            const rangePosition =
              visiblePageRangeIdx === rangeIdx
                ? 'inner'
                : rangeIdx < visiblePageRangeIdx
                ? 'outer-left'
                : 'outer-right'

            return (
              <div
                key={`pagination-subcontainer-${rangeIdx}`}
                className={`${styles['pagination-container']} ${styles[rangePosition]}`}
              >
                {Array(length)
                  .fill(null)
                  .map((_, pIdx) => {
                    const absolutePageIdx = rangeStart + pIdx
                    return (
                      <div
                        key={`pagination-item-${absolutePageIdx}`}
                        className={`${styles['page-item']} ${
                          currentPageIndex === absolutePageIdx
                            ? styles.active
                            : styles.inactive
                        }`}
                        onClick={() =>
                          currentPageIndex !== absolutePageIdx
                            ? onPageChange(absolutePageIdx)
                            : undefined
                        }
                      >
                        {absolutePageIdx + 1}
                      </div>
                    )
                  })}
              </div>
            )
          })}
      </div>
      <div
        key={`pagination-item-forward`}
        className={`${styles['page-item']} ${styles.inactive} ${
          currentPageIndex === pageCount - 1 ? styles.muted : ''
        }`}
        onClick={() =>
          currentPageIndex < pageCount - 1 && onPageChange(currentPageIndex + 1)
        }
      >
        <i className="fas fa-angle-right" />
      </div>
      <div
        key={`pagination-item-fast-forward`}
        className={`${styles['page-item']} ${styles.inactive} ${
          visiblePageRangeIdx === lastPageRangeIdx ? styles.muted : ''
        }`}
        onClick={() =>
          visiblePageRangeIdx < lastPageRangeIdx &&
          setVisiblePageRangeIdx(visiblePageRangeIdx + 1)
        }
      >
        <i className="fas fa-angle-double-right" />
      </div>
    </div>
  )
}
