import { DataProxy, FetchResult } from '@apollo/client'

export const fromMutationResult = <
  T extends Record<string, unknown>,
  V extends { [k in keyof V]: T[M[k]] } & Record<string, unknown>,
  M extends { [k in keyof V]: keyof T }
>(
  fn: (cache: DataProxy, obj: V) => void,
  keysMapping: M,
) => (cache: DataProxy, result: FetchResult<T>): void => {
  const values = Object.keys(keysMapping).reduce(
    (acc, key) => ({
      ...acc,
      [key]: result.data && result.data[keysMapping[key]],
    }),
    {} as V,
  )

  return fn(cache, values)
}
