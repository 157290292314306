import { insertText } from './insert-text'
import {
  blockStyle,
  isMultipleLines,
  multilineStyle,
  orderedList,
} from './md-styles'

export interface MdStylesArgs {
  prefix: string
  suffix: string
  blockPrefix: string
  blockSuffix: string
  multiline: boolean
  replaceNext: string
  prefixSpace: boolean
  scanFor: string
  surroundWithNewlines: boolean
  orderedList: boolean
  trimFirst: boolean
}

export const styleSelectedText = (
  textarea: HTMLTextAreaElement,
  styleArgs: MdStylesArgs,
): void => {
  const text = textarea.value.slice(
    textarea.selectionStart,
    textarea.selectionEnd,
  )
  let result
  if (styleArgs.orderedList) {
    result = orderedList(textarea)
  } else if (styleArgs.multiline && isMultipleLines(text)) {
    result = multilineStyle(textarea, styleArgs)
  } else {
    result = blockStyle(textarea, styleArgs)
  }

  insertText(textarea, result)
}

export const apply = (
  textarea: HTMLTextAreaElement,
  stylesToApply: Partial<MdStylesArgs>,
): void => {
  const defaults: MdStylesArgs = {
    prefix: '',
    suffix: '',
    blockPrefix: '',
    blockSuffix: '',
    multiline: false,
    replaceNext: '',
    prefixSpace: false,
    scanFor: '',
    surroundWithNewlines: false,
    orderedList: false,
    trimFirst: false,
  }

  const style = Object.assign({}, defaults, stylesToApply)

  textarea.focus()
  styleSelectedText(textarea, style)
}
