/** @jsx m */

const { extend } = flarum.core.compat['extend']
const Button = flarum.core.compat['components/Button']
const DiscussionPage = flarum.core.compat['components/DiscussionPage']
const DiscussionControls = flarum.core.compat['utils/DiscussionControls']

import SubscriptionMenu from './components/SubscriptionMenu'

export default function addSubscriptionControls() {
  extend(
    DiscussionControls,
    'userControls',
    function (items, discussion, context) {
      if (app.session.user && !(context instanceof DiscussionPage)) {
        const states = {
          none: {
            label: app.translator.trans(
              'flarum-subscriptions.forum.discussion_controls.follow_button',
            ),
            icon: 'fas fa-bookmark',
            save: 'follow',
          },
          follow: {
            label: app.translator.trans(
              'flarum-subscriptions.forum.discussion_controls.unfollow_button',
            ),
            icon: 'far fa-bookmark',
            save: false,
          },
          ignore: {
            label: app.translator.trans(
              'flarum-subscriptions.forum.discussion_controls.unignore_button',
            ),
            icon: 'fas fa-eye',
            save: false,
          },
        }

        const subscription = discussion.subscription() || 'none'

        items.add(
          'subscription',
          Button.component({
            children: states[subscription].label,
            icon: states[subscription].icon,
            onclick: discussion.save.bind(discussion, {
              subscription: states[subscription].save,
            }),
          }),
        )
      }
    },
  )

  extend(DiscussionPage.prototype, 'sidebarItems', function (items) {
    if (app.session.user) {
      const discussion = this.discussion

      items.add('subscription', SubscriptionMenu.component({ discussion }))
    }
  })
}
