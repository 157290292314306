import { Fragment, FunctionalComponent, h } from 'preact'
import {
  useMatomo,
  createInstance as createMatomoInstance,
  MatomoProvider,
} from '@datapunt/matomo-tracker-react'
import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'preact/hooks'

import { CurrentUser } from 'store/local/user'
import { CurrentRouterUrl } from 'store/local/navigation'

const analytics = createMatomoInstance({
  urlBase: process.env.ANALYTICS_SERVER_URL as string,
  siteId: process.env.ANALYTICS_SITE_ID ? +process.env.ANALYTICS_SITE_ID : 0,
  disabled: process.env.ANALYTICS_ENABLED !== 'true',
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: process.env.PROTOCOL === 'https',
    setRequestMethod: 'POST',
  },
})

const MatomoAnalytics: FunctionalComponent = ({ children }) => {
  const { enableLinkTracking, pushInstruction, trackPageView } = useMatomo()
  const user = useReactiveVar(CurrentUser)
  const url = useReactiveVar(CurrentRouterUrl)
  const userId = user?.id
  enableLinkTracking()

  useEffect(() => {
    pushInstruction('setUserId', userId)
    url && trackPageView({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    url && trackPageView({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return <Fragment>{children}</Fragment>
}

export const MatomoHandler: FunctionalComponent = ({ children }) => {
  return (
    <MatomoProvider value={analytics}>
      <MatomoAnalytics>{children}</MatomoAnalytics>
    </MatomoProvider>
  )
}
