import { useQuery } from '@apollo/client'
import { h, FunctionalComponent, Fragment } from 'preact'
import { route } from 'preact-router'

import styles from './styles.scss'
import { Loader } from 'ui/atoms/Loader'
import { getRouteUrl, PageType } from 'common/routing'
import { Button } from 'ui/atoms/Button'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import {
  GetUserResourcesInput,
  GetUserResourcesResult,
  GET_USER_RESOURCES,
} from 'store/operations/resource'
import { ResourceCard } from 'ui/organisms/ResourceCard'

const RESOURCE_LIMIT_PER_REQUEST = 6

export const UserResources: FunctionalComponent<{ userId: string }> = ({
  userId,
}) => {
  const { t } = useContext(TranslateContext)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const {
    data: getUserResourcesData,
    loading: getUserResourcesLoading,
    fetchMore,
  } = useQuery<GetUserResourcesResult, GetUserResourcesInput>(
    GET_USER_RESOURCES,
    {
      variables: { userId, limit: RESOURCE_LIMIT_PER_REQUEST, offset: 0 },
      fetchPolicy: 'cache-and-network',
    },
  )

  return (
    <div className={styles['user-resources']}>
      <Loader
        loaded={!getUserResourcesLoading}
        passingProps={{
          userResources: getUserResourcesData?.getUserResources,
        }}
        className={styles['user-resources-loader']}
      >
        {({ userResources: { resources, count, totalCount } }) => (
          <Fragment>
            <div className={styles['resources-grid']}>
              {resources.map((r) => (
                <ResourceCard
                  key={`resource-item-${r.id}`}
                  className={styles['resource-item']}
                  resource={r}
                  showScore={false}
                  onClick={() =>
                    route(getRouteUrl(PageType.RESOURCE, { id: r.id }))
                  }
                />
              ))}
            </div>
            {totalCount > count && (
              <Button
                style="btn-info"
                className={styles['load-more-btn']}
                onClick={async () => {
                  setIsFetchingMore(true)
                  await fetchMore({
                    variables: {
                      limit: RESOURCE_LIMIT_PER_REQUEST,
                      offset: count,
                      userId,
                    },
                  })
                  setIsFetchingMore(false)
                }}
                loading={isFetchingMore}
                disabled={isFetchingMore}
              >
                {t('core.user_profile.user_resources.load_more_button')}
              </Button>
            )}
            {totalCount === 0 && (
              <div className={styles['no-item']}>
                {t('core.user_profile.user_resources.no_resources')}
              </div>
            )}
          </Fragment>
        )}
      </Loader>
    </div>
  )
}
