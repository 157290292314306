/** @jsx m */

const { extend } = flarum.core.compat['extend']
const IndexPage = flarum.core.compat['components/IndexPage']
const Separator = flarum.core.compat['components/Separator']

import TagLinkButton from './components/TagLinkButton'
import TagCategoryLinkButton from './components/TagCategoryLinkButton'
import TagsPage from './components/TagsPage'
import sortTags from '../common/utils/sortTags'

export default function () {
  // Add a list of all the tags to the index page's sidebar.
  extend(IndexPage.prototype, 'navItems', function (items) {
    if (app.current instanceof TagsPage) return

    items.add('separator', Separator.component(), -10)

    const params = this.stickyParams()
    const tags = app.store.all('tags')
    const currentTags = this.currentTags()

    const addTag = (tag) => {
      const active = currentTags.indexOf(tag) !== -1

      items.add(
        'tag' + tag.id(),
        TagLinkButton.component({
          tag,
          active,
          onclick: () => {
            const newTags = active
              ? currentTags.filter((t) => t !== tag).map((t) => t.slug())
              : currentTags.concat(tag).map((t) => t.slug())

            if (newTags.length) {
              m.route(
                app.route('tag', {
                  tags: newTags.join(','),
                  ...params,
                }),
              )
            } else {
              m.route(app.route('index'))
            }
          },
        }),
        -10,
      )
    }
    // const addCategory = (category) => {
    //   const expandedIndex = app.expandedTagCategories.indexOf(category)
    //   const expanded = expandedIndex !== -1

    //   items.add(
    //     'category' + category.id(),
    //     TagCategoryLinkButton.component({
    //       category,
    //       expanded,
    //       onclick: () => {
    //         if (expanded) {
    //           app.expandedTagCategories.splice(expandedIndex, 1)
    //         } else {
    //           app.expandedTagCategories.push(category)
    //         }
    //       },
    //     }),
    //     -10,
    //   )
    // }

    sortTags(tags)
      .filter(
        (tagOrCategory) =>
          tagOrCategory.isPrimary() ||
          app.expandedTagCategories.indexOf(tagOrCategory.parent()) !== -1,
      )
      .forEach((tagOrCategory) => {
        if (tagOrCategory.isPrimary()) {
          addTag(tagOrCategory)
        } else {
          addTag(tagOrCategory)
        }
      })
  })
}
