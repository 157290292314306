import { gql } from '@apollo/client'
import {
  FileToUpload,
  InitiatedUpload,
  PresignedRequestInfo,
} from '@modbox/s3-uploads-client'

import { UploadType } from 'utils/upload'

import {
  InitiatedUploadFragment,
  PresignedRequestInfoFragment,
} from './fragments/upload'

export type InitiateUploadInput = {
  uploadType: UploadType
  files: FileToUpload[]
}
export type InitiateUploadResult = {
  initiateUpload: InitiatedUpload[]
}
export const INITIATE_UPLOAD = gql`
  query InitiateUpload($uploadType: UploadType!, $files: [FileToUpload!]!) {
    initiateUpload(uploadType: $uploadType, files: $files) {
      ...InitiatedUploadFragment
    }
  }

  ${InitiatedUploadFragment}
`

export type GetPartUploadPresignedRequestInput = {
  uploadType: UploadType
  uploadId: string
  key: string
  partNumber: number
}
export type GetPartUploadPresignedRequestResult = {
  getPartUploadPresignedRequest: PresignedRequestInfo
}
export const GET_PART_UPLOAD_PRESIGNED_REQUEST = gql`
  query GetPartUploadPresignedRequest(
    $uploadType: UploadType!
    $uploadId: String!
    $key: String!
    $partNumber: Int!
  ) {
    getPartUploadPresignedRequest(
      uploadType: $uploadType
      uploadId: $uploadId
      key: $key
      partNumber: $partNumber
    ) {
      ...PresignedRequestInfoFragment
    }
  }

  ${PresignedRequestInfoFragment}
`
