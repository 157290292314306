export const debounce = <Args extends unknown[] = unknown[]>(
  debounceInterval: number,
  fn: (...args: Args) => unknown,
): ((...args: Args) => void) => {
  let timeout: NodeJS.Timeout | null = null

  return (...args: Args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => void fn(...args), debounceInterval)
  }
}
