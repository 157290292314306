import { h, FunctionalComponent } from 'preact'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { RefetchQueriesFunction, useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'preact-router'

import styles from './styles.scss'
import { Badge } from 'ui/atoms/Badge'
import { Post } from 'ui/organisms/Post'
import {
  DeleteDiscussionInput,
  DeleteDiscussionResult,
  DELETE_DISCUSSION,
  GetDiscussionByIdInput,
  GetDiscussionByIdResult,
  GET_DISCUSSION_BY_ID,
  LockDiscussionInput,
  LockDiscussionResult,
  LOCK_DISCUSSION,
  StickDiscussionInput,
  StickDiscussionResult,
  STICK_DISCUSSION,
  UpdateDiscussionSubscriptionInput,
  UpdateDiscussionSubscriptionResult,
  UpdateDiscussionTagsInput,
  UpdateDiscussionTagsResult,
  UpdateDiscussionTitleInput,
  UpdateDiscussionTitleResult,
  UPDATE_DISCUSSION_SUBSCRIPTION,
  UPDATE_DISCUSSION_TAGS,
  UPDATE_DISCUSSION_TITLE,
} from 'store/operations/discussion'
import { DiscussionSubscriptionDropdownButton } from 'ui/organisms/DiscussionSubscriptionDropdownButton'
import { fromEvent, toggleEvent } from 'utils/components'
import { DiscussionReplyAndManageButton } from 'ui/organisms/DiscussionReplyAndManageButton'
import { Loader } from 'ui/atoms/Loader'
import {
  EditorToolbarUpload,
  EditorToolbarUploadState,
} from 'ui/molecules/TextEditorToolbar/ToolbarUpload'
import { PostComposer } from 'ui/organisms/PostComposer'
import {
  AddPostInput,
  AddPostResult,
  ADD_POST,
  DeletePostInput,
  DeletePostResult,
  DELETE_POST,
  EditPostInput,
  EditPostResult,
  EDIT_POST,
  GetDiscussionPostsInput,
  GetDiscussionPostsResult,
  GET_DISCUSSION_POSTS,
} from 'store/operations/post'
import { InfiniteScroll } from 'ui/atoms/InfiniteScroll'
import { debounce } from 'utils/async'
import { Button } from 'ui/atoms/Button'
import { FlagPostModal } from 'ui/organisms/FlagPostModal'
import { getRouteUrl, PageType } from 'common/routing'
import { getApp } from 'legacy/app'
import TagDiscussionModal from 'legacy/src/extensions/tags/js/src/forum/components/TagDiscussionModal'
import { BackButton } from 'ui/molecules/BackButton'
import { useAlerts } from 'services/AlertManager'
import { modalContent, useModal } from 'services/ModalManager'
import { ConfirmDiscussionDeletionModal } from 'ui/organisms/ConfirmDiscussionDeletionModal'

const POST_LIMIT_PER_REQUEST = 30
const SCROLL_INTO_POST_OFFSET = 150
const getPostElementId = (discussionId: string, postNumber: number): string =>
  `${discussionId}-post-number-${postNumber}`

export const DiscussionPage: FunctionalComponent = () => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()
  const [{ matches }, route] = useRouter<{
    id: string
    near?: string
    moderatedPostNumber?: string
    moderatedFlagId?: string
  }>()

  const renameInputRef = useRef<HTMLInputElement | null>(null)
  const [newPost, setNewPost] = useState('')
  const [uploads, setUploads] = useState<EditorToolbarUpload[]>([])
  const [showComposer, setShowComposer] = useState(false)
  const [editingPostId, setEditingPostId] = useState<string | null>(null)
  const [renameValue, setRenameValue] = useState<string>('')
  const [isRenaming, setIsRenaming] = useState(false)
  const [postStreamLoading, setPostStreamLoading] = useState({
    previous: false,
    next: false,
  })

  // This is to flush history state when component is unmounted (page reload for example)
  useEffect(() => history.replaceState({ shouldScrollToPost: true }, ''), [])
  // This get the 'shouldScrollToPost' prop from history state
  // Using history state allow to see when routing comes from url rewriting on this page or from
  // a redirect from another component even if this page is loaded
  // (ex: from the notification dropdown while being on this page)
  const shouldScrollToPost =
    (history.state as { shouldScrollToPost?: boolean } | undefined)
      ?.shouldScrollToPost ?? true

  const discussionIdUrlParam = matches.id
  const discussionId = discussionIdUrlParam.split('-')[0]
  const moderationMode =
    !!matches.moderatedPostNumber && !!matches.moderatedFlagId
  const nearFromUrl = moderationMode
    ? +(matches.moderatedPostNumber as string)
    : matches.near
    ? +matches.near
    : undefined
  const { data: getDiscussion, loading, error } = useQuery<
    GetDiscussionByIdResult,
    GetDiscussionByIdInput
  >(GET_DISCUSSION_BY_ID, {
    variables: {
      id: discussionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const {
    data: getPosts,
    loading: postsLoading,
    error: postsError,
    fetchMore,
  } = useQuery<GetDiscussionPostsResult, GetDiscussionPostsInput>(
    GET_DISCUSSION_POSTS,
    {
      variables: {
        discussionId,
        near: shouldScrollToPost ? nearFromUrl : undefined,
        limit: POST_LIMIT_PER_REQUEST,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  )
  const [addPost, { loading: addPostLoading }] = useMutation<
    AddPostResult,
    AddPostInput
  >(ADD_POST)
  const [editPost, { loading: editPostLoading }] = useMutation<
    EditPostResult,
    EditPostInput
  >(EDIT_POST)
  const [deletePost] = useMutation<DeletePostResult, DeletePostInput>(
    DELETE_POST,
  )
  const [deleteDiscussion] = useMutation<
    DeleteDiscussionResult,
    DeleteDiscussionInput
  >(DELETE_DISCUSSION, {
    variables: { discussionId },
    onCompleted: () => {
      route(getRouteUrl(PageType.DISCUSSIONS))
    },
  })
  const [
    updateDiscussionTitle,
    { loading: discussionTitleUpdateLoading },
  ] = useMutation<UpdateDiscussionTitleResult, UpdateDiscussionTitleInput>(
    UPDATE_DISCUSSION_TITLE,
  )
  const [updateDiscussionTags] = useMutation<
    UpdateDiscussionTagsResult,
    UpdateDiscussionTagsInput
  >(UPDATE_DISCUSSION_TAGS)
  const [lockDiscussion] = useMutation<
    LockDiscussionResult,
    LockDiscussionInput
  >(LOCK_DISCUSSION)
  const [stickDiscussion] = useMutation<
    StickDiscussionResult,
    StickDiscussionInput
  >(STICK_DISCUSSION)
  const [updateSubscription] = useMutation<
    UpdateDiscussionSubscriptionResult,
    UpdateDiscussionSubscriptionInput
  >(UPDATE_DISCUSSION_SUBSCRIPTION)

  const [flagPostModal] = useModal(modalContent(FlagPostModal), {
    size: 'medium',
    title: t('core.community.post.flag_post.title'),
    className: 'FlagPostModal',
  })
  const [deleteDiscussionModal] = useModal(
    modalContent(ConfirmDiscussionDeletionModal),
    {
      size: 'small',
      title: t('core.community.discussion_page.delete_modal.title'),
      onConfirm: deleteDiscussion,
    },
  )
  useEffect(() => {
    // If post is loading, we don't want to trigger scroll to post yet
    if (postsLoading) {
      return
    }

    // Near param is returned from the server when it was included in the query
    // It is used to scroll to a specific post when the page is loaded
    const nearFromServer = getPosts?.getDiscussionPosts.near
    // const discussionId = getDiscussion?.getDiscussionById.id

    if (discussionId && nearFromServer && shouldScrollToPost) {
      const elemId = `${
        moderationMode ? 'moderation-flag-' : ''
      }${getPostElementId(discussionId, nearFromServer)}`
      const elem = document.getElementById(elemId)
      if (!elem) {
        // In case element is not found on the dom, we don't want to scroll to it
        // This should never happen, but it's better to be safe than sorry
        history.replaceState({ shouldScrollToPost: false }, '')
        return
      }

      const { top } = elem.getBoundingClientRect()
      const scrollToPosition =
        top + window.pageYOffset - SCROLL_INTO_POST_OFFSET
      window.scrollTo({
        behavior: 'smooth',
        top: scrollToPosition,
      })
      debounce(200, () => {
        history.replaceState({ shouldScrollToPost: false }, '')
      })()
    } else if (nearFromServer === null && shouldScrollToPost) {
      history.replaceState({ shouldScrollToPost: false }, '')
    }
  }, [discussionId, getPosts, moderationMode, postsLoading, shouldScrollToPost])

  useEffect(() => {
    if (isRenaming) {
      renameInputRef.current?.focus()
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
    }
  }, [isRenaming])

  if (!getDiscussion || !getPosts) {
    return (
      <section className={`${styles['page-section']} ${styles.loading}`}>
        <Loader loaded={!loading && !!error}>
          {t('core.lib.error.generic_message')}
        </Loader>
      </section>
    )
  }
  const discussion = getDiscussion.getDiscussionById
  const { posts, offset, totalCount, count } = getPosts.getDiscussionPosts

  const closeComposer = (): void => {
    setNewPost('')
    setUploads([])
    setEditingPostId(null)
    setShowComposer(false)
  }

  const getRefetchParams: RefetchQueriesFunction = (near?: number) => [
    {
      query: GET_DISCUSSION_POSTS,
      variables: {
        discussionId,
        limit: POST_LIMIT_PER_REQUEST,
        offset,
        near,
      },
    },
  ]

  const onUploadRemove = (toBeRemoved: EditorToolbarUpload): void =>
    setUploads((state) => {
      const idx = state.findIndex((u) => u === toBeRemoved)

      const newState =
        toBeRemoved.state === EditorToolbarUploadState.Idle && idx !== -1
          ? [
              ...state.slice(0, idx),
              {
                ...toBeRemoved,
                state: EditorToolbarUploadState.Removed,
              } as EditorToolbarUpload<EditorToolbarUploadState.Removed>,
              ...state.slice(idx + 1),
            ]
          : // TODO: Here we should handle upload abortion as well
            state.filter((u) => u !== toBeRemoved)

      return newState
    })

  const onSend = async (): Promise<void> => {
    const commonVariables = {
      markdownContent: newPost,
    }

    // Filter only completed upload to add. At this point all should be completed
    // as ensured by the PostComposer component.
    const filesToAdd = uploads
      .filter(
        (
          u,
        ): u is Extract<
          EditorToolbarUpload,
          { state: EditorToolbarUploadState.Uploaded }
        > => u.state === EditorToolbarUploadState.Uploaded,
      )
      .map((u) => ({ ...u.file, __typename: undefined }))
    // Filter deleted files.
    const filesToDelete = uploads
      .filter(
        (
          u,
        ): u is Extract<
          EditorToolbarUpload,
          { state: EditorToolbarUploadState.Removed }
        > => u.state === EditorToolbarUploadState.Removed,
      )
      .map((u) => ({ ...u.file, __typename: undefined }))

    try {
      if (editingPostId) {
        await editPost({
          variables: {
            ...commonVariables,
            filesToAdd,
            filesToDelete,
            postId: editingPostId,
          },
        })
      } else {
        await addPost({
          variables: { ...commonVariables, files: filesToAdd, discussionId },
          refetchQueries: () => {
            return getRefetchParams()
          },
        })
      }
      closeComposer()
    } catch (e: unknown) {
      showAlert({ message: (e as Error).message, type: 'error' })
    }
  }

  const onLoadMore = (direction: 'next' | 'previous'): void => {
    // Do not load more if we are waiting to scroll to a post
    const shouldScrollToPost =
      (history.state as { shouldScrollToPost?: boolean } | undefined)
        ?.shouldScrollToPost ?? true
    if (shouldScrollToPost) {
      return
    }

    const execLoadMore = debounce(800, (direction: 'next' | 'previous') => {
      void fetchMore({
        variables: {
          offset: direction === 'next' ? offset + count : offset,
          direction,
        },
      }).then(() =>
        setPostStreamLoading({
          ...postStreamLoading,
          [direction]: false,
        }),
      )
    })

    setPostStreamLoading({ ...postStreamLoading, [direction]: true })
    execLoadMore(direction)
  }

  const onScrollItemIndexChange = (idx: number): void => {
    const postNumber = posts[idx] && posts[idx].number
    const newUrl = getRouteUrl(PageType.DISCUSSION, {
      id: discussionIdUrlParam,
      near: `${postNumber}`,
    })

    history.replaceState(history.state, '', newUrl)
  }

  return (
    <section className={styles['page-section']}>
      <div className={styles.hero}>
        <div className="container">
          <div className={styles.badges}>
            {discussion.tags.map((t, idx) => (
              <Badge key={`tag-badge-${t.slug}-${idx}`} value={t.name} />
            ))}
          </div>
          {!isRenaming ? (
            <div className={styles.title}>{discussion.title}</div>
          ) : (
            <div className={`${styles.title} ${styles.editing}`}>
              <form className="Form">
                <div className="Form-group">
                  <input
                    ref={renameInputRef}
                    type="text"
                    value={renameValue}
                    onInput={fromEvent(setRenameValue)}
                    className={`FormControl ${styles['form-item']}`}
                  />
                  <Button
                    size="sm"
                    className={styles['form-item']}
                    padding="md"
                    loading={discussionTitleUpdateLoading}
                    onClick={() =>
                      updateDiscussionTitle({
                        variables: { id: discussion.id, title: renameValue },
                        refetchQueries: getRefetchParams(),
                      }).then(() => {
                        setIsRenaming(false)
                        setRenameValue('')
                      })
                    }
                  >
                    {t('core.generic.button.edit_label')}
                  </Button>
                  <Button
                    size="sm"
                    className={`${styles['form-item']} ${styles['cancel-btn']}`}
                    padding="md"
                    style="link"
                    onClick={() => {
                      setIsRenaming(false)
                      setRenameValue('')
                    }}
                  >
                    {t('core.generic.button.cancel_label')}
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      <div className={`${styles.content} container`}>
        <div className={styles.posts}>
          <InfiniteScroll
            keepScrollPosition
            hasMore={{
              previous: !isRenaming && offset !== 0,
              next: !isRenaming && totalCount - offset > count,
            }}
            loading={postStreamLoading}
            onLoadMore={onLoadMore}
            onScrollItemIndexChange={
              !moderationMode
                ? debounce(200, onScrollItemIndexChange)
                : undefined
            }
          >
            {posts?.map((p) => (
              <Post
                elementId={getPostElementId(discussion.id, p.number)}
                post={p}
                canReply={discussion.canPost}
                className={styles.post}
                key={getPostElementId(discussion.id, p.number)}
                moderationFlagId={
                  moderationMode &&
                  p.number.toString() === matches.moderatedPostNumber
                    ? matches.moderatedFlagId
                    : undefined
                }
                onEvent={{
                  edit: () => {
                    setNewPost(p.markdownContent)
                    setUploads(
                      (p.uploads || []).map((file) => ({
                        state: EditorToolbarUploadState.Idle,
                        file,
                      })),
                    )

                    setEditingPostId(p.id)
                    setShowComposer(true)
                  },
                  delete: () => deletePost({ variables: { postId: p.id } }),
                  flag: () => flagPostModal.show({ postId: p.id }),
                  reply: () => {
                    setNewPost(
                      t('core.community.discussion_page.reply_prefix', {
                        user:
                          p.author?.username ??
                          t('core.community.post.deleted_user'),
                      }),
                    )
                    setShowComposer(true)
                  },
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
        <div className={styles.aside}>
          <div className={styles.sticky}>
            <DiscussionReplyAndManageButton
              sticky={discussion.isSticky}
              locked={discussion.isLocked}
              onPost={() => setShowComposer(true)}
              onRename={() => {
                setIsRenaming(true)
                setRenameValue(discussion.title)
              }}
              onTagEdit={() =>
                getApp().modal.show(
                  new (TagDiscussionModal as {
                    new (args: {
                      selectedTagIds: string[]
                      onEdit: (tagIds: string[]) => void
                    }): void
                  })({
                    selectedTagIds: discussion.tags.map((t) => t.id),
                    onEdit: (tagIds: string[]) =>
                      void updateDiscussionTags({
                        variables: { discussionId, tagIds },
                        refetchQueries: getRefetchParams(),
                      }),
                  }),
                )
              }
              onLock={toggleEvent(
                (lock) =>
                  void lockDiscussion({
                    variables: {
                      id: discussionId,
                      lock,
                    },
                    refetchQueries: getRefetchParams(),
                  }),
                discussion.isLocked,
              )}
              onStick={toggleEvent(
                (stick) =>
                  void stickDiscussion({
                    variables: {
                      id: discussionId,
                      stick,
                    },
                    refetchQueries: getRefetchParams(),
                  }),
                discussion.isSticky,
              )}
              onDelete={deleteDiscussionModal.show}
              permissions={{
                canPost: discussion.canPost,
                canDelete: discussion.canDelete,
                canManage: discussion.canManage,
                canUpdate: discussion.canUpdate,
              }}
            />
            <DiscussionSubscriptionDropdownButton
              subscription={discussion.subscription}
              locked={discussion.isLocked}
              canSubscribe={discussion.canSubscribe}
              onSubscriptionChange={(subscription) =>
                updateSubscription({
                  variables: {
                    discussionId,
                    subscription,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <PostComposer
        values={{ text: newPost, uploads: uploads }}
        onChange={{ text: setNewPost, uploads: setUploads }}
        onUploadRemove={onUploadRemove}
        onDismiss={closeComposer}
        placeholder={t('core.community.discussion_page.composer_placeholder')}
        show={showComposer}
        title={discussion.title}
        onSend={onSend}
        loading={editPostLoading || addPostLoading}
      />
      <BackButton />
    </section>
  )
}

export default DiscussionPage
