import { Fragment, FunctionalComponent, h } from 'preact'

import styles from './styles.scss'
import { fromEvent } from 'utils/components'
import {
  getAllTagsFromTagsCategories,
  getTagAndAncestorsByCategory,
  getTagChildren,
  getTagParent,
} from 'utils/tags'
import { TagsCategoryWithTags } from 'types/tagsCategory'

export const TeacherTagsSelect: FunctionalComponent<{
  tagNodeId: string | null
  categoriesWithTags: TagsCategoryWithTags[]
  onChange: (tagNodeId: string | null) => void
  isDisabled?: boolean
}> = ({
  tagNodeId,
  categoriesWithTags,
  onChange,
  isDisabled: isGloballyDisabled = false,
}) => {
  const allTags = getAllTagsFromTagsCategories(categoriesWithTags)
  const currentCategoryIdx = categoriesWithTags.findIndex(
    (c) => c.id === getTagChildren(allTags, tagNodeId)[0]?.category.id,
  )
  const currentPosition =
    currentCategoryIdx !== -1
      ? currentCategoryIdx
      : tagNodeId
      ? categoriesWithTags.length - 1
      : 0

  const selectedTagsByCategory =
    categoriesWithTags && tagNodeId
      ? getTagAndAncestorsByCategory(categoriesWithTags, tagNodeId)
      : {}

  return (
    <Fragment>
      {categoriesWithTags
        .filter((c) => c.isForUser)
        .map((category, cIdx) => {
          const selectedTag = selectedTagsByCategory[category.id]
          const tags =
            !tagNodeId || selectedTag?.parentId === null
              ? category.tags.filter((t) => t.parentId === null)
              : getTagChildren(
                  category.tags,
                  selectedTag?.parentId || tagNodeId,
                )

          const isDisabled =
            isGloballyDisabled || (!tags.length && !selectedTag)

          if (isDisabled && currentPosition > cIdx) {
            return null
          }

          return (
            <div
              key={`tags-category-${category.slug}`}
              className={`Form-group ${styles['select-wrapper']}`}
            >
              <select
                className={`${styles.select} FormControl ${
                  selectedTag ? styles.selected : ''
                }`}
                name={`${category.slug}-select`}
                onChange={fromEvent((value) => {
                  if (!value && selectedTag) {
                    return onChange(
                      getTagParent(
                        getAllTagsFromTagsCategories(categoriesWithTags),
                        selectedTag.id,
                      )?.id || null,
                    )
                  }

                  onChange(value || null)
                })}
                disabled={isDisabled}
              >
                <option value="">{category.name}</option>
                {tags.map((tag) => (
                  <option
                    key={`tag-${tag.slug}`}
                    value={tag.id}
                    selected={tag.id === selectedTag?.id}
                  >
                    {tag.name}
                  </option>
                ))}
              </select>
            </div>
          )
        })}
    </Fragment>
  )
}
