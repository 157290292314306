import { h, FunctionalComponent, RefObject } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import styles from './styles.scss'

export interface TooltipProps {
  children?: string | h.JSX.Element | h.JSX.Element[]
  className?: string
  content?: TooltipProps['children']
  position?: 'top' | 'left' | 'right' | 'bottom'
  width?: 'fit-content' | 'fit-parent'
  for: RefObject<HTMLElement | null>
  forceVisible?: boolean
}

export const Tooltip: FunctionalComponent<TooltipProps> = (props) => {
  const {
    children,
    className = '',
    content,
    position = 'top',
    width: widthParam = 'fit-parent',
    for: forElement,
    forceVisible = false,
  } = props

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!forElement.current) {
      return
    }

    forElement.current.onmouseenter = () => setVisible(true)
    forElement.current.onmouseleave = () => setVisible(false)
  }, [forElement])

  if (!forElement.current) {
    return <div className={styles['tooltip-parent']} />
  }

  const {
    offsetTop: top,
    offsetLeft: left,
    offsetHeight: height,
    offsetWidth: width,
  } = forElement.current

  const tooltipContent = content || children

  return (
    <div
      className={styles['tooltip-parent']}
      style={{
        top: `${top}px`,
        left: `${left}px`,
        height: `${height}px`,
        width: `${width}px`,
      }}
    >
      <span
        className={`${className} ${styles.tooltip} ${styles[position]} ${
          styles[widthParam]
        } ${visible || forceVisible ? styles.visible : ''}`}
      >
        {tooltipContent}
      </span>
    </div>
  )
}
