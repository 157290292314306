/** @jsx m */

const { extend } = flarum.core.compat['extend']
const LinkButton = flarum.core.compat['components/LinkButton']
const IndexPage = flarum.core.compat['components/IndexPage']
const DiscussionList = flarum.core.compat['components/DiscussionList']

export default function addSubscriptionFilter() {
  extend(IndexPage.prototype, 'navItems', function (items) {
    if (app.session.user) {
      const params = this.stickyParams()

      params.filter = 'following'

      items.add(
        'following',
        LinkButton.component({
          href: app.route('index.filter', params),
          children: app.translator.trans(
            'flarum-subscriptions.forum.index.following_link',
          ),
          icon: 'fas fa-bookmark',
        }),
        50,
      )
    }
  })

  extend(DiscussionList.prototype, 'requestParams', function (params) {
    if (this.props.params.filter === 'following') {
      params.filter.q = (params.filter.q || '') + ' is:following'
    }
  })
}
