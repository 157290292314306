import Resizer from 'react-image-file-resizer'

import { FileType, FILE_INFO } from './constants'
import { StoredS3Object } from 'types/file'

const entries = Object.entries as <T>(
  o: T,
) => Array<[Extract<keyof T, string>, T[keyof T]]>

export const getFileType = (mimetype: string): FileType | null => {
  const found = entries(FILE_INFO).find(([, { mime }]) =>
    mime.split(',').includes(mimetype),
  )

  if (!found) {
    return null
  }

  return found[0]
}

export const getFileIcon = (mimetype: string): string => {
  const filetype = getFileType(mimetype)

  if (!filetype) {
    return ''
  }

  return FILE_INFO[filetype].icon
}

export const getResourceFilesDownloadUrl = (
  resourceId: string,
  token?: string,
): string => {
  return `${process.env.PROTOCOL || ''}://${
    process.env.PUBLIC_URL || ''
  }/download/all-resource-files/${resourceId}${
    token ? `?token=${encodeURIComponent(token)}` : ''
  }`
}

export const getFilePreviewUrl = (file: StoredS3Object): string => {
  const type = getFileType(file.mimetype)

  if (!type) {
    return ''
  }

  return FILE_INFO[type].getPreviewUrl(file.url)
}

export const resizeImageFile = (
  file: File,
  {
    maxWidth,
    maxHeight,
    minWidth = 0,
    minHeight = 0,
    compressType = 'JPEG',
  }: {
    maxWidth: number
    maxHeight: number
    minWidth?: number
    minHeight?: number
    compressType?: 'JPEG' | 'PNG' | 'WEBP'
  },
): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        compressType,
        100,
        0,
        (uri) => resolve(uri as File),
        'file',
        minWidth,
        minHeight,
      )
    } catch (e) {
      reject(e)
    }
  })
}
