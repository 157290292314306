import { useMutation, useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent, Fragment } from 'preact'
import { useContext, useRef, useState } from 'preact/hooks'
import { hasNonNullableKeys } from '@modbox/ts-utils'

import {
  TogglePostLikeInput,
  TogglePostLikeResult,
  TOGGLE_POST_LIKE,
} from 'store/operations/post'
import { Post as PostType } from 'types/post'
import { Avatar } from 'ui/atoms/Avatar'
import { Button } from 'ui/atoms/Button'
import { DropdownItem } from 'ui/atoms/DropdownItem'
import { DropdownSeparator } from 'ui/atoms/DropdownSeparator'
import { PostLikes } from 'ui/organisms/PostLikes'
import { DropdownButton } from 'ui/molecules/DropdownButton'
import styles from './styles.scss'
import mdStyles from 'ui/quarks/Markdown.scss'
import { FileItem } from 'ui/molecules/FileItem'
import { DeletedPost } from './DeletedPost'
import { EventPost } from './EventPost'
import { CurrentUser } from 'store/local/user'
import { formatDateAsDelay } from 'utils/date'
import { route } from 'preact-router'
import { getRouteUrl, PageType } from 'common/routing'
import { ModerationFlag } from 'ui/organisms/ModerationFlag'

export const Post: FunctionalComponent<{
  elementId?: string
  className?: string
  post: PostType
  canReply?: boolean
  canLike?: boolean
  noActions?: boolean
  moderationFlagId?: string
  onEvent?: {
    delete?: () => void
    reply?: () => void
    edit?: () => void
    flag?: () => void
  }
}> = ({
  className = '',
  post,
  onEvent = {},
  elementId,
  canReply = true,
  canLike = true,
  noActions = false,
  moderationFlagId,
}) => {
  const { t } = useContext(TranslateContext)

  const [dropdownExpanded, setDropdownExpanded] = useState(false)
  const expandButtonRef = useRef<HTMLDivElement>(null)

  const authenticatedUser = useReactiveVar(CurrentUser)
  const [toggleLike] = useMutation<TogglePostLikeResult, TogglePostLikeInput>(
    TOGGLE_POST_LIKE,
    { variables: { id: post.id } },
  )

  const {
    delete: onDelete,
    edit: onEdit,
    reply: onReply,
    flag: onFlag,
  } = onEvent

  const createdAtLabel = formatDateAsDelay(t, post.createdAt)
  const likedByMe =
    !!authenticatedUser &&
    post.likedByUsers.some((u) => u.id === authenticatedUser.id)

  const moderationFlag = !!moderationFlagId && (
    <ModerationFlag
      className={styles['moderation-flag']}
      flagId={moderationFlagId}
      elementId={elementId && `moderation-flag-${elementId}`}
      onFlagReviewed={() =>
        route(
          getRouteUrl(PageType.DISCUSSION, {
            id: post.discussionId.toString(),
            near: post.number.toString(),
          }),
        )
      }
    />
  )

  if (hasNonNullableKeys(post, ['deletedAt', 'deletedByUsername'])) {
    return (
      <Fragment>
        {moderationFlag}
        <DeletedPost
          elementId={elementId}
          post={post}
          className={`${className} ${
            moderationFlagId ? styles['with-moderation-flag'] : ''
          }`}
          createdAtLabel={createdAtLabel}
        />
      </Fragment>
    )
  }
  if (post.type !== 'comment') {
    return (
      <Fragment>
        {moderationFlag}
        <EventPost
          elementId={elementId}
          post={
            post as PostType & { type: Exclude<PostType['type'], 'comment'> }
          }
          className={`${className} ${
            moderationFlagId ? styles['with-moderation-flag'] : ''
          }`}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      {moderationFlag}
      <div
        className={`${styles['post-wrapper']} ${className} ${
          moderationFlagId ? styles['with-moderation-flag'] : ''
        }`}
        id={elementId}
      >
        <div className={`${styles.post} ${mdStyles.markdown}`}>
          <Avatar
            size="lg"
            url={post.author?.avatarUrl}
            username={post.author?.username}
            goToProfileOnClick={!noActions}
          />
          <div className={styles['post-content']}>
            <div className={styles.header}>
              <span
                className={`${styles.username} ${
                  !noActions ? styles.clickable : ''
                }`}
                onClick={
                  !noActions
                    ? () =>
                        post.author?.username &&
                        route(
                          getRouteUrl(PageType.USER_PROFILE, {
                            username: post.author.username,
                          }),
                        )
                    : undefined
                }
              >
                {post.author?.username || t('core.community.post.deleted_user')}
              </span>
              <span className={styles.date}>
                {createdAtLabel}
                {post.editedAt
                  ? ` (${t('core.community.post.edited_post_label')})`
                  : ''}
              </span>
            </div>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: post.content }}
            />

            {post.uploads && (
              <div className={styles.uploads}>
                {post.uploads.map((u, idx) => (
                  <FileItem
                    file={u}
                    key={`post-${post.id}-attachment-${idx}`}
                    canDownload
                    onAction={{
                      download: () => window.open(u.url, '_blank'),
                    }}
                    onClickAction="download"
                  />
                ))}
              </div>
            )}
          </div>
          <PostLikes
            className={styles['post-likes']}
            likedByUsers={post.likedByUsers}
          />
          {!noActions && (
            <div
              className={`${styles['post-actions']} ${
                dropdownExpanded ? styles.active : ''
              }`}
            >
              {canLike && (
                <Button
                  size="sm"
                  padding="md"
                  style="link"
                  onClick={post.canLike ? () => toggleLike() : undefined}
                >
                  {t(`core.community.post.${likedByMe ? 'un' : ''}like_button`)}
                </Button>
              )}
              {canReply && onReply && (
                <Button size="sm" padding="md" style="link" onClick={onReply}>
                  {t('core.community.post.reply_button')}
                </Button>
              )}
              {post.canDelete || post.canUpdate || post.canFlag ? (
                <DropdownButton
                  onExpandChange={setDropdownExpanded}
                  for={expandButtonRef}
                  align="right"
                  inline
                  buttons={[
                    <Button
                      ref={expandButtonRef}
                      key="post-edit-btn-2"
                      active={dropdownExpanded}
                      style="link-primary-faded-hover"
                      padding="sm"
                      size="sm"
                      iconOnly
                      rounded
                    >
                      <i className={'fas fa-ellipsis-h'} />
                    </Button>,
                  ]}
                >
                  {post.canFlag && (
                    <DropdownItem
                      hasIcon
                      iconClassName="fas fa-flag"
                      onClick={onFlag}
                    >
                      {t('core.community.post.edit_dropdown.flag_button')}
                    </DropdownItem>
                  )}
                  {post.canFlag && (post.canUpdate || post.canDelete) && (
                    <DropdownSeparator />
                  )}
                  {post.canUpdate && (
                    <DropdownItem
                      hasIcon
                      iconClassName="fas fa-pen"
                      onClick={onEdit}
                    >
                      {t('core.community.post.edit_dropdown.edit_button')}
                    </DropdownItem>
                  )}
                  {post.canUpdate && post.canDelete && <DropdownSeparator />}
                  {post.canDelete && (
                    <DropdownItem
                      hasIcon
                      iconClassName="fas fa-trash-alt"
                      onClick={onDelete}
                    >
                      {t('core.community.post.edit_dropdown.delete_button')}
                    </DropdownItem>
                  )}
                </DropdownButton>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}
