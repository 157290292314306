/** @jsx m */

const Notification = flarum.core.compat['components/Notification']

export default class DiscussionLockedNotification extends Notification {
  icon() {
    return 'fas fa-lock'
  }

  href() {
    const notification = this.props.notification

    return app.route.discussion(
      notification.subject(),
      notification.content().postNumber,
    )
  }

  content() {
    return app.translator.trans(
      'flarum-lock.forum.notifications.discussion_locked_text',
      { user: this.props.notification.fromUser() },
    )
  }
}
