import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useRouter } from 'preact-router'
import { useContext } from 'preact/hooks'

import styles from './styles.scss'
import { getRoutePath, getRouteUrl, PageType } from 'common/routing'

type UserPageType =
  | PageType.USER_PROFILE_RESOURCES
  | PageType.USER_PROFILE_POSTS
  | PageType.USER_PROFILE_DISCUSSIONS
  | PageType.USER_PROFILE_SETTINGS

export const UserNav: FunctionalComponent<{ showSettings: boolean }> = ({
  showSettings,
}) => {
  const { t } = useContext(TranslateContext)
  const [
    {
      matches: { username },
      path,
    },
    route,
  ] = useRouter<{ username: string }>()

  const routeTo = (page: UserPageType) => () =>
    route(getRouteUrl(page, { username }))
  const isActive = (page: UserPageType): boolean => getRoutePath(page) === path

  return (
    <div className={styles['user-nav']}>
      <ul className={`${styles.menu}`}>
        <li
          className={`${styles['item-container']} ${styles.hasIcon} ${
            isActive(PageType.USER_PROFILE_RESOURCES) ? styles.active : ''
          }`}
          onClick={routeTo(PageType.USER_PROFILE_RESOURCES)}
        >
          <i className={`${styles.icon} fas fa-book-open`} />
          <div className={styles.content}>
            {t('core.user_profile.nav.resources_link')}
          </div>
        </li>
        <li
          className={`${styles['item-container']} ${styles.hasIcon} ${
            isActive(PageType.USER_PROFILE_POSTS) ? styles.active : ''
          }`}
          onClick={routeTo(PageType.USER_PROFILE_POSTS)}
        >
          <i className={`${styles.icon} far fa-comment`} />
          <div className={styles.content}>
            {t('core.user_profile.nav.posts_link')}
          </div>
        </li>
        <li
          className={`${styles['item-container']} ${styles.hasIcon} ${
            isActive(PageType.USER_PROFILE_DISCUSSIONS) ? styles.active : ''
          }`}
          onClick={routeTo(PageType.USER_PROFILE_DISCUSSIONS)}
        >
          <i className={`${styles.icon} fas fa-bars`} />
          <div className={styles.content}>
            {t('core.user_profile.nav.discussions_link')}
          </div>
        </li>
        <li className={styles['separator-item']} />
        {showSettings && (
          <li
            className={`${styles['item-container']} ${styles.hasIcon} ${
              isActive(PageType.USER_PROFILE_SETTINGS) ? styles.active : ''
            }`}
            onClick={routeTo(PageType.USER_PROFILE_SETTINGS)}
          >
            <i className={`${styles.icon} fas fa-cog`} />
            <div className={styles.content}>
              {t('core.user_profile.nav.settings_link')}
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}
