import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import { getGraphQLErrors } from 'utils/components'
import { Button } from 'ui/atoms/Button'
import {
  ForgotPasswordResult,
  ForgotPasswordInput,
  FORGOT_PASSWORD,
} from 'store/operations/user'
import { ModalContent } from 'services/ModalManager'
import { useAlerts } from 'services/AlertManager'

export const ChangePasswordModal: ModalContent<{ email: string }> = ({
  onDismiss,
  email,
}) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()

  const [updatePassword, { loading, error, called }] = useMutation<
    ForgotPasswordResult,
    ForgotPasswordInput
  >(FORGOT_PASSWORD, {
    onError: (e) => {
      showAlert({ type: 'error', message: getGraphQLErrors([e])[0] })
    },
  })
  // Dismiss on success
  called && !loading && !error && onDismiss()

  return (
    <div className="Modal-body">
      {
        <div className="Form Form--centered">
          <p className="helpText">
            {t('core.user_profile.settings.change_password.text')}
          </p>
          <div className="Form-group">
            <Button
              loading={loading}
              onClick={() =>
                void updatePassword({ variables: { email, update: true } })
              }
            >
              {t('core.user_profile.settings.change_password.send_button')}
            </Button>
          </div>
        </div>
      }
    </div>
  )
}
