import { Fragment, FunctionalComponent, h } from 'preact'

import { useRef } from 'preact/compat'
import { Tooltip } from '../Tooltip'
import styles from './styles.scss'

interface NumericBadgePropsInterface {
  className?: string
  value: number
  limit?: number
}

export const NumericBadge: FunctionalComponent<NumericBadgePropsInterface> = (
  props,
) => {
  const { value, className, limit = Infinity } = props
  const elemRef = useRef<HTMLDivElement>()

  const displayTooltip = value > limit
  const displayedValue = displayTooltip ? `${limit}+` : value.toString()

  return (
    <Fragment>
      <div
        ref={elemRef}
        className={`${className || ''} ${styles['numeric-badge']}`}
      >
        <span className={styles.label}>{displayedValue}</span>
      </div>
      {displayTooltip && (
        <Tooltip
          content={value.toString()}
          position="right"
          width="fit-content"
          for={elemRef}
        />
      )}
    </Fragment>
  )
}
