import { h, FunctionalComponent } from 'preact'

import styles from './styles.scss'

export type BannerType = 'neutral' | 'info' | 'success' | 'error'
export const Banner: FunctionalComponent<{
  className?: string
  type: BannerType
}> = ({ className = '', type, children }) => {
  const iconMapping: Record<BannerType, string> = {
    error: 'fas fa-times',
    info: 'fas fa-info',
    neutral: 'fas fa-info',
    success: 'far fa-check-circle',
  }
  return (
    <div className={`${styles.banner} ${styles[type]} ${className}`}>
      <i className={`${iconMapping[type]} ${styles.icon}`} />
      <div>{children}</div>
    </div>
  )
}
