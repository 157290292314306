import { gql } from '@apollo/client'

import { SimpleResult } from 'types/graphql'
import { Notification } from 'types/notification'
import { NotificationFragment } from './fragments/notification'
import { SimpleResultFragment } from './fragments/simpleResult'

export type GetUserNotificationsInput = {
  limit: number
  offset: number
}
export type GetUserNotificationsResult = {
  getUserNotifications: {
    notifications: Notification[]
    offset: number
    count: number
    totalCount: number
  }
}
export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications($limit: Int!, $offset: Int!) {
    getUserNotifications(limit: $limit, offset: $offset) {
      notifications {
        ...Notification
      }
      offset
      count
      totalCount
    }
  }

  ${NotificationFragment}
`

export type GetUserNotificationsCountInput = void
export type GetUserNotificationsCountResult = {
  getUserNotificationsCount: {
    count: number
  }
}
export const GET_USER_NOTIFICATIONS_COUNT = gql`
  query GetUserNotificationsCount {
    getUserNotificationsCount {
      count
    }
  }
`

export type MarkNotificationAsReadInput = {
  notificationId: string
}
export type MarkNotificationAsReadResult = {
  markNotificationAsRead: SimpleResult
}
export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId) {
      ...SimpleResult
    }
  }

  ${SimpleResultFragment}
`

export type DeleteNotificationInput = {
  id: string
}
export type DeleteNotificationResult = {
  deleteNotification: SimpleResult
}
export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId) {
      ...SimpleResult
    }
  }

  ${SimpleResultFragment}
`
