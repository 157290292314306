/** @jsx m */

export const phrases = {
  'fof-upload.forum.buttons.attach': 'Joindre',
  'fof-upload.forum.states.error': 'Erreur',
  'fof-upload.forum.states.loading': 'Chargement',
  'fof-upload.forum.states.success': 'Succ\u00e8s',
  'fof-upload.forum.states.unauthorized': "Vous n'êtes pas autoris\u00e9",
  'fof-userbio.forum.userbioPlaceholder': 'Présentez-vous en quelques mots',
  'flarum-likes.forum.notifications.others_text':
    '{count} autre|{count} autres',
  'flarum-likes.forum.notifications.post_liked_text':
    '{username} a aim\u00e9 votre message',
  'flarum-likes.forum.post.like_link': 'J\u2019aime',
  'flarum-likes.forum.post.liked_by_self_text': '{users} aimez \u00e7a.',
  'flarum-likes.forum.post.liked_by_text':
    '{users} aime \u00e7a.|{users} aiment \u00e7a.',
  'flarum-likes.forum.post.others_link': '{count} autre|{count} autres',
  'flarum-likes.forum.post.unlike_link': 'Je n\u2019aime plus',
  'flarum-likes.forum.post.you_text': 'Vous',
  'flarum-likes.forum.post_likes.title': 'Utilisateurs qui aiment \u00e7a',
  'flarum-likes.forum.settings.notify_post_liked_label':
    'Quelqu\u2019un aime un message que j\u2019ai publi\u00e9',
  'flarum-approval.forum.badge.awaiting_approval_tooltip':
    'En attente d\u2019approbation',
  'flarum-approval.forum.post.awaiting_approval_text':
    'En attente d\u2019approbation',
  'flarum-approval.forum.post_controls.approve_button': 'Approuver',
  'core.forum.change_email.confirm_password_placeholder':
    'Confirmer le mot de passe',
  'core.forum.change_email.confirmation_message':
    'Nous avons envoy\u00e9 un courriel de confirmation \u00e0 {email}. Si vous ne le recevez pas prochainement, veuillez v\u00e9rifier le contenu de votre dossier contenant les pourriels.',
  'core.forum.change_email.dismiss_button': 'Valider',
  'core.forum.change_email.incorrect_password_message':
    'Le mot de passe que vous avez sp\u00e9cifi\u00e9 est incorrect.',
  'core.forum.change_email.submit_button': 'Valider',
  'core.forum.change_email.title': 'Modifier l\u2019adresse de courriel',
  'core.forum.change_password.send_button': 'Courriel de r\u00e9initialisation',
  'core.forum.change_password.text':
    'Cliquez sur le bouton ci-dessous et consultez le courriel que vous allez recevoir pour modifier votre mot de passe.',
  'core.forum.change_password.title': 'Modifier le mot de passe',
  'core.forum.composer.close_tooltip': 'Fermer',
  'core.forum.composer.exit_full_screen_tooltip':
    'Quitter le mode plein \u00e9cran',
  'core.forum.composer.full_screen_tooltip': 'Plein \u00e9cran',
  'core.forum.composer.minimize_tooltip': 'R\u00e9duire',
  'core.forum.composer.preview_tooltip': 'Pr\u00e9visualiser',
  'core.forum.composer_discussion.body_placeholder':
    'R\u00e9digez un message\u2026',
  'core.forum.composer_discussion.discard_confirmation':
    'Vous n\u2019avez pas publi\u00e9 votre discussion. Souhaitez-vous l\u2019abandonner\u00a0?',
  'core.forum.composer_discussion.submit_button': 'Publier la discussion',
  'core.forum.composer_discussion.title': 'Nouvelle discussion',
  'core.forum.composer_discussion.title_placeholder': 'Titre de la discussion',
  'core.forum.composer_edit.discard_confirmation':
    'Vous n\u2019avez pas enregistr\u00e9 vos modifications. Souhaitez-vous les abandonner\u00a0?',
  'core.forum.composer_edit.post_link': 'Message #{number} de {discussion}',
  'core.forum.composer_edit.submit_button': 'Valider',
  'core.forum.composer_reply.body_placeholder':
    'R\u00e9digez une r\u00e9ponse\u2026',
  'core.forum.composer_reply.discard_confirmation':
    'Vous n\u2019avez pas publi\u00e9 votre r\u00e9ponse. Souhaitez-vous l\u2019abandonner\u00a0?',
  'core.forum.composer_reply.posted_message':
    'Votre r\u00e9ponse a \u00e9t\u00e9 publi\u00e9e.',
  'core.forum.composer_reply.submit_button': 'Publier la r\u00e9ponse',
  'core.forum.composer_reply.view_button': 'Consulter',
  'core.forum.discussion_controls.cannot_reply_button':
    'Impossible de r\u00e9pondre',
  'core.forum.discussion_controls.cannot_reply_text':
    'Vous ne pouvez pas r\u00e9pondre \u00e0 cette discussion.',
  'core.forum.discussion_controls.delete_button': 'Supprimer',
  'core.forum.discussion_controls.delete_confirmation':
    '\u00cates-vous s\u00fbr de vouloir supprimer cette discussion\u00a0?',
  'core.forum.discussion_controls.delete_forever_button':
    'Supprimer d\u00e9finitivement',
  'core.forum.discussion_controls.log_in_to_reply_button':
    'Se connecter pour r\u00e9pondre',
  'core.forum.discussion_controls.rename_button': 'Renommer',
  'core.forum.discussion_controls.reply_button': 'R\u00e9pondre',
  'core.forum.discussion_controls.restore_button': 'Restaurer',
  'core.forum.discussion_list.empty_text':
    'Il semble qu\u2019il n\u2019y ait aucune discussion ici.',
  'core.forum.discussion_list.load_more_button': 'Charger davantage',
  'core.forum.discussion_list.mark_as_read_tooltip': 'Marquer comme lu',
  'core.forum.discussion_list.replied_text':
    '{username} a r\u00e9pondu \u00e0 cette discussion {ago}',
  'core.forum.discussion_list.started_text':
    '{username} a publi\u00e9 cette discussion {ago}',
  'core.forum.edit_user.activate_button': 'Activer l\u2019utilisateur',
  'core.forum.edit_user.email_heading': 'Adresse de courriel',
  'core.forum.edit_user.email_label': 'Adresse de courriel',
  'core.forum.edit_user.groups_heading': 'Groupes',
  'core.forum.edit_user.password_heading': 'Mot de passe',
  'core.forum.edit_user.password_label': 'Mot de passe',
  'core.forum.edit_user.set_password_label':
    'D\u00e9finir un nouveau mot de passe',
  'core.forum.edit_user.submit_button': 'Valider',
  'core.forum.edit_user.title': 'Modifier l\u2019utilisateur',
  'core.forum.edit_user.username_heading': 'Nom d\u2019utilisateur',
  'core.forum.edit_user.username_label': 'Nom d\u2019utilisateur',
  'core.forum.forgot_password.dismiss_button': 'Valider',
  'core.forum.forgot_password.email_placeholder': 'Adresse de courriel',
  'core.forum.forgot_password.email_sent_message':
    'Nous vous avons envoy\u00e9 un courriel contenant un lien pour r\u00e9initialiser votre mot de passe. Si vous ne le recevez pas prochainement, veuillez v\u00e9rifier le contenu de votre dossier contenant les pourriels.',
  'core.forum.forgot_password.not_found_message':
    'Il n\u2019y a aucun utilisateur inscrit avec cette adresse de courriel.',
  'core.forum.forgot_password.submit_button':
    'R\u00e9cup\u00e9rer un mot de passe',
  'core.forum.forgot_password.text':
    'Saisissez votre adresse de courriel pour que nous vous envoyons un lien qui vous permettra de r\u00e9initialiser votre mot de passe.',
  'core.forum.forgot_password.title': 'Mot de passe oubli\u00e9',
  'core.forum.header.admin_button': 'Administration',
  'core.forum.header.back_to_index_tooltip':
    'Revenir \u00e0 la liste de discussions',
  'core.forum.header.discussions_link': 'Toutes les discussions',
  'core.forum.header.log_in_link': 'Connexion',
  'core.forum.header.log_out_button': 'D\u00e9connexion',
  'core.forum.header.profile_button': 'Profil',
  'core.forum.header.search_placeholder': 'Rechercher dans le forum',
  'core.forum.header.settings_button': 'Param\u00e8tres',
  'core.forum.header.sign_up_link': 'Inscription',
  'core.forum.index.all_discussions_link': 'Toutes les discussions',
  'core.forum.index.cannot_start_discussion_button':
    'Impossible de d\u00e9marrer une discussion',
  'core.forum.index.mark_all_as_read_confirmation':
    '\u00cates-vous s\u00fbr de vouloir marquer toutes les notifications comme lues\u00a0?',
  'core.forum.index.mark_all_as_read_tooltip': 'Tout marquer comme lu',
  'core.forum.index.refresh_tooltip': 'Actualiser',
  'core.forum.index.start_discussion_button': 'Nouvelle discussion',
  'core.forum.index_sort.latest_button': 'Activit\u00e9',
  'core.forum.index_sort.newest_button': 'Nouveaut\u00e9',
  'core.forum.index_sort.oldest_button': 'Anciennet\u00e9',
  'core.forum.index_sort.relevance_button': 'Pertinence',
  'core.forum.index_sort.top_button': 'Popularit\u00e9',
  'core.forum.log_in.forgot_password_link': 'Mot de passe oubli\u00e9\u00a0?',
  'core.forum.log_in.invalid_login_message':
    'Vos identifiants sont incorrects.',
  'core.forum.log_in.password_placeholder': 'Mot de passe',
  'core.forum.log_in.remember_me_label': 'Se souvenir de moi',
  'core.forum.log_in.sign_up_text':
    'Pas encore inscrit\u00a0? <a>Inscription</a>',
  'core.forum.log_in.submit_button': 'Connexion',
  'core.forum.log_in.title': 'Connexion',
  'core.forum.log_in.username_or_email_placeholder':
    'Nom d\u2019utilisateur ou adresse de courriel',
  'core.forum.notifications.discussion_renamed_text':
    '{username} a renomm\u00e9 le titre de votre discussion',
  'core.forum.notifications.empty_text': 'Aucune notification',
  'core.forum.notifications.mark_all_as_read_tooltip': 'Tout marquer comme lu',
  'core.forum.notifications.mark_as_read_tooltip': 'Marquer comme lu',
  'core.forum.notifications.title': 'Notifications',
  'core.forum.notifications.tooltip': 'Notifications',
  'core.forum.post.edited_text': 'Modifi\u00e9',
  'core.forum.post.edited_tooltip':
    '{username} a modifi\u00e9 ce message {ago}',
  'core.forum.post.number_tooltip': 'Message #{number}',
  'core.forum.post_controls.delete_button': 'Supprimer',
  'core.forum.post_controls.delete_forever_button':
    'Supprimer d\u00e9finitivement',
  'core.forum.post_controls.edit_button': 'Modifier',
  'core.forum.post_controls.restore_button': 'Restaurer',
  'core.forum.post_scrubber.now_link': 'Maintenant',
  'core.forum.post_scrubber.original_post_link': 'Message original',
  'core.forum.post_scrubber.unread_text': '{count} non lus',
  'core.forum.post_scrubber.viewing_text':
    '{index} sur {count} message|{index} sur {count} messages',
  'core.forum.post_stream.discussion_renamed_old_tooltip':
    'L\u2019ancien titre \u00e9tait\u00a0: \u00ab\u00a0{old}\u00a0\u00bb',
  'core.forum.post_stream.discussion_renamed_text':
    '{username} a renomm\u00e9 le titre en {new}.',
  'core.forum.post_stream.load_more_button': 'Charger davantage',
  'core.forum.post_stream.reply_placeholder':
    'R\u00e9digez une r\u00e9ponse\u2026',
  'core.forum.post_stream.time_lapsed_text': '{period} plus tard',
  'core.forum.rename_discussion.submit_button': 'Renommer',
  'core.forum.rename_discussion.title': 'Renommer la discussion',
  'core.forum.search.all_discussions_button':
    'Rechercher \u00ab\u00a0{query}\u00a0\u00bb dans toutes les discussions',
  'core.forum.search.discussions_heading': 'Discussions',
  'core.forum.search.users_heading': 'Utilisateurs',
  'core.forum.settings.account_heading': 'Compte',
  'core.forum.settings.change_email_button':
    'Modifier l\u2019adresse de courriel',
  'core.forum.settings.change_password_button': 'Modifier le mot de passe',
  'core.forum.settings.notifications_heading': 'Notifications',
  'core.forum.settings.notify_by_email_heading': 'Courriel',
  'core.forum.settings.notify_by_web_heading': 'Web',
  'core.forum.settings.notify_discussion_renamed_label':
    'Quelqu\u2019un renomme une discussion que j\u2019ai d\u00e9marr\u00e9e',
  'core.forum.settings.newsletter_subscription_label':
    "Recevoir la lettre de nouvelles d'EnseignerLeClimat",
  'core.forum.settings.privacy_disclose_online_label':
    'Autoriser les autres \u00e0 voir que je suis en ligne',
  'core.forum.settings.privacy_heading': 'Confidentialit\u00e9',
  'core.forum.settings.title': 'Param\u00e8tres',
  'core.forum.sign_up.dismiss_button': 'Valider',
  'core.forum.sign_up.email_placeholder': 'Adresse de courriel',
  'core.forum.sign_up.log_in_text':
    'D\u00e9j\u00e0 inscrit\u00a0? <a>Connexion</a>',
  'core.forum.sign_up.password_placeholder': 'Mot de passe',
  'core.forum.sign_up.submit_button': 'Inscription',
  'core.forum.sign_up.title': 'Inscription',
  'core.forum.sign_up.username_placeholder': 'Nom d\u2019utilisateur',
  'core.forum.sign_up.welcome_text': 'Bienvenue {username}\u00a0!',
  'core.forum.user.avatar_remove_button': 'Supprimer',
  'core.forum.user.avatar_upload_button': 'Choisir un nouvel avatar',
  'core.forum.user.avatar_upload_tooltip': 'Choisir un nouvel avatar',
  'core.forum.user.add_teacher_tags_link':
    'Vous êtes un enseignant ? Renseignez vos informations',
  'core.forum.user.discussions_link': 'Discussions',
  'core.forum.user.in_discussion_text': 'Dans {discussion}',
  'core.forum.user.joined_date_text': 'Inscrit {ago}',
  'core.forum.user.online_text': 'En ligne',
  'core.forum.user.posts_empty_text':
    'Il semble qu\u2019il n\u2019y ait aucun message ici.',
  'core.forum.user.posts_link': 'Messages',
  'core.forum.user.posts_load_more_button': 'Charger davantage',
  'core.forum.user.settings_link': 'Param\u00e8tres',
  'core.forum.user_controls.button': 'Commandes',
  'core.forum.user_controls.delete_button': 'Supprimer',
  'core.forum.user_controls.delete_confirmation':
    '\u00cates-vous s\u00fbr de vouloir supprimer cet utilisateur\u00a0? Tous ses messages seront \u00e9galement supprim\u00e9s.',
  'core.forum.user_controls.delete_error_message':
    'La suppression de l\u2019utilisateur <i>{username} ({email})</i> a \u00e9chou\u00e9',
  'core.forum.user_controls.delete_success_message':
    'L\u2019utilisateur <i>{username} ({email})</i> a \u00e9t\u00e9 supprim\u00e9',
  'core.forum.user_controls.edit_button': 'Modifier',
  'core.forum.user_email_confirmation.alert_message':
    'Nous avons envoy\u00e9 un courriel de confirmation \u00e0 {email}. Si vous ne le recevez pas prochainement, veuillez v\u00e9rifier le contenu de votre dossier contenant les pourriels.',
  'core.forum.user_email_confirmation.resend_button':
    'Renvoyer le courriel de confirmation',
  'core.forum.user_email_confirmation.sent_message': 'Envoy\u00e9',
  'core.lib.badge.hidden_tooltip': 'Masqu\u00e9e',
  'core.lib.error.generic_message':
    'Oups\u00a0! Quelque chose s\u2019est mal pass\u00e9. Veuillez actualiser la page puis r\u00e9essayer.',
  'core.lib.error.not_found_message':
    'La ressource que vous souhaitez atteindre est introuvable.',
  'core.lib.error.permission_denied_message':
    'Vous n\u2019avez pas la permission de faire cela.',
  'core.lib.error.rate_limit_exceeded_message':
    'Vous \u00eates un peu trop rapide. Veuillez r\u00e9essayer dans quelques secondes.',
  'core.lib.number_suffix.kilo_text': 'k',
  'core.lib.number_suffix.mega_text': 'M',
  'core.lib.series.glue_text': ', ',
  'core.lib.series.three_text': '{first}, {second} et {third}',
  'core.lib.series.two_text': '{first} et {second}',
  'core.lib.username.deleted_text': '[supprim\u00e9]',
  'flarum-tags.forum.choose_tags.choose_placeholder':
    'S\u00e9lectionnez une \u00e9tiquette|S\u00e9lectionnez {count} \u00e9tiquettes',
  'flarum-tags.forum.choose_tags.edit_title':
    'Modifiez les \u00e9tiquettes de {title}',
  'flarum-tags.forum.choose_tags.submit_button': 'Valider',
  'flarum-tags.forum.choose_tags.title':
    'S\u00e9lectionnez les \u00e9tiquettes de votre discussion',
  'flarum-tags.forum.composer_discussion.choose_tags_link':
    'S\u00e9lectionnez des \u00e9tiquettes',
  'flarum-tags.forum.discussion_controls.edit_tags_button':
    'Modifier les \u00e9tiquettes',
  'flarum-tags.forum.header.back_to_tags_tooltip':
    'Revenir \u00e0 la liste d\u2019\u00e9tiquettes',
  'flarum-tags.forum.index.more_link': 'Plus\u2026',
  'flarum-tags.forum.index.tags_link': '\u00c9tiquettes',
  'flarum-tags.forum.index.untagged_link': 'Sans \u00e9tiquette',
  'flarum-tags.forum.post_stream.added_and_removed_tags_text':
    '{username} a ajout\u00e9 {tagsAdded} et a supprim\u00e9 {tagsRemoved}.',
  'flarum-tags.forum.post_stream.added_tags_text':
    '{username} a ajout\u00e9 {tagsAdded}.',
  'flarum-tags.forum.post_stream.removed_tags_text':
    '{username} a supprim\u00e9 {tagsRemoved}.',
  'flarum-tags.forum.post_stream.tags_text':
    'l\u2019\u00e9tiquette {tags}|les \u00e9tiquettes {tags}',
  'flarum-tags.lib.deleted_tag_text': 'Supprim\u00e9e',
  'flarum-akismet.forum.post.akismet_flagged_text':
    'Akismet a signal\u00e9 \u00e7a comme ind\u00e9sirable',
  'flarum-akismet.forum.post.not_spam_button': 'Non ind\u00e9sirable',
  'flarum-markdown.forum.composer.bold_tooltip': 'Mettre le texte en gras',
  'flarum-markdown.forum.composer.code_tooltip': 'Ins\u00e9rer du code',
  'flarum-markdown.forum.composer.header_tooltip': 'Ajouter un titre',
  'flarum-markdown.forum.composer.image_tooltip': 'Ajouter une image',
  'flarum-markdown.forum.composer.italic_tooltip':
    'Mettre le texte en italique',
  'flarum-markdown.forum.composer.link_tooltip': 'Ajouter un lien hypertexte',
  'flarum-markdown.forum.composer.ordered_list_tooltip':
    'Cr\u00e9er une liste num\u00e9rot\u00e9e',
  'flarum-markdown.forum.composer.quote_tooltip': 'Ins\u00e9rer une citation',
  'flarum-markdown.forum.composer.unordered_list_tooltip':
    'Cr\u00e9er une liste \u00e0 puces',
  'flarum-emoji.forum.composer.emoji_tooltip': 'Ins\u00e9rer un emoji',
  'flarum-emoji.forum.composer.type_to_search_text':
    '\u00c9crivez ici pour rechercher un emoji',
  'flarum-subscriptions.forum.badge.following_tooltip': 'Suivie',
  'flarum-subscriptions.forum.badge.ignoring_tooltip': 'Ignor\u00e9e',
  'flarum-subscriptions.forum.discussion_controls.follow_button': 'Suivre',
  'flarum-subscriptions.forum.discussion_controls.unfollow_button':
    'Ne plus suivre',
  'flarum-subscriptions.forum.discussion_controls.unignore_button':
    'Ne plus ignorer',
  'flarum-subscriptions.forum.index.following_link': 'Suivies',
  'flarum-subscriptions.forum.notifications.new_post_text':
    '{username} a publi\u00e9 un message',
  'flarum-subscriptions.forum.settings.follow_after_reply_label':
    'Suivre automatiquement les discussions auxquelles j\u2019ai r\u00e9pondu',
  'flarum-subscriptions.forum.settings.notify_new_post_label':
    'Quelqu\u2019un publie dans une discussion que je suis',
  'flarum-subscriptions.forum.sub_controls.follow_button': 'Suivre',
  'flarum-subscriptions.forum.sub_controls.following_button': 'Suivie',
  'flarum-subscriptions.forum.sub_controls.following_text':
    'M\u2019envoyer une notification lorsqu\u2019une r\u00e9ponse est publi\u00e9e.',
  'flarum-subscriptions.forum.sub_controls.ignoring_button': 'Ignor\u00e9e',
  'flarum-subscriptions.forum.sub_controls.ignoring_text':
    'Ne jamais m\u2019envoyer de notification. Masquer de la liste de discussions.',
  'flarum-subscriptions.forum.sub_controls.not_following_button': 'Non suivie',
  'flarum-subscriptions.forum.sub_controls.not_following_text':
    'Ne m\u2019envoyer une notification que lorsque je suis @mentionn\u00e9.',
  'flarum-subscriptions.forum.sub_controls.notify_alert_tooltip':
    'Recevoir une notification sur le forum lorsque de nouveaux messages sont publi\u00e9s',
  'flarum-subscriptions.forum.sub_controls.notify_email_tooltip':
    'Recevoir un courriel lorsque de nouveaux messages sont publi\u00e9s',
  'flarum-mentions.forum.composer.mention_tooltip':
    'Mentionner un utilisateur ou un message',
  'flarum-mentions.forum.composer.reply_to_post_text':
    'R\u00e9pondre \u00e0 #{number}',
  'flarum-mentions.forum.notifications.others_text':
    '{count} autre|{count} autres',
  'flarum-mentions.forum.notifications.post_mentioned_text':
    '{username} a r\u00e9pondu \u00e0 votre message',
  'flarum-mentions.forum.notifications.user_mentioned_text':
    '{username} vous a mentionn\u00e9',
  'flarum-mentions.forum.post.mentioned_by_self_text':
    '{users} avez r\u00e9pondu \u00e0 \u00e7a.',
  'flarum-mentions.forum.post.mentioned_by_text':
    '{users} a r\u00e9pondu \u00e0 \u00e7a.|{users} ont r\u00e9pondu \u00e0 \u00e7a.',
  'flarum-mentions.forum.post.others_text': '{count} autre|{count} autres',
  'flarum-mentions.forum.post.quote_button': 'Citer',
  'flarum-mentions.forum.post.reply_link': 'R\u00e9pondre',
  'flarum-mentions.forum.post.you_text': 'Vous',
  'flarum-mentions.forum.settings.notify_post_mentioned_label':
    'Quelqu\u2019un r\u00e9pond \u00e0 un message que j\u2019ai publi\u00e9',
  'flarum-mentions.forum.settings.notify_user_mentioned_label':
    'Quelqu\u2019un me mentionne dans un message',
  'flarum-mentions.forum.user.mentions_link': 'Mentions',
  'flarum-suspend.forum.notifications.user_suspended_text':
    '{username} vous a suspendu pendant {timeReadable}',
  'flarum-suspend.forum.notifications.user_unsuspended_text':
    '{username} a retir\u00e9 votre suspension',
  'flarum-suspend.forum.suspend_user.indefinitely_label':
    'Suspendre ind\u00e9finiment',
  'flarum-suspend.forum.suspend_user.limited_time_days_text': ' jour(s)',
  'flarum-suspend.forum.suspend_user.limited_time_label':
    'Suspendre temporairement\u2026',
  'flarum-suspend.forum.suspend_user.not_suspended_label': 'Non suspendu',
  'flarum-suspend.forum.suspend_user.status_heading': 'Statut de la suspension',
  'flarum-suspend.forum.suspend_user.submit_button': 'Enregistrer',
  'flarum-suspend.forum.suspend_user.title': 'Suspendre {username}',
  'flarum-suspend.forum.user_badge.suspended_tooltip': 'Suspendu',
  'flarum-suspend.forum.user_controls.suspend_button': 'Suspendre',
  'flarum-lock.forum.badge.locked_tooltip': 'Verrouill\u00e9e',
  'flarum-lock.forum.discussion_controls.lock_button': 'Verrouiller',
  'flarum-lock.forum.discussion_controls.unlock_button': 'D\u00e9verrouiller',
  'flarum-lock.forum.notifications.discussion_locked_text':
    '{username} a verrouill\u00e9 votre discussion',
  'flarum-lock.forum.post_stream.discussion_locked_text':
    '{username} a verrouill\u00e9 cette discussion.',
  'flarum-lock.forum.post_stream.discussion_unlocked_text':
    '{username} a d\u00e9verrouill\u00e9 cette discussion.',
  'flarum-lock.forum.settings.notify_discussion_locked_label':
    'Quelqu\u2019un verrouille une discussion que j\u2019ai d\u00e9marr\u00e9e',
  'flarum-flags.forum.flag_post.confirmation_message':
    'Merci d\u2019avoir signal\u00e9 ce message. Les mod\u00e9rateurs s\u2019en occuperont d\u00e8s que possible.',
  'flarum-flags.forum.flag_post.dismiss_button': 'Valider',
  'flarum-flags.forum.flag_post.reason_details_placeholder':
    'Informations compl\u00e9mentaires (facultatif)',
  'flarum-flags.forum.flag_post.reason_inappropriate_label': 'Inappropri\u00e9',
  'flarum-flags.forum.flag_post.reason_inappropriate_text':
    'Ce message est offensant, vulgaire, ou ne respecte pas les <a>r\u00e8gles de la communaut\u00e9</a>.',
  'flarum-flags.forum.flag_post.reason_missing_message':
    'Veuillez communiquer certaines informations \u00e0 nos mod\u00e9rateurs.',
  'flarum-flags.forum.flag_post.reason_off_topic_label': 'hors-sujet',
  'flarum-flags.forum.flag_post.reason_off_topic_text':
    'Ce message n\u2019est pas pertinent \u00e0 la discussion actuelle et devrait \u00eatre d\u00e9plac\u00e9 ailleurs.',
  'flarum-flags.forum.flag_post.reason_other_label':
    'Autre (veuillez pr\u00e9ciser)',
  'flarum-flags.forum.flag_post.reason_spam_label': 'Ind\u00e9sirable',
  'flarum-flags.forum.flag_post.reason_spam_text':
    'Ce message est publicitaire.',
  'flarum-flags.forum.flag_post.submit_button': 'Signaler le message',
  'flarum-flags.forum.flag_post.title': 'Signaler le message',
  'flarum-flags.forum.flagged_posts.empty_text': 'Aucun message signal\u00e9',
  'flarum-flags.forum.flagged_posts.item_text':
    '{username} dans <em>{discussion}</em>',
  'flarum-flags.forum.flagged_posts.title': 'Messages signal\u00e9s',
  'flarum-flags.forum.flagged_posts.tooltip': 'Messages signal\u00e9s',
  'flarum-flags.forum.post.dismiss_flag_button': 'Annuler le signalement',
  'flarum-flags.forum.post.flagged_by_text':
    '{username} a signal\u00e9 \u00e7a',
  'flarum-flags.forum.post.flagged_by_with_reason_text':
    '{username} a signal\u00e9 \u00e7a comme {reason}',
  'flarum-flags.forum.post_controls.flag_button': 'Signaler',
  'flarum-auth-twitter.forum.log_in.with_twitter_button':
    'Connexion avec Twitter',
  'flarum-auth-facebook.forum.log_in.with_facebook_button':
    'Connexion avec Facebook',
  'flarum-sticky.forum.badge.sticky_tooltip': '\u00c9pingl\u00e9e',
  'flarum-sticky.forum.discussion_controls.sticky_button': '\u00c9pingler',
  'flarum-sticky.forum.discussion_controls.unsticky_button':
    'D\u00e9s\u00e9pingler',
  'flarum-sticky.forum.post_stream.discussion_stickied_text':
    '{username} a \u00e9pingl\u00e9 cette discussion.',
  'flarum-sticky.forum.post_stream.discussion_unstickied_text':
    '{username} a d\u00e9s\u00e9pingl\u00e9 cette discussion.',
  'flarum-auth-github.forum.log_in.with_github_button': 'Connexion avec GitHub',
  'flarum-pusher.forum.discussion_list.show_updates_text':
    'Afficher la discussion mise \u00e0 jour|Afficher les {count} discussions mises \u00e0 jour',
  'saleksin-auth-google.forum.log_in.with_google_button':
    'Connexion avec Google',
  'fof-reactions.forum.disabled-reaction':
    'Vous ne pouvez pas utiliser cette r\u00e9action pour le moment, veuillez actualiser la page',
  'fof-reactions.forum.notification':
    '{username} a r\u00e9agi avec {reaction} \u00e0 votre message',
  'fof-reactions.forum.reacting-own-post':
    'Vous ne pouvez pas r\u00e9agir \u00e0 votre propre message',
  'fof-reactions.forum.settings.notify_post_reacted_label':
    "Quelqu'un a r\u00e9agi \u00e0 l'un de mes messages",
  'fof-reactions.forum.warning':
    'Votre r\u00e9action a \u00e9t\u00e9 convertie',
  'the-turk-diff.forum.confirmDelete':
    'Voulez-vous vraiment supprimer le contenu de cette modification de l\u2019historique?',
  'the-turk-diff.forum.deleteErrorMessage':
    'La suppression du contenu de l\u2019\u00e9dition a \u00e9chou\u00e9e.',
  'the-turk-diff.forum.deleteSuccessMessage':
    'Le contenu de la modification a \u00e9t\u00e9 supprim\u00e9.',
  'the-turk-diff.forum.deletedText': '(effac\u00e9)',
  'the-turk-diff.forum.deletedTooltip':
    '{username} a effac\u00e9 ce contenu {ago}',
  'the-turk-diff.forum.differences': 'Diff\u00e9rences',
  'the-turk-diff.forum.emptyText': 'Aucune r\u00e9vision',
  'the-turk-diff.forum.revisionInfo':
    'Edit\u00e9 {revisionCount} fois, le plus r\u00e9cemment en haut|Edit\u00e9 {revisionCount} fois, le plus r\u00e9cemment en haut',
  'core.forum.index_sort.popular_button': 'Populaire',
  'core.forum.index_sort.unpopular_button': 'Unpopulaire',
  'flarum_discussion_views.forum.discussion_controls.resetviews_button':
    'D\u00e9finir le nombre de vues',
  'flarum_discussion_views.forum.modal_resetviews.label':
    'D\u00e9finir le nombre de vues',
  'flarum_discussion_views.forum.modal_resetviews.submit': 'Soumettre',
  'flarum_discussion_views.forum.modal_resetviews.title':
    'R\u00e9initialiser le compteur de vues',
  'flarum_discussion_views.forum.viewlist.title': 'Derniers visiteurs',
  'fof-ban-ips.forum.ban_ip_button': 'Bannir une adresse IP',
  'fof-ban-ips.forum.modal.ban_ip_no_users':
    'Personne d\u2019autre ne sera banni',
  'fof-ban-ips.forum.modal.ban_options_all_ip':
    'Bannir toutes les IP utilis\u00e9es par<i>{username}</i>',
  'fof-ban-ips.forum.modal.ban_options_only_ip':
    'Bannir uniquement l\u2019adresse IP actuelle (<code>{ip}</code>)',
  'fof-ban-ips.forum.unban_ip_button': 'D\u00e9bannir une adresse IP',
  'fof-ban-ips.forum.user_badge.banned_tooltip': 'Banni(e)',
  'fof-ban-ips.forum.user_controls.ban_button': 'Bannir',
  'fof-ban-ips.forum.user_controls.unban_button': 'D\u00e9bannir',
  'fof-ban-ips.lib.modal.address_label': 'Adresse IP',
  'fof-ban-ips.lib.modal.ban_button': 'Bannir',
  'fof-ban-ips.lib.modal.ban_ip_confirmation':
    '\u00cates-vous s\u00fbr de vouloir bloquer l\u2019utilisation de cette adresse IP sur le forum? Cela emp\u00eachera les utilisateurs poss\u00e9dant cette adresse IP de se connecter et de s\u2019inscrire.',
  'fof-ban-ips.lib.modal.ban_ip_users':
    'L\u2019utilisateur {users} sera banni|Les utilisateurs {users} seront bannis',
  'fof-ban-ips.lib.modal.check_button': 'V\u00e9rifier',
  'fof-ban-ips.lib.modal.reason_label': 'Motif',
  'fof-ban-ips.lib.modal.save_button': 'Enregistrer',
  'fof-ban-ips.lib.modal.title': 'Bannir des adresses IP',
  'fof-ban-ips.lib.modal.unban_button': 'D\u00e9bannir',
  'fof-ban-ips.lib.modal.unban_ip_confirmation':
    '\u00cates-vous s\u00fbr de vouloir d\u00e9bannir cet utilisateur et tous les autres utilisateurs non bannis par d\u2019autres adresses IP?',
  'fof-ban-ips.lib.modal.unban_ip_no_users':
    'Personne d\u2019autre ne sera d\u00e9banni',
  'fof-ban-ips.lib.modal.unban_ip_users':
    'L\u2019utilisateur {users} sera d\u00e9banni|Les utilisateurs {users} seront d\u00e9bannis',
  'fof-ban-ips.lib.modal.unban_options_all_ip':
    'D\u00e9bannir toutes les adresses IP utilis\u00e9es par <i>{username}</i>',
  'fof-ban-ips.lib.modal.unban_options_only_ip':
    'D\u00e9bannir uniquement l\u2019adresse IP actuelle (<code>{ip}</code>)',
  'fof-ban-ips.lib.modal.unban_title': 'D\u00e9bannir des adresses IP',
  'fof-ban-ips.lib.modal.unbanned_ips': 'D\u00e9bannir <code>{ips}</code>.',
  'nomiscz-auth-steam.forum.alerts.already_linked':
    'Le compte Steam est d\u00e9j\u00e0 associ\u00e9.',
  'nomiscz-auth-steam.forum.alerts.already_used':
    'Le compte Steam est d\u00e9j\u00e0 associ\u00e9 \u00e0 un autre utilisateur.',
  'nomiscz-auth-steam.forum.alerts.error':
    'Quelque chose c\u2019est mal pass\u00e9. Merci d\u2019essayer plus tard!',
  'nomiscz-auth-steam.forum.alerts.link_success':
    'L\u2019association au compte Steam a r\u00e9ussi.',
  'nomiscz-auth-steam.forum.alerts.unlink_success':
    'La dissociation du compte Steam a r\u00e9ussi.',
  'nomiscz-auth-steam.forum.buttons.link': 'Associer un compte Steam',
  'nomiscz-auth-steam.forum.buttons.login': 'Se connecter avec Steam',
  'nomiscz-auth-steam.forum.buttons.unlink': 'Dissocier un compte Steam',
  'nomiscz-auth-steam.forum.modals.link.title': 'Associer un compte Steam',
  'nomiscz-auth-steam.forum.modals.unlink.buttons.cancel': 'Non!',
  'nomiscz-auth-steam.forum.modals.unlink.buttons.confirm':
    'Oui, dissocier le compte',
  'nomiscz-auth-steam.forum.modals.unlink.info.confirm':
    '\u00cates-vous s\u00fbr de vouloir dissocier votre compte Steam?',
  'nomiscz-auth-steam.forum.modals.unlink.info.no_providers':
    'Vous n\u2019avez pas d\u2019autres fournisseurs de connexion. Vous devrez probablement r\u00e9initialiser votre mot de passe.',
  'nomiscz-auth-steam.forum.modals.unlink.title': 'Dissocier un compte Steam',
  'kvothe-sub.forum.signup': 'S\u2019inscrire',
  'core.forum.index_sort.hot_button': 'Tendances',
  'fof-gamification.forum.mod_item': 'Voir les votants',
  'fof-gamification.forum.modal.downvotes_label': 'Votants n\u00e9gatifs:',
  'fof-gamification.forum.modal.title': 'Votants',
  'fof-gamification.forum.modal.upvotes_label': 'Votants positifs:',
  'fof-gamification.forum.nav.name': 'Classements',
  'fof-gamification.forum.notification.downvote':
    '{username} a vot\u00e9 n\u00e9gativement votre message',
  'fof-gamification.forum.notification.upvote':
    '{username} a vot\u00e9 positivement votre message',
  'fof-gamification.forum.ranking.amount': 'Points',
  'fof-gamification.forum.ranking.name': 'Utilisateurs',
  'fof-gamification.forum.ranking.rank': 'Rang',
  'fof-gamification.forum.user.points': 'Points: {points}',
  'fof-gamification.forum.user.rank': '{rank}',
  'fof-gamification.forum.vote_title': 'Afficher qui a vot\u00e9',
  'fof-gamification.forum.votes': 'Votes',
  'clarkwinkelmann-author-change.forum.controls.edit': 'Modifier l\u2019auteur',
  'clarkwinkelmann-author-change.forum.modal.cancel': 'Annuler',
  'clarkwinkelmann-author-change.forum.modal.submit': 'Sauvegarder',
  'clarkwinkelmann-author-change.forum.modal.title-discussion':
    'Modifier l\u2019auteur de la discussion',
  'clarkwinkelmann-author-change.forum.modal.title-post':
    'Modifier l\u2019auteur du message',
  'clarkwinkelmann-author-change.forum.search.no-results':
    'Aucun r\u00e9sultat',
  'clarkwinkelmann-author-change.forum.search.placeholder':
    'Rechercher des utilisateurs',
  'clarkwinkelmann-author-change.forum.search.type-more':
    'Tapez 3 caract\u00e8res pour effectuer une recherche dans la base de donn\u00e9es',
  'fof-prevent-necrobumping.forum.composer.warning.checkbox_label':
    "Je suis conscient que cette discussion est plut\u00f4t ancienne, mais j'aimerais quand m\u00eame r\u00e9pondre.",
  'fof-prevent-necrobumping.forum.composer.warning.description':
    "Il est tr\u00e8s probable que ce sujet ne n\u00e9cessite pas plus de discussion, le faire remonter n'est (peut-\u00eatre) pas utile.",
  'fof-prevent-necrobumping.forum.composer.warning.error':
    'Cette discussion est trop ancienne pour y r\u00e9pondre.',
  'fof-prevent-necrobumping.forum.composer.warning.title':
    'Ce sujet a plus de <b>{time}</b> jours.',
  'core.forum.badge.frontpage_tooltip': 'Marqu\u00e9 comme page d\u2019accueil',
  'core.forum.index_sort.front_button': 'Page d\u2019accueil',
  'core.forum.post_controls.pull_from_front_button':
    'Retirer de la page d\u2019accueil',
  'core.forum.post_controls.push_to_front_button':
    'Mettre en page d\u2019accueil',
  'flarum-best-answer.forum.best_answer': 'Meilleure r\u00e9ponse',
  'flarum-best-answer.forum.best_answer_button': 'Meilleure r\u00e9ponse',
  'flarum-best-answer.forum.best_answer_label':
    'D\u00e9fini par <a>{username}</a>',
  'flarum-best-answer.forum.header_best_answer':
    'Marqu\u00e9 par l\u2019auteur du message comme la meilleure r\u00e9ponse',
  'flarum-best-answer.forum.notification.content':
    'Veuillez s\u00e9lectionner la meilleure r\u00e9ponse si votre question a \u00e9t\u00e9 r\u00e9solue.',
  'flarum-best-answer.forum.remove_best_answer':
    'Supprimer la meilleure r\u00e9ponse actuelle',
  'flarum-best-answer.forum.this_best_answer':
    'C\u2019est la meilleure r\u00e9ponse',
  'fof-split.forum.modal.new_discussion_label':
    'Pr\u00e9cisez le titre de la nouvelle discussion',
  'fof-split.forum.modal.submit_button': 'Diviser',
  'fof-split.forum.modal.title': 'Diviser la discussion',
  'fof-split.forum.post.was_split_from':
    '{count} message a \u00e9t\u00e9 s\u00e9par\u00e9 de {target}.|{count} messages ont \u00e9t\u00e9 s\u00e9par\u00e9s de {target}.',
  'fof-split.forum.post.was_split_to':
    '{count} message a \u00e9t\u00e9 s\u00e9par\u00e9 vers {target}.|{count} messages ont \u00e9t\u00e9 s\u00e9par\u00e9s vers {target}.',
  'fof-split.forum.split.from': 'Diviser \u00e0 partir d\u2019ici',
  'fof-split.forum.split.to': 'Diviser jusque-l\u00e0',
  'fof-byobu.forum.badges.is_private.tooltip': 'Discussion priv\u00e9e',
  'fof-byobu.forum.buttons.add_recipients': 'Destinataires',
  'fof-byobu.forum.buttons.cancel': 'Annuler',
  'fof-byobu.forum.buttons.edit_recipients': 'Modifier les destinataires',
  'fof-byobu.forum.buttons.send_pd': 'Envoyer un message \u00e0 {username}',
  'fof-byobu.forum.buttons.submit': 'Envoyer',
  'fof-byobu.forum.composer_private_discussion.submit_button':
    'Publier une discussion priv\u00e9e',
  'fof-byobu.forum.composer_private_discussion.title_placeholder':
    'Titre de la discussion priv\u00e9e',
  'fof-byobu.forum.input.search_recipients':
    'Rechercher un destinataire en saisissant ses trois premiers caract\u00e8res\u2026',
  'fof-byobu.forum.labels.recipients':
    '{count} destinataire|{count} destinataires',
  'fof-byobu.forum.labels.user_deleted': '-supprim\u00e9-',
  'fof-byobu.forum.modal.help.add_recipients':
    'Cette discussion ne sera visible que par les utilisateurs que vous choisissez ici. Au moins 1 destinataire (autre que vous-m\u00eame) est requis',
  'fof-byobu.forum.modal.help.update_recipients':
    'Cette discussion ne sera visible que par les utilisateurs que vous choisissez ici.',
  'fof-byobu.forum.modal.titles.add_recipients':
    'Ajouter des destinataires \u00e0 votre discussion',
  'fof-byobu.forum.modal.titles.update_recipients':
    'Mettre \u00e0 jour les destinataires de {title}',
  'fof-byobu.forum.nav.private_discussions': 'Discussion priv\u00e9e',
  'fof-byobu.forum.nav.start_button': 'Nouvelle discussion priv\u00e9e',
  'fof-byobu.forum.notifications.pd_label':
    'Quelqu\u2019un m\u2019a inclus dans une discussion priv\u00e9e',
  'fof-byobu.forum.notifications.pd_reply_label':
    'Quelqu\u2019un a post\u00e9 dans une discussion priv\u00e9e dont je suis le destinataire',
  'fof-byobu.forum.notifications.pd_reply_text':
    '{username} a post\u00e9 dans une discussion priv\u00e9e dont vous faites partie',
  'fof-byobu.forum.notifications.pd_text':
    'a d\u00e9marr\u00e9 une nouvelle discussion priv\u00e9e avec vous',
  'fof-byobu.forum.post.recipients_modified.added':
    '{username} a ajout\u00e9 {added} aux destinataires.',
  'fof-byobu.forum.post.recipients_modified.added_and_removed':
    '{username} a ajout\u00e9 {added} et a supprim\u00e9 {removed} aux destinataires.',
  'fof-byobu.forum.post.recipients_modified.removed':
    '{username} a supprim\u00e9 {removed} des destinataires.',
  'fof-byobu.forum.search.headings.groups': 'Groupes de permissions',
  'fof-byobu.forum.user.byobu_link': 'Discussions priv\u00e9es',
  'fof-byobu.forum.user.dropdown_label': 'Discussions priv\u00e9es',
  'fof-byobu.forum.user.settings.block_pd':
    'Bloquer les discussions priv\u00e9es',
  'flagrow-mason.forum.answers.choose-option': 'Choisissez une option',
  'flagrow-mason.forum.answers.no-option-selected':
    'Aucune option s\u00e9lectionn\u00e9e',
  'flagrow-mason.forum.answers-modal.edit-title':
    'Editer les champs pour {title}',
  'flagrow-mason.forum.answers-modal.save': 'Sauvegarder les champs',
  'flagrow-mason.forum.discussion-controls.edit-answers': 'Editer les champs',
  'flagrow-mason.forum.post-answers.no-answer': 'Pas de r\u00e9ponse',
  'flagrow-mason.forum.tags.inadequate-settings':
    'Les param\u00e8tres d\'\u00e9tiquette ne sont pas configur\u00e9s correctement. Autorisez jusqu\'\u00e0 une balise principale et secondaire ou d\u00e9sactivez la fonction "Balises en tant que champs".\n',
  'flagrow-mason.forum.tags.tags-label': 'Etiquettes',
  'fof-polls.forum.composer_discussion.add_poll': 'Ajouter un sondage',
  'fof-polls.forum.composer_discussion.edit_poll': 'Modifier le sondage',
  'fof-polls.forum.days_remaining': 'Le sondage se termine dans {time}',
  'fof-polls.forum.modal.add_option_label': 'Ajouter une option',
  'fof-polls.forum.modal.add_title': 'Ajouter un sondage',
  'fof-polls.forum.modal.date_placeholder':
    'Date de fin du sondage (facultatif)',
  'fof-polls.forum.modal.edit_title': 'Modifier le sondage',
  'fof-polls.forum.modal.include_question': 'Vous devez inclure une question',
  'fof-polls.forum.modal.max':
    'Vous pouvez avoir un maximum de 10 r\u00e9ponses',
  'fof-polls.forum.modal.min':
    'Vous devez inclure au minimum de 2 r\u00e9ponses',
  'fof-polls.forum.modal.no_voters': 'Aucun vote',
  'fof-polls.forum.modal.option_placeholder': 'R\u00e9pondre',
  'fof-polls.forum.modal.options_label': 'R\u00e9ponses',
  'fof-polls.forum.modal.public_poll_label':
    'Permettre aux utilisateurs de voir qui a vot\u00e9',
  'fof-polls.forum.modal.question_placeholder': 'Question',
  'fof-polls.forum.modal.submit': 'Soumettre',
  'fof-polls.forum.moderation.delete': 'Supprimer le sondage',
  'fof-polls.forum.moderation.delete_confirm':
    '\u00cates-vous s\u00fbr de vouloir supprimer ce sondage?',
  'fof-polls.forum.moderation.edit': 'Modifier le sondage',
  'fof-polls.forum.no_permission':
    'Vous n\u2019avez pas la permission de voter',
  'fof-polls.forum.poll_ended': 'Ce sondage est termin\u00e9',
  'fof-polls.forum.public_poll': 'Voir les votants',
  'fof-polls.forum.tooltip.badge': 'Sondage',
  'fof-polls.forum.tooltip.votes': '1 vote|{count} votes',
  'fof-polls.forum.votes_modal.title': 'Votants',
  'reflar-level-ranks.forum.desc.expText': 'EXP',
  'reflar-level-ranks.lib.defaults.level': 'Niveau',
  'fof-merge-discussions.forum.discussion.merge': 'Fusionner',
  'fof-merge-discussions.forum.modal.load_preview_button': 'Aper\u00e7u',
  'fof-merge-discussions.forum.modal.submit_button': 'Fusionner',
  'fof-merge-discussions.forum.modal.title': 'Fusionner les discussions',
  'fof-merge-discussions.forum.modal.type_from_help_text':
    'Choisissez une discussion pour la fusionner avec <b>{title}</b>.',
  'fof-merge-discussions.forum.modal.type_from_label':
    'Fusionner cette discussion avec une autre',
  'fof-merge-discussions.forum.modal.type_target_help_text':
    'Choisissez une ou plusieurs discussions \u00e0 fusionner avec <b>{title}</b>.',
  'fof-merge-discussions.forum.modal.type_target_label': 'Fusionner avec moi',
  'fof-merge-discussions.forum.post.merged':
    'Fusion de <b>{count}</b> messages de <i>{titles}</i>.|Fusion de <b>{count}</b> messages de <i>{titles}</i>.',
  'fof-secure-https.forum.removed':
    "Image supprim\u00e9e en raison de l'absence de connexion s\u00e9curis\u00e9e via HTTPS.",
  'fof-secure-https.forum.show': 'Afficher quand m\u00eame',
  'fof-follow-tags.forum.index.follow_tag_button': 'Suivre',
  'fof-follow-tags.forum.index.unfollow_tag_button': 'Ne plus suivre',
  'fof-follow-tags.forum.notifications.new_discussion_text':
    '{username} a d\u00e9marr\u00e9',
  'fof-follow-tags.forum.notifications.new_post_text':
    '{username} a publi\u00e9',
  'fof-follow-tags.forum.settings.notify_new_discussion_label':
    'Quelqu\u2019un cr\u00e9\u00e9 une discussion avec une \u00e9tiquette que je suis',
  'fof-follow-tags.forum.settings.notify_new_post_label':
    'Quelqu\u2019un publie un message avec une \u00e9tiquette que je suis',
  'fof-follow-tags.forum.sub_controls.follow_button': 'Suivre',
  'fof-follow-tags.forum.sub_controls.following_button': 'Suivie',
  'fof-follow-tags.forum.sub_controls.following_text':
    'Soyez inform\u00e9 de toutes les nouvelles discussions.',
  'fof-follow-tags.forum.sub_controls.hiding_button': 'Cach\u00e9e',
  'fof-follow-tags.forum.sub_controls.hiding_text':
    'Masquer les discussions de la page Toutes les discussions.',
  'fof-follow-tags.forum.sub_controls.ignoring_button': 'Ignor\u00e9e',
  'fof-follow-tags.forum.sub_controls.ignoring_text':
    'Ne jamais \u00eatre notifi\u00e9.',
  'fof-follow-tags.forum.sub_controls.lurking_button': 'Observ\u00e9e',
  'fof-follow-tags.forum.sub_controls.lurking_text':
    'Soyez inform\u00e9 de toutes les nouvelles discussions et r\u00e9ponses.',
  'fof-follow-tags.forum.sub_controls.not_following_button': 'Ne pas suivre',
  'fof-follow-tags.forum.sub_controls.not_following_text':
    'Soyez averti seulement quand vous \u00eates @mentionn\u00e9.',
  'fof-follow-tags.forum.sub_controls.notify_alert_tooltip':
    'Recevez une notification lorsqu\u2019il y a de nouvelles discussions ou de nouveaux messages',
  'fof-follow-tags.forum.sub_controls.notify_email_tooltip':
    'Recevez un e-mail lorsqu\u2019il y a de nouvelles discussions ou de nouveaux messages',
  'flagrow-impersonate.forum.user_controls.impersonate_button':
    'Se connecter en tant que l\u2019utilisateur',
  'flarum_profile_views.forum.user.title_last_viewers': 'Derniers visiteurs',
  'flarum_profile_views.forum.user.views_count_text':
    'Profil vu {viewcount} fois',
  'kilowhat-mailing.forum.links.mail_all':
    'Envoyer un email \u00e0 tous les utilisateurs',
  'kilowhat-mailing.forum.links.mail_individual':
    'Envoyer un email \u00e0 cet utilisateur',
  'kilowhat-mailing.forum.modal_mail.default_subject':
    'Message de l\u2019administration du forum',
  'kilowhat-mailing.forum.modal_mail.message_label': 'Message',
  'kilowhat-mailing.forum.modal_mail.recipients_label': 'Destinataires',
  'kilowhat-mailing.forum.modal_mail.recipients_placeholder':
    'Indiquez d\u2019autres destinataires ...',
  'kilowhat-mailing.forum.modal_mail.subject_label': 'Sujet',
  'kilowhat-mailing.forum.modal_mail.submit_button': 'Envoyer',
  'kilowhat-mailing.forum.modal_mail.title_text': '\u00c9crire un email',
  'kilowhat-mailing.forum.modal_sent.on_its_way':
    'Tape m\u2019en cinq! Votre email est en route vers {recipientsCount} destinataires !',
  'kilowhat-mailing.forum.modal_sent.title_text': 'Email envoy\u00e9 !',
  'kilowhat-mailing.forum.recipient_kinds.email': 'Email',
  'kilowhat-mailing.forum.recipient_kinds.group': 'Groupe',
  'kilowhat-mailing.forum.recipient_kinds.user': 'Utilisateur',
  'kvothe-keyboard-shortcuts.forum.categories.disc-list': 'Liste de discussion',
  'kvothe-keyboard-shortcuts.forum.categories.disc-page': 'Page de discussion',
  'kvothe-keyboard-shortcuts.forum.categories.global': 'Global',
  'kvothe-keyboard-shortcuts.forum.categories.logged-in':
    'Une fois connect\u00e9',
  'kvothe-keyboard-shortcuts.forum.categories.logged-out':
    'Une fois d\u00e9connect\u00e9',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.back': 'Retour',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.clear':
    'Effacer les notifications',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.first-post':
    'Premier message',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.follow':
    'Suivre / Ne plus suivre',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.help':
    'Aide sur les raccourcis clavier',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.last-post':
    'Dernier message',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.login':
    'Afficher le module de connexion',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.mark-read':
    'Marquer tous les messages comme lus',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.new-disc':
    'Nouvelle discussion',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.next-disc':
    'Prochaine discussion',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.next-post':
    'Nouveau message',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.notifications':
    'Notifications',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.pin-sidebar':
    '\u00c9pingler la barre lat\u00e9rale',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.prev-disc':
    'Discussion pr\u00e9c\u00e9dente',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.prev-post':
    'Message pr\u00e9c\u00e9dent',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.refresh': 'Rafra\u00eechir',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.reply': 'R\u00e9pondre',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.search': 'Rechercher',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.sidebar':
    'Afficher / Masquer la barre lat\u00e9rale',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.signup':
    "Afficher le module d'inscription",
  'kvothe-keyboard-shortcuts.forum.key-descriptions.sort-latest':
    'Trier par activit\u00e9',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.sort-newest':
    'Trier par nouveaut\u00e9',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.sort-oldest':
    'Trier par anciennet\u00e9',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.sort-top':
    'Trier par popularit\u00e9',
  'kvothe-keyboard-shortcuts.forum.key-descriptions.user-menu':
    'Ouvrir le menu utilisateur',
  'kvothe-keyboard-shortcuts.forum.modal-title': 'Raccourcis clavier',
  'fof-stopforumspam.forum.message.spam':
    'Vos informations ont \u00e9t\u00e9 marqu\u00e9es comme spam.',
  'fof-drafts.forum.composer.alert': 'Brouillon enregistr\u00e9.',
  'fof-drafts.forum.composer.exit_alert':
    'Ignorer les modifications apport\u00e9es au brouillon?',
  'fof-drafts.forum.composer.title': 'Enregistrer le brouillon',
  'fof-drafts.forum.dropdown.alert':
    '\u00cates-vous s\u00fbr de vouloir supprimer votre brouillon?',
  'fof-drafts.forum.dropdown.button': 'Supprimer le brouillon',
  'fof-drafts.forum.dropdown.empty_text':
    'Vous n\u2019avez enregistr\u00e9 aucun brouillon',
  'fof-drafts.forum.dropdown.title': 'Brouillons',
  'fof-drafts.forum.dropdown.tooltip': 'Brouillons',
  'fof-terms.forum.accept-modal.accept': 'Confirmer',
  'fof-terms.forum.accept-modal.close': 'Vous pouvez fermer cette fen\u00eatre',
  'fof-terms.forum.accept-modal.i-accept':
    'J\u2019accepte les nouvelles <a>{policy}</a>',
  'fof-terms.forum.accept-modal.title': 'Mise \u00e0 jour des conditions',
  'fof-terms.forum.accept-modal.updated-at':
    'Les conditions ont \u00e9t\u00e9 mises \u00e0 jour {date}',
  'fof-terms.forum.accept-modal.updated-recently':
    'Les conditions ont \u00e9t\u00e9 mises \u00e0 jour depuis votre derni\u00e8re visite',
  'fof-terms.forum.signup.i-accept': 'J\u2019accepte les <a>{policy}</a>',
  'fof-terms.forum.signup.must-accept':
    'Les {policy} doivent \u00eatre accept\u00e9es.',
  'fof-terms.forum.state-modal.accepted-at': 'Accept\u00e9 \u00e0 {date}',
  'fof-terms.forum.state-modal.not-accepted': 'Pas accept\u00e9',
  'fof-terms.forum.state-modal.title':
    '\u00c9tat des conditions pour l\u2019utilisateur {username}',
  'fof-terms.forum.update-alert.can-accept-message':
    'Nous avons r\u00e9cemment mis \u00e0 jour les conditions. Vous devez les accepter pour continuer \u00e0 utiliser ce site.',
  'fof-terms.forum.update-alert.must-accept-message':
    'Nous avons temporairement restreint l\u2019acc\u00e8s \u00e0 votre compte pendant que vous v\u00e9rifiez les nouvelles conditions.',
  'fof-terms.forum.update-alert.review':
    'Cliquez ici pour consulter et accepter les conditions.',
  'fof-terms.forum.user_controls.state_button': '\u00c9tat des conditions',
  'fof-masquerade.forum.alerts.profile-completion-required':
    'Veuillez compl\u00e9ter les champs de profil n\u00e9cessaires ci-dessous.',
  'fof-masquerade.forum.buttons.save-profile': 'Sauvegarder',
  'fof-masquerade.forum.buttons.view-profile': 'Voir le profil',
  'fof-masquerade.forum.fields.boolean.no': 'Non',
  'fof-masquerade.forum.fields.boolean.yes': 'Oui',
  'fof-masquerade.forum.fields.select.none-optional':
    'Aucune option s\u00e9lectionn\u00e9e (facultatif)',
  'fof-masquerade.forum.fields.select.none-required':
    'Veuillez s\u00e9lectionner une option (obligatoire)',
  'fof-share-social.forum.discussion.share_button': 'Partager',
  'fof-share-social.forum.modal.title': 'Partager',
  'fof-share-social.lib.networks.facebook': 'Facebook',
  'fof-share-social.lib.networks.linkedin': 'LinkedIn',
  'fof-share-social.lib.networks.my_mail': 'My World',
  'fof-share-social.lib.networks.odnoklassniki': 'Odnoklassniki',
  'fof-share-social.lib.networks.qq': 'QQ',
  'fof-share-social.lib.networks.qzone': 'QZone',
  'fof-share-social.lib.networks.reddit': 'Reddit',
  'fof-share-social.lib.networks.twitter': 'Twitter',
  'fof-share-social.lib.networks.vkontakte': 'VKontakte',
  'fof-user-directory.forum.page.nav': 'R\u00e9pertoire utilisateurs',
  'fof-user-directory.forum.page.sort.default': 'Par d\u00e9faut',
  'fof-user-directory.forum.page.sort.least_discussions':
    'Le moins de discussions',
  'fof-user-directory.forum.page.sort.least_posts': 'Le moins de messages',
  'fof-user-directory.forum.page.sort.most_discussions':
    'Le plus de discussions',
  'fof-user-directory.forum.page.sort.most_posts': 'Le plus de messages',
  'fof-user-directory.forum.page.sort.newest': 'Le plus r\u00e9cent',
  'fof-user-directory.forum.page.sort.oldest': 'Le plus ancien',
  'fof-user-directory.forum.page.sort.relevance': 'Pertinence',
  'fof-user-directory.forum.page.sort.seen_oldest': 'Connexion ancienne',
  'fof-user-directory.forum.page.sort.seen_recent': 'Connexion r\u00e9cente',
  'fof-user-directory.forum.page.sort.username_az':
    'Nom d\u2019utilisateur (a-z)',
  'fof-user-directory.forum.page.sort.username_za':
    'Nom d\u2019utilisateur (z-a)',
  'fof-user-directory.forum.search.users_heading':
    'Rechercher tous les utilisateurs pour "{query}"',
  'fof-subscribed.forum.notifications.discussion_created_text':
    '{username} a cr\u00e9\u00e9 une discussion',
  'fof-subscribed.forum.notifications.post_unapproved_text':
    '{username} a publi\u00e9 un message qui n\u00e9cessite une approbation',
  'fof-subscribed.forum.notifications.user_created_text':
    '{username} vient de s\u2019inscrire',
  'fof-subscribed.forum.settings.defaults_label':
    'D\u00e9finir "{setting}" activ\u00e9 par d\u00e9faut',
  'fof-subscribed.forum.settings.forced_label':
    'Forcer l\u2019activation de  "{setting}" pour tout le monde',
  'fof-subscribed.forum.settings.notify_discussion_created_label':
    'Quelqu\u2019un a cr\u00e9\u00e9 une discussion',
  'fof-subscribed.forum.settings.notify_post_unapproved_label':
    'Un message publi\u00e9 doit \u00eatre approuv\u00e9',
  'fof-subscribed.forum.settings.notify_user_created_label':
    'Quand quelqu\u2019un s\u2019inscrit',
  'reflar-doorman.forum.sign_up.doorkey_required':
    'S\u2019il vous pla\u00eet entrer un code d\u2019invitation.',
  'reflar-doorman.forum.sign_up.doorman_placeholder': 'Code d\u2019invitation',
  'reflar-doorman.forum.sign_up.doorman_placeholder_optional':
    'Code d\u2019invitation (optionnel)',
  'reflar-doorman.forum.sign_up.invalid_doorkey':
    'Le code d\u2019invitation que vous avez entr\u00e9 n\u2019est pas valide.',
  'fof-ignore-users.forum.profile_link': 'Utilisateurs ignor\u00e9s',
  'fof-ignore-users.forum.user_controls.ignore_button': 'Ignorer',
  'fof-ignore-users.forum.user_controls.ignore_confirmation':
    '\u00cates-vous s\u00fbr de vouloir ignorer cet utilisateur?',
  'fof-ignore-users.forum.user_controls.unignore_button': 'Ne plus ignorer',
  'fof-ignore-users.forum.user_controls.unignore_confirmation':
    '\u00cates-vous s\u00fbr de ne plus vouloir ignorer cet utilisateur?',
  'fof-nightmode.forum.day': 'Mode jour',
  'fof-nightmode.forum.night': 'Mode nuit',
  'fof-spamblock.forum.user_controls.spammer_button': 'Spammeur',
  'fof-spamblock.forum.user_controls.spammer_confirmation':
    "\u00cates-vous s\u00fbr de vouloir\n\u2022 Masquer toutes les discussions et les messages de l'utilisateur\n\u2022 [flarum/suspend] Suspendre l'utilisateur",
  'wiseclock-reply2see.forum.hidden_content': 'CONTENU CACH\u00c9',
  'wiseclock-reply2see.forum.reply_to_see':
    'Vous devez {reply} et actualiser pour voir le contenu',
  'fof-auth-gitlab.forum.log_in.with_gitlab_button': 'Connexion avec GitLab',
  'fof-username-request.forum.account_label':
    'Changer le nom d\u2019utilisateur',
  'fof-username-request.forum.action.approval_label': 'Approuver',
  'fof-username-request.forum.action.decision_title': 'D\u00e9cision',
  'fof-username-request.forum.action.help_text':
    'Assurez-vous que le nom d\u2019utilisateur respecte les directives du forum.',
  'fof-username-request.forum.action.name':
    '{username} a demand\u00e9 que son nom d\u2019utilisateur soit chang\u00e9 en "{newUsername}"',
  'fof-username-request.forum.action.reason_title': 'Raison du refus',
  'fof-username-request.forum.action.rejected_label': 'Refuser',
  'fof-username-request.forum.action.submit_button': 'Soumettre',
  'fof-username-request.forum.action.success': 'D\u00e9cision envoy\u00e9e.',
  'fof-username-request.forum.action.title':
    'Changement de nom d\u2019utilisateur',
  'fof-username-request.forum.dropdown.empty_text':
    'Aucune demande de nom d\u2019utilisateur trouv\u00e9e',
  'fof-username-request.forum.dropdown.exerpt':
    'Nom d\u2019utilisateur demand\u00e9: {requestedUsername}',
  'fof-username-request.forum.dropdown.item_text':
    '{username} a demand\u00e9 un changement de nom d\u2019utilisateur',
  'fof-username-request.forum.dropdown.title':
    'Demandes de changement de nom d\u2019utilisateur',
  'fof-username-request.forum.dropdown.tooltip':
    'Demandes de changement de nom d\u2019utilisateur',
  'fof-username-request.forum.profile_link':
    'Histoire du nom d\u2019utilisateur',
  'fof-username-request.forum.request.confirmation_message':
    'Votre demande a \u00e9t\u00e9 re\u00e7ue et sera examin\u00e9e bient\u00f4t.',
  'fof-username-request.forum.request.current_request':
    'Vous avez d\u00e9j\u00e0 demand\u00e9 le nom d\u2019utilisateur "{username}." Vous pouvez mettre \u00e0 jour ou supprimer cette demande.',
  'fof-username-request.forum.request.delete_button': 'Supprimer la demande',
  'fof-username-request.forum.request.deleted':
    'Demande de nom d\u2019utilisateur supprim\u00e9e.',
  'fof-username-request.forum.request.dismiss_button': 'Compris',
  'fof-username-request.forum.request.submit_button': 'Soumettre la demande',
  'fof-username-request.forum.request.title':
    'Demander un changement de nom d\u2019utilisateur',
  'fof-username-request.forum.results.approved':
    'Votre demande de changement de nom d\u2019utilisateur a \u00e9t\u00e9 approuv\u00e9e!',
  'fof-username-request.forum.results.new_username':
    'Votre nom d\u2019utilisateur est maintenant "{username}"',
  'fof-username-request.forum.results.reason': 'Raison: <i>"{reason}"</i>',
  'fof-username-request.forum.results.rejected':
    'Votre demande de nom d\u2019utilisateur a \u00e9t\u00e9 refus\u00e9e',
  'fof-username-request.forum.results.resubmit':
    'Vous pouvez soumettre une nouvelle demande de nom d\u2019utilisateur \u00e0 partir des param\u00e8tres de votre compte.',
  'fof-username-request.forum.results.title':
    'Changement de nom d\u2019utilisateur',
  'fof-auth-discord.forum.log_in.with_discord_button': 'Connexion avec Discord',
}

// Custom French Moment.js Locales
// Based on: https://github.com/moment/moment/blob/develop/locale/fr.js

export const momentConfig = {
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
    '_',
  ),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
    '_',
  ),
  monthsParseExact: true,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY', // Swiss French uses DD.MM.YYYY. Canadian French uses YYYY-MM-DD.
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    ss: '%d secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
  ordinal: function (number, period) {
    switch (period) {
      // TODO: Return 'e' when day of month > 1. Move this case inside
      // block for masculine words below.
      // See https://github.com/moment/moment/issues/3375
      case 'D':
        return number + (number === 1 ? 'er' : '')

      // Words with masculine grammatical gender: mois, trimestre, jour
      default:
      case 'M':
      case 'Q':
      case 'DDD':
      case 'd':
        return number + (number === 1 ? 'er' : 'e')

      // Words with feminine grammatical gender: semaine
      case 'w':
      case 'W':
        return number + (number === 1 ? 're' : 'e')
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week. Canadian French uses 0, because Sunday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year. Canadian French uses 6, because the week that contains Jan 1st is the first week of the year.
  },
}
