/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const Model = flarum.core.compat['Model']
const Discussion = flarum.core.compat['models/Discussion']
const NotificationGrid = flarum.core.compat['components/NotificationGrid']

import DiscussionLockedPost from './components/DiscussionLockedPost'
import DiscussionLockedNotification from './components/DiscussionLockedNotification'
import addLockBadge from './addLockBadge'
import addLockControl from './addLockControl'

app.initializers.add('flarum-lock', () => {
  app.postComponents.discussionLocked = DiscussionLockedPost
  app.notificationComponents.discussionLocked = DiscussionLockedNotification

  Discussion.prototype.isLocked = Model.attribute('isLocked')
  Discussion.prototype.canLock = Model.attribute('canLock')

  addLockBadge()
  addLockControl()

  extend(NotificationGrid.prototype, 'notificationTypes', function(items) {
    items.add('discussionLocked', {
      name: 'discussionLocked',
      icon: 'fas fa-lock',
      label: app.translator.trans(
        'flarum-lock.forum.settings.notify_discussion_locked_label',
      ),
    })
  })
})
