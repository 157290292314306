/** @jsx m */

import m from 'mithril'
import moment from 'moment'
import bidi from './bidi'
import punycode from 'punycode'
// import ColorThief from 'color-thief-browser'
import s9e from './lib/s9e'
import jquery from 'jquery'
import { phrases, momentConfig } from './forum-translations'

global.m = m
global.moment = moment
global.m.bidi = bidi
global.s9e = s9e
global.punycode = punycode
// global.ColorThief = ColorThief
global.jQuery = global.$ = jquery

const core = require('./core/forum')
const { app, Session, compat } = core

global.app = app
global.flarum = {
  core: {
    ...core,
    compat,
  },
  extensions: {},
}

require('./extensions/forum')

const apiUrl = process.env.API_URL
const protocol = process.env.PROTOCOL

const getForumResource = async (restoredSessionMeta) => {
  const resourcesRequest = m.request({
    method: 'GET',
    url: `${protocol}://${apiUrl}`,
    background: true,
    config: (xhr) => {
      if (restoredSessionMeta.accessToken) {
        xhr.setRequestHeader(
          'X-App-Authorization',
          `Token ${restoredSessionMeta.accessToken}`,
        )
        xhr.withCredentials = true
      }
    },
  })

  const userRequest = restoredSessionMeta.userId
    ? m.request({
        method: 'GET',
        url: `${protocol}://${apiUrl}/users/${restoredSessionMeta.userId}`,
        background: true,
        config: (xhr) => {
          if (restoredSessionMeta.accessToken) {
            xhr.setRequestHeader(
              'X-App-Authorization',
              `Token ${restoredSessionMeta.accessToken}`,
            )
            xhr.withCredentials = true
          }
        },
      })
    : Promise.resolve({ data: {} })

  return Promise.all([resourcesRequest, userRequest]).then(
    ([resources, user]) => ({
      resources: [
        resources.data,
        ...(resources.included || []),
        user.data,
        ...(user.included || []),
      ],
      session: {
        userId: resources.data.attributes.userId,
        ...restoredSessionMeta,
      },
      locales: { fr: 'Français' },
      locale: 'fr',
      apiDocument: {
        links: {
          first: `${protocol}://${apiUrl}/discussions?sort=\u0026page%5Blimit%5D=20`,
        },
        data: [],
      },
    }),
  )
}

const loadApp = async (restoredSessionMeta) => {
  // Setup locale
  app.translator.addTranslations(phrases)
  moment.locale('fr', momentConfig)

  const resources = await getForumResource(restoredSessionMeta)
  app.load(resources)
}

const startup = async (restoredSessionMeta, retry = false) => {
  console.log('restoredSessionMeta: ', restoredSessionMeta)
  if (restoredSessionMeta.accessToken && restoredSessionMeta.userId) {
    console.log('Using restored session ...')
  } else if (restoredSessionMeta.accessToken || restoredSessionMeta.userId) {
    return startup(true)
  } else {
    console.log('Using fresh session ...')
  }

  return loadApp(restoredSessionMeta)
    .then(() => app.bootExtensions(flarum.extensions))
    .then(() => app.boot())
    .catch((e) => {
      if (!retry) {
        Session.flushSessionMeta()
        return startup({ userId: null, accessToken: null }, true)
      }

      console.error(e)
    })
}

export { startup }
