import { DataProxy } from '@apollo/client'

import { User, AuthenticatedUser, AccessToken, DisplayUser } from 'types/user'
import { toLegacyUser } from 'legacy/transformers/user'
import { getApp } from 'legacy/app'
import {
  CurrentAccessToken,
  CurrentToken,
  CurrentUser,
  IsAuthenticated,
  IsUserVerified,
} from 'store/local/user'
import { omitUndefinedValues } from 'utils/sanitize'
import {
  DisplayUserFragment,
  FullUserFragment,
} from 'store/operations/fragments/user'

const DISPLAY_USER_KEYS = [
  'id',
  'username',
  'email',
  'firstName',
  'lastName',
  'avatarUrl',
  'lastSeenAt',
  'joinedAt',
  'bio',
  'preferences',
  'isTeacher',
  'tagNodeId',
]

// Persist changes to localStorage
// Is the only way you should ever update the session properties
// Otherwise your changes won't be persisted across page reload
const updateSessionMeta = (
  { user, accessToken }: { user: User | null; accessToken: AccessToken | null },
  forceUpdate = false,
): void => {
  if ((user !== undefined && accessToken !== undefined) || forceUpdate) {
    if (!user) localStorage.removeItem('userId')
    else localStorage.setItem('userId', user.id)

    if (!accessToken) localStorage.removeItem('accessToken')
    else localStorage.setItem('accessToken', accessToken.token)

    const app = getApp()
    app.session.user =
      user && accessToken ? toLegacyUser({ user, accessToken }) : null
    app.session.accessToken = accessToken?.token || null
  }
}

export const restoreSessionMeta = (): {
  userId: string | null
  accessToken: string | null
} => {
  const userId = localStorage.getItem('userId') || null
  const accessToken = localStorage.getItem('accessToken')

  return {
    userId,
    accessToken,
  }
}

export const updateCurrentUser = (updatedUser: Partial<User>): void => {
  // Only keep defined fields
  const sanitizedUpdatedUser = omitUndefinedValues(updatedUser) as User
  const user = CurrentUser()
  const consolidatedUser = {
    ...user,
    ...sanitizedUpdatedUser,
  }

  // Update legacy user
  const accessToken = CurrentAccessToken()
  const app = getApp()
  app.session.user =
    consolidatedUser && accessToken
      ? toLegacyUser({
          user: consolidatedUser,
          accessToken,
        })
      : null

  // Merge and update user
  CurrentUser(consolidatedUser)
}

export const updateAuthenticatedUser = (
  cache: DataProxy,
  { authenticatedUser }: { authenticatedUser: AuthenticatedUser | null },
): void => {
  updateSessionMeta({
    user: authenticatedUser?.user || null,
    accessToken: authenticatedUser?.accessToken || null,
  })

  IsAuthenticated(!!authenticatedUser)
  IsUserVerified(!!authenticatedUser?.user.isEmailConfirmed)
  CurrentAccessToken(authenticatedUser?.accessToken)
  CurrentToken(authenticatedUser?.accessToken.token)
  CurrentUser(authenticatedUser?.user)
}

export const updateCachedUser = (
  cache: DataProxy,
  { incomingUser }: { incomingUser: User | DisplayUser },
): void => {
  if (!incomingUser) {
    return
  }

  const fullUser = cache.readFragment<User>({
    id: `User:${incomingUser.id}`,
    fragment: FullUserFragment,
  })
  const displayUser = cache.readFragment<DisplayUser>({
    id: `DisplayUser:${incomingUser.id}`,
    fragment: DisplayUserFragment,
  })

  const getDisplayUser = (user: User | DisplayUser): DisplayUser => {
    const entries = Object.entries(user).filter(([key]) =>
      DISPLAY_USER_KEYS.includes(key),
    )

    return {
      ...Object.fromEntries(entries),
      preferences: {
        discloseEmail: user.preferences.discloseEmail,
        discloseOnline: user.preferences.discloseOnline,
      },
    } as DisplayUser
  }
  const updatedFullUser = fullUser && {
    ...fullUser,
    ...incomingUser,
    preferences: { ...fullUser.preferences, ...incomingUser.preferences },
    email: incomingUser.email === null ? fullUser.email : incomingUser.email,
    lastSeenAt:
      incomingUser.lastSeenAt === null
        ? fullUser.lastSeenAt
        : incomingUser.lastSeenAt,
  }
  const updatedDisplayUser = displayUser && {
    ...displayUser,
    ...getDisplayUser(incomingUser),
  }

  updatedFullUser &&
    cache.writeFragment<User>({
      id: `User:${incomingUser.id}`,
      fragment: FullUserFragment,
      data: updatedFullUser,
    })
  updatedDisplayUser &&
    cache.writeFragment<DisplayUser>({
      id: `DisplayUser:${incomingUser.id}`,
      fragment: DisplayUserFragment,
      data: updatedDisplayUser,
    })

  const currentUser = CurrentUser()
  if (currentUser && currentUser.id === incomingUser.id) {
    updateCurrentUser({
      ...incomingUser,
      email: incomingUser.email ?? currentUser.email,
      lastSeenAt: incomingUser.lastSeenAt ?? currentUser.lastSeenAt,
      preferences: { ...currentUser.preferences, ...incomingUser.preferences },
    })
  }
}
