import { useQuery } from '@apollo/client'
import { h, FunctionalComponent, Fragment } from 'preact'
import { route } from 'preact-router'

import styles from './styles.scss'
import { Loader } from 'ui/atoms/Loader'
import {
  GetUserDiscussionsInput,
  GetUserDiscussionsResult,
  GET_USER_DISCUSSIONS,
} from 'store/operations/discussion'
import { DiscussionItem } from 'ui/organisms/DiscussionItem'
import { getRouteUrl, PageType } from 'common/routing'
import { Button } from 'ui/atoms/Button'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

const DISCUSSION_LIMIT_PER_REQUEST = 10

export const UserDiscussions: FunctionalComponent<{ userId: string }> = ({
  userId,
}) => {
  const { t } = useContext(TranslateContext)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const {
    data: getUserDiscussionsData,
    loading: getUserDiscussionsLoading,
    fetchMore,
  } = useQuery<GetUserDiscussionsResult, GetUserDiscussionsInput>(
    GET_USER_DISCUSSIONS,
    {
      variables: { userId, limit: DISCUSSION_LIMIT_PER_REQUEST, offset: 0 },
      fetchPolicy: 'cache-and-network',
    },
  )

  return (
    <div className={styles['user-discussions']}>
      <Loader
        loaded={!getUserDiscussionsLoading}
        passingProps={{
          userDiscussions: getUserDiscussionsData?.getUserDiscussions,
        }}
        className={styles['user-discussions-loader']}
      >
        {({ userDiscussions: { discussions, count, totalCount } }) => (
          <Fragment>
            {discussions.map((d) => (
              <DiscussionItem
                key={`discussion-item-${d.id}`}
                className={styles['discussion-item']}
                discussion={d}
                onClick={() =>
                  route(getRouteUrl(PageType.DISCUSSION, { id: d.id }))
                }
              />
            ))}
            {totalCount > count && (
              <Button
                style="btn-info"
                className={styles['load-more-btn']}
                onClick={async () => {
                  setIsFetchingMore(true)
                  await fetchMore({
                    variables: {
                      limit: DISCUSSION_LIMIT_PER_REQUEST,
                      offset: count,
                      userId,
                    },
                  })
                  setIsFetchingMore(false)
                }}
                loading={isFetchingMore}
                disabled={isFetchingMore}
              >
                {t('core.user_profile.user_discussions.load_more_button')}
              </Button>
            )}
            {totalCount === 0 && (
              <div className={styles['no-item']}>
                {t('core.user_profile.user_discussions.no_discussions')}
              </div>
            )}
          </Fragment>
        )}
      </Loader>
    </div>
  )
}
