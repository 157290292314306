import { h, Fragment, ComponentChildren } from 'preact'
import { forwardRef, useRef } from 'preact/compat'

import styles from './styles.scss'
import { Tooltip, TooltipProps } from 'ui/atoms/Tooltip'
import { Loader } from '../Loader'

export interface ButtonProps {
  children: NonNullable<ComponentChildren>
  className?: string
  onMouseDown?: (event: MouseEvent) => void
  onClick?: (e: h.JSX.TargetedEvent<HTMLDivElement, MouseEvent>) => void
  style?:
    | 'btn-primary'
    | 'btn-primary-faded'
    | 'btn-danger'
    | 'btn-info'
    | 'link'
    | 'link-primary-faded'
    | 'link-primary-faded-hover'
    | 'link-darken-hover'
  size?: 'xs' | 'sm' | 'lg'
  tooltip?: TooltipProps['content']
  tooltipPosition?: TooltipProps['position']
  tooltipWidth?: TooltipProps['width']
  disabled?: boolean
  fill?: boolean
  padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg'
  active?: boolean
  iconOnly?: boolean
  rounded?: boolean
  bold?: boolean
  loading?: boolean
  noRadius?: boolean
  textNoWrap?: boolean
}

const styleToLoaderColor: {
  [style in NonNullable<ButtonProps['style']>]: string
} = {
  'btn-primary': '#fff',
  'btn-primary-faded': '#669977',
  'btn-danger': '#fff',
  'btn-info': '#53aaf1',
  link: '#2aa99c',
  'link-primary-faded': '#000',
  'link-primary-faded-hover': '#000',
  'link-darken-hover': '#fff',
}

// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLDivElement, ButtonProps>(
  (props, forwardedRef) => {
    const localRef = useRef<HTMLDivElement>(null)
    const ref = forwardedRef || localRef

    const {
      children,
      className,
      onClick,
      onMouseDown,
      tooltip,
      style = 'btn-primary',
      size = 'lg',
      tooltipPosition = 'top',
      tooltipWidth,
      disabled = false,
      fill = false,
      iconOnly = false,
      padding = iconOnly ? 'none' : 'lg',
      active = false,
      rounded = false,
      bold = style !== 'link',
      loading = false,
      noRadius = false,
      textNoWrap = false,
    } = props

    return (
      <Fragment>
        <div
          ref={ref}
          className={`${styles.button} ${className || ''} ${
            disabled ? styles.disabled : ''
          } ${styles[`size-${size}` as 'size-xs' | 'size-sm' | 'size-lg']} ${
            fill ? styles.fill : ''
          } ${styles[style]} ${
            styles[
              `padding-${padding}` as
                | 'padding-none'
                | 'padding-sm'
                | 'padding-md'
                | 'padding-lg'
            ]
          } ${active ? styles.active : ''} ${
            iconOnly ? styles['icon-only'] : ''
          } ${rounded ? styles.rounded : ''} ${bold ? styles.bold : ''} ${
            loading ? styles.loading : ''
          } ${noRadius ? styles['no-radius'] : ''} ${
            textNoWrap ? styles['text-no-wrap'] : ''
          }`}
          onClick={!disabled ? onClick : undefined}
          onMouseDown={onMouseDown}
        >
          {loading && (
            <Loader
              className={styles.loader}
              size="sm"
              color={styleToLoaderColor[style]}
            />
          )}
          {children}
        </div>
        {tooltip && (
          <Tooltip
            content={tooltip}
            position={tooltipPosition}
            width={tooltipWidth}
            for={ref}
          />
        )}
      </Fragment>
    )
  },
)
