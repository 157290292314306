import { gql } from '@apollo/client'

export const FullTagFragment = gql`
  fragment FullTag on Tag {
    id
    name
    slug
    description
    parentId
    isForResource
    isForUser
  }
`

export const FullTagWithCategorySlugFragment = gql`
  fragment FullTagWithCategorySlug on Tag {
    ...FullTag
    category {
      slug
    }
  }

  ${FullTagFragment}
`
