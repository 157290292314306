/** @jsx m */

const Model = flarum.core.compat['Model']
const Discussion = flarum.core.compat['models/Discussion']
const IndexPage = flarum.core.compat['components/IndexPage']

import Tag from '../common/models/Tag'
import DiscussionTaggedPost from './components/DiscussionTaggedPost'

import addTagList from './addTagList'
import addTagFilter from './addTagFilter'
import addTagLabels from './addTagLabels'
import addTagControl from './addTagControl'
import addTagComposer from './addTagComposer'

app.initializers.add('flarum-tags', function (app) {
  app.routes.tag = {
    path: '/discussions/tags/:tags',
    component: IndexPage.component(),
  }

  app.route.tag = (tag) => app.route('tag', { tags: tag.slug() })

  app.postComponents.discussionTagged = DiscussionTaggedPost

  app.store.models.tags = Tag

  app.expandedTagCategories = []

  Discussion.prototype.tags = Model.hasMany('tags')
  Discussion.prototype.canTag = Model.attribute('canTag')

  addTagList()
  addTagFilter()
  addTagLabels()
  addTagControl()
  addTagComposer()
})

// Expose compat API
import tagsCompat from './compat'
const { compat } = flarum.core

Object.assign(compat, tagsCompat)
