import { gql } from '@apollo/client'
import { DisplayPostFragment } from './post'
import { DisplayUserFragment } from './user'

export const NotificationFragment = gql`
  fragment Notification on Notification {
    id
    type
    discussionId
    postId
    body
    redirectUrl
    createdAt
    readAt
    fromUser {
      ...DisplayUser
    }
    post {
      ...DisplayPostFragment
    }
    discussion {
      id
      title
    }
  }

  ${DisplayUserFragment}
  ${DisplayPostFragment}
`
