import { h, FunctionalComponent } from 'preact'

import { CommunityTag, Tag } from 'types/tag'
// import { CategoryIcon } from '../CategoryIcon'
import { Badge } from 'ui/atoms/Badge'

interface TagBadgePropsInterface {
  className?: string
  tag: Tag | CommunityTag
  // displayIcon?: boolean
}

export const TagBadge: FunctionalComponent<TagBadgePropsInterface> = (
  props,
) => {
  const { tag, className /*, displayIcon = false*/ } = props

  return (
    <Badge
      className={className}
      // HINT: Badge icons are disbaled for now to allow compliance between Tag and ComunityTags
      // icon={
      //   (!!tag.category?.slug && displayIcon && (
      //     <CategoryIcon categorySlug={tag.category.slug} />
      //   )) ||
      //   undefined
      // }
      value={tag.name}
    />
  )
}
