import { Fragment, h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import { Button } from 'ui/atoms/Button'
import { REGISTER, RegisterInput, RegisterResult } from 'store/operations/user'
import { useMutation, useQuery } from '@apollo/client'
import { fromMutationResult } from 'store/cache/utils'
import { updateAuthenticatedUser } from 'store/cache/user'
import {
  fromEvent,
  toggleEvent,
  getGraphQLErrors,
  handleEnterKey,
} from 'utils/components'
import { getApp } from 'legacy/app'
import {
  GET_CATEGORIES_WITH_TAGS,
  GetCategoriesWithTagsResult,
} from 'store/operations/tagsCategory'
import { getAllTagsFromTagsCategories, getTagParent } from 'utils/tags'
import { TeacherTagsSelect } from 'ui/molecules/TeacherTagsSelect'
import styles from './styles.scss'
import { ModalContent, useGlobalModal } from 'services/ModalManager'
import { sanitizeUsername } from 'utils/sanitize'

export const SignupModal: ModalContent = ({
  onDismiss,
  setAlerts,
  autoFocusRef,
}) => {
  const { t } = useContext(TranslateContext)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false)
  const [discloseEmail, setDiscloseEmail] = useState(false)
  const [isTeacher, setIsTeacher] = useState(true)
  const [tagNodeId, setTagNodeId] = useState<string | null>(null)
  const generatedUsername =
    !!firstName && !!lastName
      ? sanitizeUsername(`${firstName}.${lastName}`).toLowerCase()
      : ''

  const [loginModal] = useGlobalModal('login')

  const {
    data: getTagsData,
    error: getTagsError,
    loading: getTagsLoading,
  } = useQuery<GetCategoriesWithTagsResult>(GET_CATEGORIES_WITH_TAGS)
  const [
    register,
    { error: registerError, loading: registerLoading },
  ] = useMutation<RegisterResult, RegisterInput>(REGISTER, {
    onCompleted: async () => {
      await getApp().session.afterLogin()
      onDismiss()
    },
    update: fromMutationResult(updateAuthenticatedUser, {
      authenticatedUser: 'register',
    }),
  })

  const isValid =
    !!email &&
    !!password &&
    !!(username || generatedUsername) &&
    (isTeacher
      ? !!tagNodeId &&
        !!getTagParent(
          getAllTagsFromTagsCategories(
            getTagsData?.getCategoriesWithTags || [],
          ),
          tagNodeId,
        )
      : true)

  const execRegister = (): void => {
    void register({
      variables: {
        username: username || generatedUsername,
        firstName: firstName || null,
        lastName: lastName || null,
        email,
        password,
        subscribeToNewsletter,
        discloseEmail,
        isTeacher,
        tagNodeId: isTeacher ? tagNodeId : null,
      },
    })
  }

  const switchToLogin = (): void => {
    onDismiss()
    loginModal?.show()
  }

  setAlerts(getGraphQLErrors([registerError, getTagsError]))

  return (
    <Fragment>
      <div className="Modal-body">
        <div className="Form Form--centered">
          <div className="Form-group">
            <input
              className="FormControl"
              name="username"
              type="text"
              placeholder={t('core.signup.first_name_placeholder')}
              value={firstName}
              onInput={fromEvent(setFirstName)}
              disabled={registerLoading}
              ref={autoFocusRef}
              onKeyPress={handleEnterKey(execRegister)}
            />
          </div>

          <div className="Form-group">
            <input
              className="FormControl"
              name="username"
              type="text"
              placeholder={t('core.signup.last_name_placeholder')}
              value={lastName}
              onInput={fromEvent(setLastName)}
              disabled={registerLoading}
              onKeyPress={handleEnterKey(execRegister)}
            />
          </div>

          <div className="Form-group">
            <input
              className="FormControl"
              name="username"
              type="text"
              placeholder={t('core.signup.username_placeholder')}
              value={username || generatedUsername}
              onInput={fromEvent(setUsername)}
              disabled={registerLoading}
              onKeyPress={handleEnterKey(execRegister)}
            />
          </div>

          <div className="Form-group">
            <input
              className="FormControl"
              name="email"
              type="email"
              placeholder={t('core.signup.email_placeholder')}
              value={email}
              onInput={fromEvent(setEmail)}
              disabled={registerLoading}
              onKeyPress={handleEnterKey(execRegister)}
            />
          </div>

          <div className="Form-group">
            <input
              className="FormControl"
              name="password"
              type="password"
              placeholder={t('core.signup.password_placeholder')}
              value={password}
              onInput={fromEvent(setPassword)}
              disabled={registerLoading}
              onKeyPress={handleEnterKey(execRegister)}
            />
          </div>

          <div className="Form-group">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  disabled={registerLoading}
                  checked={isTeacher}
                  onInput={toggleEvent(setIsTeacher, isTeacher)}
                />
                {t('core.signup.is_teacher_placeholder')}
              </label>
            </div>
          </div>

          {isTeacher && getTagsData?.getCategoriesWithTags && (
            <TeacherTagsSelect
              categoriesWithTags={getTagsData.getCategoriesWithTags}
              onChange={setTagNodeId}
              tagNodeId={tagNodeId}
            />
          )}

          <div
            className={styles['legal-consent']}
            dangerouslySetInnerHTML={{
              __html: t('core.signup.legal_consent_html'),
            }}
          />

          <div className="Form-group">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  disabled={registerLoading}
                  checked={subscribeToNewsletter}
                  onInput={toggleEvent(
                    setSubscribeToNewsletter,
                    subscribeToNewsletter,
                  )}
                />
                {t('core.signup.subscribe_to_newsletter')}
              </label>
            </div>
          </div>
          <div className="Form-group">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  disabled={registerLoading}
                  checked={discloseEmail}
                  onInput={toggleEvent(setDiscloseEmail, discloseEmail)}
                />
                {t('core.signup.disclose_email')}
              </label>
            </div>
          </div>

          <div className="Form-group">
            <Button
              style="btn-primary"
              disabled={!isValid}
              fill
              // loading={registerLoading}
              onClick={execRegister}
            >
              {t('core.signup.submit_button')}
            </Button>
          </div>
        </div>
      </div>

      <div className="Modal-footer">
        <p className="SignUpModal-signUp">
          {t('core.signup.login_label_text')}
          <a onClick={switchToLogin}>{t('core.signup.login_link_text')}</a>
        </p>
      </div>
    </Fragment>
  )
}
