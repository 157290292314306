/** @jsx m */

const { extend } = flarum.core.compat['extend']
const IndexPage = flarum.core.compat['components/IndexPage']
const DiscussionList = flarum.core.compat['components/DiscussionList']

export default function() {
  IndexPage.prototype.currentTags = function() {
    const slugs = this.params().tags ? this.params().tags.split(',') : null

    if (slugs) {
      return slugs.map(slug => app.store.getBy('tags', 'slug', slug))
    }

    return []
  }

  // Add a parameter for the IndexPage to pass on to the DiscussionList that
  // will let us filter discussions by tag.
  extend(IndexPage.prototype, 'params', function(params) {
    params.tags = m.route.param('tags')
  })

  // Translate that parameter into a gambit appended to the search query.
  extend(DiscussionList.prototype, 'requestParams', function(params) {
    params.include.push('tags')

    if (this.props.params.tags) {
      params.filter.q =
        (params.filter.q || '') + ' tag:' + this.props.params.tags
    }
  })
}
