import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'
import { DropdownManagerContext } from 'services/DropdownManager'
import { Dropdown } from 'ui/molecules/Dropdown'

export const DropdownRoot: FunctionalComponent = () => {
  const [dropdowns] = useContext(DropdownManagerContext)

  return (
    <div id="dropdowns">
      {dropdowns.map((d) => {
        return (
          <Dropdown
            key={`dropdown-${d.id}`}
            {...d.properties}
            setProps={d.setProps}
            id={d.id}
          />
        )
      })}
    </div>
  )
}
