import { FunctionalComponent, h } from 'preact'
import { route } from 'preact-router'

import illustration from 'assets/landing/illustration.png'
import jjProfile from 'assets/landing/jj-profile.png'
import jmProfile from 'assets/landing/jm-profile.png'
import { Button } from 'ui/atoms/Button'
import styles from './styles.scss'
import { getRouteUrl, PageType } from 'common/routing'
import { ResourceCard } from 'ui/organisms/ResourceCard'
import { FullFilteredResource, ResourceMetadatas } from 'types/resource'
import { Tag } from 'types/tag'
import { PartenersLogo } from 'ui/molecules/PartenersLogo'

export const LandingPage: FunctionalComponent = () => {
  return (
    <div className={`${styles['landing-page']}`}>
      <div className="bg-green-pale py-16 md:py-10">
        <div className="container flex md:flex-row flex-col">
          <div className="flex flex-col justify-between">
            <div className="text-xl leading-normal md:text-2xl md:leading-normal lg:text-4xl lg:leading-normal tracking-wide text-bold font-roboto-slab mb-3 md:mb-5">
              Enseigner le climat,
              <br />
              la plateforme à destination
              <br />
              de la communauté enseignante
            </div>
            <div className="text-lg md:text-xl xl:text-2xl">
              Des <strong>ressources</strong> et une <strong>communauté</strong>
              <br />
              pour enseigner les enjeux socio-écologiques.
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center md:items-end mt-12 md:mt-0">
            <img src={illustration} className="w-1/2 m-4" />
            <div className="flex flex-col lg:flex-row items-center">
              <Button
                className="mb-4 lg:mb-0 lg:mr-2"
                padding="md"
                bold={false}
                onClick={() => route(getRouteUrl(PageType.RESOURCES))}
              >
                <i className={`fas fa-seedling ${styles['btn-icon']}`} />
                Découvrez les ressources
              </Button>
              <Button
                padding="md"
                bold={false}
                onClick={() => route(getRouteUrl(PageType.DISCUSSIONS))}
              >
                <i className={`fas fa-user-graduate ${styles['btn-icon']}`} />
                Consultez la communauté
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray">
        <div className="container py-16">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-[10%]">
            <div>
              <h2
                className={`text-xl lg:text-2xl mb-4 ${styles['underlined']}`}
              >
                Des ressources pour vous aider
              </h2>

              <div className="my-2 flex justify-between items-center">
                <span className="text-base w-2/3">
                  Une bibliothèque de <strong>cours</strong> et{' '}
                  <strong>supports</strong> pensés par vous et vos pairs
                </span>
                <Button
                  padding="md"
                  style="btn-info"
                  bold={false}
                  onClick={() => route(getRouteUrl(PageType.RESOURCES))}
                  className="ml-4"
                  textNoWrap
                >
                  Découvrir
                </Button>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-3 mt-4">
                {[
                  {
                    title: "L'Écoconception dans l'automobile",
                    authorUserame: 'Jean-Marc Jancovici',
                    authorAvatar: jmProfile,
                    viewsCount: 139,
                    recommendationCount: 26,
                    tags: [
                      { id: 'dummy', name: 'Cours complet' } as Tag,
                      { id: 'dummy', name: 'Exercice' } as Tag,
                    ],
                  },
                  {
                    title: 'Cycle de vie des batteries',
                    authorUserame: 'Héléna',
                    viewsCount: 210,
                    recommendationCount: 15,
                    tags: [{ id: 'dummy', name: 'Examen' } as Tag],
                  },
                  {
                    title: "Les énergies fossiles dans l'histoire",
                    authorUserame: 'Clémence',
                    viewsCount: 238,
                    recommendationCount: 41,
                    tags: [
                      { id: 'dummy', name: 'Présentation' } as Tag,
                      { id: 'dummy', name: 'Article' } as Tag,
                    ],
                  },
                  {
                    title: 'Les défis environnementaux du XXIe siècle',
                    authorUserame: 'Jean Jouzel',
                    authorAvatar: jjProfile,
                    viewsCount: 167,
                    recommendationCount: 12,
                    tags: [
                      { id: 'dummy', name: 'Vidéo' } as Tag,
                      { id: 'dummy', name: 'Atelier' } as Tag,
                    ],
                  },
                ].map(
                  (
                    {
                      authorUserame,
                      recommendationCount,
                      tags,
                      title,
                      viewsCount,
                      authorAvatar,
                    },
                    idx,
                  ) => (
                    <ResourceCard
                      key={`demo-resource-card-${idx}`}
                      className={`shadow-lg ${
                        idx > 1 ? 'hidden sm:block md:hidden lg:block' : ''
                      }`}
                      showScore={false}
                      resource={
                        ({
                          title,
                          author: {
                            avatarUrl: authorAvatar,
                            firstName: '',
                            lastName: '',
                            username: authorUserame,
                            id: 'dummy',
                          },
                          canFlag: false,
                          canUpdate: false,
                          createdAt: new Date().toString(),
                          description: 'This is a description',
                          files: [],
                          hyperlinks: null,
                          id: 'dummy',
                          keywords: 'key words',
                          markdownDescription: '',
                          metadatas: {
                            totalViewCount: viewsCount,
                            totalRecommendationCount: recommendationCount,
                          } as Required<ResourceMetadatas>,
                          originalAuthor: '',
                          tags,
                        } as unknown) as FullFilteredResource
                      }
                    />
                  ),
                )}
              </div>
            </div>

            <div>
              <h2
                className={`text-xl lg:text-2xl mb-4 ${styles['underlined']}`}
              >
                Une communauté à vos côtés
              </h2>

              <div className="flex justify-between items-center">
                <span className="text-base w-2/3">
                  Prolongez la connaissance en <strong>discutant</strong> avec
                  les membres de notre <strong>communauté</strong>
                </span>
                <Button
                  padding="md"
                  style="btn-info"
                  bold={false}
                  onClick={() => route(getRouteUrl(PageType.DISCUSSIONS))}
                  textNoWrap
                >
                  Découvrir
                </Button>
              </div>

              <div className="lg:relative font-ubuntu">
                <div className="bg-white rounded-lg p-3 my-4 shadow-lg lg:w-3/4 lg:z-10 lg:absolute lg:top-0 lg:left-0">
                  <div className="flex">
                    <img className="self-center inline-block" src={jmProfile} />
                    <div className="ml-2">
                      <span className="block font-bold text-sm">
                        Jean-marc Jancovici
                      </span>
                      <span className="block text-xs">
                        Enseignant à l’école des Mines de Paris
                      </span>
                    </div>
                  </div>
                  <div className="my-2 text-sm">
                    <span className="block mb-2">Bonjour à tous</span>
                    Je souhaite réaliser une conférence dans une grande école
                    d’ingénieur sur la problématique énergie-climat, en 2
                    heures.
                    <br />
                    Cependant, je sèche sur un point : l’ozone...
                  </div>
                </div>
                <div className="bg-white rounded-lg p-3 mt-4 shadow-lg lg:absolute lg:mt-48 lg:ml-36">
                  <div className="flex flex-row-reverse">
                    <img className="self-center inline-block" src={jjProfile} />
                    <div className="mr-2 text-right">
                      <span className="block font-bold font-sm">
                        Jean Jouzel
                      </span>
                      <span className="block text-xs">
                        Glaciologue & Enseignant
                      </span>
                    </div>
                  </div>
                  <div className="my-2 text-sm">
                    <span className="block mb-2">Bonjour Jean-Marc, </span>
                    Rien de plus simple ! Voici un document pour t’expliquer le
                    rôle de l’ozone dans le cadre de la formation de la glace en
                    haute altitudes ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="container py-8">
            <h2
              className={`text-xl lg:text-2xl font-light m-0 mb-5 ${styles['underlined']}`}
            >
              Avec nous dans l&apos;aventure
            </h2>
            <PartenersLogo className="grid-cols-2 md:grid-cols-4" />
          </div>
        </div>

        <div className="container py-16">
          <div className="text-4xl text-center text-bold font-roboto-slab mb-12">
            Toutes vos questions
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-bold my-2">
              Qu’est-ce que enseignerleclimat.org ?
            </h2>
            <p className="text-xl">
              La plateforme enseignerleclimat.org a pour objectif de favoriser
              le partage de ressources et d’expériences entre enseignants et
              autres acteurs de la communauté pédagogique sur l’enseignement des
              enjeux socio-écologiques. Cette plateforme est ouverte aux
              professionnels de l’enseignement, quelque soit leurs disciplines,
              du primaire au supérieur !
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-bold my-2">Qui peut s’inscrire ?</h2>
            <p className="text-xl">
              Cette plateforme s&apos;adresse en priorité aux enseignants et
              assimilés (intervenants, conférenciers...) qui recherchent ou
              souhaitent partager du contenu pédagogique avec leurs pairs.
              Cependant, toute personne qui recherche de telles ressources
              (étudiants, responsable pédagogique...) peut également utiliser
              cette plateforme.
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl font-bold my-2">
              Enseigner le climat... mais le reste ?
            </h2>
            <p className="text-xl">
              Bien que la problématique du dérèglement climatique soit à
              l’origine de notre projet, nous vous invitons à penser plus grand
              et à discuter de l’ensemble des enjeux liés aux limites
              planétaires (ressources, biodiversité, énergie…) et les enjeux
              sociaux et sociétaux afférents (santé, pollution, inégalités,
              conflits…).
            </p>
          </div>
          <div className="flex justify-center my-4">
            <Button
              style="btn-info"
              bold={false}
              onClick={() =>
                window.open(
                  'https://docs.google.com/document/d/e/2PACX-1vQqDNh_SHnKF1E7pA6hw_gT78Z4MQ0uDsHklorQrX3EDZz34Vb8OJYkNNO31VORJo7u2tPLAYVdEEQs/pub',
                )
              }
            >
              CONSULTER LA FAQ
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
