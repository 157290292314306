import { FunctionalComponent, h } from 'preact'

import shiftProjectLogo from 'assets/landing/the-shift-project-logo.png'
import theShiftersLogo from 'assets/landing/the-shifters-logo.png'
import edltLogo from 'assets/landing/enseignants-de-la-transition-logo.png'
import uvedLogo from 'assets/landing/uved-logo.png'

import shiftProjectLogoBright from 'assets/landing/the-shift-project-logo-bright.png'
import theShiftersLogoBright from 'assets/landing/the-shifters-logo-bright.png'
import edltLogoBright from 'assets/landing/enseignants-de-la-transition-logo-bright.png'
import uvedLogoBright from 'assets/landing/uved-logo-bright.png'

export const PartenersLogo: FunctionalComponent<{
  className?: string
  brightLogos?: boolean
}> = ({ className = '', brightLogos = false }) => {
  return (
    <div className={`inline-grid gap-y-4 gap-x-12 items-end ${className}`}>
      <a href="https://theshiftproject.org" target="_blank">
        <img src={brightLogos ? shiftProjectLogoBright : shiftProjectLogo} />
      </a>
      <a href="https://theshiftproject.org/equipe/#benevoles" target="_blank">
        <img src={brightLogos ? theShiftersLogoBright : theShiftersLogo} />
      </a>
      <a href="https://www.enseignantsdelatransition.org/" target="_blank">
        <img src={brightLogos ? edltLogoBright : edltLogo} />
      </a>
      <a href="https://www.uved.fr/" target="_blank">
        <img src={brightLogos ? uvedLogoBright : uvedLogo} />
      </a>
    </div>
  )
}
