/** @jsx m */

const { extend } = flarum.core.compat['extend']
const DiscussionControls = flarum.core.compat['utils/DiscussionControls']
const DiscussionPage = flarum.core.compat['components/DiscussionPage']
const Button = flarum.core.compat['components/Button']

export default function addStickyControl() {
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canSticky()) {
      items.add(
        'sticky',
        Button.component({
          children: app.translator.trans(
            discussion.isSticky()
              ? 'flarum-sticky.forum.discussion_controls.unsticky_button'
              : 'flarum-sticky.forum.discussion_controls.sticky_button',
          ),
          icon: 'fas fa-thumbtack',
          onclick: this.stickyAction.bind(discussion),
        }),
      )
    }
  })

  DiscussionControls.stickyAction = function() {
    this.save({ isSticky: !this.isSticky() }).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update()
      }

      // m.redraw()
    })
  }
}
