import { FunctionalComponent } from 'preact'

import DiscussionPage from 'ui/pages/DiscussionPage'
import DiscussionsPage from 'ui/pages/DiscussionsPage'
import LandingPage from 'ui/pages/LandingPage'
import NotFoundPage from 'ui/pages/NotFoundPage'
import ResourcePage from 'ui/pages/ResourcePage'
import ResourcesPage from 'ui/pages/ResourcesPage'
import UserPage from 'ui/pages/UserPage'
import ResetPasswordPage from 'ui/pages/ResetPasswordPage'
import { PageType } from 'common/routing'
import { AboutPage } from 'ui/pages/AboutPage'

const routeToComponent = {
  [PageType.LANDING]: LandingPage,
  [PageType.RESOURCES]: ResourcesPage,
  [PageType.RESOURCE]: ResourcePage,
  [PageType.RESOURCE_MODERATION]: ResourcePage,
  [PageType.DISCUSSIONS]: DiscussionsPage,
  [PageType.DISCUSSIONS_BY_TAGS]: DiscussionsPage,
  [PageType.DISCUSSION]: DiscussionPage,
  [PageType.DISCUSSION_MODERATION]: DiscussionPage,
  [PageType.USER_PROFILE]: UserPage,
  [PageType.USER_PROFILE_RESOURCES]: UserPage,
  [PageType.USER_PROFILE_POSTS]: UserPage,
  [PageType.USER_PROFILE_DISCUSSIONS]: UserPage,
  [PageType.USER_PROFILE_SETTINGS]: UserPage,
  [PageType.RESET_PASSWORD]: ResetPasswordPage,
  [PageType.NOT_FOUND]: NotFoundPage,
  [PageType.ABOUT]: AboutPage,
}

export const getRouteComponent = (page: PageType): FunctionalComponent => {
  return routeToComponent[page]
}
