/** @jsx m */

const { extend } = flarum.core.compat['extend']
const app = flarum.core.compat['app']
const Post = flarum.core.compat['models/Post']
const Model = flarum.core.compat['Model']
const NotificationGrid = flarum.core.compat['components/NotificationGrid']

import addLikeAction from './addLikeAction'
import addLikesList from './addLikesList'
import PostLikedNotification from './components/PostLikedNotification'

app.initializers.add('flarum-likes', () => {
  app.notificationComponents.postLiked = PostLikedNotification

  Post.prototype.canLike = Model.attribute('canLike')
  Post.prototype.likes = Model.hasMany('likes')

  addLikeAction()
  addLikesList()

  extend(NotificationGrid.prototype, 'notificationTypes', function(items) {
    items.add('postLiked', {
      name: 'postLiked',
      icon: 'far fa-thumbs-up',
      label: app.translator.trans(
        'flarum-likes.forum.settings.notify_post_liked_label',
      ),
    })
  })
})
