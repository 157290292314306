import { gql } from '@apollo/client'
import { SimpleResult } from 'types/graphql'

import { ModerationFlag } from 'types/moderationFlag'
import { ModerationFlagFragment } from './fragments/moderationFlags'

export type GetModerationFlagsCountInput = void
export type GetModerationFlagsCountResult = {
  getModerationFlagsCount: { count: number }
}
export const GET_MODERATION_FLAGS_COUNT = gql`
  query GetModerationFlagsCount {
    getModerationFlagsCount {
      count
    }
  }
`

export type GetModerationFlagsInput = void
export type GetModerationFlagsResult = {
  getModerationFlags: ModerationFlag[]
}
export const GET_MODERATION_FLAGS = gql`
  query GetModerationFlags {
    getModerationFlags {
      ...ModerationFlagFragment
    }
  }

  ${ModerationFlagFragment}
`

export type GetModerationFlagByIdInput = { flagId: string }
export type GetModerationFlagByIdResult = {
  getModerationFlagById: ModerationFlag
}
export const GET_MODERATION_FLAG_BY_ID = gql`
  query GetModerationFlagById($flagId: String!) {
    getModerationFlagById(flagId: $flagId) {
      ...ModerationFlagFragment
    }
  }

  ${ModerationFlagFragment}
`

export type MarkFlagAsReviewedInput = { flagId: string }
export type MarkFlagAsReviewedResult = {
  markFlagAsReviewed: SimpleResult
}
export const MARK_FLAG_AS_REVIEWED = gql`
  mutation MarkFlagAsReviewed($flagId: String!) {
    markFlagAsReviewed(flagId: $flagId) {
      ok
    }
  }
`

export type AddModerationFlagInput =
  | {
      resourceId: string
      reason: string
      reasonDetail?: string
    }
  | {
      postId: string
      reason: string
      reasonDetail?: string
    }
export type AddModerationFlagResult = {
  addModerationFlag: boolean
}
export const ADD_MODERATION_FLAG = gql`
  mutation addModerationFlag(
    $postId: ID
    $resourceId: ID
    $reason: String!
    $reasonDetail: String
  ) {
    addModerationFlag(
      postId: $postId
      resourceId: $resourceId
      reason: $reason
      reasonDetail: $reasonDetail
    )
  }
`
