import { FunctionalComponent, JSX } from 'preact'
import { createPortal } from 'preact/compat'

export const Portal: FunctionalComponent<{
  into: string
  children: JSX.Element
}> = ({ into, children }) => {
  const select = into.startsWith('#')
    ? (id: string) => document.getElementById(id.slice(1))
    : (tag: string) => document.getElementsByTagName(tag)[0]
  const element = select(into)

  if (!element) {
    throw new Error(`[Portal] element not found in the DOM: ${into}`)
  }

  return createPortal(children, element)
}
