/** @jsx m */

const app = flarum.core.compat['app']
const Model = flarum.core.compat['Model']
const Discussion = flarum.core.compat['models/Discussion']

import DiscussionStickiedPost from './components/DiscussionStickiedPost'
import addStickyBadge from './addStickyBadge'
import addStickyControl from './addStickyControl'
import addStickyExcerpt from './addStickyExcerpt'

app.initializers.add('flarum-sticky', () => {
  app.postComponents.discussionStickied = DiscussionStickiedPost

  Discussion.prototype.isSticky = Model.attribute('isSticky')
  Discussion.prototype.canSticky = Model.attribute('canSticky')

  addStickyBadge()
  addStickyControl()
  addStickyExcerpt()
})
