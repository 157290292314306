import { gql } from '@apollo/client'

export const ResourceMetadatasFragment = gql`
  fragment ResourceMetadatas on ComputedResourceMetadatas {
    resourceId
    totalScore
    totalViewCount
    totalRecommendationCount
    isRecommendedByMe
    isReliable
  }
`

export const FilteredResourceMetadatasFragment = gql`
  fragment FilteredResourceMetadatas on ComputedResourceMetadatas {
    ...ResourceMetadatas
    filteredScore
    filteredViewCount
    filteredRecommendationCount
  }

  ${ResourceMetadatasFragment}
`
