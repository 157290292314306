import { gql } from '@apollo/client'
import { SimpleResult } from 'types/graphql'

import {
  Resource,
  FullResource,
  FullFilteredResource,
  CreateResourcePayload,
  UpdateResourcePayload,
} from 'types/resource'
import {
  FullResourceFragment,
  FullFilteredResourceFragment,
} from './fragments/resource'

export type CreateResourceInput = CreateResourcePayload
export type CreateResourceResult = {
  createResource: Resource
}
export const CREATE_RESOURCE = gql`
  mutation CreateResource(
    $originalAuthor: String
    $hyperlinks: String
    $files: [UploadedFile!]!
    $keywords: String!
    $postedForTagNodeId: ID
    $tags: [ID!]!
    $markdownDescription: String!
    $title: String!
  ) {
    createResource(
      originalAuthor: $originalAuthor
      hyperlinks: $hyperlinks
      files: $files
      keywords: $keywords
      postedForTagNodeId: $postedForTagNodeId
      tags: $tags
      markdownDescription: $markdownDescription
      title: $title
    ) {
      ...FullResource
    }
  }

  ${FullResourceFragment}
`

export type UpdateResourceInput = {
  resourceId: string
} & UpdateResourcePayload
export type UpdateResourceResult = {
  updateResource: Resource
}
export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $resourceId: ID!
    $originalAuthor: String
    $hyperlinks: String
    $keywords: String!
    $tags: [ID!]!
    $markdownDescription: String!
    $title: String!
    $filesToAdd: [UploadedFile!]!
    $filesToDelete: [ID!]!
  ) {
    updateResource(
      resourceId: $resourceId
      originalAuthor: $originalAuthor
      hyperlinks: $hyperlinks
      keywords: $keywords
      tags: $tags
      markdownDescription: $markdownDescription
      title: $title
      filesToAdd: $filesToAdd
      filesToDelete: $filesToDelete
    ) {
      ...FullResource
    }
  }

  ${FullResourceFragment}
`

export type GetResourcesInput = {
  tagsFilter?: string[]
  teacherTagNodeId?: string
  pageIndex?: number
  pageItemCount?: number
  searchTerms?: string
  allPublic?: boolean
}
export type GetResourcesResult = {
  getResources: {
    resources: FullFilteredResource[]
    pageCount: number
    itemCount: number
    pageIndex: number
    pageItemCount: number
    isRelevant: boolean
  }
}
export const GET_RESOURCES = gql`
  query GetResources(
    $tagsFilter: [ID!]
    $teacherTagNodeId: ID
    $pageIndex: Int
    $pageItemCount: Int
    $searchTerms: String
    $allPublic: Boolean
  ) {
    getResources(
      tagsFilter: $tagsFilter
      teacherTagNodeId: $teacherTagNodeId
      pageIndex: $pageIndex
      pageItemCount: $pageItemCount
      searchTerms: $searchTerms
      allPublic: $allPublic
    ) {
      resources {
        ...FullFilteredResource
      }
      itemCount
      pageCount
      pageItemCount
      isRelevant
    }
  }

  ${FullFilteredResourceFragment}
`

export type GetUserResourcesInput = {
  limit: number
  offset: number
  userId: string
}
export type GetUserResourcesResult = {
  getUserResources: {
    resources: FullResource[]
    offset: number
    count: number
    totalCount: number
  }
}
export const GET_USER_RESOURCES = gql`
  query GetUserResources($offset: Int!, $limit: Int!, $userId: ID!) {
    getUserResources(offset: $offset, limit: $limit, userId: $userId) {
      resources {
        ...FullResource
      }
      offset
      count
      totalCount
    }
  }

  ${FullResourceFragment}
`

export type GetResourceByIdInput = {
  id: string
}
export type GetResourceByIdResult = {
  getResourceById: FullResource
}
export const GET_RESOURCE_BY_ID = gql`
  query GetResourceById($id: ID!) {
    getResourceById(id: $id) {
      ...FullResource
    }
  }

  ${FullResourceFragment}
`

export type ToggleResourceRecommendationInput = {
  resourceId: string
  tagNodeId?: string | null
}
export type ToggleResourceRecommendationResult = {
  toggleResourceRecommendation: FullResource
}
export const TOGGLE_RESOURCE_RECOMMENDATION_VOTE = gql`
  mutation ToggleResourceRecommendation($resourceId: ID!, $tagNodeId: ID) {
    toggleResourceRecommendation(
      resourceId: $resourceId
      tagNodeId: $tagNodeId
    ) {
      ...FullResource
    }
  }

  ${FullResourceFragment}
`

export type AddResourceDownloadInput = {
  resourceId: string
  fileId?: string
}
export type AddResourceDownloadResult = {
  addResourceDownload: SimpleResult
}
export const ADD_RESOURCE_DOWNLOAD = gql`
  mutation AddResourceDownload($resourceId: ID!, $fileId: ID) {
    addResourceDownload(resourceId: $resourceId, fileId: $fileId) {
      ok
    }
  }
`
