import { h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import { fromEvent } from 'utils/components'
import { Button } from 'ui/atoms/Button'
import {
  ForgotPasswordResult,
  ForgotPasswordInput,
  FORGOT_PASSWORD,
} from 'store/operations/user'
import { ModalContent } from 'services/ModalManager'

export const ForgotPasswordModal: ModalContent = ({
  onDismiss,
  autoFocusRef,
}) => {
  const { t } = useContext(TranslateContext)
  const [email, setEmail] = useState('')

  const [forgotPassword, { loading, error, called }] = useMutation<
    ForgotPasswordResult,
    ForgotPasswordInput
  >(FORGOT_PASSWORD)
  const success = called && !error

  return (
    <div className="Modal-body">
      {success ? (
        <div className="Form Form--centered">
          <p className="helpText">
            {t('core.forgot_password_modal.email_sent_message')}
          </p>
          <div className="Form-group">
            <Button onClick={onDismiss}>
              {t('core.forgot_password_modal.dismiss_button')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="Form Form--centered">
          <p className="helpText">{t('core.forgot_password_modal.text')}</p>
          <div className="Form-group">
            <input
              className="FormControl"
              name="email"
              type="email"
              placeholder={t('core.forgot_password_modal.email_placeholder')}
              disabled={loading}
              ref={autoFocusRef}
              value={email}
              onChange={fromEvent(setEmail)}
            />
          </div>
          <div className="Form-group">
            <Button
              loading={loading}
              onClick={() => void forgotPassword({ variables: { email } })}
            >
              {t('core.forgot_password_modal.submit_button')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
