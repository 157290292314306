import { useReactiveVar } from '@apollo/client'
import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'

import { CurrentUser } from 'store/local/user'
import { Button } from 'ui/atoms/Button'
import {
  ANON_RECOMMENDATION_TIP_READ_KEY,
  AUTHENTICATED_RECOMMENDATION_TIP_READ_KEY,
} from 'utils/constants'

import styles from './styles.scss'

const getTipReadState = (authenticated: boolean): boolean => {
  return !!localStorage.getItem(
    authenticated
      ? AUTHENTICATED_RECOMMENDATION_TIP_READ_KEY
      : ANON_RECOMMENDATION_TIP_READ_KEY,
  )
}

interface RecommendationButtonPropsInterface {
  active?: boolean
  className?: string
  large?: boolean
  onClick: (e: h.JSX.TargetedEvent<HTMLDivElement, MouseEvent>) => void
  count: number
}

export const RecommendationButton: FunctionalComponent<
  RecommendationButtonPropsInterface
> = (props) => {
  const { t } = useContext(TranslateContext)
  const authenticatedUser = useReactiveVar(CurrentUser)
  const isAuthenticated = !!authenticatedUser
  const { active = false, className, large = false, onClick, count } = props
  const [isTipRead, setIsTipRead] = useState(getTipReadState(isAuthenticated))

  useEffect(() => {
    const tipReadState = getTipReadState(isAuthenticated)
    if (tipReadState !== isTipRead) {
      setIsTipRead(tipReadState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const markTipAsRead = (): void => {
    localStorage.setItem(
      isAuthenticated
        ? AUTHENTICATED_RECOMMENDATION_TIP_READ_KEY
        : ANON_RECOMMENDATION_TIP_READ_KEY,
      'true',
    )
    setIsTipRead(true)
  }

  return (
    <div className={styles['recommendation-wrapper']}>
      <div
        className={`${className || ''} ${styles['recommendation-button']} ${
          large ? styles.large : styles.small
        } ${active ? styles.active : ''} ${isTipRead ? '' : styles.animated}`}
        onClick={onClick}
      >
        <i className={`${styles.regular} far fa-heart`} />
        <i className={`${styles.solid} fas fa-heart`} />
      </div>
      <div className={styles['hover-wrapper']}>
        <div className={styles['recommendation-count']}>{count}</div>
        <div
          className={`${styles.tip} ${active ? styles.active : ''} ${
            isTipRead ? styles.read : ''
          }`}
        >
          <div className={styles['tip-text']}>
            <span>{t('core.resource.header.recommendations_tip_tagline')}</span>
            <br />
            <span>
              {t('core.resource.header.recommendations_tip_reason')}
            </span>{' '}
            <span>
              <strong>
                {isAuthenticated
                  ? t(
                      'core.resource.header.recommendations_tip_authenticated_action',
                    )
                  : t(
                      'core.resource.header.recommendations_tip_unauthenticated_action',
                    )}
              </strong>
            </span>
          </div>
          <div className={styles['tip-button']}>
            <Button
              size="sm"
              style="btn-info"
              padding="xs"
              bold={false}
              onClick={markTipAsRead}
            >
              {t('core.resource.header.recommendations_tip_understood')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
