import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'
import { Button } from '../Button'

import styles from './styles.scss'

export const MobileWarning: FunctionalComponent<{ onContinue: () => void }> = ({
  onContinue,
}) => {
  const { t } = useContext(TranslateContext)

  return (
    <div className={styles['mobile-warning']}>
      <h1>
        <i className="fas fa-graduation-cap" />
        <i className="fas fa-times" />
        <i className="fas fa-mobile-alt" />
      </h1>
      <p>{t('core.mobile.warning_text')}</p>
      <Button size="sm" style="btn-danger" onClick={onContinue}>
        {t('core.mobile.continue_button')}
      </Button>
    </div>
  )
}
