import { TranslateParams } from '@denysvuika/preact-translate/src/translateParams'
import { DisplayUser } from 'types/user'

export const getDeletedUserPlaceholder = (
  t: (key: string, params?: TranslateParams | undefined) => string,
): DisplayUser => ({
  id: 'deleted-user',
  username: t('core.user.deleted_account.username'),
  isTeacher: false,
  joinedAt: new Date().toISOString(),
  preferences: { discloseEmail: false, discloseOnline: false },
  avatarUrl: null,
  bio: null,
  email: null,
  firstName: null,
  lastName: null,
  lastSeenAt: null,
  tagNodeId: null,
})
