import { gql } from '@apollo/client'
import { DisplayPostFragment } from './post'
import { DisplayResourceFragment } from './resource'

import { DisplayUserFragment } from './user'

export const ModerationFlagFragment = gql`
  fragment ModerationFlagFragment on ModerationFlag {
    id
    postId
    post {
      ...DisplayPostFragment
    }
    resourceId
    resource {
      ...DisplayResource
    }
    type
    userId
    reason
    reasonDetail
    createdAt
    user {
      ...DisplayUser
    }
  }

  ${DisplayResourceFragment}
  ${DisplayPostFragment}
  ${DisplayUserFragment}
`
