import { TranslateContext } from '@denysvuika/preact-translate'
import { TranslateParams } from '@denysvuika/preact-translate/src/translateParams'
import { h, FunctionalComponent } from 'preact'
import { useContext } from 'preact/hooks'

import { Post } from 'types/post'
import styles from './styles.scss'

const formatters: {
  [eventType in Exclude<Post['type'], 'comment'>]: (
    post: Post,
    t: (key: string, params?: TranslateParams | undefined) => string,
  ) => {
    icon: string
    html: string
  }
} = {
  discussionRenamed: (post, t) => ({
    icon: 'fas fa-pencil-alt',
    html: t('core.community.post.event.discussion_renamed', {
      user: post.author?.username || t('core.community.post.deleted_user'),
      newTitle: (JSON.parse(post.content) as [string, string])[1],
    }),
  }),
  discussionStickied: (post, t) => ({
    icon: 'fas fa-thumbtack',
    html: t(
      `core.community.post.event.discussion_${
        (
          JSON.parse(post.content) as {
            sticky: boolean
          }
        ).sticky
          ? ''
          : 'un'
      }stickied`,
      {
        user: post.author?.username || t('core.community.post.deleted_user'),
      },
    ),
  }),
  discussionLocked: (post, t) => ({
    icon: 'fas fa-lock',
    html: t(
      `core.community.post.event.discussion_${
        (
          JSON.parse(post.content) as {
            locked: boolean
          }
        ).locked
          ? ''
          : 'un'
      }locked`,
      {
        user: post.author?.username || t('core.community.post.deleted_user'),
      },
    ),
  }),
  discussionTagged: (post, t) => {
    const [before, after] = JSON.parse(post.content) as [string[], string[]]
    const deleted = before.filter((b) => !after.includes(b))
    const added = after.filter((a) => !before.includes(a))
    const updates = [
      !!added.length &&
        t('core.community.post.event.discussion_tag_added', {
          tags: added.join(', '),
          pluralOrSingularTag: t(
            `core.community.post.event.discussion_tag_updated_${
              added.length > 1 ? 'plural' : 'singular'
            }`,
          ),
        }),
      !!added.length &&
        !!deleted.length &&
        t('core.community.post.event.discussion_tag_updated_and'),
      !!deleted.length &&
        t('core.community.post.event.discussion_tag_deleted', {
          tags: deleted.join(', '),
          pluralOrSingularTag: t(
            `core.community.post.event.discussion_tag_updated_${
              deleted.length > 1 ? 'plural' : 'singular'
            }`,
          ),
        }),
    ]
      .filter((x) => !!x)
      .join(' ')

    return {
      icon: 'fas fa-tag',
      html: t('core.community.post.event.discussion_tag_updated', {
        user: post.author?.username || t('core.community.post.deleted_user'),
        updates: updates,
      }),
    }
  },
}

export const EventPost: FunctionalComponent<{
  post: Post & { type: Exclude<Post['type'], 'comment'> }
  className?: string
  elementId?: string
}> = ({ post, className = '', elementId }) => {
  const { t } = useContext(TranslateContext)

  const { icon, html } = formatters[post.type](post, t)

  return (
    <div className={`${styles['post-wrapper']} ${className}`} id={elementId}>
      <div className={`${styles.post} ${styles.event}`}>
        <div className={styles['post-content']}>
          <i className={`${styles.icon} ${icon}`} />
          <div
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </div>
  )
}
