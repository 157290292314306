import {
  h,
  AnyComponent,
  Component,
  createContext,
  ComponentChildren,
} from 'preact'
import { useContext } from 'preact/hooks'

const ALERT_DURATION_DEFAULT = 5000

export interface AlertAttributes {
  className?: string
  type: 'info' | 'error' | 'warning' | 'success' | 'neutral'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: AnyComponent<any, any>[]
  dismissible?: boolean
  onDismiss?: () => void
  // Setting duration to 0 will disable alert auto-dismiss
  duration?: number
  // This message will be passed through translation
  // and left as it is if no translation is found
  message: string
}
export interface Alert {
  attributes: AlertAttributes
  dismiss: () => void
}
export type AlertContext = [
  alerts: Alert[],
  showAlert: (alert: AlertAttributes) => Alert,
]
export const AlertManagerContext = createContext<AlertContext>([
  [],
  () => {
    throw new Error('no_alert_provider_found')
  },
])

export const useAlerts = (
  filter?: Partial<Alert>,
): [Alert[], (alertAttr: AlertAttributes) => Alert] => {
  const [alerts, showAlert] = useContext(AlertManagerContext)
  const filteredAlerts = filter
    ? alerts.filter((a) =>
        ((Object.keys(filter) as unknown) as (keyof Alert)[]).every(
          (k) => a[k] === filter[k],
        ),
      )
    : alerts

  return [filteredAlerts, showAlert]
}

export class AlertProvider extends Component<
  { children?: ComponentChildren },
  { alerts: Alert[] }
> {
  state: { alerts: Alert[] } = { alerts: [] }

  constructor() {
    super()
  }

  showAlert = (alertAttr: AlertAttributes): Alert => {
    const duration = alertAttr.duration ?? ALERT_DURATION_DEFAULT
    const dismissible = alertAttr.dismissible ?? true
    const alert = {
      attributes: { ...alertAttr, duration, dismissible },
      dismiss: (): void => {
        const idx = this.state.alerts.indexOf(alert)
        if (idx !== -1) {
          this.setState({
            alerts: [
              ...this.state.alerts.slice(0, idx),
              ...this.state.alerts.slice(idx + 1),
            ],
          })
        }
      },
    }

    const onTimeout = (): void => {
      alert.dismiss()
      alert.attributes.onDismiss && alert.attributes.onDismiss()
    }

    this.setState((state) => ({ alerts: state.alerts.concat(alert) }))
    setTimeout(onTimeout, duration)

    return alert
  }

  render(): h.JSX.Element {
    return (
      <AlertManagerContext.Provider value={[this.state.alerts, this.showAlert]}>
        {this.props.children}
      </AlertManagerContext.Provider>
    )
  }
}
