import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent } from 'preact'
import { useContext, useMemo, useRef } from 'preact/hooks'

import { useDropdown } from 'services/DropdownManager'
import { AvatarProps, Avatar } from '../Avatar'
import { DropdownItem } from '../DropdownItem'
import { Loader } from '../Loader'

import styles from './styles.scss'

export const EditableAvatar: FunctionalComponent<
  AvatarProps & {
    onUpdate: (file: File) => void
    onDelete: () => void
    loading?: boolean
  }
> = ({ onUpdate, onDelete, loading = false, ...avatarProps }) => {
  const { t } = useContext(TranslateContext)
  const updateBtnRef = useRef<HTMLDivElement>(null)

  const childrenFn = useMemo(
    () => () =>
      [
        <DropdownItem
          key="profile-avatar-update-btn"
          hasIcon
          iconClassName="fas fa-upload"
        >
          <label
            htmlFor="update-profile-avatar-file-upload"
            className={styles['upload-label']}
          >
            {t('core.user_profile.update_avatar_dropdown_btn')}
          </label>
          <input
            className={styles['hidden-input']}
            id="update-profile-avatar-file-upload"
            type="file"
            accept="image/*"
            onChange={(event) => {
              const file = (
                event.target as unknown as {
                  files: FileList
                }
              ).files[0]
              onUpdate(file)
            }}
          />
        </DropdownItem>,
        <DropdownItem
          key="profile-avatar-delete-btn"
          hasIcon
          iconClassName="fas fa-times"
          onClick={onDelete}
        >
          {t('core.user_profile.delete_avatar_dropdown_btn')}
        </DropdownItem>,
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useDropdown({
    for: updateBtnRef,
    children: childrenFn,
    align: 'left',
  })

  return (
    <div className={styles['editable-avatar']}>
      <Avatar {...avatarProps} />
      <div
        ref={updateBtnRef}
        className={`${styles['edit-mask']} ${loading ? styles.loading : ''}`}
      >
        {loading ? (
          <Loader size="sm" color="white" />
        ) : (
          <i className="fas fa-pencil-alt" />
        )}
      </div>
    </div>
  )
}
