import { CommunityTag } from './tag'
import { DisplayUser, User } from './user'

export enum DiscussionSubscription {
  FOLLOW = 'FOLLOW',
  IGNORE = 'IGNORE',
}

export interface Discussion {
  id: string
  title: string
  commentCount: number
  postNumberIndex: number
  createdAt: string
  tags: CommunityTag[]
  author: DisplayUser | null
  isSticky: boolean
  isLocked: boolean
  canPost: boolean
  canSubscribe: boolean
  canUpdate: boolean
  canDelete: boolean
  canManage: boolean
  subscription: DiscussionSubscription | null
}

export interface DiscussionWithLastPostData extends Discussion {
  lastPostedUser: {
    id: User['id']
    username: User['username']
  }
  lastPostNumber: number
  lastPostedAt: string
}
