import {
  h,
  Component,
  createContext,
  ComponentChildren,
  RefObject,
  FunctionalComponent,
} from 'preact'
import { useContext, useEffect, useState } from 'preact/hooks'
import { OptionalKeys, RemappedOmit } from 'utils/types'
import { v4 as uuid } from 'uuid'

export type AutoFocusRef = RefObject<HTMLInputElement>
export type AnyExtendedProps = Record<string, unknown>

export type ModalProperties<
  ExtendedProps extends AnyExtendedProps = AnyExtendedProps
> = {
  show: boolean
  size: 'small' | 'large' | 'medium'
  className?: string
  isDismissible: boolean
  title: string
  alerts: string[]
  onDismiss: () => void
} & ExtendedProps
export type ModalSetProps<
  ExtendedProps extends AnyExtendedProps = AnyExtendedProps
> = (props: Partial<ModalProperties<ExtendedProps>>) => void

type ModalPropertiesWithDefault =
  | 'show'
  | 'isDismissible'
  | 'onDismiss'
  | 'alerts'
export interface Modal<
  ExtendedProps extends AnyExtendedProps,
  JITProps extends Partial<ModalProperties<ExtendedProps>> = Partial<
    ModalProperties<ExtendedProps>
  >,
  ShowParam = Record<string, never> extends JITProps
    ? Record<string, never>
    : OptionalKeys<JITProps, ModalPropertiesWithDefault> &
        Partial<ModalProperties<ExtendedProps>>
> {
  id: string
  properties: Partial<ModalProperties<ExtendedProps>> &
    Pick<ModalProperties<ExtendedProps>, ModalPropertiesWithDefault>
  content: ModalContentFactory<ExtendedProps>
  setProps: ModalSetProps<ExtendedProps>
  show: (
    ...params: Record<string, never> extends ShowParam
      ? [props?: ShowParam]
      : [props: ShowParam]
  ) => void
  dismiss: () => void
  remove: () => void
}

export type ModalContent<
  ExtendedProps extends AnyExtendedProps = Record<never, never>
> = FunctionalComponent<
  ModalProperties<ExtendedProps> & {
    setProps: ModalSetProps<ExtendedProps>
    setAlerts: (alerts: string[]) => void
    autoFocusRef: AutoFocusRef
  }
>
export type ModalContentFactory<ExtendedProps extends AnyExtendedProps> = (
  props: ModalProperties<ExtendedProps>,
  setProps: ModalSetProps<ExtendedProps>,
  autoFocusRef: AutoFocusRef,
) => ComponentChildren

export const modalContent = <ExtendedProps extends AnyExtendedProps>(
  ModalContentComponent: ModalContent<ExtendedProps>,
): ModalContentFactory<ExtendedProps> => (
  props,
  setProps,
  autoFocusRef,
): ComponentChildren => {
  const setAlerts = (alerts: string[]): void => {
    if (
      alerts.length !== props.alerts?.length ||
      alerts
        .map((a, idx) => !!props.alerts && props.alerts[idx] === a)
        .some((x) => !x)
    ) {
      setProps({ alerts } as Partial<ModalProperties<ExtendedProps>>)
    }
  }

  return (
    <ModalContentComponent
      {...props}
      setProps={setProps}
      setAlerts={setAlerts}
      autoFocusRef={autoFocusRef}
    />
  )
}

export const useModal = <
  ExtendedProps extends AnyExtendedProps,
  AOTProps extends Partial<ModalProperties<ExtendedProps>>,
  JITProps = RemappedOmit<ModalProperties<ExtendedProps>, keyof AOTProps>
>(
  modalContentFactory: ModalContentFactory<ExtendedProps>,
  props: AOTProps,
): [modal: Modal<ExtendedProps, JITProps>, isShown: boolean] => {
  const [, { createModal, getModalById }] = useContext(ModalManagerContext)
  const [modalId, setModalId] = useState<string | null>(null)

  const modal =
    (modalId && getModalById<ExtendedProps, JITProps>(modalId)) ||
    createModal<ExtendedProps, AOTProps, JITProps>(modalContentFactory, props)
  if (!modalId) {
    setModalId(modal.id)
  }

  useEffect(() => {
    return () => {
      const modalToRemove = getModalById<ExtendedProps, JITProps>(modal.id)
      modalToRemove?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [modal, modal.properties.show]
}

interface UseGlobalModal {
  <ExtendedProps extends AnyExtendedProps>(id: string): [
    modal?: Modal<ExtendedProps, Record<never, never>>,
    isShown?: boolean,
  ]
  <
    ExtendedProps extends AnyExtendedProps,
    Props extends OptionalKeys<
      ModalProperties<ExtendedProps>,
      ModalPropertiesWithDefault
    >
  >(
    id: string,
    modalContentFactory: ModalContentFactory<ExtendedProps>,
    props: Props,
  ): [modal: Modal<ExtendedProps, Record<never, never>>, isShown: boolean]
}
export const useGlobalModal: UseGlobalModal = <
  ExtendedProps extends AnyExtendedProps,
  Props = OptionalKeys<
    ModalProperties<ExtendedProps>,
    ModalPropertiesWithDefault
  >
>(
  id: string,
  modalContentFactory?: ModalContentFactory<ExtendedProps>,
  props?: Props,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, any] => {
  const [, { createModal, getModalById }] = useContext(ModalManagerContext)
  const [modalId] = useState<string>(id)

  if (!modalContentFactory || !props) {
    const existingModal = getModalById<ExtendedProps, Record<never, never>>(
      modalId,
    )
    return [existingModal, existingModal?.properties.show]
  }

  const modal =
    getModalById<ExtendedProps, Record<never, never>>(modalId) ||
    createModal<ExtendedProps, Props, Record<never, never>>(
      modalContentFactory,
      props,
      id,
    )

  return [modal, modal.properties.show]
}

type CreateModalFunction = <
  ExtendedProps extends AnyExtendedProps,
  AOTProps extends Partial<ModalProperties<ExtendedProps>>,
  JITProps extends Partial<ModalProperties<ExtendedProps>>
>(
  modalContentFactory: ModalContentFactory<ExtendedProps>,
  props: AOTProps,
  modalId?: string,
) => Modal<ExtendedProps, JITProps>

export type ModalContext = [
  modals: Modal<AnyExtendedProps>[],
  functions: {
    createModal: CreateModalFunction
    getModalById: <
      ExtendedProps extends AnyExtendedProps,
      JITProps extends Partial<ModalProperties<ExtendedProps>>
    >(
      id: string,
    ) => Modal<ExtendedProps, JITProps> | undefined
  },
]
export const ModalManagerContext = createContext<ModalContext>([
  [],
  {
    createModal: () => {
      throw new Error('no_modal_provider_found')
    },
    getModalById: () => {
      throw new Error('no_modal_provider_found')
    },
  },
])

export class ModalProvider extends Component<
  { children?: ComponentChildren },
  { modals: Modal<AnyExtendedProps>[] }
> {
  state: { modals: Modal<AnyExtendedProps>[] } = { modals: [] }

  getModalById = <
    ExtendedProps extends AnyExtendedProps,
    JITProps extends Partial<ModalProperties<ExtendedProps>>
  >(
    id: string,
  ): Modal<ExtendedProps, JITProps> | undefined => {
    return this.state.modals.find((m) => m.id === id) as
      | Modal<ExtendedProps, JITProps>
      | undefined
  }

  updateModalProps = <ExtendedProps extends AnyExtendedProps>(
    id: string,
    propsUpdate: Partial<ModalProperties<ExtendedProps>>,
  ): void => {
    this.setState((state) => {
      const idx = state.modals.findIndex((m) => m.id === id)
      if (idx === -1) {
        return state
      }

      const modal = state.modals[idx]
      return {
        modals: [
          ...state.modals.slice(0, idx),
          {
            ...modal,
            properties: {
              ...modal.properties,
              ...propsUpdate,
              onDismiss: propsUpdate.onDismiss
                ? () => {
                    modal.dismiss()
                    propsUpdate.onDismiss && propsUpdate.onDismiss()
                  }
                : modal.properties.onDismiss,
            },
          },
          ...state.modals.slice(idx + 1),
        ],
      }
    })
  }

  removeModal = (id: string): void => {
    this.setState((state) => {
      const idx = state.modals.findIndex((m) => m.id === id)
      if (idx === -1) {
        return state
      }

      return {
        modals: [...state.modals.slice(0, idx), ...state.modals.slice(idx + 1)],
      }
    })
  }

  createModal = <
    ExtendedProps extends AnyExtendedProps,
    AOTProps extends Partial<ModalProperties<ExtendedProps>>,
    JITProps extends Partial<ModalProperties<ExtendedProps>>
  >(
    modalContentFactory: ModalContentFactory<ExtendedProps>,
    properties: AOTProps,
    modalId?: string,
  ): Modal<ExtendedProps, JITProps> => {
    const id = modalId || uuid()

    const modal: Modal<ExtendedProps, JITProps> = {
      id,
      properties: {
        ...properties,
        alerts: properties.alerts ?? [],
        isDismissible: properties.isDismissible ?? true,
        show: properties.show ?? false,
        onDismiss: () => {
          modal.dismiss()
          properties.onDismiss && properties.onDismiss()
        },
      },
      content: modalContentFactory,
      show: (props?: Partial<ModalProperties<ExtendedProps>>) => {
        this.updateModalProps(id, {
          ...(props || {}),
          show: true,
        })
      },
      dismiss: () => {
        this.updateModalProps(id, { show: false })
      },
      remove: () => this.removeModal(id),
      setProps: (props) => this.updateModalProps(id, props),
    }

    this.setState((state) => ({
      modals: state.modals.concat(
        (modal as unknown) as Modal<AnyExtendedProps>,
      ),
    }))
    return modal
  }

  render(): h.JSX.Element {
    return (
      <ModalManagerContext.Provider
        value={[
          this.state.modals,
          { createModal: this.createModal, getModalById: this.getModalById },
        ]}
      >
        {this.props.children}
      </ModalManagerContext.Provider>
    )
  }
}

export const ModalConsumer = ModalManagerContext.Consumer
