import { User, AccessToken } from 'types/user'
import { objectToCamelCase } from './utils'
import LegacyUserModel from 'legacy/src/core/js/common/models/User'
import { getApp } from 'legacy/app'
import { updateAuthenticatedUser } from 'store/cache/user'
import { cache } from 'store'
import { CurrentUser } from 'store/local/user'

interface LegacyModel<T = unknown> {
  id: () => string
  type: string
  data: T & {
    relationships: {
      [model: string]: {
        data: Array<{ id: string; type: string }>
      }
    }
  }
}
interface LegacyUserData {
  id: string
  type: string
  attributes: {
    avatarUrl: string | null
    bio: string | null
    canDelete: boolean
    canEdit: boolean
    canSuspend: boolean
    commentCount: number
    discussionCount: number
    displayName: string
    email: string
    isEmailConfirmed: boolean
    joinTime: string
    lastSeenAt: string
    markedAllAsReadAt: string | null
    newFlagCount: number
    newNotificationCount: number
    preferences: {
      discloseOnline: boolean
      followAfterReply: boolean
      indexProfile: boolean
      locale: string | null
      notify_discussionLocked_alert: boolean
      notify_discussionRenamed_alert: boolean
      notify_newPost_alert: boolean
      notify_newPost_email: boolean
      notify_postLiked_alert: boolean
      notify_postMentioned_alert: boolean
      notify_postMentioned_email: boolean
      notify_userMentioned_alert: boolean
      notify_userMentioned_email: boolean
      notify_userSuspended_alert: boolean
      notify_userUnsuspended_alert: boolean
      newsletterSubscription: boolean
    } | null
    unreadNotificationCount: number
    username: string
  }
}
export type LegacyUser = LegacyModel<LegacyUserData>

// type Transformer<S, T = any> = {
//   [K in keyof Partial<S>]: S[K] extends { [k: string]: any }
//     ? Transformer<S[K]> | undefined
//     : ((key: K, value: S[K]) => [keyof T, T[keyof T]]) | undefined
// }

export type Transformer<S, T> = (source: S) => T

export const fromLegacyUser: Transformer<LegacyUserModel, User> = (legacy) => {
  const camelCasedUser = objectToCamelCase(
    legacy.data as Record<string, unknown>,
  )

  const user: User = {
    ...(camelCasedUser.attributes as Record<string, unknown>),
    id: (legacy.id as () => string)(),
  } as User

  return user
}

export const toLegacyUser: Transformer<
  { user: User; accessToken: AccessToken },
  LegacyUserModel
> = (model) => {
  const legacyUserData: LegacyUserData = {
    id: `${model.user.id}`,
    type: 'users',
    attributes: {
      avatarUrl: model.user.avatarUrl,
      bio: model.user.bio,
      canDelete: false,
      canEdit: false,
      canSuspend: false,
      commentCount: model.user.commentCount,
      discussionCount: model.user.discussionCount,
      displayName: model.user.username,
      email: model.user.email,
      isEmailConfirmed: model.user.isEmailConfirmed,
      joinTime: model.user.joinedAt,
      lastSeenAt: model.user.lastSeenAt,
      markedAllAsReadAt: model.user.markedAllAsReadAt,
      newFlagCount: 0,
      newNotificationCount: model.user.newNotificationCount,
      preferences: {
        discloseOnline: model.user.preferences.discloseOnline,
        followAfterReply: model.user.preferences.followAfterReply,
        indexProfile: model.user.preferences.indexProfile,
        locale: model.user.preferences.locale,
        notify_discussionLocked_alert:
          model.user.preferences.notifyDiscussionLockedAlert,
        notify_discussionRenamed_alert:
          model.user.preferences.notifyDiscussionRenamedAlert,
        notify_newPost_alert: model.user.preferences.notifyNewPostAlert,
        notify_newPost_email: model.user.preferences.notifyNewPostEmail,
        notify_postLiked_alert: model.user.preferences.notifyPostLikedAlert,
        notify_postMentioned_alert:
          model.user.preferences.notifyPostMentionedAlert,
        notify_postMentioned_email:
          model.user.preferences.notifyPostMentionedEmail,
        notify_userMentioned_alert:
          model.user.preferences.notifyUserMentionedAlert,
        notify_userMentioned_email:
          model.user.preferences.notifyUserMentionedEmail,
        notify_userSuspended_alert:
          model.user.preferences.notifyUserSuspendedAlert,
        notify_userUnsuspended_alert:
          model.user.preferences.notifyUserUnsuspendedAlert,
        newsletterSubscription: model.user.preferences.newsletterSubscription,
      },
      unreadNotificationCount: model.user.unreadNotificationCount,
      username: model.user.username,
    },
  }
  const legacyUser = new LegacyUserModel(legacyUserData, getApp().store)
  legacyUser.exists = true

  const pushData = legacyUser.pushData.bind(legacyUser)
  return Object.assign(legacyUser, {
    pushData: (attributes: LegacyUserData['attributes']) => {
      pushData(attributes)

      const authenticatedUser = CurrentUser()

      if (authenticatedUser?.id === legacyUser.id()) {
        updateAuthenticatedUser(cache, {
          authenticatedUser: {
            user: fromLegacyUser(legacyUser),
            accessToken: model.accessToken,
          },
        })
      }
    },
  })
}
