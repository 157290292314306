import { h, FunctionalComponent } from 'preact'
import { Loader } from '../Loader'

import styles from './styles.scss'

export const Switch: FunctionalComponent<{
  loading?: boolean
  label?: string | h.JSX.Element
  isChecked: boolean
  onChange: (isChecked: boolean) => void
  className?: string
}> = ({ isChecked, onChange, label = '', loading = false, className }) => {
  return (
    <div
      class={`${className || ''} ${isChecked ? styles.on : ''} ${
        styles['switch-wrapper']
      }`}
      onClick={() => onChange(!isChecked)}
    >
      <input type="checkbox" />
      <div className={styles.switch}>
        <div className={styles.pin}>{loading && <Loader size="sm" />}</div>
      </div>
      {label}
    </div>
  )
}
