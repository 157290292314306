import { TranslateContext } from '@denysvuika/preact-translate'
import { h, FunctionalComponent, RefObject } from 'preact'
import { useContext, useEffect } from 'preact/hooks'
import { modalContent, useModal } from 'services/ModalManager'

import { Button } from 'ui/atoms/Button'
import { toggleEvent } from 'utils/components'
import { toFileArray } from 'utils/upload'
import { apply, MdStylesArgs } from './apply'
import { HyperlinkModal } from './HyperlinkModal'
import styles from './styles.scss'
import { EditorToolbarUpload, ToolbarUpload } from './ToolbarUpload'

export const TextEditorToolbar: FunctionalComponent<{
  editorRef: RefObject<HTMLTextAreaElement>
  className?: string
  values: {
    preview: boolean
    loading?: boolean
    uploads?: EditorToolbarUpload[]
    progressPercentage?: number
    canSend?: boolean
    canUpload?: boolean
    showSendButton?: boolean
  }
  onEvent?: {
    preview?: (on: boolean) => void
    upload?: (files: File[]) => void
    send?: () => void
    uploadRemove?: (upload: EditorToolbarUpload) => void
  }
}> = ({ editorRef, className = '', values, onEvent }) => {
  const { t } = useContext(TranslateContext)

  const [hyperlinkModal] = useModal(modalContent(HyperlinkModal), {
    size: 'small',
    title: t('core.composer.link_modal.title'),
    onConfirm: (text: string, url: string) => {
      applyStyle({
        prefix: `[${text}](${url})`,
      })
    },
  })

  const {
    preview,
    uploads,
    progressPercentage,
    loading = false,
    canSend = true,
    canUpload = true,
    showSendButton = true,
  } = values
  const {
    preview: onPreview,
    upload: onUpload,
    send: onSend,
    uploadRemove: onUploadRemove,
  } = onEvent || {}

  const tooltipContent = (name: string): string =>
    t(`core.composer.${name}_tooltip`)
  const applyStyle = (stylesToApply: Partial<MdStylesArgs>): void => {
    if (!editorRef.current) {
      return
    }

    apply(editorRef.current, stylesToApply)
  }

  // TODO: Find a way to avoid updating this on every redraw
  // Now needed because onSend is an arrow function redeclared at
  // each DiscussionPage redraw. Making DiscussionPage a class component
  // to avoid updating onSend reference on every redraw is a way to go.
  useEffect(() => {
    editorRef.current &&
      (editorRef.current.onkeydown = (e) => {
        if (e.metaKey || e.ctrlKey) {
          switch (e.key) {
            case 'Enter':
              e.preventDefault()
              !loading && canSend && onSend && onSend()
              break
            case 'b':
              e.preventDefault()
              !loading &&
                !preview &&
                applyStyle({ prefix: '**', suffix: '**', trimFirst: true })
              break
            case 'i':
              e.preventDefault()
              !loading &&
                !preview &&
                applyStyle({ prefix: '_', suffix: '_', trimFirst: true })
              break

            default:
              break
          }
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, canSend, editorRef, onSend])

  return (
    <div className={className}>
      {canUpload && (
        <ToolbarUpload
          uploads={uploads}
          onRemove={onUploadRemove}
          progressPercentage={progressPercentage}
        />
      )}
      <div className={styles.toolbar}>
        {showSendButton && (
          <Button
            size="sm"
            padding="md"
            className={styles['send-button']}
            loading={loading}
            disabled={!canSend || loading}
            onClick={onSend}
          >
            {t('core.composer.send_button.label')}
          </Button>
        )}

        <Button
          size="sm"
          style="btn-primary-faded"
          padding="sm"
          className={styles['action-button']}
          active={preview}
          onClick={onPreview && toggleEvent(onPreview, preview)}
        >
          <i className="far fa-eye" />
        </Button>
        {canUpload && (
          <Button
            size="sm"
            style="btn-primary-faded"
            padding="sm"
            disabled={preview || loading}
            className={`${styles['action-button']} ${styles['upload-button']}`}
          >
            <i className="fas fa-file-upload" />
            <span className={styles.label}>{t('core.composer.attach')}</span>
            <input
              type="file"
              multiple
              onChange={(e) => {
                const fileList = ((e.target as unknown) as {
                  files: FileList
                }).files
                onUpload && onUpload(toFileArray(fileList))
              }}
            />
          </Button>
        )}

        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('header')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() => applyStyle({ prefix: '### ' })}
        >
          <i className="fas fa-heading" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('bold')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({ prefix: '**', suffix: '**', trimFirst: true })
          }
        >
          <i className="fas fa-bold" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('italic')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({ prefix: '_', suffix: '_', trimFirst: true })
          }
        >
          <i className="fas fa-italic" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('quote')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({
              prefix: '> ',
              multiline: true,
              surroundWithNewlines: true,
            })
          }
        >
          <i className="fas fa-quote-left" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('code')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({
              prefix: '`',
              suffix: '`',
              blockPrefix: '```',
              blockSuffix: '```',
            })
          }
        >
          <i className="fas fa-code" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('link')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() => hyperlinkModal.show()}
        >
          <i className="fas fa-link" />
        </Button>
        {/* <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('image')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({
              prefix: '![',
              suffix: '](src)',
              replaceNext: 'src',
              scanFor: 'https?://',
            })
          }
        >
          <i className="fas fa-image" />
        </Button> */}
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('unordered_list')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({
              prefix: '- ',
              multiline: true,
              surroundWithNewlines: true,
            })
          }
        >
          <i className="fas fa-list-ul" />
        </Button>
        <Button
          className={styles['md-button']}
          style="link"
          size="sm"
          padding="sm"
          tooltip={tooltipContent('ordered_list')}
          tooltipWidth="fit-content"
          disabled={preview || loading}
          onClick={() =>
            applyStyle({ prefix: '1. ', multiline: true, orderedList: true })
          }
        >
          <i className="fas fa-list-ol" />
        </Button>
      </div>
    </div>
  )
}
