import { h, FunctionalComponent } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { useMutation } from '@apollo/client'

import styles from './styles.scss'
import { Button } from 'ui/atoms/Button'
import { User, UserPreferences } from 'types/user'
import { Switch } from 'ui/atoms/Switch'
import { modalContent, useModal } from 'services/ModalManager'
import { ChangePasswordModal } from 'ui/organisms/ChangePasswordModal'
import { ChangeEmailModal } from 'ui/organisms/ChangeEmailModal'
import {
  UpdateUserResult,
  UpdateUserInput,
  UPDATE_USER,
} from 'store/operations/user'
import { useAlerts } from 'services/AlertManager'
import { getGraphQLErrors } from 'utils/components'
import { updateCachedUser } from 'store/cache/user'
import { fromMutationResult } from 'store/cache/utils'
import { NotificationPreferencesEditor } from 'ui/organisms/NotificationPreferencesEditor'

export const UserSettings: FunctionalComponent<{ user: User }> = ({ user }) => {
  const { t } = useContext(TranslateContext)
  const [, showAlert] = useAlerts()
  const [loadings, setLoadings] = useState({
    newsletterSubscription: false,
    followAfterReply: false,
    discloseEmail: false,
    discloseOnline: false,
    notifyDiscussionRenamedAlert: false,
    notifyDiscussionLockedAlert: false,
    notifyPostLikedAlert: false,
    notifyNewPostAlert: false,
    notifyNewPostEmail: false,
  })

  const [updateUser] = useMutation<UpdateUserResult, UpdateUserInput>(
    UPDATE_USER,
    {
      onError: (e) => {
        showAlert({ type: 'error', message: getGraphQLErrors([e])[0] })
      },
      update: fromMutationResult(updateCachedUser, {
        incomingUser: 'updateUser',
      }),
    },
  )

  const [changePasswordModal] = useModal(modalContent(ChangePasswordModal), {
    size: 'small',
    title: t('core.user_profile.settings.change_password.title'),
    email: user.email,
  })
  const [changeEmailModal] = useModal(modalContent(ChangeEmailModal), {
    size: 'small',
    title: t('core.user_profile.settings.change_email.title'),
    email: user.email,
  })

  const updatePreference = (preference: keyof UserPreferences) => async (
    value: boolean,
  ): Promise<void> => {
    setLoadings((state) => ({ ...state, [preference]: true }))
    await updateUser({
      variables: {
        user: { id: user.id, preferences: { [preference]: value } },
      },
    })
    setLoadings((state) => ({ ...state, [preference]: false }))
  }

  return (
    <div className={styles['user-settings']}>
      <h3>{t('core.user_profile.settings.account_heading')}</h3>
      <Button
        style="btn-primary-faded"
        bold={false}
        size="sm"
        padding="md"
        onClick={() => changePasswordModal.show()}
      >
        {t('core.user_profile.settings.change_password.title')}
      </Button>{' '}
      <Button
        style="btn-primary-faded"
        bold={false}
        size="sm"
        padding="md"
        onClick={() => changeEmailModal.show()}
      >
        {t('core.user_profile.settings.change_email.title')}
      </Button>
      <h3>{t('core.user_profile.settings.notifications.heading')}</h3>
      <NotificationPreferencesEditor
        preferences={user.preferences}
        onChange={(preference, value) => updatePreference(preference)(value)}
        loaders={loadings}
        className="mb-6"
      />
      <Switch
        isChecked={user.preferences.newsletterSubscription}
        onChange={updatePreference('newsletterSubscription')}
        label={t(
          'core.user_profile.settings.notifications.newsletter_subscription',
        )}
        loading={loadings.newsletterSubscription}
      />
      <Switch
        isChecked={user.preferences.followAfterReply}
        onChange={updatePreference('followAfterReply')}
        label={t('core.user_profile.settings.notifications.follow_after_reply')}
        loading={loadings.followAfterReply}
      />
      <h3>{t('core.user_profile.settings.privacy.heading')}</h3>
      <Switch
        isChecked={user.preferences.discloseEmail}
        onChange={updatePreference('discloseEmail')}
        label={t('core.user_profile.settings.privacy.disclose_email')}
        loading={loadings.discloseEmail}
      />
      <Switch
        isChecked={user.preferences.discloseOnline}
        onChange={updatePreference('discloseOnline')}
        label={t('core.user_profile.settings.privacy.disclose_online')}
        loading={loadings.discloseOnline}
      />
      <h3>{t('core.user_profile.settings.delete.heading')}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: t('core.user_profile.settings.delete.text_html', {
            email: 'support@enseignerleclimat.org',
          }),
        }}
      />
    </div>
  )
}
