import { FunctionalComponent, h } from 'preact'
import { useContext, useRef } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'

import styles from './styles.scss'
import { FILE_INFO } from 'utils/constants'
import { CreateResourcePayload } from 'types/resource'
import { Button } from 'ui/atoms/Button'
import { HyperlinkItem } from 'ui/molecules/HyperlinkItem'
import { FileItem } from 'ui/molecules/FileItem'
import { handleEnterKey } from 'utils/components'

export const ResourceContentStep: FunctionalComponent<{
  partialResource: CreateResourcePayload
  filesToUpload: File[]
  onChange: (
    partialResource: CreateResourcePayload,
    newFilesToUpload?: File[],
  ) => void
}> = ({ partialResource, filesToUpload, onChange }) => {
  const { t } = useContext(TranslateContext)
  const hyperlinkInputRef = useRef<HTMLInputElement | null>(null)

  const hyperlinks =
    (partialResource.hyperlinks && partialResource.hyperlinks.split(' ')) || []

  const addHyperlink = (): void => {
    const hyperlinkValue = hyperlinkInputRef.current?.value
    if (
      !hyperlinkInputRef.current ||
      !hyperlinkValue ||
      hyperlinkValue.length < 4
    ) {
      return
    }

    const hyperlink =
      hyperlinkValue.startsWith('http://') ||
      hyperlinkValue.startsWith('https://')
        ? hyperlinkValue
        : `http://${hyperlinkValue}`
    hyperlinkInputRef.current.value = ''
    onChange({
      ...partialResource,
      hyperlinks: hyperlinks.concat(hyperlink).map(encodeURI).join(' '),
    })
  }
  const removeHyperlink = (idx: number): void => {
    const newHyperlinks = hyperlinks.slice()
    newHyperlinks.splice(idx, 1)

    onChange({
      ...partialResource,
      hyperlinks: newHyperlinks.map(encodeURI).join(' '),
    })
  }
  const addFile = (newFileList: FileList): void => {
    const nbFiles = newFileList.length + filesToUpload.length
    const newFiles = Array(nbFiles)
      .fill(null)
      .reduce<File[]>((acc, _, idx) => {
        const f = newFileList.item(idx)
        if (!f) {
          return acc
        }

        return acc.concat(f)
      }, filesToUpload)

    onChange(partialResource, newFiles)
  }
  const removeFile = (idx: number): void => {
    const newFiles = filesToUpload.slice()
    newFiles.splice(idx, 1)

    onChange(partialResource, newFiles)
  }

  return (
    <div className={`Form Form--centered ${styles.form}`}>
      <div className={styles['form-group-duo']}>
        <div>
          {filesToUpload.length ? (
            filesToUpload.map((f, idx) => (
              <FileItem
                key={`local-file-item-${f.name}-${idx}`}
                file={{ filename: f.name, mimetype: f.type }}
                canRemove
                onAction={{ remove: () => removeFile(idx) }}
                className={styles['attachment-item']}
              />
            ))
          ) : (
            <div>{t('core.resources.new_resource_modal.no_file')}</div>
          )}
        </div>

        <div>
          {hyperlinks.length ? (
            hyperlinks.map((link, idx) => (
              <HyperlinkItem
                key={`added-hyperlink-item-${link}-${idx}`}
                onRemoveClick={() => removeHyperlink(idx)}
                removable
                url={link}
                className={styles['attachment-item']}
              />
            ))
          ) : (
            <div>{t('core.resources.new_resource_modal.no_hyperlink')}</div>
          )}
        </div>
      </div>

      <div className={styles['form-group-duo']}>
        <div className="Form-group">
          <label
            className={styles['custom-file-uploader']}
            htmlFor="new-resource-file-upload"
          >
            <div className={styles['upload-text']}>
              {t('core.resources.new_resource_modal.upload_button_label')}
            </div>
            <div className={styles['upload-icon']}>
              <i className="fa fa-upload" />
            </div>
          </label>
          <input
            className={styles['hidden-input']}
            id="new-resource-file-upload"
            type="file"
            accept={Object.values(FILE_INFO).reduce(
              (mime, i) => `${mime},${i.mime}`,
              '',
            )}
            multiple
            onChange={(event: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
              const newFileList = (
                event.target as unknown as {
                  files: FileList
                }
              ).files
              addFile(newFileList)
            }}
          />
        </div>

        <div className="Form-group">
          <div className={styles['add-link-input-container']}>
            <input
              ref={hyperlinkInputRef}
              className={`FormControl  ${styles.FormControl} ${styles['add-link-input']}`}
              id="new-resource-add-link"
              type="text"
              placeholder={t(
                'core.resources.new_resource_modal.link_placeholder',
              )}
              onKeyPress={handleEnterKey(addHyperlink)}
            />
            <Button
              className={styles['add-link-button']}
              onClick={addHyperlink}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
