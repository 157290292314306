/** @jsx m */

const Component = flarum.core.compat['Component']

export default class TagLinkButton extends Component {
  view() {
    const { tag, children, active, onclick } = this.props
    const description = tag && tag.description()

    return (
      <a
        className={`TagLinkButton hasIcon ${active ? 'text-bold active' : ''}`}
        onclick={onclick}
        title={description || ''}
      >
        <i
          class={`Button-icon icon fa${active ? 's' : 'r'} fa-${
            active ? 'check-' : ''
          }square`}
        />
        {children}
      </a>
    )
  }

  static initProps(props) {
    const tag = props.tag
    props.children = tag
      ? tag.name()
      : app.translator.trans('flarum-tags.forum.index.untagged_link')
  }
}
