export enum PageGroup {
  RESOURCE,
  COMMUNITY,
}

export enum PageType {
  RESOURCES,
  RESOURCE,
  RESOURCE_MODERATION,
  DISCUSSIONS,
  DISCUSSIONS_BY_TAGS,
  DISCUSSION,
  DISCUSSION_MODERATION,
  LANDING,
  USER_PROFILE,
  USER_PROFILE_RESOURCES,
  USER_PROFILE_POSTS,
  USER_PROFILE_DISCUSSIONS,
  USER_PROFILE_SETTINGS,
  RESET_PASSWORD,
  NOT_FOUND,
  ABOUT,
}
export const ROUTES = {
  [PageType.LANDING]: {
    path: '/',
    groups: [] as PageGroup[],
    params: [] as [] | undefined,
  },
  [PageType.ABOUT]: {
    path: '/about',
    groups: [] as PageGroup[],
    params: [] as [] | undefined,
  },
  [PageType.RESOURCES]: {
    path: '/resources',
    groups: [PageGroup.RESOURCE],
    params: [] as [] | undefined,
  },
  [PageType.RESOURCE]: {
    path: '/resource/:id',
    groups: [PageGroup.RESOURCE],
    params: ['id'] as ['id'],
  },
  [PageType.RESOURCE_MODERATION]: {
    path: '/resource/:id/moderate/:moderatedFlagId',
    groups: [PageGroup.RESOURCE],
    params: ['id', 'moderatedFlagId'] as ['id', 'moderatedFlagId'],
  },
  [PageType.DISCUSSIONS]: {
    path: '/discussions/:filter?',
    groups: [PageGroup.COMMUNITY],
    params: ['filter'] as ['filter'?] | undefined,
  },
  [PageType.DISCUSSIONS_BY_TAGS]: {
    path: '/discussions/tags/:tags',
    groups: [PageGroup.COMMUNITY],
    params: ['tags'] as ['tags'],
  },
  [PageType.DISCUSSION]: {
    path: '/d/:id/:near?',
    groups: [PageGroup.COMMUNITY],
    params: ['id', 'near'] as ['id', 'near'?],
  },
  [PageType.DISCUSSION_MODERATION]: {
    path: '/d/:id/moderate/:moderatedPostNumber/flag/:moderatedFlagId',
    groups: [PageGroup.COMMUNITY],
    params: ['id', 'moderatedPostNumber', 'moderatedFlagId'] as [
      'id',
      'moderatedPostNumber',
      'moderatedFlagId',
    ],
  },
  [PageType.USER_PROFILE]: {
    path: '/u/:username/:subrouterPath*',
    groups: [] as PageGroup[],
    params: ['username', 'subrouterPath'] as ['username', 'subrouterPath'?],
  },
  [PageType.USER_PROFILE_RESOURCES]: {
    path: '/u/:username/resources',
    groups: [] as PageGroup[],
    params: ['username'] as ['username'],
  },
  [PageType.USER_PROFILE_POSTS]: {
    path: '/u/:username/posts',
    groups: [] as PageGroup[],
    params: ['username'] as ['username'],
  },
  [PageType.USER_PROFILE_DISCUSSIONS]: {
    path: '/u/:username/discussions',
    groups: [] as PageGroup[],
    params: ['username'] as ['username'],
  },
  [PageType.USER_PROFILE_SETTINGS]: {
    path: '/u/:username/settings',
    groups: [] as PageGroup[],
    params: ['username'] as ['username'],
  },
  [PageType.RESET_PASSWORD]: {
    path: '/reset-password/:token',
    groups: [] as PageGroup[],
    params: ['token'] as ['token'],
  },
  [PageType.NOT_FOUND]: {
    path: '/not-found',
    default: true,
    groups: [] as PageGroup[],
    params: [] as [] | undefined,
  },
}

export const isRouteIn = (group: PageGroup, path: string): boolean => {
  const route = Object.values(ROUTES).find((r) => r.path === path)
  return !!route && route.groups.includes(group)
}

type GetPageUrlParamsTuple<T extends PageType> = typeof ROUTES[T]['params']
type TupleLength<T extends unknown[]> = T extends { length: infer L }
  ? L
  : never
type ParamValue = string | undefined
type ParamName = string | number | undefined
type RequiredParamValue = NonNullable<string | undefined>
type DropFirstInTuple<T extends ParamName[]> = T extends [unknown, ...infer U]
  ? U extends ParamName[]
    ? U
    : never
  : never
type GetValue<T extends ParamName> = undefined extends T
  ? ParamValue
  : RequiredParamValue
type PageUrlParams<
  ParamsTuple extends ParamName[],
  ParamsObject extends Record<NonNullable<ParamName>, ParamValue> = Record<
    never,
    never
  >,
> = TupleLength<ParamsTuple> extends 0
  ? ParamsObject
  : PageUrlParams<
      DropFirstInTuple<ParamsTuple>,
      ParamsObject &
        (undefined extends ParamsTuple[0]
          ? Partial<
              Record<NonNullable<ParamsTuple[0]>, GetValue<ParamsTuple[0]>>
            >
          : Record<NonNullable<ParamsTuple[0]>, GetValue<ParamsTuple[0]>>)
    >
export type RouteParams<T extends PageType> = PageUrlParams<
  NonNullable<GetPageUrlParamsTuple<T>>
>

export const getRouteUrl = <T extends PageType>(
  page: T,
  ...params: undefined extends GetPageUrlParamsTuple<T>
    ? [RouteParams<T>?]
    : [RouteParams<T>]
): string => {
  const path = ROUTES[page].path || ''
  const paramsTuple = ROUTES[page].params as string[]

  const hydrated = paramsTuple.reduce<string>(
    (updatedPath: string, param: string) => {
      const regex = new RegExp(`:${param}[?*]?`)
      return updatedPath.replace(
        regex,
        (params &&
          (params[0] as Record<NonNullable<ParamName>, string> | undefined)?.[
            param
          ]) ??
          '',
      )
    },
    path,
  )

  return hydrated.replace(/\/+/g, '/')
}

export const getRoutePath = (page: PageType): string => {
  return ROUTES[page].path
}
