import { FunctionalComponent, h } from 'preact'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useContext } from 'preact/hooks'
import { TranslateContext } from '@denysvuika/preact-translate'
import { route } from 'preact-router'

import { ResourceTitle } from 'ui/atoms/ResourceTitle'
import styles from './styles.scss'
import { FullResource } from 'types/resource'
import { TagBadge } from 'ui/atoms/TagBadge'
import { FileItem } from 'ui/molecules/FileItem'
import { getResourceFilesDownloadUrl } from 'utils/files'
import { RecommendationButton } from 'ui/organisms/RecommendationButton'
import { Button } from 'ui/atoms/Button'
// import { FilePreviewModal } from '../FilePreviewModal'
import {
  AddResourceDownloadInput,
  AddResourceDownloadResult,
  ADD_RESOURCE_DOWNLOAD,
  ToggleResourceRecommendationInput,
  ToggleResourceRecommendationResult,
  TOGGLE_RESOURCE_RECOMMENDATION_VOTE,
} from 'store/operations/resource'
import { CurrentToken, CurrentUser } from 'store/local/user'
import { HyperlinkItem } from 'ui/molecules/HyperlinkItem'
import { modalContent, useGlobalModal, useModal } from 'services/ModalManager'
import { Avatar } from 'ui/atoms/Avatar'
import { getRouteUrl, PageType } from 'common/routing'
import { formatDateAsDelay } from 'utils/date'
import { RecommendationModal } from '../RecommendationModal'

export const ResourceHeader: FunctionalComponent<{
  resource: FullResource
}> = ({ resource }) => {
  const { t } = useContext(TranslateContext)
  const token = useReactiveVar(CurrentToken)
  const authenticatedUser = useReactiveVar(CurrentUser)

  const [toggleResourceRecommendation] = useMutation<
    ToggleResourceRecommendationResult,
    ToggleResourceRecommendationInput
  >(TOGGLE_RESOURCE_RECOMMENDATION_VOTE)

  const [addResourceDownload] = useMutation<
    AddResourceDownloadResult,
    AddResourceDownloadInput
  >(ADD_RESOURCE_DOWNLOAD, { fetchPolicy: 'no-cache', ignoreResults: true })

  const [loginModal] = useGlobalModal('login')
  // const [filePreviewModal] = useModal(modalContent(FilePreviewModal), {
  //   size: 'large',
  // })
  const [recommendationModal] = useModal(modalContent(RecommendationModal), {
    size: 'medium',
    title: t('core.resource.recommendation_modal.title'),
  })

  return (
    <div className={`${styles['resource-header']}`}>
      <div className={`container ${styles['resource-header-content']}`}>
        <div className={`${styles['resource-header-infos']}`}>
          <div className={styles.taglist}>
            {resource.tags.map((t) => (
              <TagBadge
                key={`resource-page-tagbadge-${t.id}`}
                tag={t}
                className={styles.tagitem}
              />
            ))}
          </div>
          <ResourceTitle value={resource.title} />
          <div className={`${styles.dates}`}>
            {formatDateAsDelay(t, resource.createdAt)} {/* TODO: translation */}
            {resource.updatedAt
              ? `(modifiée ${formatDateAsDelay(t, resource.updatedAt)} par ${
                  resource.updatedBy?.username ?? ''
                })`
              : null}
          </div>

          <div>
            <div
              className={`${styles['infos-author-profile']}`}
              onClick={
                resource
                  ? () => {
                      route(
                        getRouteUrl(PageType.USER_PROFILE, {
                          username: resource.author.username,
                        }),
                      )
                    }
                  : undefined
              }
            >
              <Avatar
                size="md"
                username={resource.author.username}
                url={resource.author.avatarUrl}
              />
              <div className={`${styles['author-name']}`}>
                {resource.author.username}
              </div>
            </div>
          </div>

          <div className={styles['additionnal-info']}>
            {resource.originalAuthor ? (
              <div>
                {t('core.resource.header.original_author_label')}:{' '}
                <span className={styles['original-author']}>
                  {resource.originalAuthor}
                </span>
              </div>
            ) : null}
            <div>
              {t('core.resource.header.keywords_label')}:{' '}
              {resource.keywords.split(',').map((k, idx) => (
                <span key={`keyword-${k}-${idx}`} className={styles.keyword}>
                  {k}
                </span>
              ))}
            </div>
          </div>
          <div className={`${styles['who-liked-it']}`}>
            <RecommendationButton
              large
              active={resource.metadatas?.isRecommendedByMe}
              onClick={() =>
                authenticatedUser
                  ? resource.metadatas?.isRecommendedByMe
                    ? toggleResourceRecommendation({
                        variables: { resourceId: resource.id },
                      })
                    : recommendationModal.show({
                        onRecommend: (tagNodeId: string | null) =>
                          toggleResourceRecommendation({
                            variables: { resourceId: resource.id, tagNodeId },
                          }),
                      })
                  : loginModal?.show()
              }
              count={resource.metadatas?.totalRecommendationCount ?? 0}
            />
          </div>
        </div>
        <div className={`${styles['resource-header-files']}`}>
          <div className={styles['attachment-list']}>
            {resource.files.map((file) => (
              <FileItem
                key={`resource-header-file-${file.id}`}
                // canPreview
                canDownload
                file={{
                  ...file,
                  filename: file.filename,
                }}
                onAction={{
                  download: () => {
                    // Open download link in new window
                    window.open(file.url, '_blank')
                    // Add a download to the file if user is authenticated
                    authenticatedUser &&
                      void addResourceDownload({
                        variables: { resourceId: resource.id, fileId: file.id },
                      })
                  },
                  // preview: () =>
                  //   filePreviewModal.show({ file, title: file.filename }),
                }}
                onClickAction="download"
              />
            ))}
            {resource.hyperlinks?.split(' ').map((link, idx) => (
              <HyperlinkItem
                key={`resource-header-hyperlink-${idx}`}
                url={link}
                onClick={() => window.open(link, '_blank')}
              />
            ))}
          </div>
          <div className={styles.sumup}>
            {t('core.resource.header.attachment_count', {
              nbLinks: resource.hyperlinks?.split(' ').length ?? 0,
              nbFiles: resource.files.length,
            })}
          </div>
          {!!resource.files.length && (
            <Button
              className={styles['download-all-button']}
              onClick={() =>
                window.open(
                  getResourceFilesDownloadUrl(resource.id, token),
                  '_blank',
                )
              }
            >
              {t('core.resource.header.download_button', {
                nbFiles: resource.files.length,
              })}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
