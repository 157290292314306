import { h, FunctionalComponent } from 'preact'

export const PreferenceItem: FunctionalComponent<{
  disabled: boolean
  value: boolean
}> = ({ disabled, value }) => {
  return (
    <i
      className={`text-sm ${
        value ? 'fas fa-check text-green' : 'fas fa-times text-red'
      } ${disabled ? 'opacity-50' : 'cursor-pointer'}`}
    />
  )
}
